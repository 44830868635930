import ToggleSwitchV2 from "../../../../components/common/toggle-switch/ToggleSwitchV2";
import { CALL_CENTER_ADMIN_AGENT_STATUS_UPDATE } from "../../../../constants/asyncActionsType";
import { success } from "../../../../constants/data";
import { useAppDispatch } from "../../../../hooks";
import {
  adminDashbordAgentStatusUpdate,
  getCallCenterDashbordAgent,
} from "../../../../redux/features/call-center-admin-dashbord/callCenterDashbordAsyncActions";
import { setMessage } from "../../../../redux/features/toast/toastSlice";
import { requestGenerator } from "../../../../utils/payloadGenerator";

export const AdminHeader: any[] = [
  {
    Header: () => {
      return (
        <div>
          <p>AGENT</p>
          <p>CODE</p>
        </div>
      );
    },
    accessor: "user_no",
  },
  {
    Header: "AGENT",
    accessor: "name",
  },
  {
    Header: () => {
      return (
        <div>
          <p>TOTAL</p>
          <p>LEADS</p>
        </div>
      );
    },
    accessor: "totalleads",
    Cell: ({ row }: any) => {
      return <>{row?.original?.leans_detail?.total_lead ?? 0}</>;
    },
  },
  {
    Header: () => {
      return (
        <div>
          <p>CALL</p>
          <p>LEADS</p>
        </div>
      );
    },
    accessor: "callleads",
    Cell: ({ row }: any) => {
      return <>{row?.original?.leans_detail?.call_count ?? 0}</>;
    },
  },
  {
    Header: () => {
      return (
        <div>
          <p>FOLLOW</p>
          <p>UP</p>
        </div>
      );
    },
    accessor: "followup",
    Cell: ({ row }: any) => {
      return <>{row?.original?.leans_detail?.followup_count ?? 0}</>;
    },
  },
  {
    Header: "REMAINING",
    accessor: "remaining",
    Cell: ({ row }: any) => {
      return <>{row?.original?.leans_detail?.remaining_count ?? 0}</>;
    },
  },
  {
    Header: "CONVERSION",
    accessor: "conversion",
    Cell: ({ row }: any) => {
      return <>{row?.original?.leans_detail?.conversion_count ?? 0}</>;
    },
  },
  {
    Header: "STATUS",
    Cell: (props: any) => {
      const dispatch = useAppDispatch();

      const handleStatus = (item: any) => {
        console.log(item, "status");

        const requestData = {
          id: item?._id,
          data: {
            is_active: item?.is_active ? false : true,
          },
        };

        const allAgentRequestData = {
          pageSize: 10,
          page: 1,
        };

        dispatch(
          adminDashbordAgentStatusUpdate(requestGenerator(requestData))
        ).then((e: any) => {
          if (e.type === `${CALL_CENTER_ADMIN_AGENT_STATUS_UPDATE}/fulfilled`) {
            dispatch(
              setMessage({
                message: "Status Updated Successfully",
                type: success,
              })
            );

            dispatch(
              getCallCenterDashbordAgent(requestGenerator(allAgentRequestData))
            );
          } else {
          }
        });
      };

      return (
        <>
          <div>
            <ToggleSwitchV2
              isToggled={props?.row?.original?.is_active}
              handleToggle={() => handleStatus(props?.row?.original)}
            />
          </div>
        </>
      );
    },
  },
  {
    Header: "DETAILS",
    Cell: (props: any) => {
      return (
        <>
          <span
            style={{
              color: "var(--blue1)",
              cursor: "pointer",
              fontSize: "var(--font-12)",
              fontWeight: "var(--font-normal)",
            }}
            onClick={() => props.onClick(props?.row?.original)}
          >
            VIEW
          </span>
        </>
      );
    },
  },
  {
    Header: "ASSIGN",
    Cell: (props: any) => {
      return (
        <>
          <span
            style={{
              color: "var(--blue1)",
              cursor: "pointer",
              fontSize: "var(--font-12)",
              fontWeight: "var(--font-normal)",
            }}
            onClick={() => props.onRowClick(props?.row?.original)}
          >
            VIEW
          </span>
        </>
      );
    },
  },
];

export const AdminRow: any[] = [
  {
    agentcode: "C-101",
    agent: "Vivinne King",
    languagesknown: "English, Arabic, Hindi",
    totalleads: "10",
    callleads: "10",
    followup: "10",
    remaining: "10",
    conversion: "10",
  },
  {
    agentcode: "C-101",
    agent: "Vivinne King",
    languagesknown: "English, Arabic, Hindi",
    totalleads: "10",
    callleads: "10",
    followup: "10",
    remaining: "10",
    conversion: "10",
  },
  {
    agentcode: "C-101",
    agent: "Vivinne King",
    languagesknown: "English, Arabic, Hindi",
    totalleads: "10",
    callleads: "10",
    followup: "10",
    remaining: "10",
    conversion: "10",
  },
  {
    agentcode: "C-101",
    agent: "Vivinne King",
    languagesknown: "English, Arabic, Hindi",
    totalleads: "10",
    callleads: "10",
    followup: "10",
    remaining: "10",
    conversion: "10",
  },
  {
    agentcode: "C-101",
    agent: "Vivinne King",
    languagesknown: "English, Arabic, Hindi",
    totalleads: "10",
    callleads: "10",
    followup: "10",
    remaining: "10",
    conversion: "10",
  },
];
