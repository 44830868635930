import { useEffect, useState } from 'react'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import Pagination from '../../../components/common/pagination/Pagination'

import { useAppSelector, useAppDispatch } from '../../../hooks'
import { trialBalanceTableHeaderData } from './trialBalanceTableData'
import Loader from '../../../components/common/spinner/Loader'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { getAllTrialBalance } from '../../../redux/features/trial-balance/trialBalanceAsyncActions'
import Popup from '../../../components/common/popup/Popup'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { Label } from '../../../components/common/label'
import { failure, monthOptions } from '../../../constants/data'
import TrialBalanceViewModal from './trial-balance- view-modal/TrialBalanceViewModal'
import styles from './trialBalance.module.scss'
import moment from 'moment'
import { SearchIcon } from '../../../components/common/svg-components'
import { Input } from '../../../components/common/input/input'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { transformDataWithTotal } from '../../../utils/utils'
// import { trialTetsdata } from '../../report-builder/data'

export interface Cols {}
const TrialBalance = () => {
  // const getDate = new Date();
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const firstDay = new Date(currentYear, 0, 1) // January 1st

  const [yearsFilter, setYearsFilter] = useState<any>({
    value: currentYear,
    label: currentYear,
  })
  const [monthsFilter, setMonthsFilter] = useState<any>(
    monthOptions.find((option) => option.value === currentMonth.toString())
  )
  // const [yearsFilter, setYearsFilter] = useState<any>("");
  // const [monthsFilter, setMonthsFilter] = useState<any>("");
  // const [maindata, setmainData] = useState<any[]>([])
  const [showViewBalancePopup, setShowViewBalance] = useState<boolean>(false)
  const [viewBalancePopData, setViewBalancePopData] = useState({})
  const [totalCreditAmount, setTotalCreditAmount] = useState(0)
  const [totalDebitAmount, setTotalDebitAmount] = useState(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [totalData, setTotalData] = useState(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [startDate, setStartdate] = useState<any>(
    moment(firstDay).format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState<any>(
    moment(currentDate).format('YYYY-MM-DD')
  )
  const animatedComponent = makeAnimated()
  const dispatch = useAppDispatch()
  const { isLoading, allTrialBalanceData } = useAppSelector(
    (state) => state.trialBalance
  )

  // For Table
  const data: Cols[] = allTrialBalanceData ?? []
  // const data: Cols[] = maindata ?? []
  const columns: Column<Cols>[] = trialBalanceTableHeaderData ?? []
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  // For Table
  const {
    // @ts-ignore
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore

  // Credit Amount
  useEffect(() => {
    const calculatedTotalCreditAmount = allTrialBalanceData?.reduce(
      (sum: any, row: any) => {
        const creditAmount = parseFloat(row?.credit_amount)
        return sum + creditAmount
      },
      0
    )

    setTotalCreditAmount(calculatedTotalCreditAmount)
  }, [allTrialBalanceData])
  // Debit Amount
  useEffect(() => {
    const calculatedTotaldebitAmount = allTrialBalanceData?.reduce(
      (sum: any, row: any) => {
        const debitAmount = parseFloat(row?.debit_amount)
        return sum + debitAmount
      },
      0
    )
    setTotalDebitAmount(calculatedTotaldebitAmount)
  }, [allTrialBalanceData])

  // Filter YEAR AND MONTH
  // const convertToFirstDate = getDate
  //   ?.toISOString()
  //   ?.split("-")?.[2]
  //   ?.split("")
  //   ?.slice(2)
  //   ?.join("");
  // const appendDate = `01${convertToFirstDate}`;

  // const fromDateFormat = `${yearsFilter?.value ?? yearsFilter}-${
  //   monthsFilter?.value ?? monthsFilter
  // }-${appendDate}`;

  /* Initial API call for trial Balance Sheet */

  const getData = () => {
    let endDateMoment = moment(endDate).local().endOf('days').format()
    let requestData = {
      is_active: 'true',
      search: '',
      // month: monthsFilter?.hasOwnProperty('value')
      //   ? Number(monthsFilter?.value)
      //   : undefined,
      // year: yearsFilter?.hasOwnProperty('value')
      //   ? yearsFilter?.value
      //   : undefined,
      range: {
        fromDate: new Date(startDate),
        // toDate: endDateMoment,
        toDate: new Date(endDate),
      },
      page: pageIndex,
      pageSize: dataPerPage,
    }
    if (startDate && endDate) {
      dispatch(getAllTrialBalance(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.totalData)
        }
      )
    } else {
      dispatch(
        setMessage({
          type: failure,
          message: 'Please select both From and End Date to Filter',
        })
      )
    }
  }
  useEffect(() => {
    getData()
    // const data: any[] = transformDataWithTotal(trialTetsdata)
    // setmainData(data)
  }, [dataPerPage, dispatch, pageIndex])
  const handleSearchData = () => {
    getData()
  }
  // Dynamic year
  const startYear = currentYear - 5
  let yearOptions = []

  for (let i = startYear; i <= currentYear; i++) {
    yearOptions?.push({ value: i, label: i })
  }

  const handleViewBalance = () => {
    setShowViewBalance((prevState) => !prevState)
  }
  const handleViewBalancePopup = (viewObject: {}) => {
    setShowViewBalance((prevState) => !prevState)
    setViewBalancePopData(viewObject)
  }

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const [isSubRowOpen, setIsSubRowOpen] = useState(false)

  const handleSubRowToggle = () => {
    setIsSubRowOpen(!isSubRowOpen)
  }
  return (
    <>
      {isLoading && <Loader />}
      {showViewBalancePopup && (
        <Popup
          Children={TrialBalanceViewModal}
          popData={viewBalancePopData}
          handleClose={() => handleViewBalance()}
        />
      )}
      <div className={styles.trialBalanceListContainer}>
        {/* Filters */}
        <form className={styles.trialBalanceFilter}>
          <div className={styles.formFields}>
            {/* Years */}
            <div className={styles.inputFieldContainer}>
              <Label
                htmlFor={'From'}
                labelText="From"
                customClass={styles.customLabel}
              />
              {/* <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">Select year</span>
                }
                isClearable={true}
                isSearchable={true}
                options={yearOptions}
                value={yearsFilter || ''}
                components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                styles={customDropdownStyles}
                onChange={(e: any) => {
                  setYearsFilter(e)
                }}
              /> */}
              <Input
                type="date"
                value={startDate}
                onChange={(e: any) => setStartdate(e.target.value)}
              />
            </div>
            {/* Months */}
            <div className={styles.inputFieldContainer}>
              <Label
                htmlFor={'TO'}
                labelText="To"
                customClass={styles.customLabel}
              />
              {/* <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">Select Month</span>
                }
                isClearable={true}
                isSearchable={false}
                options={monthOptions}
                value={monthsFilter || ''}
                components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                styles={customDropdownStyles}
                onChange={(e: any) => {
                  setMonthsFilter(e)
                }}
              /> */}
              <Input
                type="date"
                value={endDate}
                onChange={(e: any) => setEndDate(e.target.value)}
              />
            </div>
            <div
              className={styles.searchButton}
              onClick={() => handleSearchData()}
            >
              <SearchIcon fillColor={'#ffffff'} />
            </div>{' '}
          </div>
        </form>
        {/* Table */}
        <div className={styles.tableMainContainer}>
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              handleClick={handleViewBalancePopup}
              prepareRow={prepareRow}
              active={false}
              customClassForTh={styles.customStyleForTh}
            />
          </div>
          {/* Pagination */}
          {!!(totalData > 10) && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
        </div>
        {/* <div className={styles.tableLabelContainer}>
          <span className={styles.debitText}>
            Total Debit Amount: $ {totalDebitAmount}
          </span>
          <span className={styles.creditText}>
            Total Credit Amount: $ {totalCreditAmount}
          </span>
        </div> */}
      </div>
    </>
  )
}
export default TrialBalance
