import moment from 'moment'
import { useAppDispatch } from '../../hooks'
import { requestGenerator } from '../../utils/payloadGenerator'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { useNavigate } from 'react-router-dom'
import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { useState } from 'react'
import styles from './tableCustom.module.scss'
import {
  getAllDocument,
  updateDocumentStatus,
} from '../../redux/features/document/documentAsyncActions'
import { UPDATE_DOCUMENT_STATUS } from '../asyncActionsType'

export const employDocumentsTableHeaderData = [
  {
    Header: 'DOC ID',
    accessor: (row: any) => {
      return `${row.doc_id}`
    },
  },
  {
    Header: 'EMPLOYEE NAME',
    accessor: (row: any) => {
      if (row?.employees_name) {
        return `${row.employees_name}`
      } else if (row.company_name) {
        return `${row.company_name}`
      } else {
        return '-'
      }
    },
  },
  {
    Header: 'TYPE',
    disableSortBy: true,
    accessor: (row: any) => {
      return `${row.type}`
    },
  },
  {
    Header: 'EXPIRY DATE',
    accessor: (row: any) => {
      return moment(row?.expiry_date)?.format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: 'REMARKS',
    Cell: (props: any) => {
      const notesObject = {
        noteDetail: props?.row?.original?.remark,
      }
      return (
        <>
          {notesObject?.noteDetail ? (
            <span
              style={{
                color: 'var(--blue1)',
                cursor: 'pointer',
                fontSize: 'var(--font-12)',
                fontWeight: 'var(--font-normal)',
              }}
              onClick={() => props.onClick(notesObject)}
            >
              VIEW
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'FILE NAME',
    Cell: (props: any) => {
      const noteDetail = props?.row?.original?.attachements
      return (
        <>
          {noteDetail ? (
            <span
              style={{
                color: 'var(--blue1)',
                cursor: 'pointer',
                fontSize: 'var(--font-12)',
                fontWeight: 'var(--font-normal)',
              }}
              onClick={() => props.onRowClick(noteDetail)}
            >
              VIEW
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'STATUS',
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const is_active = row?.original?.status
      const [type, setType] = useState<string>('employee')
      const handleToggle = (item: any) => {
        const payload = {
          doc_id: item._id,
          status: !item.status,
        }
        dispatch(updateDocumentStatus(requestGenerator(payload))).then(
          (e: any) => {
            if (e.type === `${UPDATE_DOCUMENT_STATUS}/fulfilled`) {
              let payloadData = {
                type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
              }
              dispatch(getAllDocument(requestGenerator(payloadData)))
            }
          }
        )
      }
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => handleToggle(row?.original)}
        />
      )
    },
  },
  {
    Header: 'ACTIONS',
    Cell: ({ row, onOpen }: any) => {
      const handleDelete = () => {
        onOpen({
          isDeleteDialogOpen: true,
          doc_id: row?.original?._id,
        })
      }
      const navigate = useNavigate()
      const handleEdit = () => {
        if (row?.original?.status) {
          navigate('addnew', {
            state: { user: row?.original },
          })
        }
      }
      return (
        <>
          <div className={styles.docTableActions}>
            <EditIcon fillColor={colors.grey4} handleClick={handleEdit} />
            {/* <DeleteIcon
              fillColor={colors.grey4}
              customClass={styles.deleteIcon}
              handleClick={handleDelete} /> */}
          </div>
        </>
      )
    },
  },
]

export const companytDocumentsTableHeaderData = [
  {
    Header: 'EMP ID',
    accessor: (row: any) => {
      return `${row.doc_id}`
    },
  },
  {
    Header: 'COMPANY NAME',
    accessor: (row: any) => {
      if (row?.employees_name) {
        return `${row.employees_name}`
      } else if (row.company_name) {
        return `${row.company_name}`
      } else {
        return '-'
      }
    },
  },
  {
    Header: 'TYPE',
    disableSortBy: true,
    accessor: (row: any) => {
      return `${row.type}`
    },
  },
  {
    Header: 'EXPIRY DATE',
    accessor: (row: any) => {
      return moment(row?.expiry_date)?.format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: 'REMARKS',
    Cell: (props: any) => {
      const notesObject = {
        noteDetail: props?.row?.original?.remark,
      }
      return (
        <>
          {notesObject?.noteDetail ? (
            <span
              style={{
                color: 'var(--blue1)',
                cursor: 'pointer',
                fontSize: 'var(--font-12)',
                fontWeight: 'var(--font-normal)',
              }}
              onClick={() => props.onClick(notesObject)}
            >
              VIEW
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'FILE NAME',
    Cell: (props: any) => {
      const noteDetail = props?.row?.original?.attachements
      return (
        <>
          {noteDetail ? (
            <span
              style={{
                color: 'var(--blue1)',
                cursor: 'pointer',
                fontSize: 'var(--font-12)',
                fontWeight: 'var(--font-normal)',
              }}
              onClick={() => props.onRowClick(noteDetail)}
            >
              VIEW
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'STATUS',
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const is_active = row?.original?.status
      const [type, setType] = useState<string>('employee')
      const handleToggle = (item: any) => {
        const payload = {
          doc_id: item._id,
          status: !item.status,
        }
        dispatch(updateDocumentStatus(requestGenerator(payload))).then((e) => {
          if (e.type === `${UPDATE_DOCUMENT_STATUS}/fulfilled`) {
            let payloadData = {
              type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
            }
            dispatch(getAllDocument(requestGenerator(payloadData)))
          }
        })
      }
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => handleToggle(row?.original)}
        />
      )
    },
  },
  {
    Header: 'ACTIONS',
    Cell: ({ row, onOpen }: any) => {
      const handleDelete = () => {
        onOpen({
          isDeleteDialogOpen: true,
          doc_id: row?.original?._id,
        })
      }
      const navigate = useNavigate()
      const handleEdit = () => {
        if (row?.original?.status) {
          navigate('addnew', {
            state: { user: row?.original },
          })
        }
      }
      return (
        <>
          <div className={styles.docTableActions}>
            <EditIcon fillColor={colors.grey4} handleClick={handleEdit} />
            {/* <DeleteIcon
              fillColor={colors.grey4}
              customClass={styles.deleteIcon}
              handleClick={handleDelete} /> */}
          </div>
        </>
      )
    },
  },
]
