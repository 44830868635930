import { FC, useState } from 'react'
import Button from '../../../components/common/button/Button'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../../components/common/pagination/Pagination'
// import DeletePopup from '../../../components/common/modal/delete-popup/DeletePopup'
import {
  AnnualLeaveApplicationsTableDummyData,
  AnnualLeaveApplicationsTableHeaderData,
  LeavesEncashmentHistoryTableDummyData,
  LeavesEncashmentHistoryTableHeaderData,
} from './leaveEncashmentTableData'
import { trimValue } from '../../../utils/utils'
import {
  DropDownArrowIcon,
  DropDownIcon,
} from '../../../components/common/svg-components'
import {
  AMOUNT,
  DAYS,
  LEAVES_AVAILABLE,
  PERIOD,
  SALARY,
  SELECT_EMPLOYEE,
  SELECT_PAID_DATE,
  STATUS,
} from '../leave-encashment/leaveEncashmentConstant'
import { ILeaveEncashmentForm } from '../leave-encashment/leaveEncashmentInterface'
import { SubmitHandler, useForm } from 'react-hook-form'
import { leaveEncashmentValidators } from './leaveEncashmentValidator'
import styles from './leaveEncashment.module.scss'
import TableV2 from '../../../components/common/table/tableV2/TableV2'

interface ILeaveEncashment {}

export const employeeOptionsData = [
  'Employee 1',
  'Employee 2',
  'Employee 3',
  'Employee 4',
]
export const statusOptionsData = ['Paid', 'Unpaid']

const LeaveEncashment: FC<ILeaveEncashment> = () => {
  const navigate = useNavigate()
  const [disable, setDisable] = useState(true)
  const [showStatusArrow, setStatusArrow] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ILeaveEncashmentForm>({})
  const onSubmit: SubmitHandler<ILeaveEncashmentForm> = (data) => {
    navigate('/leave-management/leave-encashment/leave-formdata')
  }

  // Arrow
  const handleStatusArrow = () => {
    setStatusArrow((prevState) => !prevState)
  }

  // delete modal close
  const handleDeleteModalClose = () => {
    setShowDelete((prevState) => !prevState)
  }
  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  return (
    <>
      {/* {showDelete && (
        <Popup
          Children={DeletePopup}
          handleClose={() => handleDeleteModalClose()}
        />
      )} */}
      <div className={styles.leaveEncashmentMainContainer}>
        <div className={styles.leaveEncashmentContainer}>
          <div className={styles.leaveEncashmentTitle}>
            <p className={styles.leaveEncashmentTitleText}>
              Add Leaves Encashment
            </p>
          </div>
          {/* Add Leaves Encashment Form*/}
          <form
            className={styles.addLeavesEncashmentForm}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.formRowContainer}>
              {/* Select Employee */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={SELECT_EMPLOYEE} className={styles.formLabel}>
                    Select Employee
                    <span className="asterick">*</span>
                  </label>
                  <select
                    className={styles.inputField}
                    {...register(
                      SELECT_EMPLOYEE,
                      leaveEncashmentValidators[SELECT_EMPLOYEE]
                    )}
                  >
                    <option value="" selected disabled>
                      Select Employee
                    </option>
                    {employeeOptionsData?.map((lable, index) => (
                      <option value={lable} key={index}>
                        {lable}
                      </option>
                    ))}
                  </select>
                  <DropDownIcon
                    fillColor={'#797979'}
                    customClass={styles.downArrowStyle}
                  />
                </div>
                {errors[SELECT_EMPLOYEE] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[SELECT_EMPLOYEE].message}
                    </p>
                  </div>
                )}
              </div>
              {/* Salary */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={SALARY} className={styles.formLabel}>
                    Salary<span className="asterick">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      disable ? styles.disableInputField : styles.inputField
                    }
                    // className={styles.inputField}
                    placeholder="1000"
                    {...register(SALARY)}
                    onChange={(e) => trimValue(e)}
                    disabled={true}
                  />
                </div>
                {/* {errors[SALARY] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[SALARY].message}
                    </p>
                  </div>
                )} */}
              </div>
              {/* Period */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={PERIOD} className={styles.formLabel}>
                    Period<span className="asterick">*</span>
                  </label>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder="Enter Bank Name"
                    {...register(PERIOD, leaveEncashmentValidators[PERIOD])}
                    onChange={(e) => trimValue(e)}
                  />
                </div>
                {errors[PERIOD] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[PERIOD].message}
                    </p>
                  </div>
                )}
              </div>
              {/* Leaves Available */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label
                    htmlFor={LEAVES_AVAILABLE}
                    className={styles.formLabel}
                  >
                    Leaves Available<span className="asterick">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      disable ? styles.disableInputField : styles.inputField
                    }
                    placeholder="153"
                    {...register(LEAVES_AVAILABLE)}
                    onChange={(e) => trimValue(e)}
                    disabled={true}
                  />
                </div>
                {/* {errors[LEAVES_AVAILABLE] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[LEAVES_AVAILABLE].message}
                    </p>
                  </div>
                )} */}
              </div>
              {/* Days */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={DAYS} className={styles.formLabel}>
                    Days<span className="asterick">*</span>
                  </label>
                  <input
                    type="text"
                    className={styles.inputField}
                    placeholder="Enter Branch Location"
                    {...register(DAYS, leaveEncashmentValidators[DAYS])}
                    onChange={(e) => trimValue(e)}
                  />
                </div>
                {errors[DAYS] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">{errors[DAYS].message}</p>
                  </div>
                )}
              </div>
              {/* Amount */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={AMOUNT} className={styles.formLabel}>
                    Amount<span className="asterick">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      disable ? styles.disableInputField : styles.inputField
                    }
                    placeholder="0.00"
                    {...register(AMOUNT)}
                    onChange={(e) => trimValue(e)}
                    disabled={true}
                  />
                </div>
                {/* {errors[AMOUNT] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[AMOUNT].message}
                    </p>
                  </div>
                )} */}
              </div>
              {/* date */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label
                    htmlFor={SELECT_PAID_DATE}
                    className={styles.formLabel}
                  >
                    Salary<span className="asterick">*</span>
                  </label>
                  <input
                    type="date"
                    className={styles.inputField}
                    {...register(
                      SELECT_PAID_DATE,
                      leaveEncashmentValidators[SELECT_PAID_DATE]
                    )}
                    onChange={(e) => trimValue(e)}
                  />
                </div>
                {errors[SELECT_PAID_DATE] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[SELECT_PAID_DATE].message}
                    </p>
                  </div>
                )}
              </div>
              {/* Status */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={STATUS} className={styles.formLabel}>
                    Status
                  </label>
                  <select
                    className={styles.inputField}
                    onClick={handleStatusArrow}
                    {...register(STATUS, leaveEncashmentValidators[STATUS])}
                  >
                    <option value="" selected disabled hidden>
                      Select Status
                    </option>
                    {statusOptionsData?.map((lable, index) => (
                      <option value={lable} key={index}>
                        {lable}
                      </option>
                    ))}
                  </select>
                  {showStatusArrow ? (
                    <DropDownArrowIcon
                      fillColor={'#797979'}
                      customClass={styles.downArrowStyle}
                    />
                  ) : (
                    <DropDownIcon
                      fillColor={'#797979'}
                      customClass={styles.downArrowStyle}
                    />
                  )}
                </div>
                {errors[STATUS] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors[STATUS].message}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.addLeavesEncashmentBtnContainer}>
              <Button
                title="Submit"
                type="submit"
                customClass={styles.submitBtn}
              />
              <Button
                title="Cancel"
                type="button"
                handleClick={() => {
                  navigate('/leave-management')
                }}
                customClass={styles.CancelBtn}
              />
            </div>
          </form>
        </div>
        {/* table */}
        <div className={styles.tableMainContainer}>
          {/* Add Leaves Applications Table */}
          <div className={styles.tableContainer}>
            <div className={styles.tableTitle}>
              <p className={styles.tableTitleText}>Add Leaves Applications</p>
            </div>
            <TableV2
              tableHeaderData={AnnualLeaveApplicationsTableHeaderData}
              tableRowData={AnnualLeaveApplicationsTableDummyData}
              handleClick={handleDeleteModalClose}
            />

            {AnnualLeaveApplicationsTableDummyData &&
            AnnualLeaveApplicationsTableDummyData.length !== 0 ? (
              <Pagination
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            ) : (
              ''
            )}
          </div>
          {/* Leaves Encashment History Table */}
          <div className={styles.tableContainer}>
            <div className={styles.tableTitle}>
              <p className={styles.tableTitleText}>Leaves Encashment History</p>
            </div>
            <TableV2
              tableHeaderData={LeavesEncashmentHistoryTableHeaderData}
              tableRowData={LeavesEncashmentHistoryTableDummyData}
              handleClick={handleDeleteModalClose}
            />
            {LeavesEncashmentHistoryTableDummyData &&
            LeavesEncashmentHistoryTableDummyData.length !== 0 ? (
              <Pagination
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default LeaveEncashment
