export const REPORT_NAME = "report_name";
export const REPORT_ROLE = "report_role";
export const REPORT_LEVEL = 'report_level';
export const REPORT_LEVEL_TABLE = 'report_level_table';
export const REPORT_BASE_TABLE = "report_base_table";
export const REPORT_FILTER = 'report_filter';
export const REPORT_START_DATE = 'report_start_date';
export const REPORT_END_DATE = 'report_end_date';
export const REPORT_DATE_FILTER_BY = 'report_date_filter_by';
export const REPORT_BRANCH = 'report_branch';
export const REPORT_DOCTOR = 'report_doctor';
export const REPORT_PATIENT = 'report_patient';
export const REPORT_SORT = 'report_sort';
export const REPORT_SORTING_TYPE = 'report_sorting_type';

