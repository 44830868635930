import React, { FC, useEffect, useState } from 'react'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import {
  medicalCenterUpdateData,
  optionData,
  statusDropdownData,
  // searchModalRowData,
  // toDoData1,
} from '../../constants/data'
import { todayAppointmentHeaderData } from '../../constants/table-data/todayAppointmentData'
import { requestGenerator } from '../../utils/payloadGenerator'
import styles from './receptionist.module.scss'
import Loader from '../../components/common/spinner/Loader'
import {
  AddIconV2,
  AlarmIcon,
  DeleteIcon,
  DeleteIconV2,
  EditIcon,
  EditIconV2,
  FilterIcon,
  OfflineDoctorIcon,
  OnlineDoctorIcon,
  RatingStarIcon,
  SearchButton,
  TodoCheckIcon,
  TodoUnCheckIcon,
} from '../../components/common/svg-components'

import Divider from '../../components/common/divider/Divider'
import { Link } from 'react-router-dom'
import Popup from '../../components/common/popup/Popup'
import AddTodoModal from '../../components/common/modal/add-todo-modal/AddTodoModal'
import Button from '../../components/common/button/Button'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  deleteTodoList,
  getAllDoctorList,
  getAllMedicalCenterNews,
  getAllTodoList,
  updateTodoList,
} from '../../redux/features/receptionist/receptionistAsyncActions'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getAllTodayPatient } from '../../redux/features/appointment/appointmentAsyncActions'
import Pagination from '../../components/common/pagination/Pagination'
import { getPatientBranchList } from '../../redux/features/patient-emr/patient/patientAsyncAction'
import { doctorListData } from '../../constants/data'
import moment from 'moment'
import { handleBirthDateChange, trimValue } from '../../utils/utils'
import { getAllDoctors } from '../../redux/features/appointments/bookingAppointmentAsyncActions'
import { AVAILABLE_SLOT } from '../../constants/bookingConfirmationConstatnt'
import MedicalNewsModal from '../../components/common/modal/medical-news-modal/MedicalNewsModal'
import { socket } from '../../socket'
import Whatsapp from '../whatsapp/staff-chat/StaffChat'
import { sortBy } from 'lodash'
import StaffChat from '../whatsapp/staff-chat/StaffChat'
import AddReminderModal from '../../components/common/modal/add-reminder-modal/AddReminderModal'
import { getAllNotificationList } from '../../redux/features/app-notifications/appNotificationAsyncActions'
import { getTodayAppointmentReq } from '../../redux/features/patient-emr/patient/patientSlice'
import { colors } from '../../constants/color'
import NewsCard from '../../components/common/news-card/NewsCard'

const Receptionist: FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading, todayAppointmentData, todayAppointmentDoctorData } =
    useAppSelector((state) => state.appointment)
  const { loading, todoListData, medicalCenterNewsData, doctorListData } =
    useAppSelector((state) => state.receptionist)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [addModalData, setAddModalData] = useState({})
  const [showNewsModal, setShowNewsModal] = useState<boolean>(false)
  const [newsModalData, setNewsModalData] = useState({})
  const [selectedOption, setSelectedOption] = useState('today')
  const [selectedStatus, setSelectedStatus] = useState('All')

  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false)

  const { emrLoader } = useAppSelector((state) => state.patient)
  const [showAddReminderModal, setShowAddReminderModal] =
    useState<boolean>(false)
  const [addReminderModalData, setAddReminderModalData] = useState({})
  const { branchData } = useAppSelector((state) => state.login)
  let currentDate = moment(new Date()).format('YYYY-MM-DD')
  const [searchDate, setSearchDate] = useState(currentDate)

  let branche = branchData?.branches

  let branch_id = branche && branche.length > 0 && branche[0]?._id

  useEffect(() => {
    dispatch(getAllDoctorList(requestGenerator({ pageSize: 100 })))
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllNotificationList(requestGenerator({})))
  }, [])

  useEffect(() => {
    dispatch(getAllMedicalCenterNews(requestGenerator({})))
  }, [dispatch])

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  const [val, setVal] = useState('')
  const [branch, setBranch] = useState(branch_id)

  useEffect(() => {
    if (branch_id !== undefined) {
      let data = {
        page: pageIndex,
        pageSize: dataPerPage,
        doctor_id: val,
        branch_id: branch === undefined ? branch_id : branch,
        date: searchDate,
        status: selectedStatus.toUpperCase(),
        range: {
          fromDate: newStartDate,
          toDate: newEndDate,
        },
      }
      dispatch(getTodayAppointmentReq(data))
      dispatch(getAllTodayPatient(requestGenerator(data))).then((result) => {
        setTotalPage(result?.payload?.todaysAppointments?.lastPage)
      })
    }
  }, [dispatch, pageIndex, dataPerPage])

  const handleSelectChange = (e: any) => {
    setVal(e.target.value)
    let option = e.target.value
    if (option !== '' && branch_id !== undefined) {
      let data = {
        pageSize: dataPerPage,
        branch_id: branch === undefined ? branch_id : branch,
        doctor_id: option,
      }
      dispatch(getAllTodayPatient(requestGenerator(data))).then((result) =>
        setTotalPage(result?.payload?.todaysAppointments?.lastPage)
      )
    }
    if (option === '') {
      let data = {
        page: pageIndex,
        pageSize: dataPerPage,
        // branch_id: '6440c1d6f5baa851cd18a2f8',
        branch_id: branch === undefined ? branch_id : branch,
        doctor_id: '',
      }
      dispatch(getAllTodayPatient(requestGenerator(data))).then((result) =>
        setTotalPage(result?.payload?.todaysAppointments?.lastPage)
      )
    }
  }

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      padding: '10px',
    }),
    optionHover: (provided: any) => ({
      ...provided,

      margin: '0px !important',
    }),
  }

  // add modal close
  const handleModalClose = () => {
    setShowAddModal(false)
    setAddModalData({})
  }
  // handleAddModal
  const handleAddModal = () => {
    setShowAddModal(!showAddModal)
    setAddModalData({})
  }
  useEffect(() => {
    let payloadData = {
      page: 0,
      pageSize: 0,
      search: '',
      is_active: true,
    }
    dispatch(getAllTodoList(requestGenerator(payloadData)))
  }, [dispatch])

  // handleReadTodo
  const handleReadTodo = (item: any) => {
    let payloadData = {
      page: 0,
      pageSize: 0,
      search: '',
      is_active: true,
    }
    let id = item?._id

    dispatch(
      updateTodoList(
        requestGenerator({
          id,
          data: {
            title: item?.title,
            description: item?.description,
            priority: item?.priority,
            status: 'DONE',
          },
        })
      )
    ).then((e) => {
      if (e.type === 'receptionist/updateTodoList/fulfilled') {
        dispatch(getAllTodoList(requestGenerator(payloadData)))
      }
    })
  }

  // handleUnReadTodo
  const handleUnReadTodo = (item: any) => {
    let payloadData = {
      page: 0,
      pageSize: 0,
      search: '',
      is_active: true,
    }
    let id = item?._id

    dispatch(
      updateTodoList(
        requestGenerator({
          id,
          data: {
            title: item?.title,
            description: item?.description,
            priority: item?.priority,
            status: 'ACTIVE',
          },
        })
      )
    ).then((e) => {
      if (e.type === 'receptionist/updateTodoList/fulfilled') {
        dispatch(getAllTodoList(requestGenerator(payloadData)))
      }
    })
  }

  // handleEditModal
  const handleEditModal = (item: any) => {
    setShowAddModal(!showAddModal)
    setAddModalData(item)
  }

  // handleDeleteModal
  const handleDeleteModal = (item: any) => {
    let payloadData = {
      page: 0,
      pageSize: 0,
      search: '',
      is_active: true,
    }
    let deletPayloadData = {
      id: item?._id,
      data: {
        is_active: !item?.is_active,
      },
    }
    dispatch(deleteTodoList(requestGenerator(deletPayloadData))).then((e) => {
      if (e.type === 'receptionist/deleteTodoList/fulfilled') {
        dispatch(getAllTodoList(requestGenerator(payloadData)))
      }
    })
  }

  // covert utc format to time
  const handleLoginTime = (timestamp: any) => {
    const time = moment(timestamp).utcOffset(0, true).format('h:mm A')
    return time
  }

  const handleDate = (formatedDate: any) => {
    const utcFormatDate = moment(formatedDate).format('DD')
    return utcFormatDate
  }
  const handleMonth = (formatedDate: any) => {
    const utcFormatDate = moment(formatedDate).format('MMM')
    return utcFormatDate
  }

  // medical news modal close
  const handleNewsModalClose = () => {
    setShowNewsModal(false)
    setNewsModalData({})
  }
  // handleNewsModal
  const handleNewsModal = (item: any) => {
    setShowNewsModal(!showAddModal)
    setNewsModalData(item)
  }

  let myArray =
    todayAppointmentDoctorData &&
    todayAppointmentDoctorData?.length > 0 &&
    todayAppointmentDoctorData

  let vNew =
    todayAppointmentDoctorData &&
    todayAppointmentDoctorData?.length > 0 &&
    [...myArray].sort((a, b) => (a.dcts > b.dcts ? 1 : -1))

  const sortedData = sortBy(myArray, [(item) => item.dcts.charAt(0)])

  // add reminder modal close
  const handleReminderModalClose = () => {
    setShowAddReminderModal(false)
  }
  // handleAddReminderModal
  const handleAddReminderModal = () => {
    setShowAddReminderModal(!showAddReminderModal)
  }

  // handleReminderModal
  const handleReminderModal = (item: any) => {
    setShowAddReminderModal(!showAddModal)
    setAddReminderModalData(item)
  }

  function getMonthRange(dateString = '') {
    const date = dateString ? new Date(dateString) : new Date()
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') as any

    const startOfMonth = `${year}-${month}-01`
    const endOfMonth = `${year}-${month}-${new Date(year, month, 0).getDate()}`

    return `${startOfMonth} - ${endOfMonth}`
  }

  const getWeekRange = (dateString = '') => {
    let date
    if (dateString) {
      date = new Date(dateString)
    } else {
      date = new Date()
    }
    const dayOfWeek = date.getDay()

    // Calculate the difference between the selected date and the closest Sunday
    const diff = date.getDate() - dayOfWeek

    // Set the start of the week as Sunday
    const startOfWeek = new Date(date.setDate(diff))
    console.log('startof week ', startOfWeek)
    // Set the end of the week as Saturday
    const endOfWeek = new Date(date.setDate(date.getDate() + 6))
    console.log('endOfWeek  ', endOfWeek)

    // Format the start and end dates
    const startDateString = startOfWeek.toISOString().slice(0, 10)
    const endDateString = endOfWeek.toISOString().slice(0, 10)

    return `${startDateString} - ${endDateString}`
  }

  const getDateRange = () => {
    const today = new Date()
    const nextWeek = new Date()
    nextWeek.setDate(today.getDate() + 7)

    switch (selectedOption) {
      case 'weekly':
        let we = getWeekRange(searchDate)
        return we
      case 'monthly':
        let mm = getMonthRange(searchDate)
        return mm
      default:
        return ''
    }
  }

  const dateStrings = getDateRange().split(' - ')
  const newStartDate = dateStrings[0]
  const newEndDate = dateStrings[1]

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value)

    if (event.target.value === 'today') {
      const today = new Date()
      const year = today.getFullYear()
      const month = String(today.getMonth() + 1).padStart(2, '0')
      const day = String(today.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      setSearchDate(formattedDate)
    }
  }

  const handleStatusChange = (e: any) => {
    setSelectedStatus(e.target.value)
  }

  const handleSearch = () => {
    if (branch_id !== undefined) {
      let data = {
        page: 1,
        pageSize: 10,
        doctor_id: val,
        branch_id: branch === undefined ? branch_id : branch,
        date: searchDate,
        status: selectedStatus.toUpperCase(),
        range: {
          fromDate: newStartDate,
          toDate: newEndDate,
        },
      }
      dispatch(getTodayAppointmentReq(data))
      dispatch(getAllTodayPatient(requestGenerator(data))).then((result) => {
        setTotalPage(result?.payload?.todaysAppointments?.lastPage)
        setIsBranchDropdownOpen(false)
        setPageIndex(1)
      })
    }
  }

  useEffect(() => {
    if (searchDate !== currentDate && selectedOption === 'today') {
      setSelectedOption('Select')
    }
  }, [searchDate])

  const textWithLinks = (text: any) => {
    const regex = /(https?:\/\/[^\s]+)/g

    if (text.length > 100) {
      text = text.slice(0, 100) + '...'
    }

    const parts = text.split(regex)
    // console.log('parts', parts)
    return parts.map((part: any, index: any) => {
      if (part.match(regex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        )
      } else {
        return part
      }
    })
  }

  return (
    <>
      {/* {isLoading && loading && <Loader />} */}
      {loading && <Loader />}
      {isLoading && <Loader />}
      {emrLoader && <Loader />}
      {showAddModal && (
        <Popup
          Children={AddTodoModal}
          popData={addModalData}
          handleClose={() => handleModalClose()}
          setModelOpenClose={setShowAddModal}
        />
      )}

      {showAddReminderModal && (
        <Popup
          Children={AddReminderModal}
          popData={addReminderModalData}
          handleClose={() => handleReminderModalClose()}
          setModelOpenClose={setShowAddReminderModal}
        />
      )}

      {showNewsModal && (
        <Popup
          Children={MedicalNewsModal}
          popData={newsModalData}
          handleClose={() => handleNewsModalClose()}
        />
      )}
      <div
        className={styles.receptionistContainer}
        onClick={() => {
          if (isBranchDropdownOpen === true) {
            setIsBranchDropdownOpen(false)
          }
        }}
      >
        <div className={styles.tableContainer}>
          <div className={styles.todayAppointmentHeader}>
            <p className={styles.title}>Today's Appointments</p>
            <div className={styles.branchFilterContainer}>
              <div
                className={styles.filterIconContainer}
                onClick={() => {
                  setIsBranchDropdownOpen(true)
                }}
              >
                <FilterIcon fillColor=" #002C5A" />
              </div>
              {isBranchDropdownOpen && (
                <>
                  <div
                    className={styles.dropdownContainer}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <p className={styles.filter}>Filter</p>
                    <div className={styles.filterContainer}>
                      <p className={styles.filterText}>Date</p>
                      <input
                        type="date"
                        className={styles.selectContainer}
                        value={searchDate}
                        onChange={(e) => {
                          trimValue(e)
                          setSearchDate(e.target.value)
                          if (
                            e.target.value ===
                            moment(new Date()).format('YYYY-MM-DD')
                          ) {
                            setSelectedOption('today')
                          }
                        }}
                      />
                    </div>
                    <div className={styles.filterContainer}>
                      <p className={styles.filterText}>Period</p>
                      <select
                        className={styles.selectContainer}
                        onChange={handleOptionChange}
                        value={selectedOption}
                      >
                        <option value="Select">Select </option>
                        <option value="today">Today</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                      </select>
                    </div>
                    <div className={styles.filterContainer}>
                      <p className={styles.filterText}>Status</p>
                      <select
                        className={styles.selectContainer}
                        onChange={handleStatusChange}
                        value={selectedStatus}
                      >
                        {statusDropdownData?.map((item: any) => {
                          return (
                            <option value={item?.value}>{item?.name}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className={styles.filterContainer}>
                      <p className={styles.filterText}>Branch</p>
                      <select
                        className={styles.selectContainer}
                        value={branch}
                        onChange={(e) => {
                          setBranch(e.target.value)
                        }}
                      >
                        {branche &&
                          branche.length > 0 &&
                          branche.map((item: any, i: number) => {
                            return (
                              <option value={item?._id} key={i}>
                                {item?.name}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                    <div className={styles.filterContainer}>
                      <p className={styles.filterText}>Doctor</p>
                      <select
                        name="Select All"
                        className={styles.selectContainer}
                        onChange={(e: any) => setVal(e.target.value)}
                        value={val}
                      >
                        <option value=""> All Doctors</option>
                        {vNew &&
                          vNew.length > 0 &&
                          vNew.map((item: any, i: number) => {
                            return (
                              <option value={item?._id} key={i}>
                                {item?.dcts}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                    <div className={styles.btnContainer}>
                      <Button
                        title="Apply"
                        customClass={styles.applyBtn}
                        handleClick={() => {
                          handleSearch()
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <TableV2
              tableHeaderData={todayAppointmentHeaderData}
              tableRowData={todayAppointmentData}
            />
            {todayAppointmentData?.length > 0 && (
              <Pagination
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            )}
          </div>
        </div>
        <div className={styles.chat} id="chat">
          <span className={styles.chatText}>Staff Chat</span>
          <StaffChat />
        </div>
        <div className={styles.staffInfoContainer}>
          {/* todo container */}
          <div className={styles.todoContainer}>
            <div className={styles.mainContainerTodo}>
              <div className={styles.todoHeaderContainer}>
                <p className={styles.todoTitle}>To-Do List</p>
                <p
                  className={styles.addNewStyle}
                  onClick={() => handleAddModal()}
                >
                  <AddIconV2 fillColor={colors.white1} />
                </p>
              </div>
              <Divider customClass={styles.divider} />
              <div className={styles.container}>
                {todoListData.length > 0 ? (
                  todoListData?.map((item: any, i: any) => {
                    return (
                      <React.Fragment key={i}>
                        <div className={styles.notifyContainer}>
                          <div className={styles.nContainer}></div>
                          {item?.status === 'ACTIVE' ? (
                            <div
                              className={styles.unReadStyle}
                              // onClick={() => {
                              //   item?.title === "Remarks Notification"
                              //     ? handleReadNotificationRemark(item)
                              //     : handleReadNotification(item);
                              // }}
                            >
                              <div className={styles.titleStatusContainer}>
                                <div
                                  className={styles.titleStatusStyleContainer}
                                >
                                  <div>
                                    <TodoUnCheckIcon
                                      customClass={styles.iconStyle}
                                      handleClick={() => handleReadTodo(item)}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      width: '100%',
                                      padding: '0px 10px 0px 0px',
                                    }}
                                  >
                                    <h5 className={styles.titleStyle}>
                                      {item?.title}
                                    </h5>
                                  </div>
                                </div>

                                <div className={styles.alarmPriorityContainer}>
                                  <div
                                    className={
                                      styles.priorityListStyleContainer
                                    }
                                  >
                                    {item?.priority === 'MEDIUM' ? (
                                      <div
                                        className={styles.mediumPriorityStyle}
                                      >
                                        Medium
                                      </div>
                                    ) : item?.priority === 'HIGH' ? (
                                      <div className={styles.highPriorityStyle}>
                                        High
                                      </div>
                                    ) : item?.priority === 'LOW' ? (
                                      <div className={styles.lowPriorityStyle}>
                                        Low
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <AlarmIcon
                                    fillColor="
                                    #0BD26E"
                                    customClass={styles.alarmIcon}
                                    handleClick={() =>
                                      handleReminderModal(item)
                                    }
                                  />
                                </div>
                              </div>
                              <p className={styles.textStyle}>
                                {item?.description}
                              </p>
                              <Divider customClass={styles.divider} />
                              <div className={styles.buttonContainer}>
                                {/* <Button
                                  title="Edit"
                                  customClass={styles.editButtonStyle}
                                  handleClick={() => handleEditModal(item)}
                                />
                                <Button
                                  title="Delete"
                                  customClass={styles.deleteButtonStyle}
                                  handleClick={() => handleDeleteModal(item)}
                                /> */}
                                <EditIconV2
                                  fillColor="#002C59"
                                  width={15}
                                  height={15}
                                  customClass={styles.editButtonStyle}
                                  handleClick={() => handleEditModal(item)}
                                />
                                <DeleteIconV2
                                  fillColor="#002C59"
                                  width={15}
                                  height={15}
                                  customClass={styles.deleteButtonStyle}
                                  handleClick={() => handleDeleteModal(item)}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className={styles.isReadStyle}>
                              <div className={styles.titleStatusContainer}>
                                <div
                                  className={styles.titleStatusStyleContainer}
                                >
                                  <div>
                                    <TodoCheckIcon
                                      customClass={styles.iconStyle}
                                      handleClick={() => handleUnReadTodo(item)}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: '100%',
                                      padding: '0px 10px 0px 0px',
                                    }}
                                  >
                                    <h5 className={styles.titleStyle}>
                                      {item?.title}
                                    </h5>
                                  </div>
                                </div>
                                <div className={styles.alarmPriorityContainer}>
                                  <div
                                    className={
                                      styles.priorityListStyleContainer
                                    }
                                  >
                                    {item?.priority === 'MEDIUM' ? (
                                      <div
                                        className={styles.mediumPriorityStyle}
                                      >
                                        Medium
                                      </div>
                                    ) : item?.priority === 'HIGH' ? (
                                      <div className={styles.highPriorityStyle}>
                                        High
                                      </div>
                                    ) : item?.priority === 'LOW' ? (
                                      <div className={styles.lowPriorityStyle}>
                                        Low
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                              <p className={styles.textStyle}>
                                {item?.description}
                              </p>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <p className={styles.noRecordTextStyle}>No task available</p>
                )}
              </div>
            </div>
          </div>
          {/* medicalCenter news container  */}
          <div className={styles.medicalCenterContainer}>
            <div className={styles.medicalCenterMainContainer}>
              <div className={styles.medicalCenterHeaderContainer}>
                <p className={styles.medicalCenterTitle}>Medical Center News</p>
              </div>
              <Divider customClass={styles.divider} />
              <div className={styles.newsContainer}>
                <NewsCard />
                <div className={styles.container}>
                  {medicalCenterNewsData.length > 0 ? (
                    medicalCenterNewsData?.map((item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={styles.mcUpdatecontainer}>
                            <div className={styles.datecontainer}>
                              <div className={styles.dateStyleText}>
                                {handleDate(item?.createdAt)}
                              </div>
                              <h4 className={styles.monthStyleText}>
                                {handleMonth(item?.createdAt)}
                              </h4>
                            </div>
                            <div className={styles.updatenewscontainer}>
                              <p className={styles.title}>{item?.title}</p>
                              <p className={styles.description}>
                                {textWithLinks(item?.description)}
                              </p>
                              <p className={styles.linkStyle}>
                                {item?.description.length > 100 ? (
                                  <p
                                    onClick={() => {
                                      handleNewsModal(item)
                                    }}
                                    className={styles.linkStyle}
                                  >
                                    Read More
                                  </p>
                                ) : (
                                  ''
                                )}
                              </p>
                              {/* <p className={styles.description}>
                                {item?.description.length > 100
                                  ? item?.description.slice(0, 100) + '...'
                                  : item?.description}
                              </p>
                              <p className={styles.linkStyle}>
                                {item?.description.length > 100 ? (
                                  <p
                                    onClick={() => {
                                      handleNewsModal(item)
                                    }}
                                    className={styles.linkStyle}
                                  >
                                    Read More
                                  </p>
                                ) : (
                                  ''
                                )}
                              </p> */}
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })
                  ) : (
                    <p className={styles.noRecordTextStyle}>No news found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.doctorMainContainer}>
          <p className={styles.doctortitle}>Doctor</p>
          <div className={styles.doctorContainer}>
            {doctorListData.length > 0 ? (
              doctorListData?.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div className={styles.container}>
                      {/* <div className={styles.activeDocContainer}>
                        {item?.is_active === true ? (
                          <OnlineDoctorIcon />
                        ) : (
                          <OfflineDoctorIcon />
                        )}
                      </div> */}
                      <div className={styles.imageContainer}>
                        <img
                          src={item?.profile_pic}
                          style={{
                            width: '100%',
                            height: ' 84px',
                            borderRadius: '10px',
                          }}
                          alt=""
                        />
                      </div>
                      <div className={styles.doctorDetailsContainer}>
                        <div className={styles.ratingStyle}>
                          <span>
                            <RatingStarIcon />
                          </span>
                          <p className={styles.ratingTextStyle}>
                            {item?.avg_rating}
                          </p>
                        </div>
                        <p className={styles.doctorNameStyle}>
                          {item?.doctor_name}
                        </p>
                        <p className={styles.loginTimeStyle}>
                          <span className={styles.loginTextStyle}>
                            Login Time -
                          </span>
                          <span
                            style={{
                              fontWeight: '600',
                              fontSize: '12px',
                              paddingLeft: '5px',
                            }}
                          >
                            {item?.last_login
                              ? handleLoginTime(item?.last_login)
                              : 'NA'}
                          </span>
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            ) : (
              <p className={styles.noRecordTextStyle}>No doctor found</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Receptionist
