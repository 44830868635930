import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './documents.module.scss'

import Button from '../../../components/common/button/Button'
import { SearchIcon } from '../../../components/common/svg-components'
import Pagination from '../../../components/common/pagination/Pagination'
import Popup from '../../../components/common/popup/Popup'
import FilePopup from '../Documents-Popup/FilenamePopup'
import RemarksPopup from '../Documents-Popup/RemarksPopuo'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loader from '../../../components/common/spinner/Loader'
import { Cols } from '../../employees/employeeInterface'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { getAllDocument } from '../../../redux/features/document/documentAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
// import DeletePopup from '../../../components/common/modal/delete-popup/DeletePopup'
import { trimValue } from '../../../utils/utils'
import {
  companytDocumentsTableHeaderData,
  employDocumentsTableHeaderData,
} from '../../../constants/table-data/hrDocumentsTableData'

const Documnets = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showFilePopup, setShowFilePopup] = useState<boolean>(false)
  const [showRemarksPopup, setShowRemarksPopup] = useState<boolean>(false)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [deletePopData, setDeletePopData] = useState({})
  const [notesPopData, setNotesPopData] = useState({})
  const [filesPopData, setFilesPopData] = useState({})
  const [type, setType] = useState<string>('employee')
  const [searchValue, setSearchValue] = useState<string>()
  const [totalData, setTotalData] = useState(0)
  // Redux state
  const { isLoading, allDocumentData } = useAppSelector(
    (state) => state.document
  )

  // For Table
  const data: Cols[] = allDocumentData
  const columns: Column<Cols>[] =
    type === 'employee'
      ? employDocumentsTableHeaderData
      : companytDocumentsTableHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)

  // API call for Get All Document
  useEffect(() => {
    let payloadData = {
      page: pageIndex,
      pageSize: dataPerPage,
      order_by: { is_default: -1 },
      type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
    }
    dispatch(getAllDocument(requestGenerator(payloadData))).then(
      (result: any) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [dispatch, dataPerPage, pageIndex, deletePopData, type])

  // Page index options for pagination
  const pageIndexArray = useCallback(() => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }, [totalPage])

  const pageIndexOptions = pageIndexArray()

  // Handlers
  const handlefile = (notesObject: {}) => {
    setShowFilePopup((prevState) => !prevState)
    setFilesPopData(notesObject)
  }

  const handlefiles = () => {
    setShowFilePopup((prevState) => !prevState)
  }

  const handleremarks = () => {
    setShowRemarksPopup((prevState) => !prevState)
  }

  const handleremark = (notesObject: {}) => {
    setShowRemarksPopup((prevState) => !prevState)
    setNotesPopData(notesObject)
  }

  const onTypeChange = (e: any) => {
    setType(e.target.value === 'EMPLOYEE' ? 'employee' : 'company')
  }

  const handleDeleteModalClose = () => {
    setShowDelete((prevState) => !prevState)
  }

  const handleDeleteModalOpen = (id: any) => {
    setDeletePopData(id)
    setShowDelete((prevState) => !prevState)
  }

  const handleSearch = () => {
    setPageIndex(1)
    let requestData = {
      name: searchValue,
      page: pageIndex,
      pageSize: dataPerPage,
      sub_company: searchValue,
      type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
    }
    dispatch(getAllDocument(requestGenerator(requestData))).then(
      (result: any) => setTotalPage(result.payload.lastPage)
    )
  }
  useEffect(() => {
    if (searchValue === '') {
      setGlobalFilter('')
      const requestData = {
        name: searchValue,
        page: pageIndex,
        pageSize: dataPerPage,
        order_by: { is_default: -1 },
        type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
      }
    }
  }, [dispatch, searchValue, dataPerPage, pageIndex])
  useEffect(() => {
    if (searchValue === '') {
      setGlobalFilter('')
      const requestData = {
        name: searchValue,
        page: pageIndex,
        pageSize: dataPerPage,
        order_by: { is_default: -1 },
        type: type === 'employee' ? 'EMPLOYEE' : 'COMPANY',
      }
    }
  }, [dispatch, searchValue, dataPerPage, pageIndex])

  return (
    <>
      {isLoading && <Loader />}
      {/* {showDelete && (
        <Popup
          Children={DeletePopup}
          popData={deletePopData}
          handleClose={() => handleDeleteModalClose()}
        />
      )} */}
      {showFilePopup && (
        <Popup
          Children={FilePopup}
          popData={filesPopData}
          handleClose={() => handlefiles()}
        />
      )}
      {showRemarksPopup && (
        <Popup
          Children={RemarksPopup}
          popData={notesPopData}
          handleClose={() => handleremarks()}
        />
      )}

      <div className={styles.documentsContainer}>
        <div className={styles.searchContainer}>
          <div className={styles.searchMainContainer}>
            <div className={styles.searchContainer}>
              <input
                className={styles.searchInput}
                type="text"
                placeholder="Search By Name"
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  setGlobalFilter('')
                  trimValue(e)
                }}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
              <div
                className={styles.searchButton}
                onClick={() => handleSearch()}
              >
                <SearchIcon fillColor={'#ffffff'} />
              </div>
            </div>
          </div>
          <div className={styles.radioFieldContainer}>
            <p className={styles.radioFieldName}>Type :</p>
            <div className={styles.radiogroup}>
              <input
                className={styles.radioInput}
                type="radio"
                id="Radio"
                value="EMPLOYEE"
                checked={type === 'employee'}
                onChange={onTypeChange}
              />
              <p
                className={
                  type === 'employee' ? styles.selectText : styles.radioLabel
                }
              >
                Employee
              </p>
              <input
                className={styles.radioInput}
                type="radio"
                value="COMPANY"
                onChange={onTypeChange}
                checked={type === 'company'}
              />
              <p
                className={
                  type === 'company' ? styles.selectText : styles.radioLabel
                }
              >
                Company
              </p>
            </div>
          </div>
          <div className={styles.searchBtnContainer}>
            <Button
              title={'Add New Document'}
              customClass={styles.btnContainer}
              type="button"
              handleClick={() => {
                navigate('addnew')
              }}
            />
          </div>
        </div>
        <TableV3
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          handleClick={handleremark}
          handleRowClick={handlefile}
          handleOpen={handleDeleteModalOpen}
        />

        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}
export default Documnets
