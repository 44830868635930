import { FC, useState, useEffect } from 'react'
import styles from './purchaseinvoice.module.scss'
import Search from '../../../components/common/search/Search'
import Select from 'react-select'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import {
  pendingGrnHeaderData,
  settledGrnHeaderData,
} from '../../../constants/table-data/pendingGrnTableData'
import Popup from '../../../components/common/popup/Popup'
import ConfirmPurchaseInvoicePopup from '../confirm-purchase-invoice-popup/ConfirmPurchaseInvoicePopup'
import SearchDropDown from '../../../components/common/search-dropdown/SearchDropDown'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getAllGrn,
  getAllSupplierDetails,
} from '../../../redux/features/purchase-invoice/purchaseAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { debounce } from 'lodash'
import Pagination from '../../../components/common/pagination/Pagination'
import AttachfilesV2 from '../../../components/common/attach-files/AttachfilesV2'
import { fileType } from '../../../interfaces/interfaces'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { failure } from '../../../constants/data'
import {
  clearConformPurchaseInvoiceList,
  clearGrnList,
} from '../../../redux/features/purchase-invoice/purchaseInvoiceSlice'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import SubmittedPruchaseInvoice from '../submitted-purchase-invoice/page'
import { trimValue } from '../../../utils/utils'
import Loader from '../../../components/common/spinner/Loader'

const PurchaseInvoice = () => {
  const [selectedOption, setSelectedOption] = useState<any>('option2')
  const [purchasePopup, setPurchasePopup] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [piModal, setPiModal] = useState(false)
  const [supplierDeatils, setsupplierDeatils] = useState<any>({
    supplier_id: undefined,
    date: undefined,
  })

  const [invoicePayload, setinvoicePayload] = useState<any>('')
  const [attachments, setAttachments] = useState<fileType[]>([])
  const [error, setError] = useState('')
  const [dataPerPage, setDataPerPage] = useState<number>(5)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)

  const dispatch = useAppDispatch()
  const { suppliersInfo, grnList, conformPurchaseInvoiceList, isLoading } =
    useAppSelector((state) => state.purchaseInvoice)
  const { masterValueData } = useAppSelector((state) => state.login)

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // Display Pending and Settled GRN table
  const handleOptionChange = (e: any) => {
    const value = e.target.value
    setSelectedOption(value === selectedOption ? null : value)
  }

  // Close Confirm PurchaseInvoice Popup
  const handleModalClose1 = () => {
    setPurchasePopup(!purchasePopup)
  }

  // Confirm PurchaseInvoice Popup
  const handleConformInvoicePopup = () => {
    if (invoicePayload?.doc_no) {
      setPurchasePopup(!purchasePopup)
    } else {
      dispatch(
        setMessage({
          message: "Supplier's Invoice no is Required",
          type: failure,
        })
      )
    }
  }

  // Validation Fun for Suppler Doc No
  const validateInput = (inputValue: any) => {
    const pattern = /^(\d|\w)+$/
    return pattern.test(inputValue)
  }

  // set Supplier Doc No
  const handleDocumentNo = (e: any) => {
    const docNum = e.target.value
    const isValid = validateInput(docNum)
    if (isValid === false) {
      setError(`Please enter supplier's invoice no.`)
      setinvoicePayload({ ...invoicePayload, doc_no: '' })
    } else {
      setinvoicePayload({ ...invoicePayload, doc_no: docNum })
      setError('')
    }
  }

  const currencyData: any = [
    {
      // id: 0,
      value: 'USD',
    },
    {
      // id: 1,
      value: 'DOLLAR',
    },
  ]

  //  All Supplier Name API
  useEffect(() => {
    let data = {
      name: searchString,
    }
    setsupplierDeatils({ ...supplierDeatils, supplier_id: undefined })
    dispatch(clearGrnList())
    dispatch(getAllSupplierDetails(requestGenerator(data)))
  }, [searchString])

  //  All GRN List API
  useEffect(() => {
    if (supplierDeatils?.supplier_id !== undefined) {
      dispatch(clearConformPurchaseInvoiceList())

      const requestData = {
        invoice_status: selectedOption === 'option2' ? 'PENDING' : 'GENERATED',
        page: pageIndex,
        pageSize: dataPerPage,
        ...supplierDeatils,
      }
      dispatch(getAllGrn(requestGenerator(requestData))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }, [pageIndex, dataPerPage, supplierDeatils, selectedOption])

  //  Debounce All GRN list api
  const deb = debounce((text) => setsupplierDeatils(text), 1000)

  let name, value
  const handleGrnList = (e: any) => {
    name = e.target?.name
    value = e.target?.value
    deb({ ...supplierDeatils, [name]: value ? value : undefined })
  }

  // Supplier doc num and Attechment for Invoice Api
  useEffect(() => {
    setinvoicePayload({ ...invoicePayload, file: attachments })
  }, [attachments])

  return (
    <>
      {isLoading && <Loader />}
      {purchasePopup && (
        <Popup
          Children={ConfirmPurchaseInvoicePopup}
          handleClose={() => handleModalClose1()}
          popData={invoicePayload}
          setModelOpenClose={setAttachments}
        />
      )}
      <CustomModal
        title="Submitted PI"
        showModal={piModal}
        closeModal={() => setPiModal(false)}
        width="70dvw"
        height="85dvh"
      >
        <SubmittedPruchaseInvoice />
      </CustomModal>
      <div className={styles.purchaseInvoiceMainContainer}>
        <div className={styles.searchContainer}>
          {/* Supplier Name */}
          <span className={styles.search}>
            <p className={styles.text}>Supplier Name</p>
            <SearchDropDown
              placeholder="Supplier Name"
              searchString={searchString}
              setSearchString={setSearchString}
              dropdownDataToSee={suppliersInfo}
              dropDownKeyName="name"
              customClass={styles.search}
              handleClick={(item: any, setVal: any, setShowDropdown: any) => {
                setVal(item?.name)
                setsupplierDeatils({
                  ...supplierDeatils,
                  supplier_id: item?._id,
                })
                setShowDropdown(false)
              }}
              customClassdropdownItem={styles.dropDownItem}
            />
          </span>

          {/*  Date  */}
          <span className={styles.dateSearch}>
            <p className={styles.text}> Date</p>
            <input
              type="date"
              name="date"
              className={styles.inputSearch}
              onChange={(e) => handleGrnList(e)}
            />
          </span>
        </div>

        {/*  Supplier Doc No */}
        {selectedOption === 'option2' ? (
          <div className={styles.searchContainer2}>
            <span className={styles.search}>
              <p className={styles.text}>Supplier's Invoice no.</p>
              <span className="asterick">*</span>
              <div>
                <input
                  type="text"
                  placeholder="Supplier's Invoice no."
                  className={styles.inputSearch}
                  name="doc_no"
                  onChange={(e) => {
                    handleDocumentNo(e)
                    trimValue(e)
                  }}
                />
                <p className="dashboardFormError" style={{ marginLeft: 15 }}>
                  {error}
                </p>
              </div>
            </span>

            <div className={styles.dropDownContainer}>
              <span className={styles.dropDownLabel}> Currency</span>
              <Select
                className={styles.select}
                placeholder="Select Currency" //masterValueData
                closeMenuOnSelect={true}
                isSearchable={true}
                // options={currencyData?.map((item: any) => ({
                //   label: item?.value,
                //   value: item?.value,
                // }))}

                options={masterValueData
                  ?.find((item: any) => {
                    return item?.category_name === 'CURRENCY'
                  })
                  ?.values?.map((item: any) => ({
                    label: item?.value,
                    value: item?.value,
                  }))}
                maxMenuHeight={200}
              />
            </div>

            <div className={styles.textFieldContainer}>
              <label className={styles.text}> Conversion</label>
              <input
                type="text"
                placeholder="Conversion"
                className={styles.inputSearch}
                onChange={(e) => trimValue(e)}
              />
            </div>
          </div>
        ) : (
          <div className={styles.searchContainer2}>
            <span className={styles.search}>
              <p className={styles.text}>Invoice No</p>
              <Search
                customClassInput={styles.inputSearch}
                placeHolder="Invoice No"
                customSerachIconStyle={styles.searchIconStyle}
              />
            </span>
          </div>
        )}

        <div className={styles.radioContainer}>
          <div className={styles.internalRadio}>
            <input
              className={styles.radioInput}
              type="radio"
              value="option2"
              checked={selectedOption === 'option2'}
              onChange={handleOptionChange}
            />
            <label
              className={
                selectedOption === 'option2'
                  ? styles.internalText
                  : styles.disableText
              }
            >
              Pending GRN
            </label>
          </div>
          <div className={styles.externalRadio}>
            <input
              className={styles.radioInput}
              type="radio"
              name="options"
              value="option1"
              checked={selectedOption === 'option1'}
              onChange={handleOptionChange}
            />
            <label
              className={
                selectedOption === 'option1'
                  ? styles.externalText
                  : styles.disableText
              }
            >
              Settled GRN
            </label>
          </div>

          <div className={styles.attechmentContainer}>
            <p className={styles.text}> Attachments</p>
            <AttachfilesV2
              // fileKey='file'
              isMultiSelect={true}
              // {...register(EMR_ATTACHMENTS)}
              // setValue={setValue}
              attachments={attachments}
              setAttachments={setAttachments}
              customClassFileName={styles.fileNameStyle}
            />
          </div>
        </div>
        <Button
          title="Submitted PI"
          handleClick={() => setPiModal(true)}
          customClass={styles.submittedBtn}
        />
      </div>

      {selectedOption === 'option2' ? (
        <div className={styles.tableMainContainer}>
          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={pendingGrnHeaderData}
              tableRowData={grnList}
              active={false}
            />

            {totalData > 10 && (
              <Pagination
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageSize={dataPerPage}
              />
            )}

            <div className={styles.submitbtn}>
              <Button
                title="Generate Purchase Invoice"
                type="button"
                customClass={styles.reqSubmit}
                handleClick={handleConformInvoicePopup}
                disable={!conformPurchaseInvoiceList.length}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.tableMainContainer}>
          <div className={styles.tableContainer1}>
            <TableV2
              tableHeaderData={settledGrnHeaderData}
              tableRowData={grnList}
              active={false}
            />
          </div>
        </div>
      )}
    </>
  )
}
export default PurchaseInvoice
