import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import Button from '../../../../components/common/button/Button'
import Select from 'react-select'
import { DropdownIndicator } from '../../../../components/common/dropdown-indicator/DropdownIndicator'
import { Input } from '../../../../components/common/input/input'
import styles from './manageWard.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  addWards,
  editWard,
  getWardById,
} from '../../../../redux/features/ward/wardAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useLocation } from 'react-router-dom'
import Loader from '../../../../components/common/spinner/Loader'
import { getAllDepartmentDropdownData } from '../../../../redux/features/department/departmentAsyncActions'
import { trimValue } from '../../../../utils/utils'
import { reactSelectStyle } from '../../../../constants/data'

const ManageWard: FC = () => {
  const navigate = useNavigate()
  const { isLoading, wardInfo } = useAppSelector((state) => state.ward)
  const { departmentDropdownData } = useAppSelector((state) => state.department)

  const dispatch = useAppDispatch()
  const location = useLocation().state
  const checkLocationIdExist = location?.hasOwnProperty?.('id')

  useEffect(() => {
    let reqData = {
      is_active: true,
      page: 1,
      pageSize: 1000,
    }
    dispatch(getAllDepartmentDropdownData(requestGenerator(reqData)))
  }, [dispatch])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>()

  const watchDepartnment = watch('department')

  const onSubmit = (data: any) => {
    if (!checkLocationIdExist) {
      let responseData = {
        department_id: data?.department?.value,
        ward_name: data?.wardName,
        notes: data?.notes,
      }
      dispatch(addWards(requestGenerator(responseData))).then((result) => {
        if (result.type === 'ward/addWards/fulfilled') {
          reset()
          setValue('department', '')
          navigate('/medicalcenter/ward')
        }
      })
    } else {
      let responseData = {
        id: location?.id,
        data: {
          department_id: data?.department?.value,
          ward_name: data?.wardName,
          notes: data?.notes,
        },
      }
      dispatch(editWard(requestGenerator(responseData))).then((result) => {
        if (result.type === 'ward/editWard/fulfilled') {
          navigate('/medicalcenter/ward')
        }
      })
    }
  }

  useEffect(() => {
    if (checkLocationIdExist) {
      dispatch(getWardById(requestGenerator({ id: location?.id })))
    }
  }, [checkLocationIdExist, dispatch, location])

  useEffect(() => {
    if (checkLocationIdExist) {
      setValue('wardName', wardInfo?.ward_name)
      setValue('notes', wardInfo?.notes)
      setValue('department', {
        label: wardInfo?.department_id?.name,
        value: wardInfo?.department_id?._id,
      })
    } else {
      setValue('wardName', '')
      setValue('notes', '')
      setValue('department', null)
    }
  }, [
    checkLocationIdExist,
    location,
    setValue,
    wardInfo?.department_id?._id,
    wardInfo?.department_id?.name,
    wardInfo?.notes,
    wardInfo?.ward_name,
  ])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.manageBranchContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputFieldsContainer}>
            <div className={styles.dropDownField}>
              <label className={styles.labelWrapper}>
                Department
                <span className={styles.requiredField}>*</span>
              </label>
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder="Select Department"
                  closeMenuOnSelect={true}
                  isSearchable={true}
                  value={watchDepartnment}
                  components={{ DropdownIndicator }}
                  {...register('department', { required: true })}
                  options={departmentDropdownData?.map((item: any) => ({
                    label: item?.name,
                    value: item?._id,
                  }))}
                  onChange={(selectedOption: any) => {
                    setValue('department', selectedOption)
                  }}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors?.department?.type === 'required' && (
                  <p className={styles.errorMessage}>
                    Please select department
                  </p>
                )}
              </div>
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                labelText="Ward name"
                placeholder="Please enter ward name"
                requiredField={true}
                {...register('wardName', { required: true })}
                showErrors={errors?.wardName?.type === 'required'}
                errorMessage="Please enter ward name"
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div style={{ flexBasis: '50%', marginBlock: '10px' }}>
              <Input
                labelText="Notes"
                placeholder="Please enter notes"
                {...register('notes')}
                onChange={(e) => trimValue(e)}
              />
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Back"
              customClass={styles.backBtn}
              type="button"
              handleClick={() => {
                navigate('/medicalcenter/ward')
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default ManageWard
