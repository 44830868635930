import { UPLOAD_JSON_FILE } from '../constants/constant';

export interface IUploadModalValidators {
  [UPLOAD_JSON_FILE]: {
    required: string;
    validate: any;
  };
}

export const uploadModalValidators: IUploadModalValidators = {
  [UPLOAD_JSON_FILE]: {
    required: 'Please select json file',
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 || 'File size should be less than 2MB'
        );
      },
      fileType: (file: FileList) =>
        /json/.test(file[0].type) || 'Only .json files are allowed',
    },
  },
};
