import { SOURCE_EXTERNAL, UPLOAD_CSV_EXTERNAL } from '../constants/constant';

export interface ILeadPoolCsvUploadValidators {
  [UPLOAD_CSV_EXTERNAL]: {
    required: string;
    validate: any;
  };
  [SOURCE_EXTERNAL]: {
    required: string;
  };
}

export const leadPoolCsvUploadValidators: ILeadPoolCsvUploadValidators = {
  [SOURCE_EXTERNAL]: {
    required: 'Please select source',
  },
  [UPLOAD_CSV_EXTERNAL]: {
    required: 'Please select csv file',
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 || 'File size should be less than 2MB'
        );
      },
      fileType: (file: FileList) =>
        /csv/.test(file[0].type) || 'Only .csv files are allowed',
    },
  },
};
