import moment from "moment";
import styles from "./agents.module.scss";

export const AgentHeader: any[] = [
  {
    Header: "AGENT CODE",
    accessor: (row: any) => {
      return row.agent_code;
    },
  },
  {
    Header: "AGENT NAME",
    accessor: (row: any) => {
      return row.agent_name;
    },
  },
  {
    Header: "Mobile No.",
    accessor: (row: any) => {
      return row.phone;
    },
  },
  {
    Header: "STATUS",
    Cell: (props: any) => {
      return (
        <span
          style={{
            cursor: "pointer",
            fontSize: "var(--font-12)",
            fontWeight: "var(--font-normal)",
          }}
          className={
            props.row.original.status
              ? styles.statusActive
              : styles.statusInActive
          }
        >
          {props.row.original.status ? "Active" : "Inactive"}
        </span>
      );
    },
  },
  {
    Header: "DETAILS",
    Cell: (props: any) => {
      console.log(props.row.original, "prosp");
      // const dispatch = useAppDispatch();
      // const handleApiCall = () => {
      //   let data = {
      //     agent_id: props.row.original?._id,
      //   };
      //   dispatch(agentAdminGetData(requestGenerator(data)));
      // };
      return (
        <span
          style={{
            color: "var(--blue1)",
            cursor: "pointer",
            fontSize: "var(--font-12)",
            fontWeight: "var(--font-normal)",
          }}
          onClick={() => {
            props.onClick(props.row.original._id)
          }}
        >
          View
        </span>
      );
    },
  },
  // {
  //   Header: "ASSIGN",
  //   Cell: (props: any) => {
  //     return (
  //       <>
  //         <span
  //           style={{
  //             color: "var(--blue1)",
  //             cursor: "pointer",
  //             fontSize: "var(--font-12)",
  //             fontWeight: "var(--font-normal)",
  //           }}
  //           onClick={() => props.onRowClick()}
  //         >
  //           <EditIcon />
  //         </span>
  //       </>
  //     );
  //   },
  // },
];

export const AgentDetailsHeader: any[] = [
  {
    Header: "CAMPAIGN NAME",
    accessor: (row: any) => {
      return row.campaign_name;
    },
  },
  {
    Header: "START DATE",
    accessor: (row: any) => {
      return moment(row?.campaign_start_date).format("DD-MMM-YYYY") ?? "-";
    },
  },
  {
    Header: "END DATE",
    accessor: (row: any) => {
      return moment(row?.campaign_end_date).format("DD-MMM-YYYY") ?? "-";
    },
  },
];
