import moment from 'moment'
import {
  ATTENDENCE,
  END_DATE,
  END_TIME,
  LEAVE_STATUS,
  MONTH,
  REASON_FOR_LEAVE,
  SALARY_TYPE,
  START_DATE,
  START_TIME,
  YEAR,
} from '../../../../constants/markAllDayConstants'
import { IMarkAllDaysModalForm } from '../../../../interfaces/markAllDayInterface'
import { isDateBetweenTwoDates } from '../../../../utils/utils'

export const markAllDayValidator = {
  [ATTENDENCE]: {
    required: 'Please select present or absent',
  },
  [MONTH]: {
    required: 'Please select month',
  },
  [YEAR]: {
    required: 'Please select year',
  },
  [START_TIME]: {
    required: 'Please select start time',
  },
  [END_TIME]: {
    required: 'Please select end time',
  },
  [START_DATE]: {
    required: 'Please select start date',
    validate: {
      handleTime: (value: string, values: IMarkAllDaysModalForm) => {
        const startTime = values

        function getMonthFromString() {
          return new Date(Date.parse(values?.month + ' 1, 2012')).getMonth() + 1
        }

        const minDateValue = `${values?.year}-0${getMonthFromString()}-01`
        const firstDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth(), 1)

        const lastDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth() + 1, 0)

        const date1 = firstDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date2 = lastDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date3 = moment(startTime?.start_date).format('DD/MM/YYYY')

        if (!isDateBetweenTwoDates(date1, date2, date3)) {
          return 'Please select date of selected month'
        }
      },
    },
  },
  [END_DATE]: {
    required: 'Please select end date',
    validate: {
      handleTime: (value: string, values: IMarkAllDaysModalForm) => {
        const startTime = values
        function getMonthFromString() {
          return new Date(Date.parse(values?.month + ' 1, 2024')).getMonth() + 1
        }

        const minDateValue = `${values?.year}-0${getMonthFromString()}-01`
        const firstDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth(), 1)

        const lastDateOfMonth = (date = new Date()) =>
          new Date(date.getFullYear(), date.getMonth() + 1, 0)

        const date1 = firstDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date2 = lastDateOfMonth(
          new Date(minDateValue)
        ).toLocaleDateString()
        const date3 = moment(startTime?.end_date).format('DD/MM/YYYY')

        if (!isDateBetweenTwoDates(date1, date2, date3)) {
          return 'Please select date of selected month'
        }

        const startDate = startTime?.start_date
        const endDate = startTime?.end_date
        const ab = () => {
          return new Date(startDate) > new Date(endDate)
        }
        if (ab()) {
          return 'End date must be after Start date'
        }
      },
    },
  },
  [SALARY_TYPE]: {
    // required: 'Please select leave type',
    validate: {
      handleTime: (value: any, values: IMarkAllDaysModalForm) => {
        if (values?.isPaid !== 'UNPAID' && value === undefined) {
          return 'Leave type is Required'
        } else return
      },
    },
  },
  [LEAVE_STATUS]: {
    required: 'Please select status',
  },
  [REASON_FOR_LEAVE]: {
    required: 'Please enter reason for leave',
  },
}
