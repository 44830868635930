import {
  REMARK,
  EXPIRY_DATE,
  SUB_COMPANIES,
  DOCS,
  DOCUMENT_TYPE,
  ATTACHEMENTS,
  BRANCH,
  _EMPID,
  EMP_TYPE,
  EMP_NAME,
} from '../constants/constant'
export const documentsValidators = {
  [_EMPID]: {
    required: 'Please select employe',
  },
  [EMP_TYPE]: {
    required: 'Please select type',
  },
  [DOCUMENT_TYPE]: {
    required: 'Please select document type',
  },
  [REMARK]: {
    required: 'Please enter the remark',
  },
  [DOCS]: {
    required: 'Please select the document type',
  },

  [SUB_COMPANIES]: {
    required: 'Please select sub company',
  },
  [EMP_NAME]: {
    required: 'Please enter the document name',
  },
  [EXPIRY_DATE]: {
    required: 'Please select the expiry date',
  },
  [ATTACHEMENTS]: {
    required: 'Please attach the document',
  },

  [BRANCH]: {
    required: 'Please attach the document',
  },
}
