import styles from './tableData.module.scss';

export const ipdTestlistPopupData: any = [
  {
    Header: 'PROFILE NAME',
    accessor: 'profile_name',
    Cell: ({ row }: any) => {
      return <p>-</p>;
    },
  },
  // {
  //   Header: 'TEST ID',
  //   accessor: 'test_no',
  // },
  {
    Header: 'TEST NAME',
    accessor: 'test_name',
  },
  {
    Header: 'PRICE',
    accessor: 'price',
  },
];

export const ipdTestResultPopupData: any = [
  {
    Header: 'PROFILE NAME',
    accessor: 'profile_name',
    Cell: ({ row }: any) => {
      return <p>-</p>;
    },
  },
  {
    Header: 'TEST NAME',
    accessor: 'test_name',
  },
  {
    Header: 'COMPONENT',
    accessor: 'name',
    // Cell: ({ row }: any) => {
    //   return (
    //     <p>
    //       {row?.original?.components?.length > 0 &&
    //       row?.original?.components[0]?.name
    //         ? row?.original?.components[0]?.name
    //         : '-'}
    //     </p>
    //   );
    // },
  },
  {
    Header: 'RANGE',
    Cell: ({ row }: any) => {
      console.log('row?.original?.components', row?.original?.range);
      return (
        // <p>
        //   {row?.original?.components?.length > 0 &&
        //   row?.original?.components[0]?.range?.from !== undefined
        //     ? row?.original?.components?.length > 0 &&
        //       row?.original?.components[0]?.range?.from +
        //         ' - ' +
        //         row?.original?.components[0]?.range?.to
        //     : '-'}
        // </p>
        <p>
          {row?.original?.range === undefined
            ? '-'
            : row?.original?.range?.from + ' - ' + row?.original?.range?.to}
        </p>
      );
    },
  },
  {
    Header: 'RESULT',
    accessor: 'result',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.result?.length > 0 ? row?.original?.result : '-'}</p>
      );
    },
  },
  {
    Header: 'NOTES',
    accessor: 'test_notes',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.row?.original?.test_notes?.length > 0 &&
              props?.onRowClick(props?.row?.original?.test_notes);
          }}
        >
          {props?.row?.original?.test_notes?.length > 0 ? 'View' : '-'}
        </p>
      );
    },
  },
];
