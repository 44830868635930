import { FC, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setMessage } from '../../../redux/features/toast/toastSlice';
import { failure, reactSelectStyle, warning } from '../../../constants/data';
import TableV2 from '../../../components/common/table/tableV2/TableV2';
import Select from 'react-select';
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator';
import { colors } from '../../../constants/color';
import { useAppDispatch, useAppSelector } from '../../../hooks/index';
import {
  CloseIcon,
  SearchIcon,
} from '../../../components/common/svg-components';
import { ipdBookingTableHeaderData } from '../../../constants/table-data/ipdBookingTableData';
import Loader from '../../../components/common/spinner/Loader';
import {
  getIpdBookDoctorsList,
  getIpdAvailableBedsList,
  getIpdAllBedsList,
  getIpdBookDropdownList,
} from '../../../redux/features/ipd-booking/ipdBookingAsyncActions';
import { clearIpdBookingData } from '../../../redux/features/ipd-booking/ipdBookingSlice';
import { requestGenerator } from '../../../utils/payloadGenerator';
import Pagination from '../../../components/common/pagination/Pagination';
import Button from '../../../components/common/button/Button';
import styles from './ipdBooking.module.scss';

interface IIpdBookingPopup {
  handleOpen?: any;
  handleClose?: any;
  handleYes?: any;
  handleRowClick?: any;
  handleSubmit?: (e?: React.MouseEvent<HTMLElement>) => void;
  popData?: any;
  handleSubmitData?: any;
  handleRowData?: any;
  handleConfirm?: (e?: React.MouseEvent<HTMLElement>) => void;
}

const IpdBookingPopup: FC<IIpdBookingPopup> = ({
  handleOpen,
  handleClose,
  handleYes,
  handleRowClick,
  handleSubmit,
  popData,
  handleSubmitData,
  handleRowData,
  handleConfirm,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { branchData: getDepartmentData } = useAppSelector(
    (state) => state.login
  );
  const { isLoading, branchData, ipdBedsData, ipdBookDropDownData } =
    useAppSelector((state) => state.ipdBooking);
  // Define State Variables
  const [filterRadioValue, setFilterRadioValue] = useState<string>('');
  const [admissionDateValue, setAdmissionDateValue] = useState<string>('');
  const [patientName, setPatientName] = useState<string>('');
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [selectedDepartments, setSelectedDepartments] = useState<any>(null);
  const [selectedWards, setSelectedWards] = useState<any>(null);
  const [selectedRooms, setSelectedRooms] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>(undefined);
  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  // navigate to create case
  const navigateToCreateCase = () => {
    if (!popData?.patient_name) {
      dispatch(
        setMessage({
          message: 'Please select patient',
          type: warning,
        })
      );
    } else {
      navigate('/ipd-case-form', {
        state: {
          patient_id: popData?.patient_id,
          ipd_id: popData?._id,
          is_booking: true,
        },
      });
    }
  };

  // handle radio btn onchange
  const handleRadioBtn = (e: any) => {
    setFilterRadioValue(e.target.value);
  };

  // handle admission date
  const handleAdmissionDate = (e: any) => {
    setAdmissionDateValue(e.target.value);
  };

  // function for genrating payload
  const genrateGetPayload = useCallback(() => {
    if (filterRadioValue === 'AVAILABLE') {
      return {
        // branch_id: branchData?._id,
        department_id: selectedDepartments?.value,
        ward_id: selectedWards?.value,
        room_id: selectedRooms?.value,
        page: pageIndex,
        pageSize: dataPerPage,
      };
    } else if (filterRadioValue === 'ALL') {
      return {
        // branch_id: branchData?._id,
        patient_id: popData?.patient_id,
        department_id: selectedDepartments?.value,
        ward_id: selectedWards?.value,
        room_id: selectedRooms?.value,
        page: pageIndex,
        pageSize: dataPerPage,
      };
    }
  }, [
    // branchData,
    filterRadioValue,
    selectedDepartments,
    selectedWards,
    selectedRooms,
    popData,
    pageIndex,
    dataPerPage,
  ]);

  // API call for get ipd dropdown data
  useEffect(() => {
    if (selectedDepartments?.value) {
      const payloadData = {
        department_id: selectedDepartments?.value,
      };
      dispatch(getIpdBookDropdownList(requestGenerator(payloadData)));
      setSelectedWards(null);
      setSelectedRooms(null);
    }
  }, [dispatch, selectedDepartments]);

  // Set PageIndex and DataPerPage on tabs change
  useEffect(() => {
    setPageIndex(1);
    setDataPerPage(10);
  }, [filterRadioValue]);

  // API call for getting ipd deds data
  useEffect(() => {
    const requestData = genrateGetPayload();
    if (filterRadioValue === 'AVAILABLE') {
      dispatch(getIpdAvailableBedsList(requestGenerator(requestData))).then(
        (result) => setTotalPage(result.payload.lastPage)
      );
    } else if (filterRadioValue === 'ALL') {
      dispatch(getIpdAllBedsList(requestGenerator(requestData))).then(
        (result) => setTotalPage(result.payload.lastPage)
      );
    }
  }, [
    dispatch,
    genrateGetPayload,
    filterRadioValue,
    branchData,
    selectedDepartments,
    selectedWards,
    selectedRooms,
    popData,
  ]);

  // useEffect for set patient name
  useEffect(() => {
    setFilterRadioValue('AVAILABLE');
  }, []);

  // useEffect for set patient name
  useEffect(() => {
    setPatientName(popData?.patient_name);
  }, [popData]);

  // useEffect for getting ipd doctors list
  useEffect(() => {
    let payloadData = {
      branch_id: branchData?._id,
    };
    dispatch(getIpdBookDoctorsList(requestGenerator(payloadData)));
  }, [dispatch, branchData]);

  // clear ipd booking data on component remove
  useEffect(() => {
    return () => {
      dispatch(clearIpdBookingData());
      setSelectedRow(undefined);
    };
  }, [dispatch]);

  // useEffect for select row id
  useEffect(() => {
    if (handleRowData?._id) {
      setSelectedRow(handleRowData?._id);
    }
  }, [handleRowData]);

  return (
    <>
      {isLoading && <Loader />}

      <div
        className={styles.ipdBookingMainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <h1 className={styles.ipdBookingModalHeading}>IPD Booking</h1>
        <hr className={styles.ipdBookingModalDivider} />
        <div className={styles.ipdBookingModalContainer}>
          <div className={styles.ipdBookingFilterContainer}>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor="patient_name" className={styles.formLabel}>
                  Patient
                  <span className="asterick">*</span>
                </label>
                <div
                  className={[
                    styles.fieldAndErrorTxtContainer,
                    styles.fieldContainerForSearch,
                  ].join(' ')}
                >
                  <div className={styles.searchFieldContainer}>
                    <input
                      type="text"
                      placeholder={'Select Patient'}
                      value={patientName}
                      className={styles.inputField}
                      disabled
                    />
                    <SearchIcon
                      fillColor="#797979"
                      customClass={styles.searchIconStyle}
                      handleClick={handleOpen}
                    />
                    <p
                      className={styles.viewPatient}
                      onClick={navigateToCreateCase}
                    >
                      View
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.inputFieldContainer}>
                <label htmlFor="department" className={styles.formLabel}>
                  Department <span className="asterick">*</span>
                </label>
                <div className={styles.fieldAndErrorTxtContainer}>
                  <Select
                    className={styles.selectInputField}
                    placeholder="Select Department"
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    value={selectedDepartments}
                    options={getDepartmentData?.departments?.map(
                      (item: any) => ({
                        label: item?.name,
                        value: item?._id,
                      })
                    )}
                    isSearchable={false}
                    onChange={(e: any) => {
                      setSelectedDepartments(e);
                    }}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor="ward" className={styles.formLabel}>
                  Ward
                </label>
                <div className={styles.fieldAndErrorTxtContainer}>
                  <Select
                    className={styles.selectInputField}
                    placeholder="Select Ward"
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    value={selectedWards}
                    options={ipdBookDropDownData?.map((item: any) => ({
                      label: item?.ward_name,
                      value: item?._id,
                      room: item?.room,
                    }))}
                    isSearchable={false}
                    onChange={(e: any) => {
                      setSelectedRooms(null);
                      setSelectedWards(e);
                    }}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
              <div className={styles.inputFieldContainer}>
                <label htmlFor="room_no" className={styles.formLabel}>
                  Room No
                </label>
                <div className={styles.fieldAndErrorTxtContainer}>
                  <Select
                    className={styles.selectInputField}
                    placeholder="Select Room"
                    closeMenuOnSelect={true}
                    components={{ DropdownIndicator }}
                    value={selectedRooms}
                    options={selectedWards?.room?.map((item: any) => ({
                      label: item?.room_name,
                      value: item?._id,
                    }))}
                    isSearchable={false}
                    onChange={(e: any) => {
                      setSelectedRooms(e);
                    }}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
            </div>
            <div className={styles.formFieldContainer}>
              <div
                className={[
                  styles.inputFieldContainer,
                  styles.fieldContainerForDate,
                ].join(' ')}
              >
                <label htmlFor="admisstion_date" className={styles.formLabel}>
                  Date of Admission
                  <span className="asterick">*</span>
                </label>
                <div className={[styles.fieldAndErrorTxtContainer].join(' ')}>
                  <div className={styles.searchFieldContainer}>
                    <input
                      type="date"
                      className={styles.inputField}
                      onChange={handleAdmissionDate}
                      value={admissionDateValue}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.inputFieldContainer} />
            </div>
            <div className={styles.radioBtnContainer}>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="available" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="available"
                    value="AVAILABLE"
                    onChange={(e) => handleRadioBtn(e)}
                    checked={filterRadioValue === 'AVAILABLE'}
                  />
                  <span className={styles.customRadio} />
                  <p className={styles.radioLabelTxt}>Available</p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="all" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="all"
                    value="ALL"
                    onChange={(e) => handleRadioBtn(e)}
                    checked={filterRadioValue === 'ALL'}
                  />
                  <span className={styles.customRadio} />
                  <p className={styles.radioLabelTxt}>All</p>
                </label>
              </div>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={ipdBookingTableHeaderData}
              tableRowData={ipdBedsData}
              hadleData={{ ...popData, admissionDate: admissionDateValue }}
              handleClick={handleYes}
              setModelOpenClose={handleRowClick}
              handleRowClick={handleSubmit}
              active={selectedRow}
            />
            {ipdBedsData?.length > 0 && (
              <Pagination
                pageSize={dataPerPage}
                setDataPerPage={setDataPerPage}
                pageIndexOptions={pageIndexOptions}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            )}
          </div>
        </div>
        <div className={styles.btn}>
          <Button
            title="Submit"
            type="button"
            handleClick={handleConfirm}
            disable={Object.keys(handleSubmitData)?.length === 0}
          />
        </div>
      </div>
    </>
  );
};

export default IpdBookingPopup;
