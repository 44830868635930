import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../components/core/tab/Tabs";
import { receptionistIPDTabData } from "../../constants/data";
import Button from "../../components/common/button/Button";
import { useAppSelector } from "../../hooks";
import styles from "./IpdInvoiceLayout.module.scss";

const IpdInvoiceLayout = () => {
  const { selectedPatientRowData } = useAppSelector(
    (state) => state.ipdInvoice
  );
  const checkObjectLength = Object.keys(selectedPatientRowData);
  console.log(Object.keys(selectedPatientRowData), "selectedPatientRowData");

  const navigate = useNavigate();
  const location = useLocation().pathname;
  console.log(location, "location");

  useEffect(() => {
    if (location === "/ipd-invoice") {
      navigate("/ipd-invoice/patientinfo");
    }
  }, [location]);
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.labelContainer}>
          <p className={styles.title}>Patient IPD Invoice</p>
          {location === "/ipd-invoice/patientinfo" && (
            <div className={styles.buttonContainer}>
              <Button
                title="Receipt"
                type="button"
                customClass={styles.backButton}
                handleClick={() => navigate(`/receipt`)}
              />

              <Button
                title="Create Case"
                handleClick={() =>
                  navigate("/ipd-case-form", {
                    state: {
                      patient_id: selectedPatientRowData._id,
                    },
                  })
                }
                disable={
                  !checkObjectLength?.length ||
                  selectedPatientRowData?.hasOwnProperty("ipdData")
                }
              />
            </div>
          )}
        </div>

        <Tabs tabData={receptionistIPDTabData} />
        <Outlet />
      </div>
    </>
  );
};

export default IpdInvoiceLayout;
