import { useState, useEffect } from "react";
import makeAnimated from "react-select/animated";
import Pagination from "../../../components/common/pagination/Pagination";
import { profitLossTableHeaderData } from "./profitLossTableData";
import styles from "./profitLoss.module.scss";
import { Label } from "../../../components/common/label";
import { costCenterOptions, failure, monthOptions } from "../../../constants/data";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Loader from "../../../components/common/spinner/Loader";
import { addProfitAndLossAmtData } from "../../../redux/features/profit-loss/profitLossSlice";
import { getAllProfitLossBalance } from "../../../redux/features/profit-loss/profitLossAsyncActions";
import Popup from "../../../components/common/popup/Popup";
import ProfitLossIncomeModal from "./profit-loss-income-modal/ProfitLossIncomeModal";
import ProfitLossExpenseModal from "./profit-loss-expense-modal/ProfitLossExpenseModal";
import moment from "moment";
import { Input } from "../../../components/common/input/input";
import { SearchIcon } from "../../../components/common/svg-components";
import { setMessage } from "../../../redux/features/toast/toastSlice";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import { getAllBranch, getAllDepartment } from "../../../redux/features/vouchers/vouchersAsynActions";
import { getAllDoctorList } from "../../../redux/features/receptionist/receptionistAsyncActions";
import Select from "react-select";


const ProfitLoss = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const firstDay = new Date(currentYear, 0, 1); // January 1st

  const [yearsFilter, setYearsFilter] = useState<any>({
    value: currentYear,
    label: currentYear,
  });
  const [monthsFilter, setMonthsFilter] = useState<any>(
    monthOptions.find((option) => option.value === currentMonth.toString())
  );
  const [expenseBalancePopup, setShowExpenseBalance] = useState<boolean>(false);
  const [incomeBalancePopup, setShowIncomeBalance] = useState<boolean>(false);
  const [viewBalancePopData, setViewBalancePopData] = useState({});
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalData, setTotalData] = useState(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [expenseData, setExpenseData] = useState<any[]>([]);
  const [incomeData, setIncomeData] = useState<any[]>([]);
  const [startDate, setStartdate] = useState<any>(
    moment(firstDay).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment(currentDate).format("YYYY-MM-DD")
  );

  const [costCenter, setCostCenter] = useState<any>("");
  const [costCenterValueList, setCostCenterValueList] = useState<any>([]);
  const [costCenterValue, setCostCenterValue] = useState<any>("");


  const animatedComponent = makeAnimated();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.profitLoss);

  /* Initial API call for Profit And Loss Balance Sheet */

  const getData = () => {
    let endDateMoment = moment(endDate).local().endOf("days").format();

    const expenseRequestData = {
      is_active: "true",
      type: "Expense",
      // month: monthsFilter?.hasOwnProperty('value')
      //   ? Number(monthsFilter?.value)
      //   : undefined,
      // year: yearsFilter?.hasOwnProperty('value')
      //   ? yearsFilter?.value
      //   : undefined,
      // fromDate: new Date(startDate),
      // toDate: endDateMoment,
      range: {
        fromDate: new Date(startDate),
        // toDate: endDateMoment,
        toDate: new Date(endDate),
      },
      cost_center: {
        label: costCenter?.label,
        value: costCenterValue?.label,
      },
      page: pageIndex,
      pageSize: dataPerPage,
    };
    const incomeRequestData = {
      is_active: "true",
      type: "Income",
      // month: monthsFilter?.hasOwnProperty('value')
      //   ? Number(monthsFilter?.value)
      //   : undefined,
      // year: yearsFilter?.hasOwnProperty('value')
      //   ? yearsFilter?.value
      //   : undefined,
      range: {
        fromDate: new Date(startDate),
        // toDate: endDateMoment,
        toDate: new Date(endDate),
      },
      cost_center: {
        label: costCenter?.label,
        value: costCenterValue?.label,
      },
      page: pageIndex,
      pageSize: dataPerPage,
    };
    if (startDate && endDate) {
      dispatch(
        getAllProfitLossBalance(requestGenerator(expenseRequestData))
      ).then((result) => {
        setTotalPage(result.payload.lastPage);
        setTotalData(result.payload.totalData);
        const tempData = result.payload?.map((item: any) => {
          return {
            ...item,
            // amount: Number(item?.debit_amount) - Number(item?.credit_amount),
            amount: Number(item?.close?.debit),
          };
        });
        setExpenseData(tempData);
      });
      dispatch(
        getAllProfitLossBalance(requestGenerator(incomeRequestData))
      ).then((result) => {
        setTotalPage(result.payload.lastPage);
        setTotalData(result.payload.totalData);
        const tempData = result.payload?.map((item: any) => {
          return {
            ...item,
            // amount: Number(item?.credit_amount) - Number(item?.debit_amount),
            amount: Number(item?.close?.credit),
          };
        });
        setIncomeData(tempData);
      });
    } else {
      dispatch(
        setMessage({
          type: failure,
          message: "Please select both From and End Date to Filter",
        })
      );
    }
  };

  useEffect(() => {
    getData();
  }, [dataPerPage, dispatch, pageIndex]);
  const handleSearchData = () => {
    getData();
  };
  // Credit Amount
  useEffect(() => {
    // const calculatedTotalCreditAmount = incomeData?.reduce(
    //   (sum: any, row: any) => {
    //     const creditAmount = parseFloat(row?.amount)
    //     return sum + creditAmount
    //   },
    //   0
    // )

    const totalCredit =
      incomeData?.length > 0 ? incomeData[incomeData.length - 1]?.amount : 0;

    setTotalCreditAmount(totalCredit);
  }, [incomeData]);

  // Debit Amount
  useEffect(() => {
    // const calculatedTotaldebitAmount = expenseData?.reduce(
    //   (sum: any, row: any) => {
    //     const debitAmount = parseFloat(row?.amount)
    //     return sum + debitAmount
    //   },
    //   0
    // )
    const totalCredit =
      expenseData?.length > 0 ? expenseData[expenseData.length - 1]?.amount : 0;

    setTotalDebitAmount(totalCredit);
  }, [expenseData]);

  const profitOrLossAmount = totalCreditAmount - totalDebitAmount;
  dispatch(addProfitAndLossAmtData(profitOrLossAmount));
  // Dynamic year
  const startYear = currentYear - 5;
  let yearOptions = [];

  for (let i = startYear; i <= currentYear; i++) {
    yearOptions?.push({ value: i, label: i });
  }

  const handleViewBalance = () => {
    setShowExpenseBalance((prevState) => !prevState);
  };
  const handleViewBalancePopup = (viewObject: {}) => {
    setShowExpenseBalance((prevState) => !prevState);
    setViewBalancePopData(viewObject);
  };
  const handleIncomeBalance = () => {
    setShowIncomeBalance((prevState) => !prevState);
  };
  const handleIncomeBalancePopup = (viewObject: {}) => {
    setShowIncomeBalance((prevState) => !prevState);
    setViewBalancePopData(viewObject);
  };
  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  //  API calls for call center
  useEffect(() => {
    let payloadData = {
      search: "",
      page: 1,
      pageSize: 1000,
    };
    if (costCenter?.label === "Branch") {
      dispatch(getAllBranch(requestGenerator(payloadData))).then((e) => {
        setCostCenterValueList(e?.payload?.data);
        setCostCenterValue("")
      });
    } else if (costCenter?.label === "Doctor") {
      dispatch(getAllDoctorList(requestGenerator(payloadData))).then((e) => {
        setCostCenterValueList(e?.payload?.data);
        setCostCenterValue("")
      });
    } else {
      dispatch(getAllDepartment(requestGenerator(payloadData))).then((e) => {
        setCostCenterValueList(e?.payload?.data);
        setCostCenterValue("")
      });
    }
  }, [costCenter]);

  return (
    <>
      {isLoading && <Loader />}
      {expenseBalancePopup && (
        <Popup
          Children={ProfitLossExpenseModal}
          popData={viewBalancePopData}
          handleClose={() => handleViewBalance()}
        />
      )}
      {incomeBalancePopup && (
        <Popup
          Children={ProfitLossIncomeModal}
          popData={viewBalancePopData}
          handleClose={() => handleIncomeBalance()}
        />
      )}
      <div className={styles.profitLossMainContainer}>
        {/* Filters */}
        <form className={styles.profitLossFilter}>
          <div className={styles.formFields}>
            {/* Years */}
            <div className={styles.inputFieldContainer}>
              <Label
                htmlFor={"From"}
                labelText="From"
                customClass={styles.customLabel}
              />
              {/* <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">Select year</span>
                }
                isClearable={true}
                isSearchable={true}
                options={yearOptions}
                value={yearsFilter || ''}
                components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                styles={customDropdownStyles}
                onChange={(e: any) => {
                  setYearsFilter(e)
                }}
              /> */}
              <Input
                type="date"
                value={startDate}
                onChange={(e: any) => setStartdate(e.target.value)}
              />
            </div>
            {/* Months */}
            <div className={styles.inputFieldContainer}>
              <Label
                htmlFor={"TO"}
                labelText="To"
                customClass={styles.customLabel}
              />
              {/* <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">Select Month</span>
                }
                isClearable={true}
                isSearchable={false}
                options={monthOptions}
                value={monthsFilter || ''}
                components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                styles={customDropdownStyles}
                onChange={(e: any) => {
                  setMonthsFilter(e)
                }}
              /> */}
              <Input
                type="date"
                value={endDate}
                onChange={(e: any) => setEndDate(e.target.value)}
              />
            </div>
            {/* Cost Center */}
            <div className={styles.inputFieldContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">Cost Center</span>
                }
                // isClearable={true}
                isSearchable={true}
                options={costCenterOptions?.map((item: any) => ({
                  label: item?.label,
                  value: item?.value,
                  isDisabled: item?.isdisabled,
                }))}
                value={costCenter}
                onChange={(e) => setCostCenter(e)}
                // components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={190}
              />
            </div>
            {/* Cost Center Value*/}
            <div className={styles.inputFieldContainer}>
              <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">
                    Cost Center Value
                  </span>
                }
                // isClearable={true}
                isSearchable={true}
                options={costCenterValueList?.map((item: any) => ({
                  label: item?.name
                    ? item?.name
                    : item?.doctor_name
                    ? item?.doctor_name
                    : "",
                  value: item?._id,
                }))}
                value={costCenterValue}
                // components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={190}
                onChange={(e: any) => {
                  setCostCenterValue(e);
                }}
              />
            </div>
            <div
              className={styles.searchButton}
              onClick={() => handleSearchData()}
            >
              <SearchIcon fillColor={"#ffffff"} />
            </div>{" "}
          </div>
        </form>
        <div className={styles.profitLossTableContainer}>
          {/* Income Table */}
          <div className={styles.tableContainer}>
            <div className={styles.tableData}>
              <TableV2
                tableHeaderData={profitLossTableHeaderData ?? []}
                tableRowData={incomeData ?? []}
                active={false}
                handleClick={handleIncomeBalancePopup}
                showTableFoot={true}
                customClasssForViewSlotTrHead={
                  styles.customClasssForViewSlotTrHead
                }
              >
                {
                  <>
                    {/* <tr>
                      <td className={styles.totalText}>Total</td>
                      <td className={styles.totalAmountText}>
                        {totalCreditAmount}
                      </td>
                      <td></td>
                    </tr> */}
                    {incomeData?.length > 0 && expenseData?.length > 0 && (
                      <tr>
                        <td
                          className={
                            profitOrLossAmount > 0
                              ? styles.positiveValue
                              : styles.negativeValue
                          }
                        >
                          {profitOrLossAmount > 0 ? "Gross Profit" : "Loss"}
                        </td>
                        <td
                          className={
                            profitOrLossAmount > 0
                              ? styles.positiveValue
                              : styles.negativeValue
                          }
                        >
                          {profitOrLossAmount}
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </>
                }
              </TableV2>
            </div>
            {/* Pagination */}
            <div className={styles.pagination}>
              {/* {!!(incomeData?.length > 10) && (
                <Pagination
                  setDataPerPage={setDataPerPage}
                  pageIndexOptions={pageIndexOptions}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                />
              )} */}
            </div>
          </div>
          {/* Expense Table */}
          <div className={styles.tableContainer}>
            <div className={styles.tableData}>
              <TableV2
                tableHeaderData={profitLossTableHeaderData}
                tableRowData={expenseData ?? []}
                active={false}
                handleClick={handleViewBalancePopup}
                showTableFoot={true}
              >
                {/* {!!(expenseData?.length > 0) && (
                  <>
                    <tr>
                      <td className={styles.totalText}>Total</td>
                      <td className={styles.totalAmountText}>
                        {totalDebitAmount}
                      </td>
                      <td></td>
                    </tr>
                  </>
                )} */}
              </TableV2>
            </div>
            <div className={styles.pagination}>
              {/* Pagination */}
              {/* {!!(expenseData?.length > 10) && (
                <Pagination
                  setDataPerPage={setDataPerPage}
                  pageIndexOptions={pageIndexOptions}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                />
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfitLoss;
