import moment from "moment";

export const DetailHeader: any[] = [
  {
    Header: "LEAD NAME",
    accessor: "name",
  },
  {
    Header: "DATE ASSIGNED",
    accessor: "dateassigned",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.assigned_date;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: "DATE CONTACTED",
    accessor: "datecontacted",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.feadbacks?.createdAt;
      const formattedDate = moment(originalDate).format("DD MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  // {
  //   Header: "FOLLOW UP CALLS",
  //   accessor: "followupscall",
  //   Cell: ({ row}: any) => {
  //     const follwupCalls = row?.original?.feadbacks?.filter((item: any) => {
  //       return (
  //         item?.status === "INTERESTED" || item?.status === "SCHEDULE_LATER"
  //       );
  //     });
  //     return (
  //       <>
  //         <span>{follwupCalls?.length ?? 0 }</span>
  //       </>
  //     );
  //   },
  // },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "NOTES",
    accessor: "notes",
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      return (
        <>
          {/* <span style={{ cursor: "pointer", color: "#0e26a3" }}>View</span> */}
          <span>-</span>
        </>
      );
    },
  },
];

