import {
  Level5_CHECK,
  Level5_CODE,
  Level5_LIST,
  Level5_NAME,
} from "../constants/constant";

export interface IaddLevel5Validators {
  [Level5_NAME]: {
    required: string;
  };
  [Level5_CODE]: {
    // required: string;
  };
  [Level5_CHECK]: {};
  [Level5_LIST]: {
    required: string;
  };
}
export const addLevel5Validators: IaddLevel5Validators = {
  [Level5_NAME]: {
    required: "Please enter name",
  },
  [Level5_CODE]: {
   
  },
  [Level5_CHECK]: {},
  [Level5_LIST]: {
    required: "Please select debit or credit",
  },
};
