import axios from "axios";
import {
  APPOINTNMENT_DATA_BY_ID,
  PHYSIO_VIEW_SLOTS,
} from "../../../config/config";
import { IAPIPayload } from "../../../interfaces/apiInterface";

export const physioViewSlotsApi = (data: IAPIPayload) => {
  return axios.post(PHYSIO_VIEW_SLOTS, data);
};

export const appointmentDataById = (data: IAPIPayload) => {
  return axios.post(APPOINTNMENT_DATA_BY_ID, data);
};

