import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import GlobalSearch from '../../../components/common/global-search-component/page'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { ViewInventoryHeaderData } from './data'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import styles from './styles.module.scss'
import { useEffect, useState, FC } from 'react'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { trimValue } from '../../../utils/utils'
import Select from 'react-select'
import { getInventoryStore } from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import { viewAllInventoryData } from '../../../redux/features/branch-store/branchStoreAsyncActions'
import Pagination from '../../../components/common/pagination/Pagination'
import { clearViewInventoryData } from '../../../redux/features/branch-store/branchStoreSlice'
import Loader from '../../../components/common/spinner/Loader'

interface IViewInventory {
  isPharmacy?: boolean
}

const ViewInventory: FC<IViewInventory> = ({ isPharmacy }) => {
  const { inventoryStoreData } = useAppSelector(
    (state) => state.inventoryRequest
  )
  const { isLoading, viewInventoryData } = useAppSelector(
    (state) => state.branchStore
  )
  const { branchData } = useAppSelector((state) => state.login)
  const [storeId, setStoreId] = useState('')
  const dispatch = useAppDispatch()
  const [searchTest, setSearchTest] = useState('')
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<any>(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalData, setTotalData] = useState(0)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= Math.ceil(totalPage); i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  useEffect(() => {
    dispatch(getInventoryStore(requestGenerator({})))
  }, [dispatch])
  const data: any = viewInventoryData ?? []
  const columns: Column<any>[] = ViewInventoryHeaderData
  const options: TableOptions<any> = {
    data,
    columns,
  }

  useEffect(() => {
    if (storeId?.length) {
      let requestData = {
        item_name: searchTest,
        page: pageIndex,
        pageSize: dataPerPage,
        store_id: storeId,
      }
      dispatch(viewAllInventoryData(requestGenerator(requestData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
    return () => {
      dispatch(clearViewInventoryData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPerPage, dispatch, pageIndex, storeId])
  const {
    // @ts-ignore
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore

  const handleSearch = () => {
    if (searchTest?.length) {
      setPageIndex(1)
      let requestData = {
        item_name: searchTest,
        page: pageIndex,
        pageSize: dataPerPage,
        store_id: storeId,
      }
      dispatch(viewAllInventoryData(requestGenerator(requestData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 48,
      minHeight: 35,
    }),
    menuList: (base: any) => ({
      ...base,

      '::-webkit-scrollbar': {
        width: '4px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#a49e9e',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#a49e9e',
      },
    }),
  }

  console.log('isPharmacy', isPharmacy, branchData)

  useEffect(() => {
    if (isPharmacy) {
      let requestData = {
        page: pageIndex,
        pageSize: dataPerPage,
        store_id: branchData?.pharmacy_store[0]?._id,
      }
      dispatch(viewAllInventoryData(requestGenerator(requestData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
    return () => {
      dispatch(clearViewInventoryData())
    }
  }, [dataPerPage, dispatch, pageIndex])

  const handlePharmacySearch = () => {
    if (searchTest?.length) {
      setPageIndex(1)
      let requestData = {
        item_name: searchTest,
        page: pageIndex,
        pageSize: dataPerPage,
        store_id: branchData?.pharmacy_store[0]?._id,
      }
      dispatch(viewAllInventoryData(requestGenerator(requestData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      {!isPharmacy ? (
        <div className={styles.inputFieldContainer}>
          <span className={styles.label}>
            Store <span className="asterick">*</span>
          </span>
          <div className={styles.fieldErrorContainer}>
            <Select
              className={styles.select}
              placeholder="Select Store"
              closeMenuOnSelect={true}
              isSearchable={true}
              options={inventoryStoreData?.map((items: any) => {
                return {
                  label: items.name,
                  value: items._id,
                }
              })}
              onChange={(e: any) => {
                setStoreId(e.value)
                setSearchTest('')
              }}
              maxMenuHeight={200}
              styles={customStyles}
            />
          </div>
          {!!storeId?.length && (
            <GlobalSearch
              customClass={styles.mt_0}
              placeholder="Search by name"
              value={searchTest}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e: any) => {
                trimValue(e)
                setSearchTest(e.target.value)
                if (searchTest !== '' && e.target.value === '') {
                  let requestData = {
                    page: pageIndex,
                    pageSize: dataPerPage,
                    store_id: storeId,
                  }
                  dispatch(
                    viewAllInventoryData(requestGenerator(requestData))
                  ).then((result: any) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                }
              }}
              handleSearch={() => handleSearch()}
            />
          )}
        </div>
      ) : (
        <GlobalSearch
          customClass={styles.mt_0}
          placeholder="Search by name"
          value={searchTest}
          onKeyDown={(e: any) => {
            if (e?.key === 'Enter') {
              handlePharmacySearch()
            }
          }}
          onChange={(e: any) => {
            trimValue(e)
            setSearchTest(e.target.value)
            if (searchTest !== '' && e.target.value === '') {
              let requestData = {
                page: pageIndex,
                pageSize: dataPerPage,
                store_id: branchData?.pharmacy_store[0]?._id,
              }
              dispatch(
                viewAllInventoryData(requestGenerator(requestData))
              ).then((result: any) => {
                setTotalPage(result.payload.lastPage)
                setTotalData(result.payload.total)
              })
            }
          }}
          handleSearch={() => handlePharmacySearch()}
        />
      )}
      <div className={styles.tableContainer}>
        <TableV3
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          active={false}
        />
      </div>
      {!!(totalData > 10) && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
    </>
  )
}

export default ViewInventory
