import Header from './header/Header'
import SideBar from './sidebar/SideBar'
import { Outlet, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import usePermissions from '../../hooks/usePermissions'
import { useEffect } from 'react'
import { getDeviceTokenStore } from '../../redux/features/appointment/appointmentAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { clearIpdInvoiceData } from '../../redux/features/ipd-invoice/ipdInvoiceSlice'
import { blackListPath } from '../../constants/data'
import { socket } from '../../socket'
import { userLogout } from '../../redux/features/login/loginAsynActions'
import styles from './mainLayout.module.scss'

const MainLayout: React.FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { branchData, firebaseToken } = useAppSelector((state) => state.login)
  const { sidebar } = usePermissions()
  const location = useLocation()
  const path = useLocation().pathname
  useEffect(() => {
    if (blackListPath.includes(path)) return
    document
      ?.getElementById('header_container')
      ?.scrollIntoView({ behavior: 'smooth' })
  }, [path])

  let deviceTokenPayload = {
    device_token: firebaseToken,
  }

  useEffect(() => {
    if (firebaseToken?.length > 0) {
      dispatch(getDeviceTokenStore(requestGenerator(deviceTokenPayload)))
    }
  }, [firebaseToken])
  let module = path.split('/')[path.split('/').length - 1]
  const classObject: any = {
    schedular: styles.customHeaderChildrenContainer,
    beds: styles.customHeaderChildrenContainer,
    nurse: styles.customHeaderChildrenContainer,
  }

  const classForChildrenObject: any = {
    schedular: styles.customChildrenStyle,
    beds: styles.customChildrenStyle,
    nurse: styles.customChildrenStyle,
  }

  useEffect(() => {
    return () => {
      if (location?.pathname.split('/')[1] !== 'ipd-invoice') {
        dispatch(clearIpdInvoiceData())
      }
    }
  }, [location?.pathname])

  useEffect(() => {
    if (socket) {
      socket.on('branch_deactivated', (data: any) => {
        const findUserExists = data.find((x: any) => x == branchData._id)
        if (findUserExists) {
          dispatch(userLogout(requestGenerator({})))
        }
      })
    }
  }, [])

  return (
    <div className={styles.mainContainer}>
      <SideBar sidebarData={sidebar || []} />
      <div className={classObject[module] ?? styles.headerChildrenContainer}>
        <Header />
        <div className={classForChildrenObject[module] ?? styles.childrenStyle}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
