/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/iframe-has-title */
import { FC, useEffect, useState } from "react";
import styles from "./filenamepopup.module.scss";
import { CloseIcon } from "../../../components/common/svg-components";
import Divider from "../../../components/common/divider/Divider";
import { colors } from "../../../constants/color";
import { getAllAttachs } from "../../../redux/features/document/documentAsyncActions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestGenerator } from "../../../utils/payloadGenerator";
import Loader from "../../../components/common/spinner/Loader";
import Button from "../../../components/common/button/Button";
import { clearAttach } from "../../../redux/features/document/documentSlice";
interface IPropsData {
  handleClose?: any;
  popData?: string | any;
}
const FilenamePopup: FC<IPropsData> = ({ handleClose, popData }) => {
  const dispatch = useAppDispatch();
  const { isLoading, allAttchsData } = useAppSelector(
    (state) => state.document
  );
  const [indexImage, setIndexImage] = useState(0);
  useEffect(() => {
    let attachements = popData;

    const requestData = {
      attachements: attachements,
    };
    dispatch(getAllAttachs(requestGenerator(requestData)));
    // dispatch(clearAttach())
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearAttach());
  }, [dispatch]);

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.actionSavePopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.actionSaveContainer}>
          <p className={styles.title}>File Name</p>
          <Divider customClass={styles.dividerStyle} />
          {/* {allAttchsData[indexImage]?.includes("image") ? ( */}
          {typeof allAttchsData[indexImage] === "string" &&
          allAttchsData[indexImage]?.includes("image") ? (
            <img
              src={allAttchsData[indexImage]}
              alt="image"
              className={styles.image}
            />
          ) : (
            <iframe src={allAttchsData[indexImage]} className={styles.doc} />
          )}
          {allAttchsData?.length - 1 !== indexImage && (
            <Button
              handleClick={() => setIndexImage(indexImage + 1)}
              title="Next"
              customClass={styles.next}
            />
          )}
          {indexImage > 0 && (
            <Button
              handleClick={() => setIndexImage(indexImage - 1)}
              title="Previous"
              customClass={styles.back}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default FilenamePopup;
