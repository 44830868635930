import styles from "../style.module.scss";

export const ipdMedicationData: any = [
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "MEDICATION",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.medicines)}
        >
          View
        </p>
      );
    },
  },
  {
    Header: "DOCTOR.",
    accessor: "doctor_name",
  },
];

export const ipdMedicationInfoData: any = [
  {
    Header: "DATE & TIME",
    accessor: "time",
  },
  {
    Header: "MEDICATION NAME",
    accessor: "medicine_name",
  },
  {
    Header: "QTY",
    accessor: "quantity",
  },
  {
    Header: "DURATION",
    accessor: "duration",
  },
  {
    Header: "FREQUENCY",
    accessor: "frequency",
  },
];
