import styles from './profitLoss.module.scss'
import { EyeIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { isBoldRow, isBoldRowiWithTotal } from '../../../constants/data'

// BalanceSheet Table Data
const currentDate = new Date()
const formattedDate = `AS AT ${currentDate.getDate()} ${currentDate.toLocaleString(
  'default',
  { month: 'short' }
)} ${currentDate.getFullYear()}`
// Expense
export const profitLossTableHeaderData: any = [
  {
    Header: 'A/C NO.',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: 'DESCRIPTION',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
              ? styles.boldTd
              : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: 'AMOUNT',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.amount
            ? row?.original?.amount
            : isBoldRow.includes(row?.original?.level)
            ? ''
            : 0}{' '}
        </p>
      )
    },
  },
  // {
  //   Header: 'VIEW',
  //   Cell: (props: any) => {
  //     const viewObject = {
  //       viewBalance: props?.row?.original?.voucher,
  //     }
  //     return (
  //       <>
  //         <EyeIcon
  //           fillColor={colors.grey1}
  //           customClass={styles.eyeIcons}
  //           handleClick={() => props.onClick(viewObject)}
  //         />
  //       </>
  //     )
  //   },
  // },
]

export const expenseBalanceTableHeaderData: any = [
  {
    Header: 'VOUCHER NO.',
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: 'CREDIT BALANCE',
    accessor: (row: any) => {
      return row?.credit_amount > 0 ? row?.credit_amount : '-'
    },
  },
  {
    Header: 'DEBIT BALANCE',
    accessor: (row: any) => {
      return row?.debit_amount > 0 ? row?.debit_amount : '-'
    },
  },
]
export const incomeBalanceTableHeaderData: any = [
  {
    Header: 'VOUCHER NO.',
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: 'CREDIT BALANCE',
    accessor: (row: any) => {
      return row?.credit_amount > 0 ? row?.credit_amount : '-'
    },
  },
  {
    Header: 'DEBIT BALANCE',
    accessor: (row: any) => {
      return row?.debit_amount > 0 ? row?.debit_amount : '-'
    },
  },
]
