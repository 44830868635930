import { useState, useEffect } from 'react';
import TableV2 from '../../../../../components/common/table/tableV2/TableV2';
import { ipdMedicationHeaderData } from '../../../../../constants/table-data/ipdMedicationTableData';
import Popup from '../../../../../components/common/popup/Popup';
import MedicationViewPopup from '../../../../../components/common/modal/medication-view-popup/MedicationViewPopup';
import { requestGenerator } from '../../../../../utils/payloadGenerator';
import { getAllIpdMedication } from '../../../../../redux/features/ipd/ipdAsyncActions';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../../components/common/spinner/Loader';

const IpdMedication = () => {
  const [showMedication, setShowMedication] = useState(false);
  const [medicineData, setMedicineData] = useState<any>([]);
  const { ipdMedicationData, isLoading } = useAppSelector((state) => state.ipd);
  const dispatch = useAppDispatch();
  const location = useLocation()?.state;

  let payloadData = {
    ipd_id: location?.ipd_id,
    patient_id: location?.patient_id,
    date: '',
  };

  useEffect(() => {
    dispatch(getAllIpdMedication(requestGenerator(payloadData)));
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {showMedication && (
        <Popup
          Children={MedicationViewPopup}
          handleClose={() => setShowMedication(false)}
          popData={medicineData}
        />
      )}
      <TableV2
        tableHeaderData={ipdMedicationHeaderData}
        tableRowData={ipdMedicationData?.filter(
          (item: any) => item?.medicines?.length > 0
        )}
        active={false}
        handleRowClick={(date: any, item: any) => {
          setShowMedication(true);
          setMedicineData(
            item?.map((item: any) => {
              return { ...item, date: date };
            })
          );
        }}
      />
    </>
  );
};

export default IpdMedication;
