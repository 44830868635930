import { FC, useState, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CloseIcon } from '../../../../components/common/svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../../../components/common/divider/Divider'
import Button from '../../../../components/common/button/Button'
import { IMarkAllDaysModalForm } from '../../../../interfaces/markAllDayInterface'
import {
  ATTENDENCE,
  END_DATE,
  END_TIME,
  LEAVE_STATUS,
  MONTH,
  REASON_FOR_LEAVE,
  SALARY_TYPE,
  START_DATE,
  START_TIME,
  YEAR,
} from '../../../../constants/markAllDayConstants'
import { markAllDayValidator } from './markAllDayValidator'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { StatusArray, rollOptions } from '../../../../constants/data'
import { trimValue } from '../../../../utils/utils'
import styles from './markAllDayModal.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  addAllEmployeeAttendanceAction,
  getMonthlyEmployeeAttendanceAction,
} from '../../../../redux/features/attendance/attendanceAsyncAction'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { clearState } from '../../../../redux/features/attendance/attendanceSlice'
import moment from 'moment'
import Loader from '../../../../components/common/spinner/Loader'

interface IPropsData {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  isEditing?: boolean
  popData?: any

  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
}
const MarkAllDaysModal: FC<IPropsData> = ({
  handleClose,
  setIsEditing,
  popData,
}) => {
  const [attendenceType, setAttendenceType] = useState<string>('PRESENT')
  const dispatch = useAppDispatch()
  // data fetch from use selector

  const { setAllData, selectedMonthYear, selectedEmployList, isLoading } =
    useAppSelector((state) => state.attendance)
  const { masterValueData } = useAppSelector((state) => state.login)

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm<IMarkAllDaysModalForm>({})
  const animatedComponent = makeAnimated()
  const watchEndData = watch(END_DATE)
  const watchStartDate = watch(START_DATE)

  function getMonthFromString() {
    return (
      new Date(Date.parse(selectedMonthYear?.month + ' 1, 2012')).getMonth() + 1
    )
  }

  const minDateValue = `${selectedMonthYear?.year}-0${getMonthFromString()}-01`
  const maxDateValue = `${selectedMonthYear?.year}-0${getMonthFromString()}-31`

  // console.log('minDateValue', minDateValue)

  const onSubmit: SubmitHandler<IMarkAllDaysModalForm> = (data) => {
    const presentData = selectedEmployList?.map((item: any) => {
      return {
        emp_id: item?._id,
        name: item?.name_en,
        attendance: attendenceType,
        start_time: data?.start_time,
        end_time: data?.end_time,
        startDate: moment.utc(data?.start_date).toISOString(),
        endDate: moment.utc(data?.end_date).toISOString(),
      }
    })

    const absentData = selectedEmployList?.map((item: any) => {
      return {
        emp_id: item?._id,
        name: item?.name_en,
        attendance: attendenceType,
        start_time: data?.start_time,
        is_late: 'false',
        leave_type:
          data?.salary_type == 'Maternity Leave'
            ? 'MATERNITY_LEAVE'
            : data?.salary_type == 'Medical Leave'
            ? 'MEDICAL_LEAVE'
            : data?.salary_type == 'Haj Leave'
            ? 'HAJ_LEAVE'
            : data?.salary_type == 'Annual Leave'
            ? 'ANNUAL_LEAVE'
            : undefined,
        isPaid: data?.isPaid == 'PAID' ? true : false,
        reason: data?.reason_for_leave,
        end_time: data?.end_time,
        startDate: moment.utc(data?.start_date).toISOString(),
        endDate: moment.utc(data?.end_date).toISOString(),
        halfday: false,
      }
    })

    const payload = {
      sub_company: selectedMonthYear?.sub_company,
      search: '',
      range: {
        fromDate: moment.utc(data?.start_date).toISOString(),
        toDate: moment.utc(data?.end_date).toISOString(),
      },
      department_id: selectedMonthYear?.department_id,
    }

    if (attendenceType === 'PRESENT') {
      dispatch(
        addAllEmployeeAttendanceAction(requestGenerator(presentData))
      ).then((e) => {
        if (e.type === 'attendanceSlice/addAllEmployeeAttendance/fulfilled') {
          dispatch(
            getMonthlyEmployeeAttendanceAction(requestGenerator(payload))
          )
          dispatch(clearState())
          handleClose()
        } else {
        }
      })
    } else {
      dispatch(
        addAllEmployeeAttendanceAction(requestGenerator(absentData))
      ).then((e) => {
        if (e.type === 'attendanceSlice/addAllEmployeeAttendance/fulfilled') {
          dispatch(
            getMonthlyEmployeeAttendanceAction(requestGenerator(payload))
          )
          dispatch(clearState())
          handleClose()
        } else {
        }
      })
    }
  }
  const handleAttendenceTypeChange = (e: any) => {
    setAttendenceType(e?.target?.value === 'ABSENT' ? 'ABSENT' : 'PRESENT')
  }

  // Reset
  const handleReset = () => {
    reset()
    setValue(MONTH, '')
    setValue(YEAR, '')
  }

  //React Select Dropdown Styles
  const customDropdownStyles = {
    control: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
    }),
    input: (provided: any) => ({
      ...provided,
      height: '42px',
      margin: '0px !important',
    }),
    option: (provided: any) => ({
      ...provided,
      padding: '8px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  }

  useEffect(() => {
    setValue(MONTH, selectedMonthYear?.month)
    setValue(YEAR, selectedMonthYear?.year)
  }, [])

  const status = watch(LEAVE_STATUS)

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.markAllDayModalContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
            setIsEditing && setIsEditing(false)
          }}
        />
        <div className={styles.markAllDayContainer}>
          <p className={styles.title}>Mark For All Days</p>
          <Divider customClass={styles.dividerStyle} />
        </div>
        <form
          className={styles.markAllDaysForm}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.formRowContainer}>
            <div className={styles.formFieldContainer}>
              <div className={styles.radioFieldMainContainer}>
                <label className={styles.radioFieldName}></label>
                <div className={styles.radioFieldContainer}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="present"
                    value="PRESENT"
                    checked={attendenceType === 'PRESENT'}
                    {...register(ATTENDENCE, markAllDayValidator[ATTENDENCE])}
                    onChange={handleAttendenceTypeChange}
                  />
                  <label
                    className={
                      attendenceType === 'PRESENT'
                        ? styles.selectText
                        : styles.radioLable
                    }
                  >
                    Present
                  </label>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="absent"
                    value="ABSENT"
                    checked={attendenceType === 'ABSENT'}
                    {...register(ATTENDENCE, markAllDayValidator[ATTENDENCE])}
                    onChange={handleAttendenceTypeChange}
                  />
                  <label
                    className={
                      attendenceType === 'ABSENT'
                        ? styles.selectText
                        : styles.radioLable
                    }
                  >
                    Absent
                  </label>
                </div>
              </div>
              {errors[ATTENDENCE] && (
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  <p className="dashboardFormError">
                    {errors[ATTENDENCE].message}
                  </p>
                </div>
              )}
            </div>
          </div>
          {attendenceType === 'PRESENT' ? (
            <>
              {/* PRESENT Field */}
              <div className={styles.formRowContainer}>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={MONTH} className={styles.formLabel}>
                      Month<span className="asterick">*</span>
                    </label>
                    <input
                      disabled
                      className={styles.inputField}
                      {...register(MONTH, markAllDayValidator[MONTH])}
                    />
                  </div>
                  {errors[MONTH] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[MONTH].message}
                      </p>
                    </div>
                  )}
                </div>
                {/*  Year */}
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={YEAR} className={styles.formLabel}>
                      Select Year<span className="asterick">*</span>
                    </label>
                    <input
                      disabled
                      className={styles.inputField}
                      {...register(YEAR, markAllDayValidator[YEAR])}
                    />
                  </div>
                  {errors[YEAR] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[YEAR].message}
                      </p>
                    </div>
                  )}
                </div>

                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={START_TIME} className={styles.formLabel}>
                      Start time<span className="asterick">*</span>
                    </label>
                    <input
                      type="time"
                      className={styles.inputField}
                      {...register(START_TIME, markAllDayValidator[START_TIME])}
                    />
                  </div>
                  {errors[START_TIME] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[START_TIME].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={END_TIME} className={styles.formLabel}>
                      End time<span className="asterick">*</span>
                    </label>
                    <input
                      type="time"
                      className={styles.inputField}
                      {...register(END_TIME, markAllDayValidator[END_TIME])}
                    />
                  </div>
                  {errors[END_TIME] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[END_TIME].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={START_DATE} className={styles.formLabel}>
                      Start Date<span className="asterick">*</span>
                    </label>
                    <input
                      type="date"
                      // max={
                      //   watchEndData &&
                      //   (new Date(watchEndData)?.toISOString().split('T')[0] ??
                      //     '')
                      // }
                      className={styles.inputField}
                      {...register(
                        START_DATE,
                        markAllDayValidator[START_DATE] as any
                      )}
                    />
                  </div>
                  {errors[START_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[START_DATE].message as any}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={END_DATE} className={styles.formLabel}>
                      End Date<span className="asterick">*</span>
                    </label>
                    <input
                      type="date"
                      // min={
                      //   watchStartDate &&
                      //   new Date(watchStartDate).toISOString().split('T')?.[0]
                      // }
                      className={styles.inputField}
                      {...register(
                        END_DATE,
                        markAllDayValidator[END_DATE] as any
                      )}
                    />
                  </div>
                  {errors[END_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[END_DATE].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Absent Field */}
              <div className={styles.formRowContainer}>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={MONTH} className={styles.formLabel}>
                      Month<span className="asterick">*</span>
                    </label>
                    <input disabled className={styles.inputField} />
                  </div>
                  {errors[MONTH] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[MONTH].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={YEAR} className={styles.formLabel}>
                      Select Year<span className="asterick">*</span>
                    </label>
                    <input disabled className={styles.inputField} />
                  </div>
                  {errors[YEAR] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[YEAR].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={START_DATE} className={styles.formLabel}>
                      Start Date<span className="asterick">*</span>
                    </label>
                    <input
                      type="date"
                      // min={new Date().toISOString().split('T')?.[0]}
                      // max={
                      //   watchEndData &&
                      //   (new Date(watchEndData)?.toISOString().split('T')[0] ??
                      //     '')
                      // }
                      className={styles.inputField}
                      {...register(
                        START_DATE,
                        markAllDayValidator[START_DATE] as any
                      )}
                    />
                  </div>
                  {errors[START_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[START_DATE].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={END_DATE} className={styles.formLabel}>
                      End Date<span className="asterick">*</span>
                    </label>
                    <input
                      type="date"
                      // min={
                      //   watchStartDate &&
                      //   new Date(watchStartDate).toISOString().split('T')?.[0]
                      // }
                      className={styles.inputField}
                      {...register(
                        END_DATE,
                        markAllDayValidator[END_DATE] as any
                      )}
                    />
                  </div>
                  {errors[END_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[END_DATE].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={LEAVE_STATUS} className={styles.formLabel}>
                      Status<span className="asterick">*</span>
                    </label>
                    <Select
                      className={styles.selectInputField}
                      placeholder={
                        <div className="selectPlaceholder">Select Status</div>
                      }
                      {...register(
                        LEAVE_STATUS,
                        markAllDayValidator[LEAVE_STATUS]
                      )}
                      isClearable={true}
                      options={StatusArray?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      components={animatedComponent}
                      closeMenuOnSelect={true}
                      maxMenuHeight={130}
                      styles={customDropdownStyles}
                      onChange={(e: any) => {
                        setValue(LEAVE_STATUS, e.value)
                        trigger(LEAVE_STATUS)
                      }}
                    />
                  </div>
                  {errors[LEAVE_STATUS] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[LEAVE_STATUS].message}
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label htmlFor={SALARY_TYPE} className={styles.formLabel}>
                      Leave Type<span className="asterick">*</span>
                    </label>
                    <Select
                      className={styles.selectInputField}
                      placeholder={
                        <div className="selectPlaceholder">
                          Select Leave Type
                        </div>
                      }
                      {...register(
                        SALARY_TYPE,
                        markAllDayValidator[SALARY_TYPE]
                      )}
                      isClearable={true}
                      options={masterValueData
                        ?.find(
                          (item: any) => item?.category_name === 'LEAVE-TYPE'
                        )
                        ?.values?.map((item: any) => ({
                          label: item?.value,
                          value: item?._id,
                        }))}
                      components={animatedComponent}
                      closeMenuOnSelect={true}
                      maxMenuHeight={130}
                      styles={customDropdownStyles}
                      onChange={(e: any) => {
                        setValue(SALARY_TYPE, e.label)
                        trigger(SALARY_TYPE)
                      }}
                      isDisabled={status === 'UNPAID' ? true : false}
                    />
                  </div>
                  {errors[SALARY_TYPE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[SALARY_TYPE].message}
                      </p>
                    </div>
                  )}
                </div>

                <div className={styles.textareaFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={REASON_FOR_LEAVE}
                      className={styles.formLabel}
                    >
                      Reason<span className="asterick">*</span>
                    </label>
                    <textarea
                      className={styles.textareaInputField}
                      placeholder="Enter Reason For Leave"
                      {...register(
                        REASON_FOR_LEAVE,
                        markAllDayValidator[REASON_FOR_LEAVE]
                      )}
                      onChange={(e) => trimValue(e)}
                    />
                  </div>
                  {errors[REASON_FOR_LEAVE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpanTextarea}></span>
                      <p className="dashboardFormError">
                        {errors[REASON_FOR_LEAVE].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className={styles.btnContainer}>
            <Button
              title="Submit"
              type="submit"
              customClass={styles.submitBtn}
            />
            <Button
              title="Reset"
              type="reset"
              customClass={styles.resetBtn}
              handleClick={handleReset}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default MarkAllDaysModal
