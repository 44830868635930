import { FC, useState } from 'react'

import { colors } from '../../../../constants/color'
import styles from './trialBalanceViewModal.module.scss'
import { CloseIcon } from '../../../../components/common/svg-components'
import { viewBalanceTableHeaderData } from '../trialBalanceTableData'
import Pagination from '../../../../components/common/pagination/Pagination'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'

interface IbalanceViewModalProps {
  heading?: string
  message?: string
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void
  popData?: string | any
}

const TrialBalanceViewModal: FC<IbalanceViewModalProps> = ({
  heading,
  handleClose,
  popData,
}) => {
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  // Credit Amount
  const totalCreditAmount = popData?.viewBalance?.reduce(
    (sum: any, row: any) => {
      const creditAmount = parseFloat(row?.credit_amount)
      return sum + creditAmount
    },
    0
  )
  // Debit Amount
  const totalDebitAmount = popData?.viewBalance?.reduce(
    (sum: any, row: any) => {
      const debitAmount = parseFloat(row?.debit_amount)
      return sum + debitAmount
    },
    0
  )

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  return (
    <div
      className={styles.balanceViewModalContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose()
        }}
      />
      <h1 className={styles.balanceViewModalHeading}>
        {popData?.viewBalance?.[0]?.accounts_data?.name}
      </h1>
      <hr className={styles.balanceViewDivider} />
      <div className={styles.tableData}>
        <TableV2
          tableHeaderData={viewBalanceTableHeaderData}
          tableRowData={popData?.viewBalance ?? []}
          active={false}
        />
      </div>
      {/* Pagination */}
      {popData?.viewBalance?.length > 9 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
      <div className={styles.tableLabelContainer}>
        <span className={styles.debitText}>
          Total Debit Amount: $ {totalDebitAmount}
        </span>
        <span className={styles.creditText}>
          Total Credit Amount: $ {totalCreditAmount}
        </span>
      </div>
    </div>
  )
}

export default TrialBalanceViewModal
