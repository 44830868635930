import { FC, useState, useEffect } from 'react'

import { colors } from '../../../../constants/color'
import styles from './profitLossExpenseModal.module.scss'
import { CloseIcon } from '../../../../components/common/svg-components'
import Pagination from '../../../../components/common/pagination/Pagination'
import { expenseBalanceTableHeaderData } from '../profitLossTableData'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'

interface IProfitLossExpenseModal {
  heading?: string
  message?: string
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void
  popData?: string | any
}

const ProfitLossExpenseModal: FC<IProfitLossExpenseModal> = ({
  heading,
  handleClose,
  popData,
}) => {
  const [totalDebitAmount, setTotalDebitAmount] = useState(0)
  const [totalCreditAmount, setTotalCreditAmount] = useState(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  // Debit Amount
  useEffect(() => {
    const calculatedTotaldebitAmount = popData?.viewBalance?.reduce(
      (sum: any, row: any) => {
        const debitAmount = parseFloat(row?.debit_amount)
        return sum + debitAmount
      },
      0
    )
    setTotalDebitAmount(calculatedTotaldebitAmount)
    const calculatedTotalcreditAmount = popData?.viewBalance?.reduce(
      (sum: any, row: any) => {
        const credit = parseFloat(row?.credit_amount)
        return sum + credit
      },
      0
    )
    setTotalCreditAmount(calculatedTotalcreditAmount)
  }, [popData?.viewBalance])

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()
  return (
    <div
      className={styles.balanceViewModalContainer}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose()
        }}
      />
      <h1 className={styles.balanceViewModalHeading}>Expense Details </h1>
      <hr className={styles.balanceViewDivider} />
      <div className={styles.tableData}>
        <TableV2
          tableHeaderData={expenseBalanceTableHeaderData ?? []}
          tableRowData={popData?.viewBalance ?? []}
          active={false}
        />
      </div>
      {/* Pagination */}
      {popData?.viewBalance?.length > 10 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      )}
      <div className={styles.tableLabelContainer}>
        <span className={styles.creditText}>
          Total Credit Amount: $ {totalCreditAmount}
        </span>
        <span className={styles.debitText}>
          Total Debit Amount: $ {totalDebitAmount}
        </span>
      </div>
    </div>
  )
}

export default ProfitLossExpenseModal
