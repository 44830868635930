import {
  addCampaign,
  searchCriteria,
  getAllCampaign,
  updateCampaign,
  getLeadsForCampaign,
  assignAgentToLeads,
} from './campaignAsyncActions'
import { createSlice } from '@reduxjs/toolkit'

export interface ICampaign {
  isLoading: boolean
  addCampaignData: string
  searchCriteriaData: []
  allCampaignData: []
  updateCampaignData: {}
  error: any
  leadsData: any[]
  total: any
}
export const initialState: ICampaign = {
  isLoading: false,
  addCampaignData: '',
  searchCriteriaData: [],
  allCampaignData: [],
  updateCampaignData: {},
  error: null,
  leadsData: [],
  total: 0,
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    clearCampaignData: (state) => {
      state.allCampaignData = []
      state.updateCampaignData = {}
      state.searchCriteriaData = []
      state.leadsData = []
      state.total = 0
    },
    getupdateCampaignData: (state, action) => {
      state.updateCampaignData = action.payload
    },
    clearUpdateCampaignData: (state) => {
      state.updateCampaignData = {}
    },
  },
  extraReducers(builder) {
    // ADD CAMPAIGNS
    builder
      .addCase(addCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addCampaign.fulfilled, (state, action) => {
        state.isLoading = false
        state.addCampaignData = action.payload
      })
      .addCase(addCampaign.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // SEARCH CRITERIA
    builder
      .addCase(searchCriteria.pending, (state) => {
        state.isLoading = true
      })
      .addCase(searchCriteria.fulfilled, (state, action) => {
        state.isLoading = false
        state.searchCriteriaData = action.payload?.data
      })
      .addCase(searchCriteria.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // GET ALL CAMPAIGNS
    builder
      .addCase(getAllCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAllCampaign.fulfilled, (state, action) => {
        state.isLoading = false
        state.allCampaignData = action.payload?.data
        state.total = action.payload?.total ? action.payload?.total : 0
        // console.log('action.payload?.data', action.payload?.data)
      })
      .addCase(getAllCampaign.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
    // UPDATE CAMPAIGNS
    builder
      .addCase(updateCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.isLoading = false
        state.addCampaignData = action.payload
      })
      .addCase(updateCampaign.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    // GET LEADS FOR CAMPAIGNS
    builder
      .addCase(getLeadsForCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getLeadsForCampaign.fulfilled, (state, action) => {
        state.isLoading = false
        state.leadsData = action.payload?.data
      })
      .addCase(getLeadsForCampaign.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })

    // ASSIGN AGENT TO LEADS
    builder
      .addCase(assignAgentToLeads.pending, (state) => {
        state.isLoading = true
      })
      .addCase(assignAgentToLeads.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(assignAgentToLeads.rejected, (state, error) => {
        state.isLoading = false
        state.error = error
      })
  },
})

export const {
  clearUpdateCampaignData,
  clearCampaignData,
  getupdateCampaignData,
} = campaignSlice.actions
export default campaignSlice.reducer
