import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import styles from './tableData.module.scss'

export const reportMappingTableHeaderData: any = [
  {
    Header: 'ROLE',
    accessor: 'name',
  },
  {
    Header: 'REPORT TYPE',
    aaccessor: 'report_typr',
    Cell: ({ row }: any) => {
      const reports_access: any = row?.original?.reports_access
      return reports_access && reports_access?.length > 0
        ? reports_access?.map((item: any, index: number) => {
            return (
              <div key={`report-${index}`} className={styles.columnFlex}>
                <span>
                  {index + 1}. {item}
                </span>
              </div>
            )
          })
        : '-'
    },
  },
  {
    Header: 'ACTIONS',
    Cell: (props: any) => {
      const handleEdit = (item: any) => {
        props.onClick(item)
      }
      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={() => handleEdit(props?.row?.original)}
        />
      )
    },
  },
]
