import { FC, useState } from 'react';
import styles from './confirmpurchaseinvoicepopup.module.scss';
import Divider from '../../../components/common/divider/Divider';
import { colors } from '../../../constants/color';
import { CloseIcon } from '../../../components/common/svg-components';
import Button from '../../../components/common/button/Button';
import TableV2 from '../../../components/common/table/tableV2/TableV2';
import { purchaseInvoiceHeaderData } from '../../../constants/table-data/purchaseInvoiceTableData';
import Popup from '../../../components/common/popup/Popup';
import PurchaseCreatedPopup from '../purchase-created/PurchaseCreated';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getPurchaseInvoice } from '../../../redux/features/purchase-invoice/purchaseAsyncActions';
import { requestGenerator } from '../../../utils/payloadGenerator';
import { CREATE_PURCHASE_INVOICE } from '../../../constants/asyncActionsType';
import { setMessage } from '../../../redux/features/toast/toastSlice';
import { failure, success } from '../../../constants/data';
import { clearStates } from '../../../redux/features/purchase-invoice/purchaseInvoiceSlice';
import Loader from '../../../components/common/spinner/Loader';
interface IConfirmPurchaseInvoicePopup {
  handleClose?: any;
  popData?: any;
  setModelOpenClose?: any;
}

const ConfirmPurchaseInvoicePopup: FC<IConfirmPurchaseInvoicePopup> = ({
  handleClose,
  popData,
  setModelOpenClose,
}) => {
  const [purchasePopup, setPurchasePopup] = useState(false);

  const { conformPurchaseInvoiceList, isLoading } = useAppSelector(
    (state) => state.purchaseInvoice
  );

  const dispatch = useAppDispatch();

  // Close Purchase Created Popup
  const handleModalClose1 = () => {
    setPurchasePopup(!purchasePopup);
  };

  // Genetarate Purchase Invoice API
  const handlePurchaseInvoice = () => {
    const receivedItems = conformPurchaseInvoiceList?.flatMap(
      (received_items: any) => {
        return received_items?.received_items;
      }
    );

    let demo = receivedItems?.some((item: any) => {
      console.log(item, 'item');
      return isNaN(item?.price);
    });

    if (!demo) {
      const requestedPaylod = {
        supplier_doc_no: popData?.doc_no,
        file: popData?.file,
        grn_items: conformPurchaseInvoiceList?.map((items: any) => {
          return {
            _id: items?._id,
            received_items: items?.received_items,
          };
        }),
        type: 'INVENTORY_PURCHASE',
      };

      dispatch(getPurchaseInvoice(requestGenerator(requestedPaylod))).then(
        (e) => {
          if (e.type === `${CREATE_PURCHASE_INVOICE}/fulfilled`) {
            setPurchasePopup(!purchasePopup);
            dispatch(clearStates());
            setModelOpenClose('');
            dispatch(
              setMessage({
                message: 'Purchase_invoice Generated',
                type: success,
              })
            );
          }
        }
      );
    } else {
      dispatch(
        setMessage({
          message: 'Please Enter Price',
          type: failure,
        })
      );
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {purchasePopup && (
        <Popup
          Children={PurchaseCreatedPopup}
          handleClose={() => handleModalClose1()}
        />
      )}

      <div
        className={styles.PurchaseInvoicePopupMainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.paymentContainer}>
          <p className={styles.title}> Confirm Purchase Invoice</p>
          <Divider customClass={styles.dividerStyle} />
        </div>

        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={purchaseInvoiceHeaderData}
            tableRowData={conformPurchaseInvoiceList}
            active={false}
          />
        </div>

        <div className={styles.button}>
          <Button title="Submit" handleClick={handlePurchaseInvoice} />
        </div>
      </div>
    </>
  );
};

export default ConfirmPurchaseInvoicePopup;
