export const IpdRequestHeaderData: any = [
  {
    Header: "TEST TYPE",
    accessor: "test_type",
  },
  {
    Header: "TEST NAME",
    accessor: "test_name",
  },
  {
    Header: "PRIORITY",
    accessor: "priority",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
  {
    Header: "BILLABLE",
    Cell: (props: any) => {
      return <>{props.row.original.is_billable ? "Yes" : "No"}</>;
    },
  },
  {
    Header: "NOTES",
    Cell: (props: any) => {
      return <>{props.row.original.test_notes?.length ? props.row.original.test_notes : '-'}</>;
    },
  },
];
