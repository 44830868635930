import moment from 'moment'
import { EditIcon } from '../../components/common/svg-components'
import { useAppDispatch } from '../../hooks'
import { reportBuilderFormData, updateBaseTableColumn, updateBaseTableOrder } from '../../redux/features/role/roleSlice'
import { useNavigate } from 'react-router-dom'
import { colors } from '../color'
import ToggleSwitchV2 from '../../components/common/toggle-switch/ToggleSwitchV2'
import { getReportBuilderList, updateReportStatusById } from '../../redux/features/role/roleAsynActions'
import { requestGenerator } from '../../utils/payloadGenerator'

export const reportBuilderHeaderData: any = [
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'CREATED AT',
    Cell: ({ row }: any) => {
      const convertDate = moment(row?.original?.createdAt).format('DD-MMM-YYYY')
      return <>{row?.original?.createdAt ? <span>{convertDate}</span> : '-'}</>
    },
  },
  {
    Header: 'ACTIONS',
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const navigate = useNavigate()

      const handleEdit = () => {
        if (props.row.original?._id && props.row.original?.raw_aggregator_json) {
          const reportFormData = {
            ...props.row.original?.raw_aggregator_json,
            id: props.row.original?._id
          }
          dispatch(reportBuilderFormData(reportFormData))
          dispatch(updateBaseTableColumn(props.row.original?.raw_aggregator_json?.selctedBaseTableColumns))
          dispatch(updateBaseTableOrder(props.row.original?.raw_aggregator_json?.selctedBaseTableOrders))
          navigate('/reportbuilder/createreport')
        }
      }
      return (
        <EditIcon
          fillColor={colors.grey4}
          handleClick={handleEdit}
        />
      )
    },
  },
  {
    Header: 'STATUS',
    Cell: (props: any) => {
      const is_active = props?.row?.original?.is_active
      const report_id = props?.row?.original?._id
      const dispatch = useAppDispatch()

      const handleToggle = () => {
        const payload = {
          id: report_id,
          data: { is_active: !is_active },
        }
        dispatch(updateReportStatusById(requestGenerator(payload))).then(
          (res) => {
            if (res.type === 'role/updateReportStatus/fulfilled') {
              dispatch(getReportBuilderList(requestGenerator({})))
            }
          }
        )
      }
      return (
        <>
          <ToggleSwitchV2
            isToggled={is_active}
            handleToggle={() => handleToggle()}
          />
        </>
      )
    },
  },
]
