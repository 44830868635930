import { EyeIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { isBoldRow, isBoldRowiWithTotal } from '../../../constants/data'
import styles from './trialBalance.module.scss'
import { original } from '@reduxjs/toolkit'

// Old  Trial Balance Table Data

// export const trialBalanceTableHeaderData: any = [
//   {
//     Header: 'PARTICULAR MONTH',
//     accessor: (row: any) => {
//       return row?._id ?? '-'
//     },
//   },
//   {
//     Header: 'CLOSING BALANCE',
//     columns: [
//       {
//         Header: 'DEBIT AMOUNT',
//         Cell: ({ row }: any) => {
//           return (
//             <>{row?.original?.debit_amount ? row?.original?.debit_amount : 0}</>
//           )
//         },
//       },
//       {
//         Header: 'CREDIT AMOUNT',
//         Cell: ({ row }: any) => {
//           return (
//             <>
//               {row?.original?.credit_amount ? row?.original?.credit_amount : 0}
//             </>
//           )
//         },
//       },
//     ],
//   },

//   {
//     Header: 'VIEW',
//     Cell: (props: any) => {
//       const viewObject = {
//         viewBalance: props?.row?.original.voucher,
//       }
//       return (
//         <>
//           <EyeIcon
//             fillColor={colors.grey1}
//             customClass={styles.openEyeIcon}
//             handleClick={() => props.onClick(viewObject)}
//           />
//         </>
//       )
//     },
//   },
// ]

export const viewBalanceTableHeaderData: any = [
  {
    Header: 'VOUCHER NO.',
    accessor: (row: any) => {
      return row?.number ?? ''
    },
  },
  {
    Header: 'DEBIT BALANCE',
    accessor: (row: any) => {
      return row?.debit_amount ?? ''
    },
  },
  {
    Header: 'CREDIT BALANCE',
    accessor: (row: any) => {
      return row?.credit_amount ?? ''
    },
  },
]

// New Client Change

export const trialBalanceTableHeaderData: any = [
  {
    Header: 'A/C NO.',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: 'DESCRIPTION',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: 'OPENING BALANCE',
    columns: [
      {
        Header: 'Opening Dr',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                ? styles.boldTd
                : styles.normalStyleTd
              }
            >
              {row?.original?.open?.debit
                ? row?.original?.open?.debit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
      {
        Header: 'Opening Cr',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                ? styles.boldTd
                : styles.normalStyleTd
              }
            >
              {row?.original?.open?.credit
                ? row?.original?.open?.credit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
    ],
  },
  {
    Header: 'CURRENT BALANCE',
    columns: [
      {
        Header: 'Dr',
        accessor: 'current_debit',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                ? styles.boldTd
                : styles.normalStyleTd
              }
            >
              {row?.original?.current?.debit
                ? row?.original?.current?.debit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
      {
        Header: 'Cr',
        accessor: 'current_credit',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                ? styles.boldTd
                : styles.normalStyleTd
              }
            >
              {row?.original?.current?.credit
                ? row?.original?.current?.credit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
    ],
  },

  {
    Header: 'CLOSING BALANCE',
    columns: [
      {
        Header: 'Closing Dr',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                ? styles.boldTd
                : styles.normalStyleTd
              }
            >
              {row?.original?.close?.debit
                ? row?.original?.close?.debit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
      {
        Header: 'Closing Cr',
        Cell: ({ row }: any) => {
          return (
            <p
              className={
                isBoldRowiWithTotal.includes(row?.original?.level)
                ? styles.boldTd
                : styles.normalStyleTd
              }
            >
              {row?.original?.close?.credit
                ? row?.original?.close?.credit
                : isBoldRow.includes(row?.original?.level)
                ? ''
                : 0}
            </p>
          )
        },
      },
    ],
  },
]
