import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../../components/common/button/Button'
import { failure, medicalNewsArray } from '../../../constants/data'
import styles from './mobileAppNews.module.scss'
import {
  NEWS_TITLE,
  NEWS_DESCRIPTION,
} from '../../../constants/mobileAppConfigurationConstant'
import { IMedicalCenterNewsForm } from '../../../interfaces/mobileAppConfigurationInterfaces'
import { medicalCenterNewsValidators } from '../../../form-validators/mobileAppConfigurationValidators'
import { CREATE_MEDICAL_CENTER_NEWS } from '../../../constants/asyncActionsType'
import {
  createMedicalCenterNews,
  getAllMedicalCenterNews,
} from '../../../redux/features/mobile_app_configuration/mobileAppConfigurationAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import Loader from '../../../components/common/spinner/Loader'
import { removeProperties } from '../../../utils/utils'
import { clearState } from '../../../redux/features/mobile_app_configuration/mobileAppConfigurationSlice'
import { setMessage } from '../../../redux/features/toast/toastSlice'

const MobileAppNews: FC = () => {
  const dispatch = useAppDispatch()
  const { isLoading, medicalCenterNewsData } = useAppSelector(
    (state) => state.mobileAppConfig
  )

  // get medical news data
  useEffect(() => {
    dispatch(getAllMedicalCenterNews(requestGenerator({})))
  }, [dispatch])

  // Form Handiling using react hook form
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IMedicalCenterNewsForm>()

  // set default form data
  useEffect(() => {
    if (medicalCenterNewsData.length > 0) {
      const arr = medicalCenterNewsData?.map((item: any, number: number) => {
        return {
          title: item?.title,
          description: item?.description,
        }
      })
      let formdata = { ...arr }
      reset(formdata)
    } else {
      const obj = { title: '', description: '' }
      const formData = medicalNewsArray?.map((item: any) => {
        return obj
      })
      let formDataMap: any = { ...formData }
      reset(formDataMap)
    }
  }, [reset, medicalCenterNewsData])

  // Envoke on submit the form
  const onSubmit: SubmitHandler<IMedicalCenterNewsForm> = (data) => {
    const payload = Object.values(data)
    let valid = false
    let message = 'Please add at least one news with all details'
    let finalPayload: any[] = []
    if (payload?.some((item) => item?.title && item?.description)) {
      finalPayload = payload?.map((item, index) => {
        if (item?.title && item?.description) {
          valid = true
          return item
        } else if (!item?.title && item?.description) {
          valid = false
          message = `Please provide a title for news ${index + 1}`
          return undefined
        } else if (item?.title && !item?.description) {
          valid = false
          message = `Please provide a description for news ${index + 1}`
          return undefined
        }
      })
    } else {
      finalPayload = []
      valid = false
      message = 'Please add at least one news with all details'
    }
    if (finalPayload && finalPayload.length > 0 && valid) {
      const reqPayload = finalPayload?.filter((item) => item !== undefined)
      console.log('reqPayload', reqPayload)
      dispatch(createMedicalCenterNews(requestGenerator(reqPayload))).then(
        (e) => {
          if (e.type === `${CREATE_MEDICAL_CENTER_NEWS}/fulfilled`) {
            reset()
            dispatch(getAllMedicalCenterNews(requestGenerator({})))
          }
        }
      )
    } else {
      dispatch(
        setMessage({
          message,
          type: failure,
        })
      )
    }
  }

  // Function For Trim Input Field Data
  const handleChange = (e: any) => {
    const value = e.target.value
    if (value.length === 1 && value === ' ') {
      e.target.value = ''
    } else if (
      value.length > 1 &&
      value[0] === ' ' &&
      value[value.length - 1] === ' '
    ) {
      e.target.value = value.trim()
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.mobileAppNewsContainer}>
        <h1 className={styles.mobileAppNewsTitle}>Medical Center News</h1>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.mobileAppNewsForm}
        >
          {medicalNewsArray.map((medicalNews) => {
            return (
              <div key={medicalNews.id} className={styles.formContainer}>
                <p className={styles.newsName}>{medicalNews.name}</p>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${medicalNews.id}.${NEWS_TITLE}`}
                      className={styles.formLabel}
                    >
                      News Title
                    </label>
                    <input
                      type="text"
                      placeholder="Enter News Title"
                      className={styles.formInput}
                      {...register(`${medicalNews.id}.${NEWS_TITLE}`)}
                      autoComplete="off"
                      onChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </div>
                </div>
                <div className={styles.formFieldContainer}>
                  <div className={styles.inputFieldContainer}>
                    <label
                      htmlFor={`${medicalNews.id}.${NEWS_DESCRIPTION}`}
                      className={styles.formLabel}
                    >
                      Description
                    </label>
                    <textarea
                      className={styles.textArea}
                      placeholder="Enter Description"
                      {...register(`${medicalNews.id}.${NEWS_DESCRIPTION}`)}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
            )
          })}
          <div className={styles.formButtonContainer}>
            <Button title="Submit" type="submit" />
            <Button
              title="Reset"
              type="button"
              customClass={styles.resetBtn}
              handleClick={() => {
                dispatch(clearState())
              }}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default MobileAppNews
