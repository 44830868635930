import { FC } from 'react'
import styles from './landingPage.module.scss'
import loginBackgroundImage from '../../../assets/images/loginImageHealthbox.png'
import { Outlet } from 'react-router'

interface ILandingPage {}
const LandingPage: FC<ILandingPage> = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.formStyleContainer}>
          <div className={styles.formStyle}>
            <Outlet />
          </div>
        </div>
        <div className={styles.landingPageImageContainer}>
          <img
            src={loginBackgroundImage}
            className={styles.landingPageImageStyle}
            alt="error to display"
          />
        </div>
      </div>
    </>
  )
}

export default LandingPage
