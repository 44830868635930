import { FC, useEffect, useState } from 'react'
import styles from './departmentServicesPopup.module.scss'
import {
  CheckIcon,
  CloseIcon,
  SearchButton,
  UncheckIcon,
} from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import {
  blockInvalidCharacter,
  disableArrowKey,
  trimValue,
} from '../../../../utils/utils'
import TableV2 from '../../table/tableV2/TableV2'
import Button from '../../button/Button'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getPlanAllService } from '../../../../redux/features/insurance/insuranceAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Pagination from '../../pagination/Pagination'
import Loader from '../../spinner/Loader'
import { departmentServiceHeaderData } from '../../../../constants/table-data/departmentServiceData'
import {
  addDepartmentService,
  clearDepartmentServiceData,
  handleActive,
  handleAddDiscount,
  handleAddToNewArray,
  updatedDeptService,
} from '../../../../redux/features/insurance/insuranceSlice'
import Select from 'react-select'
import { GET_PLAN_ALL_SERVICE } from '../../../../constants/asyncActionsType'
import { reactSelectStyle } from '../../../../constants/data'

interface IDepartmentServices {
  handleClose?: any
  popData?: any
  setModelOpenClose?: any
}

const DepartmentServicesPopup: FC<IDepartmentServices> = ({
  handleClose,
  setModelOpenClose,
}) => {
  const dispatch = useAppDispatch()
  const {
    isLoading,
    departmentServiceData,
    palnDepartmentList,
    selDeptSrv,
    selectedServiceId,
  } = useAppSelector((state) => state.insurance)
  const [service, setService] = useState('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [dept, setDept] = useState({
    label: palnDepartmentList[0]?.department_name,
    value: palnDepartmentList[0]?.department_id,
  })
  const [deptList, setDeptList] = useState<any>(selDeptSrv)
  const [focusDiscount, setFocusDiscount] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (selDeptSrv?.length > 0) {
      setDeptList(selDeptSrv)
    } else {
      setDeptList([])
    }
  }, [selDeptSrv])

  //** This useeffect is commented to resolve issue, issue is service selection should be persist on submit and close popup */
  // useEffect(() => {
  //   let filterData = selDeptSrv?.filter((obj: any) =>
  //     palnDepartmentList?.some(
  //       (item: any) => item?.department_id === obj?.department_id
  //     )
  //   )
  //   dispatch(addDepartmentService(filterData))
  // }, [palnDepartmentList])

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    if (service === '') {
      let payload = {
        department_id: dept?.value,
        search: '',
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(getPlanAllService(requestGenerator(payload))).then((result) => {
        if (result.type === `${GET_PLAN_ALL_SERVICE}/fulfilled`) {
          setTotalPage(result.payload.lastPage)
          let updatedArray =
            result?.payload?.data && result?.payload?.data?.length > 0
              ? result?.payload?.data?.map((object: any) => {
                  let filterData = selDeptSrv?.filter(
                    (item: any) => item?._id === object?._id
                  )
                  if (selectedServiceId?.includes(object._id)) {
                    return {
                      ...object,
                      checked: true,
                      discounted_price: filterData[0]?.discounted_price,
                    }
                  } else {
                    return {
                      ...object,
                      discounted_price: object?.price
                        ? object?.price
                        : object?.sell_price ?? 0,
                    }
                  }
                })
              : []
          dispatch(updatedDeptService(updatedArray))
        }
      })
    }
  }, [service, pageIndex, dataPerPage, dept])

  const handleSearch = () => {
    let payload = {
      department_id: dept?.value,
      search: service,
      page: 1,
      pageSize: 10,
      is_active: true,
    }
    dispatch(getPlanAllService(requestGenerator(payload))).then((result) => {
      setTotalPage(result.payload.lastPage)
      const updatedArray =
        result?.payload?.data && result?.payload?.data?.length > 0
          ? result?.payload?.data?.map((object: any) => {
              let filterData = selDeptSrv?.filter(
                (item: any) => item?._id === object?._id
              )
              if (selectedServiceId?.includes(object._id)) {
                return {
                  ...object,
                  checked: true,
                  discounted_price: filterData[0]?.discounted_price,
                }
              } else {
                return {
                  ...object,
                  discounted_price: object?.price
                    ? object?.price
                    : object?.sell_price ?? 0,
                }
              }
            })
          : []
      dispatch(updatedDeptService(updatedArray))
    })
  }

  const handleSubmit = () => {
    if (error === false) {
      setTimeout(() => {
        setModelOpenClose(false)
        dispatch(clearDepartmentServiceData())
      }, 200)
      dispatch(addDepartmentService(deptList))
    }
  }

  const handleDiscount = (e: any, _id: any) => {
    let newId = _id
    let newData = deptList?.map((item: any) => {
      if (item?._id === newId) {
        let updateData = {
          ...item,
          discounted_price: e.target.value !== '' ? e.target.value : null,
        }
        return updateData
      } else {
        return item
      }
    })
    setDeptList(newData)
    let data: any = {
      value: e.target.value,
      _id: _id,
    }
    dispatch(handleAddDiscount(data))
  }

  const handleDiscountFocus = (_id: any) => {
    setFocusDiscount(_id)
  }

  const departmentServiceHeaderData: any = [
    {
      Header: 'SERVICE ID',
      accessor: 'service_no',
      Cell: ({ row }: any) => {
        return (
          <p>
            {row?.original?.service_no
              ? row?.original?.service_no
              : row?.original?.test_no}
          </p>
        )
      },
    },
    {
      Header: 'SERVICE NAME',
      accessor: 'name',
    },
    {
      Header: 'PRICE',
      accessor: 'cost',
      Cell: ({ row }: any) => {
        return (
          <p>
            {row?.original?.price
              ? row?.original?.price
              : row?.original?.sell_price ?? 0}
          </p>
        )
      },
    },
    // {
    //   Header: 'DISCOUNTED PRICE',
    //   accessor: 'disounted_price',
    // },
    {
      Header: 'DISCOUNTED PRICE',
      accessor: 'discounted_price',
      Cell: ({ row }: any) => {
        const _id = row?.original?._id
        let discountVal = Number(row?.original?.discounted_price)
        if (discountVal < 0) {
          setError(true)
        } else {
          setError(false)
        }
        return (
          <>
            <input
              className={styles.inputField}
              value={row?.original?.discounted_price}
              type="number"
              onChange={(e) => handleDiscount(e, _id)}
              onFocus={() => handleDiscountFocus(_id)}
              autoFocus={focusDiscount === _id}
              onKeyDown={(e: any) => {
                disableArrowKey(e)
                blockInvalidCharacter(e)
              }}
              onWheel={(e: any) => {
                e.target.blur()
              }}
            />
            {discountVal < 0 && (
              <p className={styles.errorText}>Enter positive value</p>
            )}
          </>
        )
      },
    },
    {
      Header: 'SELECT',
      Cell: ({ row }: any) => {
        const dispatch = useAppDispatch()

        const handleCheck = (id: any, type: any) => {
          dispatch(handleActive(id))
          let data: any = {
            event: type,
            item: id,
          }
          dispatch(handleAddToNewArray(data))
        }

        return (
          <>
            {row?.original?.checked ? (
              <CheckIcon
                fillColor={colors.green1}
                handleClick={() =>
                  handleCheck(row?.original, row.original.checked)
                }
              />
            ) : (
              <UncheckIcon
                fillColor={colors.grey1}
                handleClick={() =>
                  handleCheck(row?.original, row.original.checked)
                }
              />
            )}
          </>
        )
      },
    },
  ]

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.popupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>Department Services</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tableSearchContainer}>
            <div className={styles.inputFieldContainer}>
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder="Search by service"
                onChange={(e) => {
                  trimValue(e)
                  setService(e.target.value)
                  setFocusDiscount('')
                }}
              />

              <Select
                className={styles.select}
                placeholder="Select Dept."
                closeMenuOnSelect={true}
                isSearchable={true}
                value={dept}
                maxMenuHeight={200}
                options={palnDepartmentList?.map((item: any) => ({
                  label: item.department_name,
                  value: item.department_id,
                }))}
                onChange={(e: any) => {
                  setDept(e)
                }}
                styles={reactSelectStyle}
              />

              <SearchButton
                handleClick={() => handleSearch()}
                customClass={styles.inputSearchButton}
                width={38}
              />
            </div>
            <div className={styles.table}>
              {}
              <TableV2
                tableHeaderData={departmentServiceHeaderData}
                tableRowData={departmentServiceData}
                active={false}
              />
            </div>
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
            {/* <div style={{ marginTop: '30px' }}>
              <TableV2
                tableHeaderData={selectedServiceHeaderData}
                tableRowData={deptList}
                active={false}
              />
            </div> */}
            <div style={{ margin: '20px auto', textAlign: 'center' }}>
              <Button title="Submit" handleClick={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DepartmentServicesPopup
