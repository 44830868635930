import moment from "moment";
import styles from "../style.module.scss";

export const ipdTestsData: any = [
  {
    Header: "DATE",
    accessor: (row: any) => {
      return moment(row?.job_date).format("DD-MMM-YYYY") ?? "-";
    },
  },
  {
    Header: "TEST DEPT.",
    accessor: "job_type",
  },
  {
    Header: "TEST NAME",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.profile)}
        >
          View
        </p>
      );
    },
  },
  {
    Header: "DOCTOR",
    accessor: (row: any) => {
      return row?.doctor_name ?? "-";
    },
  },
  {
    Header: "RESULTS",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original)}
        >
          View
        </p>
      );
    },
  },
];

export const ipdTestNameData: any = [
  {
    Header: "TEST ID",
    accessor: "test_no",
  },
  {
    Header: "TESTS",
    accessor: "test_name",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },
];

export const ipdTestResultsData: any = [
  {
    Header: "TEST NAME",
    accessor: (row: any) => {
      return row.test_name ?? "-";
    },
  },
  {
    Header: "OBSERVATIONS",
    accessor: (row: any) => {
      return row.result ?? "-";
    },
  },
  {
    Header: "REMARKS",
    accessor: (row: any) => {
      return row.remark ?? "-";
    },
  },
];
