import { useAppSelector } from '../../../hooks'

// Apply Leave Table Data
export const applyLeaveTableHeaderData: any = [
  {
    Header: 'Annual',
    Cell: (props: any) => {
      const { selectedObj } = useAppSelector((state) => state.employee)

      const { allLeaveTable, leaveDataInfo } = useAppSelector(
        (state) => state.leave
      )
      return (
        <>
          {leaveDataInfo?.leave_data?.days}
          {/* {allLeaveTable?.length > 0 ? props?.row?.original?.annual_leave : selectedObj?.company_details?.annual_leave} */}
        </>
      )
    },
  },
  {
    Header: 'Used',
    Cell: (props: any) => {
      const { selectedObj } = useAppSelector((state) => state.employee)
      const { allLeaveTable } = useAppSelector((state) => state.leave)
      console.log('allLeaveTable', allLeaveTable)
      return (
        <>
          {allLeaveTable?.length > 0 ? props?.row?.original[0]?.leave_count : 0}
        </>
      )
    },
  },
  {
    Header: 'Available',

    Cell: (props: any) => {
      const { selectedObj } = useAppSelector((state) => state.employee)
      const { allLeaveTable, leaveDataInfo } = useAppSelector(
        (state) => state.leave
      )
      const availbaleLeave =
        props?.row?.original?.annual_leave - props?.row?.original?.leave_count
      const defaultAvailable = selectedObj?.company_details?.annual_leave

      const totalLeave =
        allLeaveTable?.length > 0 ? props?.row?.original[0]?.leave_count : 0
      console.log('totalLeave', totalLeave)
      return <>{leaveDataInfo?.leave_data?.days - totalLeave}</>
    },

    // accessor: (row: any) => {
    //   const availbaleLeave = row?.annual_leave -  row?.leave_count;
    //   return `${availbaleLeave ?? 0}`;
    // },
  },
  {
    Header: 'Current',
    // accessor: (row: any) => {
    //   const availbaleLeave = row?.annual_leave -  row?.leave_count;
    //   return `${availbaleLeave}`;
    // },
    Cell: () => {
      const { allCalculation } = useAppSelector((state) => state.leave)
      return <>{allCalculation?.workingDays ?? 0}</>
    },
  },
  {
    Header: 'Balance',
    Cell: ({ row }: any) => {
      const { selectedObj } = useAppSelector((state) => state.employee)
      const { allLeaveTable } = useAppSelector((state) => state.leave)
      const { allCalculation, leaveDataInfo } = useAppSelector(
        (state) => state.leave
      )

      const defaultAvailableLeave =
        allLeaveTable[0]?.annual_leave - allLeaveTable[0]?.leave_count
      const currentLeaveBalance =
        defaultAvailableLeave - allCalculation?.workingDays

      // const available = selectedObj?.company_details?.annual_leave -  0;
      // const balaence2 = available -  allCalculation?.workingDays

      const totalLeave =
        allLeaveTable?.length > 0 ? row?.original[0]?.leave_count : 0

      const available = leaveDataInfo?.leave_data?.days - totalLeave

      const current = allCalculation?.workingDays ?? 0

      return (
        <>
          {
            // allCalculation?.totalDays ? currentLeaveBalance : defaultAvailableLeave
            // allLeaveTable?.length > 0
            //   ? allCalculation?.totalDays
            //     ? currentLeaveBalance
            //     : defaultAvailableLeave
            //   : 0
            available - current
          }
        </>
      )
    },
  },
]
