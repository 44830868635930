import styles from "./patientIpdInvoiceTableData.module.scss";
import moment from "moment";
import Select from "react-select";
import Button from "../../components/common/button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  handleAddedItems,
  handleRemoveAddedItem,
  setTestAddText,
  updatedNewPaymentAmountArray,
  updatedTreatmentBillStatus,
} from "../../redux/features/ipd-invoice/ipdInvoiceSlice";
import { useEffect, useState } from "react";
import { disableArrowKey, disableScroll } from "../../utils/utils";
import { DeleteIcon } from "../../components/common/svg-components";
import { colors } from "../color";
// import { DropdownIndicator } from "../../../components/common/dropdown-indicator/DropdownIndicator";

export const patientIPDInfoHeaderData: any = [
  {
    Header: "Case ID",
    // accessor: 'case_no',
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      return (
        <span style={{ cursor: "pointer", color: "#0e26a3" }}>
          {row?.original?.case_no}
        </span>
      );
    },
  },
  {
    Header: "Patient Name",
    // accessor: 'insurance_plan',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.user?.name}</span>;
    },
  },
  {
    Header: "Doctor Name",
    // accessor: 'expiry_date',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.doctor_detail?.doctor_name}</span>;
    },
  },
  {
    Header: "Admitted on",
    // accessor: 'policy_no',
    Cell: ({ row }: any) => {
      const AdmitDate = moment(
        row?.original?.booking_detail?.admission_date
      ).format("DD MMM YYYY");
      return <span>{AdmitDate}</span>;
    },
  },
  {
    Header: "Discharge Date",
    // accessor: 'details',
    Cell: ({ row }: any) => {
      const AdmitDate = moment(
        row?.original?.booking_detail?.discharge_date
      ).format("DD MMM YYYY");
      return <span>{AdmitDate}</span>;
    },
  },
  {
    Header: "Medical Situation",
    // accessor: 'coPay',
    Cell: ({ row }: any) => {
      return <span style={{ cursor: "pointer", color: "#0e26a3" }}>View</span>;
    },
  },
  {
    Header: "Status",
    // accessor: 'notes',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.is_case_open ? "Open" : "Close"}</span>;
    },
  },
];

export const PatientListHeaderData: any = [
  {
    Header: "file no.",
    // accessor: 'emr_no',
    Cell: ({ row, onPopClose, onRowClick, invoiceFlag }: any) => {
      return (
        <span
          onClick={() => {
            onRowClick(row?.original);
          }}
          className={styles.caseId}
          style={{
            cursor: "pointer",
            color: "#0e26a3",
          }}
        >
          {row?.original?.emr_no}
        </span>
      );
    },
  },

  {
    Header: "Patient name",
    accessor: "patient_name",
  },

  {
    Header: "National ID",
    accessor: "national_id",
  },

  {
    Header: "mobile",
    accessor: "phone",
  },
];

export const patientConsumbleHeaderData: any = [
  {
    Header: "Date",
    // accessor: 'plan_id',
    Cell: ({ row }: any) => {
      const date = moment(new Date()).format("DD MMM YYYY");
      return <span>{date}</span>;
    },
  },
  {
    Header: "Item ID",
    accessor: "item_no",
  },
  {
    Header: "Item Name",
    accessor: "name",
  },
  // {
  //   Header: "Description",
  //   accessor: "policy_no",
  // },
  {
    Header: "BIllable",
    Cell: (props: any) => {
      const billableTypes: any = [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ];
      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={true}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            // value={props?.row?.original?.billable}
            defaultValue={{
              label: billableTypes?.[0]?.label,
              value: billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            // onChange={(e: any) => handleRequest(e)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100px",
                minHeight: "30px",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      );
    },
  },
  {
    Header: "Price",
    accessor: "sell_price",
  },
  {
    Header: "Notes",
    accessor: (row: any) => {
      return row?.notes ?? "-";
    },
  },
];

export const patientChargesHeaderData: any = [
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ row }: any) => {
      const date = moment(row?.original?.date).format("DD MMM YYYY");
      return <span>{date}</span>;
    },
  },
  // {
  //   Header: "Service ID",
  //   Cell: (props: any) => {
  //     return <>{props.row.original.test_no || props.row.original.sessionId}</>;
  //   },
  // },
  {
    Header: "Service Name",
    Cell: (props: any) => {
      return (
        <>
          {props.row.original.test_name || props.row.original.service_id.name}
        </>
      );
    },
  },
  // {
  //   Header: "Description",
  //   Cell: (props: any) => {
  //     return <>{"-"}</>;
  //   },
  // },
  {
    Header: "BIllable",
    accessor: "billable",
    Cell: (props: any) => {
      const { chargesFilterList } = useAppSelector((state) => state.ipdInvoice);

      const dispatch = useAppDispatch();
      
      const handleChange = (e: any, item: any) => {
        const obj = chargesFilterList.find((item: any) => {
          return item?._id === props.row.original?._id;
        });

        const requestObj = {
          _id: props.row.original?._id,
          billable: e?.value,
        };

        // console.log(e, 'e')
        dispatch(updatedTreatmentBillStatus(requestObj));
      };

      const billableTypes: any = [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ];
      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={props.row.original?.type === "job_item"}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            // value={props?.row?.original?.billable}
            defaultValue={{
              label: props.row.original?.isBillable ?billableTypes?.[0]?.label : billableTypes?.[1]?.label,
              value: props.row.original?.isBillable ? billableTypes?.[0]?.value :  billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            onChange={(e: any) => handleChange(e, props.row.original._id)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100px",
                minHeight: "30px",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      );
    },
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Notes",
    Cell: (props: any) => {
      return <>{props.row.original.notes ?? "-"}</>;
    },
  },
];

export const inventoryItemsHeaderData: any = [
  {
    Header: "Item ID",
    accessor: "item_no",
  },
  {
    Header: "Item Name",
    accessor: "name",
  },
  {
    Header: "Description",
    Cell: (props: any) => {
      return <>{"-"}</>
    },
  },
  {
    Header: "BIllable",
    accessor: "billable",
    Cell: (props: any) => {
      const billableTypes: any = [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ];

      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={true}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            // value={{
            //     label: props?.row?.original?.billable,
            //     value: props?.row?.original?.billable,
            //   }}
            defaultValue={{
              label: billableTypes?.[0]?.label,
              value: billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            // onChange={(e: any) => handleRequest(e)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100px",
                minHeight: "30px",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      );
    },
  },
  {
    Header: "Price",
    accessor: "sell_price",
  },

  {
    Header: "Add Items",
    accessor: "Add item",
    Cell: (props: any) => {
      const { addTestText } = useAppSelector((state) => state.ipdInvoice);
      const dispatch = useAppDispatch();

      const handleTestData = (row: any) => {
        let updateRow = {
          ...row,
          id: row?.item_no,
          name: row?.name,
          isBillable: "Yes",
          price: row?.sell_price,
          quantity: 1,
          type: "inventory_item",
          unitPrice: row?.sell_price,
        };

        // delete updateRow._id;
        dispatch(handleAddedItems(updateRow));
      };

      const handleAdd = (id: any) => {
        dispatch(setTestAddText(id));
      };

      const handleRemove = (id: any) => {
        dispatch(handleRemoveAddedItem(id));
      };

      return (
        <>
          <div
            className={styles.addTestPopupAddToJob}
            onClick={() => handleAdd(props.row.original._id)}
          >
            {!addTestText.includes(props.row.original._id) ? (
              <Button
                title="Add"
                customClass={styles.addedServiceButton}
                handleClick={() => handleTestData(props?.row?.original)}
              />
            ) : (
              <Button
                title="Added"
                customClass={styles.addServiceButton}
                handleClick={() => handleRemove(props?.row?.original?._id)}
              />
            )}
          </div>
        </>
      );
    },
  },
];

export const patientServicesHeaderData: any = [
  // {
  //   Header: "ID",
  //   accessor: "id",
  // },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Description",
    Cell: (props: any) => {
      return <>{"-"}</>;
    },
  },
  {
    Header: "BIllable",
    accessor: "billable",
    Cell: (props: any) => {
      const billableTypes: any = [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ];
      return (
        <div className={styles.billableContainer}>
          <Select
            // className={styles.selectInputField}
            isDisabled={true}
            closeMenuOnSelect={true}
            // components={{ DropdownIndicator }}
            isSearchable={false}
            defaultValue={{
              label: props.row.original?.isBillable ? billableTypes?.[0]?.label : billableTypes?.[1]?.label,
              value: props.row.original?.isBillable ? billableTypes?.[0]?.value :  billableTypes?.[0]?.value,
            }}
            options={billableTypes}
            // onChange={(e: any) => handleRequest(e)}
            maxMenuHeight={120}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100px",
                minHeight: "30px",
                cursor: "pointer",
              }),
            }}
          />
        </div>
      );
    },
  },
  {
    Header: "Price",
    accessor: "price",
  },
];

export const ipdInvoicePaymentHeaderData: any = [
  {
    Header: "PAYMENT MODE",
    accessor: "payment_label",
  },

  {
    Header: "AMOUNT (E)",
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch();
      const { patientPaymentModeList } = useAppSelector(
        (state) => state.ipdInvoice
      );
      const [error, setError] = useState("");

      const validateInput = (inputValue: any) => {
        const pattern = /^\d{1,6}(?:\.\d{1,3})?$/;
        return pattern.test(inputValue);
      };

      const handleAmountChange = (event: any) => {
        const amount = event.target.value;
        const isValid = validateInput(amount);
        if (isValid === false) {
          setError("Please enter valid amount");
        }
        if (isValid === true) {
          setError("");
        }
        const updatedData = patientPaymentModeList?.map((item: any) => {
          if (item?._id === row?.original?._id) {
            return {
              ...item,
              amount: parseFloat(amount),
            };
          }
          return item;
        });
        dispatch(updatedNewPaymentAmountArray(updatedData));
      };
      useEffect(() => {
        disableScroll();
      }, []);

      return (
        <>
          <input
            className={styles.inputFieldServiceTable}
            value={row?.original?.amount}
            type="number"
            key={row.original._id}
            onChange={handleAmountChange}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur();
            }}
            disabled={row?.original?.payment_mode === "upay" ? true : false}
          />
          <p className="dashboardFormError">{error}</p>
        </>
      );
    },
  },

  {
    Header: "APPROVAL NO.",
    Cell: ({ row }: any) => {
      return (
        <>
          <span>-</span>
        </>
      );
    },
  },

  {
    Header: "ACTION",
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch();
      const { patientPaymentModeList } = useAppSelector(
        (state) => state.ipdInvoice
      );

      const handleRemovePaymentMode = (paymentMode: any) => {
        let tempArr: any = [];
        tempArr =
          patientPaymentModeList && patientPaymentModeList.length > 0
            ? patientPaymentModeList?.filter((item: any) => {
                return item?._id !== paymentMode?._id;
              })
            : [];
        dispatch(updatedNewPaymentAmountArray(tempArr));
      };
      return (
        <>
          <DeleteIcon
            fillColor={colors.grey4}
            handleClick={() => handleRemovePaymentMode(row?.original)}
          />
        </>
      );
    },
  },
];
