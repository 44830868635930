import { FC, useEffect, useState } from 'react'
import styles from './submitrequestpopup.module.scss'
import {
  CloseIcon,
  SearchButton,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import { submitRequestPopupHeaderData } from '../../../constants/table-data/submitRequestPopupTableData'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  deleteInventoryRequest,
  getAllInventoryRequest,
  getInventoryReqsById,
} from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import Loader from '../../../components/common/spinner/Loader'
import Pagination from '../../../components/common/pagination/Pagination'
import Select from 'react-select'
import Popup from '../../../components/common/popup/Popup'
import DeleteMedicationPopup from '../../../components/common/modal/delete-medication-popup/DeleteMedicationPopup'
import { trimValue } from '../../../utils/utils'
interface ISubmitPopup {
  handleClose?: any
  handleChildClick?: any
}

const SubmitRequestPopup: FC<ISubmitPopup> = ({
  handleClose,
  handleChildClick,
}) => {
  const dispatch = useAppDispatch()
  const { isLoading, inventoryRequestData } = useAppSelector(
    (state) => state.inventoryRequest
  )
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [searchDate, setSearchDate] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [dynamicKey, setDynamicKey] = useState<any>()
  const [showDelete, setShowDelete] = useState(false)
  const [deletedId, setDeletedId] = useState('')

  console.log('searchKey', searchKey?.length)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  const data: any = inventoryRequestData ?? []
  const columns: Column<any>[] = submitRequestPopupHeaderData ?? []
  const options: TableOptions<any> = {
    data,
    columns,
  }

  const {
    // @ts-ignore
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore

  let payloadData = {
    page: pageIndex,
    pageSize: dataPerPage,
    request_date: searchDate,
    view_self: true,
  }

  let reqData = {
    ...payloadData,
    ...dynamicKey,
  }

  useEffect(() => {
    dispatch(getAllInventoryRequest(requestGenerator(reqData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [pageIndex, dataPerPage])

  const handleDelete = () => {
    dispatch(
      deleteInventoryRequest(requestGenerator({ request_id: deletedId }))
    ).then((e) => {
      if (e.type === 'inventory/deleteRequest/fulfilled') {
        dispatch(getAllInventoryRequest(requestGenerator(reqData))).then(
          (result) => {
            setTotalPage(result.payload.lastPage)
            setTotalData(result.payload.total)
          }
        )
        setShowDelete(false)
        setDeletedId('')
      }
    })
  }

  const handleEdit = (id: any) => {
    dispatch(getInventoryReqsById(requestGenerator({ request_id: id }))).then(
      (e) => {
        if (e.type === 'inventory/getRequest/fulfilled') {
          setSearchDate('')
          setSearchValue('')
          handleChildClick()
        }
      }
    )
  }

  const handleSearch = () => {
    setPageIndex(1)
    dispatch(
      getAllInventoryRequest(
        requestGenerator({ ...reqData, page: 1, pageSize: 10 })
      )
    ).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }

  const searchFilterData: any = [
    {
      lable: 'Source',
      value: 'request_source_type',
    },
    {
      lable: 'Doc. ID',
      value: 'document_id',
    },
    {
      lable: 'Destination',
      value: 'request_destination',
    },
    {
      lable: 'Sub Source',
      value: 'request_source',
    },
  ]

  useEffect(() => {
    if (dynamicKey !== undefined) {
      setDynamicKey({ [searchKey]: searchValue })
    }
  }, [searchValue, searchKey])

  useEffect(() => {
    if (searchValue === '') {
      if (searchKey === 'document_id') {
        dispatch(
          getAllInventoryRequest(
            requestGenerator({
              ...reqData,
              document_id: '',
              page: 1,
              pageSize: 10,
            })
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      } else if (searchKey === 'request_source_type') {
        dispatch(
          getAllInventoryRequest(
            requestGenerator({ ...reqData, request_source_type: '' })
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      } else if (searchKey === 'request_source') {
        dispatch(
          getAllInventoryRequest(
            requestGenerator({ ...reqData, request_source: '' })
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      } else if (searchKey === 'request_destination') {
        dispatch(
          getAllInventoryRequest(
            requestGenerator({ ...reqData, request_destination: '' })
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        })
      }
    }
  }, [searchValue])

  return (
    <>
      {isLoading && <Loader />}
      {showDelete && (
        <Popup
          Children={DeleteMedicationPopup}
          handleClose={() => setShowDelete(false)}
          handleNo={() => setShowDelete(false)}
          handleYes={() => handleDelete()}
        />
      )}
      <div
        className={styles.submitRequestPopupMainContaier}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.uploadContainer}>
          <p className={styles.title}>Submitted Requests</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.searchContainer}>
            <Select
              className={styles.select}
              placeholder=" Select "
              closeMenuOnSelect={true}
              isSearchable={true}
              options={searchFilterData?.map((item: any) => ({
                label: item?.lable,
                value: item?.value,
              }))}
              onChange={(e: any) => {
                setSearchKey(e.value)
                setDynamicKey({ [e.value]: searchValue })
                setSearchValue('')
              }}
              maxMenuHeight={200}
            />
            <input
              type="text"
              className={styles.inputField}
              // placeholder={`Search by ${searchKey}`}
              placeholder="Search"
              value={searchValue}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearchValue(e.target.value)
                // if (e.target.value === '') {
                //   dispatch(getAllInventoryRequest(requestGenerator(reqData)));
                // }
              }}
              disabled={searchKey?.length === 0 ? true : false}
            />
            <input
              type="date"
              className={styles.DateinputField}
              value={searchDate}
              onChange={(e) => {
                setSearchDate(e.target.value)
                if (e.target.value === '') {
                  dispatch(
                    getAllInventoryRequest(
                      requestGenerator({ ...reqData, request_date: '' })
                    )
                  ).then((result) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                }
              }}
            />
            <SearchButton
              handleClick={() => handleSearch()}
              width={40}
              height={40}
            />
          </div>
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              // handleOpen={handleOpen}
              setModelOpenClose={(id: any) => {
                setShowDelete(!showDelete)
                setDeletedId(id)
              }}
              handleRowClick={handleEdit}
            />
          </div>
          {totalData > 10 && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              pageSize={dataPerPage}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default SubmitRequestPopup
