import moment from "moment";
import { EyeIcon } from "../../../components/common/svg-components";
import { colors } from "../../../constants/color";
import styles from "./ledger.module.scss";

// ledger Table Data
export const ledgerTableHeaderData: any = [
  {
    Header: "DATE",
    accessor: (row: any) => {
      return moment(row?.date).format("DD MMM YYYY") ?? "-";
    },
  },
  {
    Header: "VOUCHER NO",
    accessor: (row: any) => {
      return row?.number ?? "-";
    },
  },

  {
    Header: "REFERENCE NUMBER",
    accessor: (row: any) => {
      return row?.reference_number ? row?.reference_number : "-";
    },
  },

  {
    Header: "VOUCHER REMARKS",
    accessor: (row: any) => {
      return row?.remarks ? row?.remarks : "-";
    },
  },
  // {
  //   Header: 'ENTRY REMARKS',
  //   accessor: (row: any) => {
  //     return row?.entry_remarks ? row?.entry_remarks : '-'
  //   },
  // },
  {
    Header: "Dr",
    accessor: (row: any) => {
      return row?.debit_amount ?? "-";
    },
  },
  {
    Header: "Cr",
    accessor: (row: any) => {
      return row?.credit_amount ?? "-";
    },
  },
  // {
  //   Header: 'VIEW',
  //   Cell: (props: any) => {
  //     const viewObject = {
  //       viewBalance: props?.row?.original.voucher,
  //     }
  //     return (
  //       <>
  //         <EyeIcon
  //           fillColor={colors.grey1}
  //           customClass={styles.openEyeIcon}
  //           handleClick={() => props.onClick(viewObject)}
  //         />
  //       </>
  //     )
  //   },
  // },
];

export const viewBalanceTableHeaderData: any = [
  {
    Header: "VOUCHER NO",
    accessor: (row: any) => {
      return row?.number ?? "";
    },
  },
  {
    Header: "ACCOUNT NAME",
    accessor: (row: any) => {
      return row?.accounts_data?.name ?? "";
    },
  },
  {
    Header: "DEBIT BALANCE",
    accessor: (row: any) => {
      return row?.debit_amount ?? "";
    },
  },
  {
    Header: "CREDIT BALANCE",
    accessor: (row: any) => {
      return row?.credit_amount ?? "";
    },
  },
];
