import { useAppDispatch } from "../../../hooks"

export const searchModalHeaderData: any = [
    {
      Header: 'ID.',
      accessor: 'emp_id',
      Cell: (props: any) => {     
        return (
          <span
            onClick={() => {
              props.onRowClick(props?.row?.original)
            }}
            style={{ cursor: 'pointer', color: '#0e26a3' }} >
            {props?.row?.original?.emp_id}
          </span>
        )
      },
    },
    {
      Header: 'NAME',
      accessor: (row: any) => {
        return `${row?.name_en}`
      }
    },
  
  ]
  
 