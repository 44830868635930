import { DeleteIcon, EditIcon } from '../../components/common/svg-components';
import styles from './tableData.module.scss';

export const consultationHeaderData: any = [
  {
    Header: 'DATE',
    accessor: 'date',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.date ? row?.original?.date : '-'}</p>;
    },
  },
  {
    Header: 'DOCTOR',
    accessor: 'primary_doctor_name',
  },
  {
    Header: 'NOTES',
    accessor: 'notes',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(props?.row?.original);
          }}
        >
          Notes
        </p>
      );
    },
  },
];

export const consultationPopupNotesHeaderData: any = [
  {
    Header: 'NOTES TIME',
    accessor: 'time',
  },
  {
    Header: 'NOTES',
    accessor: 'notes',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(props?.row?.original?.note);
          }}
        >
          View
        </p>
      );
    },
  },
  {
    Header: 'ACTION',
    accessor: 'nos',
    Cell: (props: any) => {
      return (
        <div className={styles.actionIconContainer}>
          <EditIcon
            handleClick={() => {
              props?.onClick(props?.row?.original);
            }}
          />
          <DeleteIcon
            fillColor="#CDD4D8"
            customClass={styles.deleteIconStyle}
            handleClick={() => {
              props?.onPopClose(props?.row?.original?._id);
            }}
          />
        </div>
      );
    },
  },
];

export const consultationTable = [
  {
    date: '23-08-2023',
    doctor: 'sunil',
    status: 'due',
    findings: '-',
  },
  {
    date: '23-08-2023',
    doctor: 'sunil',
    status: 'due',
    findings: '-',
  },
  {
    date: '23-08-2023',
    doctor: 'sunil',
    status: 'due',
    findings: '-',
  },
  {
    date: '23-08-2023',
    doctor: 'sunil',
    status: 'due',
    findings: '-',
  },
  {
    date: '23-08-2023',
    doctor: 'sunil',
    status: 'due',
    findings: '-',
  },
];
