import { FC, useEffect, useState } from 'react'
import Button from '../../components/common/button/Button'
import { CustomRadio } from '../../components/common/custom-radio'
import { Input } from '../../components/common/input/input'
import { TextArea } from '../../components/common/text-area'
import Select from 'react-select'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { Label } from '../../components/common/label'
import { useForm } from 'react-hook-form'
import { CustomModal } from '../../components/common/custom-modal/modal'
import SearchModal from './patient-search/page'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getAllroom } from '../../redux/features/room/roomAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { getAllInternalDocotorReferral } from '../../redux/features/referral/referralAsyncActions'
import {
  appointmentDataByIdAsync,
  getAllPhysioSlots,
} from '../../redux/features/physio/physioAsyncActions'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import { viewSlotsHeaderData } from './data'
import {
  clearAppointmentDataById,
  clearRoomBookingDetailById,
  setSelectedSlotStatus,
} from '../../redux/features/physio/physioSlice'
import { setMessage } from '../../redux/features/toast/toastSlice'
import {
  failure,
  hourDropdown,
  minDropdown,
  reactSelectStyle,
} from '../../constants/data'
import moment from 'moment'
import {
  bookingConfirmation,
  getAllDoctorAppointments,
} from '../../redux/features/appointments/bookingAppointmentAsyncActions'
import Loader from '../../components/common/spinner/Loader'
import {
  getBookedSlotsFromAppointmentDuration,
  setShiftTiming,
  trimValue,
} from '../../utils/utils'
import PhoneInput from 'react-phone-input-2'
import styles from './style.module.scss'
import makeAnimated from 'react-select/animated'

interface IPhysio {
  closeModal?: any
}

const PhysioAppointmentBooking: FC<IPhysio> = (props) => {
  const { closeModal } = props
  const [gender, setGender] = useState('MALE')
  const [patientModal, setPatientModal] = useState(false)
  const [patientData, setPateintData] = useState<any>({})
  const [viewSlots, setViewSlots] = useState(false)
  const { roomData } = useAppSelector((state) => state.room)
  const { referDoctorData } = useAppSelector((state) => state.referral)
  const { branchData } = useAppSelector((state) => state.ipdBooking)
  const {
    isLoading,
    physioSlots,
    selectedSlotsStatus,
    roomBookingDetailById,
    appointmentDataById,
  } = useAppSelector((state) => state.physio)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
    trigger,
    getValues,
  } = useForm({})

  const dispatch = useAppDispatch()
  const animatedComponent = makeAnimated()
  useEffect(() => {
    if (roomBookingDetailById?.appt_id) {
      let data = {
        appointment_id: roomBookingDetailById?.appt_id,
      }
      dispatch(appointmentDataByIdAsync(requestGenerator(data)))
    }
  }, [dispatch, roomBookingDetailById?.appt_id])

  const checkAppointnmentDataExist =
    appointmentDataById?.hasOwnProperty('room_id')

  useEffect(() => {
    if (checkAppointnmentDataExist) {
      const endTime = getBookedSlotsFromAppointmentDuration(
        appointmentDataById?.appointment_duration,
        appointmentDataById?.appointment_time
      )
      setValue(
        'patientName',
        appointmentDataById?.patient_name ||
          appointmentDataById?.patient_id?.user_id?.name
      )
      setValue('mobileNo', appointmentDataById?.phone)
      setValue('date', appointmentDataById?.appointment_date)
      setValue('timeFrom', appointmentDataById?.appointment_time)
      setValue('doctor', {
        label: appointmentDataById?.doctor_id?.doctor_name,
        value: appointmentDataById?.doctor_id?._id,
      })
      setValue('room', {
        label: appointmentDataById?.room_id?.room_name,
        value: appointmentDataById?.room_id?._id,
      })
      setValue('timeTo', endTime)
      // setValues for 15min time interval
      const timeFromArr = appointmentDataById?.appointment_time?.split(':')
      const timeToArr = endTime?.split(':')
      setShiftTiming(timeFromArr, 'timeFromHr', 'timeFromMin', setValue)
      setShiftTiming(timeToArr, 'timeToHr', 'timeToMin', setValue)
      setGender(appointmentDataById?.gender)
      setValue('notes', appointmentDataById?.problem_description)
    }
  }, [appointmentDataById, checkAppointnmentDataExist, setValue])
  useEffect(() => {
    const mergeTwoArrays = physioSlots.map((item: any, index: number) => {
      const startTime = item
      const endTime = moment(startTime, 'HH:mm')
        .add(15, 'minutes')
        .format('HH:mm')

      return {
        id: index,
        label: `${startTime} to ${endTime}`,
        selected: true,
      }
    })
    dispatch(setSelectedSlotStatus(mergeTwoArrays))
  }, [dispatch, physioSlots])

  const filterSelectedData = selectedSlotsStatus?.filter(
    (s: any) => s?.selected === true
  )

  const getAppointmentTimes = filterSelectedData?.map(
    (s: any) => s.label.split(' ')?.[0]
  )
  const checkPatientDataLength = patientData?.hasOwnProperty('patient_name')

  useEffect(() => {
    let data = {
      page: 0,
      pageSize: 100,
      order_by: { name: 1 },
      is_active: true,
      type: 'OPD',
    }
    dispatch(getAllroom(requestGenerator(data)))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getAllInternalDocotorReferral(
        requestGenerator({ branch: branchData?._id })
      )
    )
  }, [branchData?._id, dispatch])

  const watchRoom = watch('room')
  const watchDoctor = watch('doctor')
  const watchDate = watch('date')
  const watchTimeFrom = watch('timeFrom')
  const watchTimeTo = watch('timeTo')
  const timeFromHr = watch('timeFromHr')
  const timeFromMin = watch('timeFromMin')
  const timeToHr = watch('timeToHr')
  const timeToMin = watch('timeToMin')
  const watchPatient = watch('patientName')
  const watchNumber = watch('mobileNo')
  const watchNotes = watch('notes')

  const date = moment(watchDate)
  const dayIndex = date && date.isoWeekday()

  const onSubmit = (data: any) => {
    const currentTime = moment().format('HH:mm')
    if (!selectedSlotsStatus?.length) {
      let requestdata = {
        room_id: data?.room?.value,
        doctor_id: data?.doctor?.value,
        gender: gender,
        appointment_date: new Date(data?.date),
        appointment_day: dayIndex === 7 ? 0 : dayIndex,
        current_time: currentTime,
        patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
        convenient_time: {
          start_time: data?.timeFrom,
          end_time: data?.timeTo,
        },
      }
      dispatch(getAllPhysioSlots(requestGenerator(requestdata))).then(
        (res: any) => {
          if (res.type === 'physio/getAllPhysioSlots/fulfilled') {
            setViewSlots(true)
          }
        }
      )
    } else {
      setViewSlots(true)
    }
  }

  useEffect(() => {
    const currentTime = moment().format('HH:mm')
    if (
      watchPatient?.length > 0 &&
      watchRoom?.hasOwnProperty('value') &&
      watchDoctor?.hasOwnProperty('value')
    ) {
      if (watchTimeTo?.length && watchTimeFrom?.length && watchDate?.length) {
        let requestdata = {
          room_id: watchRoom?.value,
          doctor_id: watchDoctor?.value,
          gender: gender,
          appointment_date: new Date(watchDate),
          appointment_day: dayIndex === 7 ? 0 : dayIndex,
          current_time: currentTime,
          patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
          convenient_time: {
            start_time: watchTimeFrom,
            end_time: watchTimeTo,
          },
        }
        dispatch(getAllPhysioSlots(requestGenerator(requestdata)))
      }
    }
  }, [watchTimeTo, watchTimeFrom, watchDate])

  useEffect(() => {
    if (checkPatientDataLength) {
      setValue('patientName', patientData?.patient_name)
      setValue('mobileNo', patientData?.phone)
      setGender(patientData?.gender)
    }
  }, [checkPatientDataLength, patientData, setValue])

  const handleSave = () => {
    const date = new Date(watchDate)

    date.setHours(parseInt(getAppointmentTimes[0]?.split(':')[0]) || 0)
    date.setMinutes(parseInt(getAppointmentTimes[0]?.split(':')[1]) || 0)

    let requestData = {
      patient_name: watchPatient,
      gender: gender,
      patient_emr: patientData?.emr_no ?? undefined,
      doctor_name: watchDoctor?.label,
      appointment_date: date,
      start_time: watchTimeFrom,
      end_time: watchTimeTo,
      phone: `+${Number(watchNumber)}`,
      appointment_status: checkAppointnmentDataExist
        ? 'RESCHEDULED'
        : 'SCHEDULED',
      problem_description: watchNotes,
      room_id: watchRoom?.value,
      doctor_id: watchDoctor?.value,
      patient_id: patientData?._id || appointmentDataById?.patient_id?._id,
      branch_id: branchData?._id,
      appointment_duration: getAppointmentTimes?.length * 15,
      appointment_type: 'INPERSON',
      appointment_id: checkAppointnmentDataExist
        ? appointmentDataById?._id
        : undefined,
      appointment_time: getAppointmentTimes,
      is_booked_by_agent: false,
      is_update: checkAppointnmentDataExist ? true : false,
    }
    if (
      !watchPatient?.length ||
      !watchNumber?.length ||
      !watchRoom?.hasOwnProperty('value') ||
      !watchDoctor?.hasOwnProperty('value')
    ) {
      trigger('patientName')
      trigger('room')
      trigger('mobileNo')
      trigger('doctor')
    } else {
      dispatch(bookingConfirmation(requestGenerator(requestData))).then(
        (res: any) => {
          if (res.type === 'appoinments/bookingConfirmation/fulfilled') {
            dispatch(
              getAllDoctorAppointments(
                requestGenerator({ branch_id: branchData?._id })
              )
            )
            closeModal()
            dispatch(setSelectedSlotStatus([]))
          }
        }
      )
    }
  }

  const handleSaveSlots = () => {
    let toastData = {
      message: 'Please select less then 8 slots',
      type: failure,
    }
    if (filterSelectedData?.length > 8) {
      dispatch(setMessage(toastData))
    } else {
      setViewSlots(false)
    }
  }

  useEffect(() => {
    dispatch(setSelectedSlotStatus([]))
    dispatch(clearRoomBookingDetailById())
    dispatch(clearAppointmentDataById())
    reset()
    setValue('room', null)
    setValue('doctor', null)
    setGender('MALE')
    setValue('mobileNo', '')
  }, [dispatch, reset, setValue])

  return (
    <>
      {isLoading && <Loader />}
      <CustomModal
        showModal={patientModal}
        closeModal={() => setPatientModal(false)}
        height="80dvh"
        width="60dvw"
        title="Patient Search"
      >
        <SearchModal
          handleRowClick={(item: any) => {
            setPateintData(item)
            setPatientModal(false)
          }}
        />
      </CustomModal>
      <CustomModal
        showModal={viewSlots}
        closeModal={() => setViewSlots(false)}
        width="50dvw"
        height="75dvh"
        title="View Slots"
      >
        <div className={styles.slotsHeader}>
          <TableV2
            tableHeaderData={viewSlotsHeaderData}
            tableRowData={selectedSlotsStatus ?? []}
            active={false}
          />
        </div>
        <div className={styles.saveBtn}>
          <Button
            type="button"
            title="Save"
            handleClick={handleSaveSlots}
            disable={selectedSlotsStatus?.length > 0 ? false : true}
          />
        </div>
      </CustomModal>
      <form className={styles.formWrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.fieldsContainer}>
          <div className={styles.inputFields}>
            <Input
              labelText="Patient Name"
              placeholder="Please enter/select patient"
              requiredField
              autoComplete="off"
              showIcon={true}
              handleIconClick={() =>
                checkAppointnmentDataExist ? undefined : setPatientModal(true)
              }
              {...register('patientName', { required: true })}
              showErrors={errors?.patientName?.type === 'required'}
              errorMessage="Please enter patient name"
              onChange={(e: any) => {
                dispatch(setSelectedSlotStatus([]))
                trimValue(e)
              }}
              disabled={checkAppointnmentDataExist}
              disabledFields={checkAppointnmentDataExist}
            />
          </div>
          <div className={styles.inputFields}>
            <div className={styles.radioFieldGenderContainer}>
              <Label requiredField labelText="Gender" flexBasis="31%" />
              <CustomRadio
                label="Male"
                name="gender"
                value="Male"
                customLabel={styles.customRadioLabel}
                checked={gender === 'MALE'}
                onClick={() => {
                  setGender('MALE')
                  dispatch(setSelectedSlotStatus([]))
                }}
                disabled={checkAppointnmentDataExist || checkPatientDataLength}
              />
              <CustomRadio
                label="Female"
                name="gender"
                value="Female"
                customLabel={styles.customRadioLabel}
                checked={gender === 'FEMALE'}
                onClick={() => {
                  setGender('FEMALE')
                  dispatch(setSelectedSlotStatus([]))
                }}
                disabled={checkAppointnmentDataExist || checkPatientDataLength}
              />
            </div>
          </div>
          <div className={styles.inputFields}>
            <div className={styles.inputFieldContainer}>
              <Label
                htmlFor={'mobileNo'}
                labelText="Mobile No."
                requiredField={true}
                customClass={styles.customLabel}
              />
              <PhoneInput
                country={'kw'}
                {...register('mobileNo', { required: true })}
                value={getValues('mobileNo')}
                placeholder="Enter Mobile No."
                onChange={(phone) => {
                  const formattedPhone = phone && `+${phone}`
                  setValue('mobileNo', formattedPhone)
                  trigger('mobileNo')
                }}
                inputClass={[styles.phoneNumberInput]?.join(' ')}
                containerStyle={{ flexBasis: '70%' }}
                disabled={checkAppointnmentDataExist}
              />
            </div>
            {errors?.mobileNo?.type === 'required' && (
              <div className={styles.phoneErrorContainer}>
                <span className={styles.extraSpan} />
                <p className={styles.phoneErrorContainer}>
                  Please enter mobile no
                </p>
              </div>
            )}
          </div>
          <div className={styles.inputFields}>
            <div className={styles.dropDownField}>
              <Label requiredField labelText="Room" flexBasis="31%" />
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder="Select Room"
                  value={watchRoom}
                  {...register('room', { required: true })}
                  onChange={(e: any) => {
                    setValue('room', e)
                    dispatch(setSelectedSlotStatus([]))
                  }}
                  isSearchable={true}
                  components={{ DropdownIndicator }}
                  options={roomData?.map((item: any) => ({
                    label: item?.room_name,
                    value: item?._id,
                  }))}
                  maxMenuHeight={200}
                />
                {errors?.room?.type === 'required' && (
                  <p className={styles.errorMessage}>Please select room</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.inputFields}>
            <div className={styles.dropDownField}>
              <Label requiredField labelText="Doctor" flexBasis="31%" />
              <div className={styles.errorContainer}>
                <Select
                  className={styles.select}
                  placeholder="Select Doctor"
                  value={watchDoctor}
                  isDisabled={checkAppointnmentDataExist}
                  {...register('doctor', { required: true })}
                  onChange={(e: any) => {
                    setValue('doctor', e)
                    dispatch(setSelectedSlotStatus([]))
                  }}
                  isSearchable={true}
                  components={{ DropdownIndicator }}
                  options={referDoctorData?.map((items: any) => ({
                    label: items?.doctor_name,
                    value: items?._id,
                  }))}
                  maxMenuHeight={200}
                  styles={reactSelectStyle}
                />
                {errors?.doctor?.type === 'required' && (
                  <p className={styles.errorMessage}>Please select doctor</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.inputFields}>
            <Input
              labelText="Date"
              type="date"
              requiredField
              defaultValue={moment(new Date()).format('YYYY-MM-DD')}
              {...register('date', { required: true })}
              showErrors={errors?.date?.type === 'required'}
              errorMessage="Please select date"
              onChange={(e) => {
                setValue('date', e.target.value)
                dispatch(setSelectedSlotStatus([]))
              }}
              customClass={styles.datePicker}
            />
          </div>
          <div>
            <div className={styles.inputFields}>
              <div className={styles.radioFieldGenderContainer}>
                <Label requiredField labelText="Time" flexBasis="31%" />
                <Input
                  type="time"
                  requiredField
                  inlineStyle={{ flexBasis: '30%', display: 'none' }}
                  {...register('timeFrom', { required: true })}
                  // onChange={() => dispatch(setSelectedSlotStatus([]))}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeFromHr')}
                  isSearchable={true}
                  isClearable={true}
                  options={hourDropdown}
                  maxMenuHeight={200}
                  value={watch('timeFromHr') || []}
                  components={animatedComponent}
                  placeholder="Hour"
                  onChange={(e) => {
                    setValue('timeFromHr', e)
                    trigger('timeFromHr')
                    if (timeFromMin) {
                      setValue('timeFrom', `${e.value}:${timeFromMin.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeFromMin')}
                  isSearchable={true}
                  isClearable={true}
                  options={minDropdown?.filter((item) => item.label !== '59')}
                  maxMenuHeight={200}
                  value={watch('timeFromMin') || []}
                  components={animatedComponent}
                  placeholder="Min"
                  onChange={(e) => {
                    setValue('timeFromMin', e)
                    trigger('timeFromMin')
                    if (timeFromHr) {
                      setValue('timeFrom', `${timeFromHr.value}:${e.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
                <p>To</p>
                <Input
                  type="time"
                  requiredField
                  inlineStyle={{
                    flexBasis: '30%',
                    justifyContent: 'flex-end',
                    display: 'none',
                  }}
                  {...register('timeTo', { required: true })}
                  // onChange={() => dispatch(setSelectedSlotStatus([]))}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeToHr')}
                  isSearchable={true}
                  isClearable={true}
                  options={hourDropdown}
                  maxMenuHeight={200}
                  value={watch('timeToHr') || []}
                  components={animatedComponent}
                  placeholder="Hour"
                  onChange={(e) => {
                    setValue('timeToHr', e)
                    trigger('timeToHr')
                    if (timeToMin) {
                      setValue('timeTo', `${e.value}:${timeToMin.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
                <Select
                  className={styles.selectTimeInputField}
                  {...register('timeToMin')}
                  isSearchable={true}
                  isClearable={true}
                  options={minDropdown?.filter((item) => item.label !== '59')}
                  maxMenuHeight={200}
                  value={watch('timeToMin') || []}
                  components={animatedComponent}
                  placeholder="Min"
                  onChange={(e) => {
                    setValue('timeToMin', e)
                    trigger('timeToMin')
                    if (timeToHr) {
                      setValue('timeTo', `${timeToHr.value}:${e.value}`)
                    }
                  }}
                  styles={reactSelectStyle}
                />
              </div>
            </div>
            <div className={styles.inputFieldsButton}>
              <div className={styles.viewSlotsBtn}>
                <Button
                  title="View Slots"
                  type="submit"
                  // handleClick={() => setViewSlots(true)}
                />
              </div>
            </div>
          </div>
          <div className={styles.textFields}>
            <TextArea
              label="Notes"
              {...register('notes')}
              onChange={(e) => trimValue(e)}
            />
          </div>
        </div>
        <div className={styles.submitBtn}>
          <Button
            title="Save & Book"
            type="button"
            handleClick={() => handleSave()}
            // disable={
            //   !patientData?.hasOwnProperty("_id") &&
            //   !selectedSlotsStatus?.length
            // }
          />
        </div>
      </form>
    </>
  )
}

export default PhysioAppointmentBooking
