import { useState, useEffect } from 'react'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import {
  pharmacyMedicineHeaderData,
  pharmacyOnholdHeaderData,
} from '../../../constants/table-data/pharmacyOnholdTableData'
import styles from './pharmacyOnhold.module.scss'
import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  deletePharmacyOnholdData,
  getAllPharmacyOnholdData,
} from '../../../redux/features/pharmacy/pharmacyAsyncActions'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loader from '../../../components/common/spinner/Loader'
import Pagination from '../../../components/common/pagination/Pagination'
import Popup from '../../../components/common/popup/Popup'
import DeleteMedicationPopup from '../../../components/common/modal/delete-medication-popup/DeleteMedicationPopup'
import { useNavigate } from 'react-router-dom'

const PharmacyOnhold = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState()
  const [active, setActive] = useState<boolean>(false)
  const [medicineData, setMedicineData] = useState([])
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showDelete, setShowDelete] = useState<boolean>(false)
  const [orderId, setOrderId] = useState('')
  const [medicineDelete, setMedicineDelete] = useState<boolean>(false)
  const [medicineId, setMedicineId] = useState('')
  const dispatch = useAppDispatch()
  const { isLoading, onholdData } = useAppSelector((state) => state.pharmacy)
  const [orderItem, setOrderItem] = useState<any>({})

  console.log('orderItem', orderItem)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  const handleRow = (id: any, item: any) => {
    setOrderItem(item)
    setValue(id)
    if (value === id) {
      setActive(true)
    } else {
      setActive(false)
    }
  }

  useEffect(() => {
    if (value !== undefined && onholdData?.length > 0) {
      let holdOrderMedicine = onholdData?.filter(
        (item: any) => item?._id === value
      )
      setMedicineData(holdOrderMedicine[0]?.phrm_orders_items)
    }
  }, [value])

  useEffect(() => {
    dispatch(
      getAllPharmacyOnholdData(
        requestGenerator({
          status: 'ONHOLD',
          page: pageIndex,
          pageSize: dataPerPage,
        })
      )
    ).then((result) => {
      setTotalPage(result.payload.lastPage)
    })
  }, [pageIndex, dataPerPage])

  const clearActiveRow = () => {
    setMedicineData([])
    setActive(false)
    setValue(undefined)
  }

  useEffect(() => {
    clearActiveRow()
  }, [pageIndex])

  const deleteOrder = () => {
    dispatch(
      deletePharmacyOnholdData(requestGenerator({ order_id: orderId }))
    ).then((e) => {
      if (e?.type === 'pharmacy/deletePharmacyOnholdData/fulfilled') {
        setShowDelete(false)
        setOrderId('')
        clearActiveRow()
        dispatch(
          getAllPharmacyOnholdData(
            requestGenerator({
              status: 'ONHOLD',
              page: pageIndex,
              pageSize: dataPerPage,
            })
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
        })
      }
    })
  }

  const deleteMedicine = () => {
    dispatch(
      deletePharmacyOnholdData(requestGenerator({ order_item_id: medicineId }))
    ).then((e) => {
      if (e?.type === 'pharmacy/deletePharmacyOnholdData/fulfilled') {
        let holdOrderMedicine = medicineData?.filter(
          (item: any) => item?._id !== medicineId
        )
        setMedicineData(holdOrderMedicine)
        setMedicineDelete(false)
        setMedicineId('')
        dispatch(
          getAllPharmacyOnholdData(
            requestGenerator({
              status: 'ONHOLD',
              page: pageIndex,
              pageSize: dataPerPage,
            })
          )
        ).then((result) => {
          setTotalPage(result.payload.lastPage)
        })
      }
    })
  }

  return (
    <>
      {isLoading && <Loader />}
      {showDelete && (
        <Popup
          Children={DeleteMedicationPopup}
          handleClose={() => {
            setShowDelete(false)
            setOrderId('')
          }}
          handleNo={() => {
            setShowDelete(false)
            setOrderId('')
          }}
          handleYes={deleteOrder}
        />
      )}
      {medicineDelete && (
        <Popup
          Children={DeleteMedicationPopup}
          handleClose={() => {
            setMedicineDelete(false)
            setMedicineId('')
          }}
          handleNo={() => {
            setMedicineDelete(false)
            setMedicineId('')
          }}
          handleYes={deleteMedicine}
        />
      )}
      <div className={styles.onholdMainContainer}>
        <p className={styles.titleText}>On-hold</p>
        <div className={styles.tableContainer} style={{ width: '500px' }}>
          <TableV2
            tableHeaderData={pharmacyOnholdHeaderData}
            tableRowData={onholdData}
            handleRow={handleRow}
            setActive={setValue}
            active={value}
            handleRowClick={(id: any) => {
              setShowDelete(true)
              setOrderId(id)
            }}
          />
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
        <p className={styles.titleText}>Medicine</p>
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={pharmacyMedicineHeaderData}
            tableRowData={medicineData}
            customoTableMsg={
              value === undefined
                ? 'Please select customer name'
                : 'No records found'
            }
            active={false}
            handleRowClick={(id: any) => {
              setMedicineDelete(true)
              setMedicineId(id)
            }}
          />
        </div>
        <div className={styles.btnContainer}>
          <Button
            title="Submit"
            handleClick={() =>
              navigate('/pharmacy/pharmacy-info', {
                state: { order: orderItem },
              })
            }
            disable={orderItem?._id !== undefined ? false : true}
          />
        </div>
      </div>
    </>
  )
}

export default PharmacyOnhold
