import { CrossIcon2 } from '../../components/common/svg-components'
import { colors } from '../color'
import moment from 'moment'

export const pharmacyOnholdHeaderData: any = [
  {
    Header: 'DATE & TIME',
    accessor: 'date',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      const formattedTime = moment(originalDate).format('hh:mm')
      return <p>{formattedDate + '  ' + formattedTime}</p>
    },
  },
  { Header: 'CUSTOMER NAME', accessor: 'name' },
  { Header: 'TOTAL AMOUNT', accessor: 'total_amount' },
  {
    Header: ' ',
    Cell: (props: any) => {
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                props?.onRowClick(props?.row?.original?._id)
              }}
            />
          </div>
        </>
      )
    },
  },
]

export const pharmacyMedicineHeaderData: any = [
  { Header: 'PRODUCT', accessor: 'name' },
  { Header: 'QTY', accessor: 'qty' },
  { Header: 'UNIT PRICE', accessor: 'price' },
  { Header: 'DISCOUNT(%)', accessor: 'discount' },
  { Header: 'AMOUNT', accessor: 'total_amount' },
  {
    Header: 'EXPIRY DATE',
    Cell: ({ row }: any) => {
      return <p>-</p>
    },
  },
  {
    Header: 'BATCH',
    Cell: ({ row }: any) => {
      return <p>-</p>
    },
  },
  {
    Header: ' ',
    Cell: (props: any) => {
      return (
        <>
          <div>
            <CrossIcon2
              fillColor1={colors.red1}
              handleClick={() => {
                props?.onRowClick(props?.row?.original?._id)
              }}
            />
          </div>
        </>
      )
    },
  },
]
