// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.smartSearch_globalFilterSection__zB27a {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid var(--grey6);
  border-radius: 10px;
  width: 285px;
}
.smartSearch_globalFilterSection__zB27a .smartSearch_searchIconStyle__3XHUs {
  position: absolute;
  left: 12px;
  top: 16px;
}
.smartSearch_globalFilterSection__zB27a .smartSearch_disableSearchInput__xCHxP {
  opacity: 0.5;
  width: 285px;
  height: 50px;
  border: none;
  padding: 15px 15px 15px 45px;
  border-radius: 10px;
  background: #dcdcdc !important;
  border-radius: 10px;
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(97px);
}
.smartSearch_globalFilterSection__zB27a .smartSearch_disableSearchInput__xCHxP:focus {
  outline: none !important;
}
.smartSearch_globalFilterSection__zB27a .smartSearch_globalSearchInput__5ZckH {
  width: 285px;
  height: 50px;
  border: none;
  padding: 15px 15px 15px 45px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.smartSearch_globalFilterSection__zB27a .smartSearch_globalSearchInput__5ZckH:focus {
  outline: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/common/smart-search/smartSearch.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AAEJ;AACE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,4BAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,mDAAA;EACA,2BAAA;AACJ;AAAI;EACE,wBAAA;AAEN;AAEE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,4BAAA;EACA,mBAAA;EACA,oCAAA;EACA,mDAAA;EACA,mBAAA;AAAJ;AACI;EACE,wBAAA;AACN","sourcesContent":[".globalFilterSection {\n  display: flex;\n  align-items: center;\n  position: relative;\n  border: 1px solid var(--grey6);\n  border-radius: 10px;\n  width: 285px;\n  .searchIconStyle {\n    position: absolute;\n    left: 12px;\n    top: 16px;\n  }\n\n  .disableSearchInput {\n    opacity: 0.5;\n    width: 285px;\n    height: 50px;\n    border: none;\n    padding: 15px 15px 15px 45px;\n    border-radius: 10px;\n    background: #dcdcdc !important;\n    border-radius: 10px;\n    box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n    backdrop-filter: blur(97px);\n    &:focus {\n      outline: none !important;\n    }\n  }\n\n  .globalSearchInput {\n    width: 285px;\n    height: 50px;\n    border: none;\n    padding: 15px 15px 15px 45px;\n    border-radius: 10px;\n    background: rgba(255, 255, 255, 0.7);\n    box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n    border-radius: 10px;\n    &:focus {\n      outline: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalFilterSection": `smartSearch_globalFilterSection__zB27a`,
	"searchIconStyle": `smartSearch_searchIconStyle__3XHUs`,
	"disableSearchInput": `smartSearch_disableSearchInput__xCHxP`,
	"globalSearchInput": `smartSearch_globalSearchInput__5ZckH`
};
export default ___CSS_LOADER_EXPORT___;
