import React, { FC, useState } from "react";
import styles from "./dashboard.module.scss";
import DoctorMedicalNews from "../../../doctor-dashboard/doctor-medical-news/DoctorMedicalNews";
import Popup from "../../../../components/common/popup/Popup";
import MedicalNewsModal from "../../../../components/common/modal/medical-news-modal/MedicalNewsModal";

interface IDashboard {}

const Dashboard: FC<IDashboard> = () => {
  const [showNewsModal, setShowNewsModal] = useState<boolean>(false);
  const [newsModalData, setNewsModalData] = useState({});

  // medical news modal close
  const handleNewsModalClose = () => {
    setShowNewsModal(false);
    setNewsModalData({});
  };
  // handleNewsModal
  const handleNewsModal = (item: any) => {
    setShowNewsModal(!showNewsModal);
    setNewsModalData(item);
  };

  return (
    <>
      {showNewsModal && (
        <Popup
          Children={MedicalNewsModal}
          popData={newsModalData}
          handleClose={() => handleNewsModalClose()}
        />
      )}

      <div className={styles.dashbordContainer}>
        <DoctorMedicalNews handleNewsModal={handleNewsModal} />
      </div>

      {/* <div className={styles.requestContainer}> */}
      {/* <p className={styles.requestTextComingSoon}>Coming Soon</p>
        <p className={styles.requestText}>
          Hey! We are actively working on it.
        </p>
        <img
          src={comingsoon}
          alt="request-Vector"
          className={styles.requestVectorStyle}
        /> */}

      {/* </div>{' '} */}
      {/* <div className={styles.pageWrapper}>
        <p className={styles.dashboardTitle}>TBD</p>
      </div> */}
    </>
  );
};

export default Dashboard;
