import {
  ALLOWANCE_NAME1,
  ALLOWANCE_VALUE,
  ALLOWANCE_VALUE1,
  BASIC_SALARY,
  DEDUCTION_NAME1,
  DEDUCTION_VALUE1,
  EXPENSE_CLAIM,
  HOUR_CLOCKED,
  HOUR_RATE,
  MONTH,
  NET_SALARY,
  PRESENT_DAYS,
  STATUS,
  STATUS_TYPE,
  TOTAL_ALLOWANCE,
  TOTAL_DEDUCTION,
  TOTAL_SALARY,
  WORKING_DAYS,
  YEAR,
  _id,
  emp_id,
  name_en,
} from '../pages/payroll/creare-salary-slip/createSalaryConstant'

export const createsalaryValidators = {
  [_id]: {
    required: 'Please select id',
  },
  [STATUS_TYPE]: {
    required: '',
  },
  [BASIC_SALARY]: {
    required: 'Please select id',
  },

  [emp_id]: {
    required: 'Please select id',
  },

  [MONTH]: {
    required: 'Please select month',
  },
  [YEAR]: {
    required: 'Please select year',
  },
  [TOTAL_ALLOWANCE]: {
    required: 'Please enter allowances',
  },
  [TOTAL_DEDUCTION]: {
    required: 'Please enter deduction',
  },
  [NET_SALARY]: {
    required: 'Please enter salary',
  },
  [TOTAL_SALARY]: {
    required: 'Please enter payment',
  },
  [HOUR_RATE]: {
    required: 'Please enter rate',
  },
  [EXPENSE_CLAIM]: {
    required: '',
  },
  [STATUS]: {
    required: 'Please select status',
  },
  [HOUR_CLOCKED]: {
    required: 'Please enter hours',
  },
  [name_en]: {
    required: 'Please enter name',
  },
  [ALLOWANCE_NAME1]: {
    required: '',
  },

  [ALLOWANCE_VALUE1]: {
    required: '',
  },
  [ALLOWANCE_VALUE]: {
    required: '',
  },

  [DEDUCTION_NAME1]: {
    required: '',
  },

  [DEDUCTION_VALUE1]: {
    required: '',
  },
  [PRESENT_DAYS]: {
    required: 'Enter present days',
  },
  [WORKING_DAYS]: {
    required: 'Enter working days',
  },
}
