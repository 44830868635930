import { useForm } from "react-hook-form";
import Button from "../../../components/common/button/Button";
import { Input } from "../../../components/common/input/input";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { requestGenerator } from "../../../utils/payloadGenerator";
import {
  getIpdCaseById,
  ipdFormDischarge,
} from "../../../redux/features/ipd/ipdAsyncActions";
import Loader from "../../../components/common/spinner/Loader";
import moment from "moment";
import { trimValue } from "../../../utils/utils";

const DischargeFormModal = () => {
  const getPatiendId = useLocation().state;
  const navigate = useNavigate();
  const checkIpdIdExist = getPatiendId?.hasOwnProperty?.("ipd_id");
  const { storeIpdCaseById, isIpdCaseLoading } = useAppSelector(
    (state) => state.ipd
  );
  const admittedDate = moment(storeIpdCaseById?.admission_date).format(
    "DD-MMM-YYYY"
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checkIpdIdExist) {
      let data = {
        ipd_id: getPatiendId?.ipd_id,
      };
      dispatch(getIpdCaseById(requestGenerator(data)));
    }
  }, [checkIpdIdExist, dispatch, getPatiendId?.ipd_id]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    if (Object.keys(storeIpdCaseById)?.length > 0) {
      setValue("firstName", storeIpdCaseById?.patient_name);
      setValue("date", admittedDate);
      setValue("caseNotes", storeIpdCaseById?.case_description);
      setValue("medicalNotes", storeIpdCaseById?.medical_notes);
    }
  }, [admittedDate, setValue, storeIpdCaseById]);

  const onSubmit = (items: any) => {
    let data = {
      ipd_id: getPatiendId?.ipd_id,
      discharge_date: new Date().toISOString(),
      doctor_notes: items?.doctorNotes,
    };
    dispatch(ipdFormDischarge(requestGenerator(data))).then((e) => {
      console.log(e, "event");
      if (e.type === "ipd/ipdFormDischarge/fulfilled") {
        navigate("/ipd");
      }
    });
  };
  return (
    <>
      {isIpdCaseLoading && <Loader />}
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formFields}>
          <Input
            labelText="Patient Name"
            inlineStyle={{ flexBasis: "50%" }}
            {...register("firstName")}
            disabled
            inlineClass={styles.disabledField}
          />
          <Input
            labelText="Date of Admittance"
            {...register("date")}
            inlineStyle={{ flexBasis: "50%" }}
            disabled
            inlineClass={styles.disabledField}
          />
        </div>
        <div className={styles.formTextFields}>
          <label className={styles.labelWrapper}>Case Notes</label>
          <div className={styles.textContainer}>
            <textarea
              rows={2}
              cols={25}
              {...register("caseNotes", { required: true })}
              disabled
            />
          </div>
        </div>
        <div className={styles.formTextFields}>
          <label className={styles.labelWrapper}>Medical Notes</label>
          <div className={styles.textContainer}>
            <textarea
              rows={2}
              cols={25}
              {...register("medicalNotes")}
              disabled
            />
          </div>
        </div>
        <div className={styles.formTextFields}>
          <label className={styles.labelWrapper}>
            Doctor Note
            <span className={styles.requiredField}>*</span>
          </label>
          <div className={styles.textContainer}>
            <textarea
            placeholder="Please enter doctor notes"
              rows={2}
              cols={25}
              {...register("doctorNotes", { required: true })}
              onChange={(e) => trimValue(e)}
            />
            {errors?.doctorNotes?.type === "required" && (
              <p className="dashboardFormError">Please enter doctor notes</p>
            )}
          </div>
        </div>
        <p className={styles.note}>
          <strong> Note: </strong> Once you save discarge form you cannot get
          back to patient ipd case.
        </p>
        <div className={styles.formBtnContainer}>
          <Button title="Save" />
        </div>
      </form>
    </>
  );
};

export default DischargeFormModal;
