
import styles from "./leaveEncashment.module.scss";
import moment from "moment";

// Annual Leave Applications Table Data
export const AnnualLeaveApplicationsTableHeaderData: any = [

  {
    Header: "START_DATE",
    // accessor: "start_date",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.start_date;
      const formattedDate = moment(originalDate).format("	Do MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: "END_DATE",
    // accessor: "end_date",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.end_date;
      const formattedDate = moment(originalDate).format("	Do MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: "DAYS",
    accessor: "days",
  },
];
export const AnnualLeaveApplicationsTableDummyData: any[] = [
  {
    start_date: "1 May , 2020",
    end_date: "6 May , 2020",
    days: "6",
  },
  {
    start_date: "18 Apr , 2023",
    end_date: "22 Apr , 2023",
    days: "1",
  },
  {
    start_date: "15 Oct , 2020",
    end_date: "17 Oct , 2020",
    days: "2",
  },
];
// Leaves Encashment History Table Data
export const LeavesEncashmentHistoryTableHeaderData: any = [
  {
    Header: "PERIOD",
    accessor: "period",
  },
  {
    Header: "DAYS",
    accessor: "days",
  },
  {
    Header: "PAID_DATE",
    // accessor: "paid_date",
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.paid_date;
      const formattedDate = moment(originalDate).format("	Do MMM YYYY");
      return <>{formattedDate}</>;
    },
  },
  {
    Header: "STATUS",
    accessor: "_status",
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?._status ? (
            <span
              className={
                row?.original?._status === "Paid"
                  ? styles.statusPaid 
                  : row?.original?._status === "Unpaid"
                    ? styles.statusUnpaid
                    : ""
              }
            >
              {row?.original?._status}
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
];
export const LeavesEncashmentHistoryTableDummyData: any[] = [
  {
    period: "2019-20",
    days: "2",
    paid_date: "8 Oct , 2020",
    _status: "Paid",
    amount: "1523",
  },
  {
    period: "2019-20",
    days: "2",
    paid_date: "8 Oct , 2020",
    _status: "Unpaid",
    amount: "1523",
  },
];