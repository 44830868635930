import styles from "./style.module.scss";
import moment from "moment";

export const ipdViewAllData: any = [
  {
    Header: "ADMITTED DATE",
    accessor: (row: any) => {
      return moment(row?.admit_date).format("DD-MMM-YYYY") ?? "-";
    },
  },
  {
    Header: "CASE NO.",
    accessor: "case_no",
  },
  {
    Header: "DISCHARGE DATE",
    accessor: (row: any) => {
      return moment(row?.discharge_date).format("DD-MMM-YYYY") ?? "-";
    },
  },
  {
    Header: "CONSULTATION",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original?.conclution)}
        >
          View
        </p>
      );
    },
  },
  {
    Header: "TESTS",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.tests)}
        >
          View
        </p>
      );
    },
  },
  {
    Header: "MEDICATION",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onPopClose(props.row.original.medication)}
        >
          View
        </p>
      );
    },
  },
];
