import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './departments.module.scss'
import {
  departmentsTableHeaderData,
  departmnetsTableData,
} from '../../../constants/data'
import Pagination from '../../../components/common/pagination/Pagination'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Search from '../../../components/common/search/Search'

interface IDeparments {}

const Departments: FC<IDeparments> = () => {
  const navigate = useNavigate()
  const [showCostViewPopup, setShowCostViewPopup] = useState<boolean>(false)
  const [searchdocumentsRowData, setSearchdocumentsRowData] =
    useState<string>('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const handleCostView = () => {
    setShowCostViewPopup((prevState) => !prevState)
  }
  return (
    <>
      <div className={styles.departmensContainer}>
        <div className={styles.searchContainer}>
          <Search />
        </div>
        <TableV2
          tableHeaderData={departmentsTableHeaderData}
          tableRowData={departmnetsTableData}
          handleClick={handleCostView}
        />
        {departmnetsTableData && departmnetsTableData.length !== 0 ? (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )
}
export default Departments
