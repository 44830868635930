import { FC, useState, useEffect } from 'react'
import styles from './additionalitemspopup.module.scss'
import { CloseIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import Divider from '../../../components/common/divider/Divider'
import Button from '../../../components/common/button/Button'
import SearchDropDown from '../../../components/common/search-dropdown/SearchDropDown'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useForm } from 'react-hook-form'
import { setPurchaseOrderList } from '../../../redux/features/inventory-request/inventoryRequestSlice'
import { getItemFromStore } from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import { Input } from '../../../components/common/input/input'
import Select from 'react-select'
import { DropdownIndicator } from '../../../components/common/dropdown-indicator/DropdownIndicator'
import { useLocation } from 'react-router-dom'

interface IAdditionalItemsPoPopup {
  handleClose?: any
}

const AdditionalItemsPoPopup: FC<IAdditionalItemsPoPopup> = ({
  handleClose,
}) => {
  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm()

  const [searchString, setSearchString] = useState('')
  const [supplierName, setSupplierName] = useState<any>({})
  const { getItemWithStoreData } = useAppSelector(
    (state) => state.inventoryRequest
  )

  const location = useLocation()

  const { branchData } = useAppSelector((state) => state.login)

  useEffect(() => {
    let requestData = {
      store_id:
        location?.pathname === '/mainstore'
          ? branchData?.main_store?.[0]?._id
          : branchData?.pharmacy_store?.[0]?._id,
      type: location?.pathname === '/mainstore' ? 'GENERAL' : 'MEDICINES',
    }
    dispatch(getItemFromStore(requestGenerator(requestData)))
  }, [
    branchData?.main_store,
    branchData?.pharmacy_store,
    dispatch,
    location?.pathname,
  ])

  const onSubmit = (item: any) => {
    let data = {
      delievered_qty: item?.qty,
      unit_Type: item?.unit?.value,
      name: supplierName?.name,
      item_id: supplierName?._id,
    }
    dispatch(setPurchaseOrderList(data))
    handleClose()
  }
  return (
    <div
      className={styles.additionalItemsPopupMainContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => handleClose()}
      />
      <div className={styles.paymentContainer}>
        <p className={styles.title}> Additional Items </p>
        <Divider customClass={styles.dividerStyle} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchMainContainer}>
          <span className={styles.text}>Search Items</span>
          <SearchDropDown
            placeholder="Select Item"
            searchString={searchString}
            setSearchString={setSearchString}
            dropdownDataToSee={getItemWithStoreData}
            dropDownKeyName="name"
            customClass={styles.search}
            handleClick={(item: any, setVal: any, setShowDropdown: any) => {
              setVal(item?.name)
              setSupplierName(item)
              setShowDropdown(false)
            }}
          />
        </div>
        <div className={styles.inputFieldContainer}>
          <label className={styles.formLabel}>
            Unit
            <span className="asterick">*</span>
          </label>
          <div className={styles.fieldAndErrorTxtContainer}>
            <Select
              className={styles.selectInputField}
              isDisabled={!supplierName?.hasOwnProperty('unites')}
              placeholder="Select Unit"
              closeMenuOnSelect={true}
              components={{ DropdownIndicator }}
              value={watch('Unit')}
              options={supplierName?.unites?.map((s: any) => {
                return {
                  label: s?.value,
                  value: s?._id,
                }
              })}
              {...register('Unit', { required: true })}
              isSearchable={false}
              onChange={(e: any) => {
                setValue('Unit', e)
              }}
              maxMenuHeight={200}
            />
            {errors?.Unit?.type === 'required' && (
              <p className={styles.formError}>Please select unit</p>
            )}
          </div>
        </div>

        <div className={styles.inputFields}>
          <Input
            labelText="Qty"
            requiredField={true}
            {...register('qty', { required: true })}
            placeholder="Enter Qty"
            errorMessage="please enter qty"
            showErrors={errors?.qty?.type === 'required'}
          />
        </div>

        <div className={styles.btnContainer}>
          <Button
            title="Submit"
            type="submit"
            disable={Object?.keys(supplierName)?.length === 0}
          />
        </div>
      </form>
    </div>
  )
}

export default AdditionalItemsPoPopup
