import Select from 'react-select';
import styles from './internal.module.scss';
import moment from 'moment';
import { useAppSelector } from '../../../../hooks';
import { useState, useEffect } from 'react';
import { reactSelectStyle } from '../../../../constants/data';

export const ExternalHeader: any[] = [
  {
    Header: 'SR NO',
    accessor: 'sr_no',
  },
  {
    Header: 'ADDED ON',
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt;
      const formattedDate = moment(originalDate).format('DD MMM YYYY');
      return <>{formattedDate}</>;
    },
  },
  {
    Header: 'LEAD NAME',
    accessor: 'name',
  },
  {
    Header: 'CONTACT NO',
    accessor: 'phone',
  },
  {
    Header: 'GENDER',
    accessor: 'gender',
  },
  {
    Header: 'NATIONALITY',
    accessor: 'nationality',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.nationality?.length > 0
            ? row?.original?.nationality
            : '-'}
        </p>
      );
    },
  },
  {
    Header: 'AGE',
    accessor: 'age',
  },
  {
    Header: 'SOURCE',
    accessor: 'type',
  },
  {
    Header: 'STATUS',
    Cell: ({ row }: any) => {
      return (
        <>
          <p
            className={
              row?.original?.is_assigned ? styles.greenStyle : styles.redStyle
            }
          >
            {' '}
            {row?.original?.is_assigned ? 'Assigned' : 'Unassigned'}
          </p>
        </>
      );
    },
  },
  {
    Header: 'ASSIGN',
    Cell: (props: any) => {
      const { allUserData } = useAppSelector((state) => state.callCenter);

      let filterData = allUserData?.filter(
        (item: any) => item?._id === props?.row?.original?.assigned_to_id
      );

      const [selectUser, setSelectUser] = useState<any>(undefined);

      useEffect(() => {
        if (filterData[0]?._id !== undefined) {
          setSelectUser({
            label: filterData[0]?.name,
            value: filterData[0]?._id,
          });
        } else {
          setSelectUser(undefined);
        }
      }, [filterData[0]?.name]);

      return (
        <>
          <Select
            className={styles.select}
            placeholder="Select assignee"
            closeMenuOnSelect={true}
            isSearchable={true}
            options={allUserData?.map((item: any) => ({
              label: item?.name,
              value: item?._id,
            }))}
            onChange={(e: any) => {
              props?.onClick(props?.row?.original?._id, e?.value);
              setSelectUser({
                label: e?.label,
                value: e?.value,
              });
            }}
            value={selectUser}
            maxMenuHeight={200}
            styles={reactSelectStyle}
          />
        </>
      );
    },
  },
];
