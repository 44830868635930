import React, { useEffect, useState } from "react";
import styles from "./PatientConsumble.module.scss";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import {
  inventoryItemsHeaderData,
  patientConsumbleHeaderData,
} from "../../../constants/table-data/patientIpdInvoiceTableData";
import Button from "../../../components/common/button/Button";
import AddItems from "../../../components/common/modal/add-items-modal/AddItems";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { getAllInventoryItems } from "../../../redux/features/ipd-invoice/ipdAsyncActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { setFilteredConsumableList } from "../../../redux/features/ipd-invoice/ipdInvoiceSlice";
import { CustomModal } from "../../../components/common/custom-modal/modal";
import Pagination from "../../../components/common/pagination/Pagination";
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import { Cols } from "../../../interfaces/interfaces";

const PatientConsumble = () => {
  const [showAddItemsPopup, setshowAddItemsPopup] = useState<any>(false);
  const [billStatus, setbillStatus] = useState("All");

  const [searchValue, setSearchValue] = useState<string>("");
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [activateSmartSearch, setActivateSmartSearch] =
    useState<boolean>(false);

  const { allInventoryItems, consumableFilterList, isLoading, patientIpdInfo } =
    useAppSelector((state) => state.ipdInvoice);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  // React Table define
  const data: Cols[] = allInventoryItems;
  const columns: Column<Cols>[] = inventoryItemsHeaderData;
  const options: TableOptions<Cols> = {
    data,
    columns,
  };

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy);

  // @ts-ignore
  const { globalFilter } = state;

  const handleAddItems = () => {
    setshowAddItemsPopup(!showAddItemsPopup);
    setSearchValue("")
  };

  // Get All Inventory Items Pop Data

  const handleInventoryAllItems = () => {
    if (searchValue === '') {
      const requestData = {
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(getAllInventoryItems(requestGenerator(requestData))).then(
        (result) => setTotalPage(result.payload.lastPage)
      )
    }
  }

  // Add Item Pop Search

  const handleSearch = () => {
    setActivateSmartSearch(true);
    setPageIndex(1);
    const requestData = {
      name: searchValue,
      page: 1,
      pageSize: dataPerPage,
    };
    dispatch(getAllInventoryItems(requestGenerator(requestData))).then(
      (result) => setTotalPage(result.payload.lastPage)
    );
  };

  const handleNavigate = () => {
    navigate("/ipd-invoice/charges");
  };

  const handleRadioChange = (e: any) => {
    const { value } = e.target;
    setbillStatus(value);
    dispatch(setFilteredConsumableList(value));
  };

  // useEffect(() => {
  //   if (searchValue === "") {
  //     const requestData = {
  //       page: pageIndex,
  //       pageSize: dataPerPage,
  //     };
  //     dispatch(getAllInventoryItems(requestGenerator(requestData))).then(
  //       (result) => setTotalPage(result.payload.lastPage)
  //     );
  //   }
  // }, [searchValue, dataPerPage, pageIndex]);

  return (
    <>
      {
        // showAddItemsPopup && (
        //     <Popup
        //         Children={AddItems}
        //         handleOpen={handleInventoryAllItems}
        //         popData={allInventoryItems}
        //         headerData={inventoryItemsHeaderData}
        //         handleClose={handleAddItems}
        //         heading="Add Items"
        //     />
        // )

        <CustomModal
          title="Add Items"
          showModal={showAddItemsPopup}
          closeModal={() => setshowAddItemsPopup(!showAddItemsPopup)}
          width="750px"
          height="600px"
        >
          <AddItems
            // tableHeaderData={inventoryItemsHeaderData}
            headerGroups={headerGroups}
            // tableRowData={allInventoryItems}
            rows={rows}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            handleAllItemList={() => handleInventoryAllItems()}
            dataPerPage={dataPerPage}
            pageIndex={pageIndex}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            activateSmartSearch={activateSmartSearch}
            handleSearch={handleSearch}
            setGlobalFilter={setGlobalFilter}
            isLoading={isLoading}
            handleClose={handleAddItems}
          >
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          </AddItems>
        </CustomModal>
      }

      <div className={styles.mainContainer}>
        <div className={styles.checkboxContainer}>
          <div className={styles.fieldErrorContainer}>
            <label htmlFor="billable" className={styles.radioLabel}>
              <input
                className={styles.radioInput}
                id="billable"
                type="radio"
                value="Billable"
                checked={billStatus === "Billable"}
                onChange={handleRadioChange}
              />
              <span className={styles.customRadio} />
              Billable
            </label>

            <label htmlFor="non-billable" className={styles.radioLabel}>
              <input
                className={styles.radioInput}
                type="radio"
                id="non-billable"
                value="Non-billable"
                checked={billStatus === "Non-billable"}
                onChange={handleRadioChange}
              />
              <span className={styles.customRadio} />
              Non-billable
            </label>

            <label htmlFor="all" className={styles.radioLabel}>
              <input
                className={styles.radioInput}
                id="all"
                type="radio"
                checked={billStatus === "All"}
                onChange={handleRadioChange}
                value="All"
              />
              <span className={styles.customRadio} />
              All
            </label>
          </div>

          <Button
            type="button"
            title="Add Items"
            customClass={styles.addItemButton}
            handleClick={handleAddItems}
            disable={patientIpdInfo?.isIns}
          />
        </div>

        <div className={styles.consumbleTableContainer}>
          <TableV2
            tableHeaderData={patientConsumbleHeaderData}
            tableRowData={consumableFilterList}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            title="Next"
            customClass={styles.nextButton}
            handleClick={handleNavigate}
          />

          <Button
            title="Back"
            type="button"
            customClass={styles.backButton}
            handleClick={() => navigate(`/ipd-invoice/patientinfo`)}
          />
        </div>
      </div>
    </>
  );
};

export default PatientConsumble;
