import { FC } from 'react'
import TableV2 from '../../../../components/common/table/tableV2/TableV2'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import styles from './patientHistoryForm.module.scss'
import { useState, useEffect } from 'react'

interface IMedicationModal {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void
  popData: any
  heading: string
  headerData: any
  handleOpen: any
}

const PatientHistoryForms: FC<IMedicationModal> = ({
  handleClose,
  popData,
  heading,
  headerData,
  handleOpen,
}) => {
  const [tableData, setTabledata] = useState<any[]>([])
  useEffect(() => {
    setTabledata([popData])
  }, [popData])
  return (
    <>
      <div
        className={styles.formsModalContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose()
          }}
        />
        <h1 className={styles.formsModalHeading}>{heading}</h1>
        <hr className={styles.formsModalDivider} />
        <div className={styles.formModalContainer}>
          <div className={styles.formModalDateContainer}>
            <p className={styles.formModalDateTitle}>Date</p>
            {' : '}
            <p className={styles.formModalDate}>{popData?.diag_apt_date}</p>
          </div>
          <div className={styles.formModalTableContainer}>
            <TableV2
              tableHeaderData={headerData}
              tableRowData={tableData}
              active={false}
              handleClick={(form: any, index: any) => handleOpen(form, index)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientHistoryForms
