import {
  DEPARTMENT_PAYROL,
  MONTH_PAYROL,
  YEAR_PAYROL,
} from '../constants/constant'

export const generatePayrollValidators = {
  [YEAR_PAYROL]: {
    required: 'Please select month',
  },
  [MONTH_PAYROL]: {
    required: 'Please select year',
  },
  [DEPARTMENT_PAYROL]: {
    required: 'Please select department',
  },
}
