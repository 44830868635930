import moment from 'moment'
import { requestToDownloadPdf } from '../../utils/utils'
import { PrintIcon } from '../../components/common/svg-components'
import { SETTLED_RECEIPT_VIEW_URL } from '../../config/config'
import { requestGenerator } from '../../utils/payloadGenerator'

export const viewReceiptHeaderData: any = [
  {
    Header: 'DATE',
    Cell: ({ row }: any) => {
      const formattedDate = moment(row?.original?.createdAt).format(
        'DD MMM YYYY'
      )
      return <>{formattedDate}</>
    },
  },
  {
    Header: 'RECEIPT NO',
    accessor: 'receipt_no',
  },
  {
    Header: 'OUTSTANDING AMOUNT',
    Cell: ({ row }: any) => {
      return <span>{row?.original?.outstanding_amount ?? '-'}</span>
    },
  },
  {
    Header: 'EMAIL',
    Cell: ({ row, onPopClose, onRowClick }: any) => {
      return (
        <>
          <span
            onClick={() => {
              onRowClick(row?.original)
            }}
            style={{ cursor: 'pointer', color: '#0e26a3' }}
          >
            View
          </span>
        </>
      )
    },
  },
  {
    Header: 'PRINT',
    Cell: (props: any) => {
      const handlePrint = (receipt: any) => {
        const requestedData = {
          _id: receipt?._id,
          email: receipt?.patient_detail[0]?.user?.email,
          is_email: false,
        }
        requestToDownloadPdf(
          SETTLED_RECEIPT_VIEW_URL,
          requestGenerator(requestedData),
          's',
          props.onClick,
          'print'
        )
      }
      return (
        <>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => handlePrint(props?.row?.original)}
          >
            <PrintIcon />
          </span>
        </>
      )
    },
  },
]
