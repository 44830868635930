// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reloadButton_reloadButtonSection__cIQCw {
  display: flex;
  align-items: center;
  margin-left: 7px;
}
.reloadButton_reloadButtonSection__cIQCw .reloadButton_reloadButton__BJtOS {
  border: none;
  outline: none;
  padding: 10px 12px;
  border-radius: 50%;
  margin-right: 15px;
  background: var(--green1);
}
.reloadButton_reloadButtonSection__cIQCw .reloadButton_reloadButtonText__LmXhR {
  width: 91px;
  color: var(--grey4);
  font-size: var(--font-12);
  font-weight: var(--font-normal);
}`, "",{"version":3,"sources":["webpack://./src/components/common/reload-button/reloadButton.module.scss","webpack://./src/styles/mixin.scss"],"names":[],"mappings":"AAEA;ECoBE,aAAA;EACA,mBAAA;EDnBA,gBAAA;AAAF;AACE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACE;EACE,WAAA;ECJF,mBDKgB;ECJhB,yBDI8B;ECH9B,+BDG8C;AAGhD","sourcesContent":["@import '../../../styles//mixin.scss';\n\n.reloadButtonSection {\n  @include flexVertical;\n  margin-left: 7px;\n  .reloadButton {\n    border: none;\n    outline: none;\n    padding: 10px 12px;\n    border-radius: 50%;\n    margin-right: 15px;\n    background: var(--green1);\n  }\n  .reloadButtonText {\n    width: 91px;\n    @include font(var(--grey4), var(--font-12), var(--font-normal));\n  }\n}\n","@mixin pageWrapper($padding) {\n  background: var(--white2);\n  // backdrop-filter: blur(6px);\n  box-shadow: 0px 10px 117px 14px rgba(0, 0, 0, 0.05);\n  border-radius: 10px;\n  padding: $padding;\n  margin: 20px 20px 30px 0px;\n}\n\n@mixin font($color, $size, $weight) {\n  color: $color;\n  font-size: $size;\n  font-weight: $weight;\n}\n\n@mixin flexCenter {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin flexVertical {\n  display: flex;\n  align-items: center;\n}\n\n@mixin flexColumn {\n  display: flex;\n  flex-direction: column;\n}\n\n@mixin responsive($width) {\n  @media (max-width: $width) {\n    @content;\n  }\n}\n\n@mixin animation($name) {\n  @keyframes #{$name} {\n    @content;\n  }\n}\n\n@mixin iconHoverText($content) {\n  cursor: pointer;\n\n  &:hover::after {\n    @include font(var(--black2), var(--font-12), var(--font-semibold));\n    @include flexCenter;\n    position: absolute;\n    content: $content;\n    background-color: var(--white1);\n    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.1);\n    border-radius: 6px;\n    width: 116px;\n    height: 36px;\n    z-index: 1;\n    opacity: 1;\n    margin: -25px 0 0 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reloadButtonSection": `reloadButton_reloadButtonSection__cIQCw`,
	"reloadButton": `reloadButton_reloadButton__BJtOS`,
	"reloadButtonText": `reloadButton_reloadButtonText__LmXhR`
};
export default ___CSS_LOADER_EXPORT___;
