import {
  PHARMACY_ITEM_CODE,
  PHARMACY_PRODUCTS,
  PHARMACY_SELL_PRICE,
  PHARMACY_DISCOUNT,
  PHARMACY_QTY,
  PHARMACY_SUB_QTY,
  PHARMACY_EXPIRY_DATE,
  PHARMACY_AVAILABLE_QTY,
} from '../constants/constant'

export interface IaddPharmacyCustomerInformationSecondValidators {
  [PHARMACY_ITEM_CODE]: {
    required: string
  }

  [PHARMACY_PRODUCTS]: {
    required: string
  }

  [PHARMACY_SELL_PRICE]: {
    required: string
  }

  [PHARMACY_DISCOUNT]: {
    required: string
  }

  [PHARMACY_QTY]: {
    required: string
    validate: any
  }

  [PHARMACY_EXPIRY_DATE]: {
    required: string
  }

  [PHARMACY_AVAILABLE_QTY]: {
    required: any
  }
}

export const addPharmacyCustomerSecondInformation: any =
  {
    [PHARMACY_ITEM_CODE]: {
      required: 'Please select item code',
    },
    [PHARMACY_PRODUCTS]: {
      required: 'Please select product',
    },
    [PHARMACY_SELL_PRICE]: {
      required: 'Please enter sell price',
    },
    [PHARMACY_DISCOUNT]: {
      required: 'Please enter disount',
    },
    [PHARMACY_QTY]: {
      required: 'Please enter qty',
      validate: {
        handleQty: (value: string, values: IaddPharmacyCustomerInformationSecondValidators) => {
          const pharmacyAvailabelQty = values[PHARMACY_AVAILABLE_QTY];
          if(Number(pharmacyAvailabelQty) === 0 || Number(value) === 0) {
            return "Qty should not be zero";
          }
          else if (Number(value) > Number(pharmacyAvailabelQty)) {
            // console.log("startTime", pharmacyAvailabelQty);
            return "Qty should be less than available Qty";
          }
        },
      },
    },
    [PHARMACY_EXPIRY_DATE]: {
      required: 'Please select expiry date',
    },
}
