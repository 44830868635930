export const SearchModalHeaderData: any = [
    {
      Header: "FILE NO.",
      accessor: "emr_no",
      Cell: (props: any) => {
        return (
          <>
            <span
              style={{ color: "#0e26a3", cursor: "pointer" }}
              onClick={() => props.onClick(props?.row?.original)}
            >
              {props?.row?.original?.emr_no}
            </span>
          </>
        );
      },
    },
    {
      Header: "PATIENT NAME",
      accessor: "patient_name",
    },
  
    {
      Header: "NATIONAL ID",
      accessor: "national_id",
    },
    {
      Header: "MOBILE",
      accessor: "phone",
    },
  ];