import React, { FC, useEffect, useState } from 'react';
import styles from './ipdTestResultPopup.module.scss';
import { CloseIcon } from '../../svg-components';
import { colors } from '../../../../constants/color';
import Divider from '../../divider/Divider';
import TableV2 from '../../table/tableV2/TableV2';
import { ipdTestResultPopupData } from '../../../../constants/table-data/ipdTestlistPopupData';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { requestGenerator } from '../../../../utils/payloadGenerator';
import { GetAllAddResultData } from '../../../../redux/features/jobs/jobsAsyncActions';

interface IIpdTestResultPopup {
  handleClose?: any;
  popData?: any;
  handleOpen?: any;
}

const IpdTestResultPopup: FC<IIpdTestResultPopup> = ({
  handleClose,
  popData,
  handleOpen,
}) => {
  const dispatch = useAppDispatch();
  const { getAllAddResultData } = useAppSelector((state) => state.labsJob);
  const [resultData, setResultData] = useState<any>(popData);

  let result = [] as any;
  useEffect(() => {
    for (let i = 0; i < popData.length; i++) {
      const b = {} as any;
      const { test_component_id, test_name, profile_name } = popData[i];
      for (let j = 0; j < getAllAddResultData.length; j++) {
        const { _id, components } = getAllAddResultData[j];
        if (test_component_id === _id) {
          // b.test_name = test_name;
          // b.profile_name = profile_name;
          // b.components = components;
          for (let k = 0; k < components.length; k++) {
            result.push({
              ...components[k],
              test_name,
              profile_name,
            });
          }
        }
      }
      // result.push(b);
      setResultData(result);
    }
  }, [popData, getAllAddResultData]);

  useEffect(() => {
    let testId = popData?.map((item: any) => item?.test_component_id);
    dispatch(GetAllAddResultData(requestGenerator({ testIds: testId })));
  }, [popData]);

  return (
    <>
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>Result</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tabelContainer}>
            <TableV2
              tableHeaderData={ipdTestResultPopupData}
              tableRowData={resultData}
              active={false}
              handleRowClick={handleOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IpdTestResultPopup;
