import { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../../../components/common/button/Button";
import Popup from "../../../components/common/popup/Popup";
import {
  NumberToWord,
  disableArrowKey,
  numberToWordConverter,
  reactDropdownStyles,
  reactVoucherStyles,
  trimValue,
} from "../../../utils/utils";
import { colors } from "../../../constants/color";
import {
  ACCOUNT,
  COST_CENTER,
  COST_CENTER_VALUE,
  CREDIT_AMOUNT,
  DATE,
  DEBIT_AMOUNT,
  ENTRY_DATE,
  ENTRY_REMARKS,
  REFERENCE_NO,
  REF_DOC_CATEGORY,
  VOUCHERS_TYPE,
  VOUCHER_NO,
  VOUCHER_REMARKS,
  VOUCHER_STATUS,
} from "../voucherConstant";
import { IAddVoucherEntries, IVoucherForm } from "../voucherInterface";
import { vouchersValidators } from "../voucherValidator";
import VoucherLogModal from "../voucher-log-modal/VoucherLogModal";
import AddVoucherModal from "../add-voucher-modal/AddVoucherModal";
import CostViewModal from "../cost-view-modal/CostViewModal";
import {
  CloseIcon,
  PlusIcon,
  SearchIcon,
} from "../../../components/common/svg-components";
import { voucherTableHeaderData } from "./vouchersTableData";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getAccountNumber,
  getVoucher,
  getreferenceDoc,
  getvoucherData,
  submitVoucherData,
  updatevoucherData,
  getAllDepartment,
  getAllBranch,
} from "../../../redux/features/vouchers/vouchersAsynActions";
import { requestGenerator } from "../../../utils/payloadGenerator";
import Loader from "../../../components/common/spinner/Loader";
import {
  clearCostCenterData,
  clearSubmitVoucherData,
  clearVoucherListData,
  emptyAddVoucherData,
  removeCostCenterData,
  setAddVoucherData,
  setCostCenterData,
  setEditCostCenterData,
} from "../../../redux/features/vouchers/vouchersSlice";
import {
  costCenterOptions,
  failure,
  voucherStatusOptions,
} from "../../../constants/data";
import styles from "./vouchers.module.scss";
import { setMessage } from "../../../redux/features/toast/toastSlice";
import TableV2 from "../../../components/common/table/tableV2/TableV2";
import { getAllDoctorList } from "../../../redux/features/receptionist/receptionistAsyncActions";
import DeleteMedicationPopup from "../../../components/common/modal/delete-medication-popup/DeleteMedicationPopup";
import jsPDF from "jspdf";

export function uniqueID() {
  return Math.floor(Math.random() * Date.now());
}

const Vouchers = () => {
  const [showVoucherLogModal, setShowVoucherLogModal] =
    useState<boolean>(false);
  const [showAddVoucherModal, setShowAddVoucherModal] =
    useState<boolean>(false);
  const [showSaveNext, setShowSaveNext] = useState<boolean>(false);
  const [disableSaveNext, setDisabledShowSaveNext] = useState<boolean>(false);
  const [showCostViewPopup, setShowCostViewPopup] = useState<boolean>(false);
  const [dataById, setDataById] = useState<any>({});
  const [voucherSearch, setVoucherSearch] = useState<any>("");
  const [costViewData, setCostViewData] = useState({});
  const [voucherStatus, setVoucherStatus] = useState<string>("");
  const [CreditAmount, setCreditAmount] = useState<number>(0);
  const [DebitAmount, setDebitAmount] = useState<number>(0);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deletePopData, setDeletePopData] = useState("");
  const [mergeVouchersData, setMergeVouchersData] = useState<any>([]);
  const [voucherVal, setVoucherVal] = useState<any>("");
  const [isVoucher, setIsVoucher] = useState<Boolean>(false);
  const [costCenterValue, setCostCenterValue] = useState<any>([]);
  const [costCenterOption, setcostCenterOption] = useState<any>([]);
  const [updateDisable, setButtonDisable] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const [pdfBtnDisabled, setPdfBtnDisabled] = useState(true);

  const animatedComponent = makeAnimated();
  const dispatch = useAppDispatch();
  const {
    loading,
    getVoucherName,
    getAccountNum,
    getReferenceDoc,
    addVoucherData,
    voucherListData,
    costCenterList,
    getVoucherData,
  } = useAppSelector((state) => state.vouchers);

  console.log("getVoucherData", getVoucherData);

  const doctorState = useAppSelector((state) => state.receptionist);
  const { userData } = useAppSelector((state) => state.login);

  const getVouchers = getVoucherName?.data;
  const getAccNum = getAccountNum?.data;
  const getRef = getReferenceDoc?.refAttributes;
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    watch: watch1,
    trigger: trigger1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm<IVoucherForm>({});

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    watch: watch2,
    trigger: trigger2,
    setError: setError2,
    reset: reset2,
    // control,
    formState: { errors: errors2 },
    clearErrors: clearErrors2,
  } = useForm<IAddVoucherEntries>({});

  const watchCreditAmount = watch2(CREDIT_AMOUNT);
  const watchDebitAmount = watch2(DEBIT_AMOUNT);
  const costCenter = watch2(COST_CENTER);

  const watchCostCenter = watch2(COST_CENTER);
  const watchCostCenterValue = watch2(COST_CENTER_VALUE);

  const onSaveNextVoucher: SubmitHandler<IVoucherForm> = (data) => {
    setShowSaveNext(true);
    setValue2(ENTRY_DATE, moment(new Date()).format("YYYY-MM-DD"));
  };

  const handleSubmitVoucherData = (data: any) => {
    if (!voucherListData?.length) {
      const init = {
        type: voucherVal ? voucherVal?.label : "",
        initials: voucherVal ? voucherVal?.value : "",
        number: "",
        date: data?.date,
        remarks: data?.voucher_remarks,
        status: voucherStatus || "NON-POSTED",
        reference_doc_category: data?.reference_doc_category.value,
        reference_number: data?.reference_number,
        entries: mergeVouchersData,
        is_active: true,
        levelId: 4,
      };
      dispatch(submitVoucherData(requestGenerator(init))).then((res: any) => {
        if (res.type === "submit-voucher-data/fulfilled") {
          setMergeVouchersData([]);
          dispatch(clearVoucherListData());
          dispatch(clearSubmitVoucherData());
          setVoucherSearch("");
          setShowSaveNext(false);
          setValue1(VOUCHERS_TYPE, "");
          setValue1(VOUCHER_NO, "");
          setValue1(VOUCHER_REMARKS, "");
          setValue1(DATE, "");
          setValue1(REFERENCE_NO, "");
          setValue1(REF_DOC_CATEGORY, "");
          clearErrors2(COST_CENTER);
          clearErrors2(COST_CENTER_VALUE);
          clearErrors2(ACCOUNT);
          clearErrors2(DEBIT_AMOUNT);
          clearErrors2(CREDIT_AMOUNT);
          clearErrors2(ENTRY_DATE);
        }
      });
    } else {
      const init = {
        id: voucherListData?.[0]?._id,
        data: {
          status: voucherStatus || "NON-POSTED",
          type: data?.voucher_type?.value ?? "",
          initials: voucherVal ? voucherVal?.value : "",
          date: data?.date,
          number: data?.voucher_number,
          remarks: data?.voucher_remarks,
          reference_doc_category: data?.reference_doc_category.value,
          reference_number: data?.reference_number,
          entries: mergeVouchersData,
          is_active: true,
          levelId: 4,
        },
      };
      dispatch(updatevoucherData(requestGenerator(init))).then((res: any) => {
        if (res.type === "update-voucher-data/fulfilled") {
          setMergeVouchersData([]);
          dispatch(clearVoucherListData());
          dispatch(clearSubmitVoucherData());
          // dispatch(emptyAddVoucherData());
          setVoucherSearch("");
          setShowSaveNext(false);
          handleReset();
          dispatch(clearCostCenterData());
          clearErrors2(COST_CENTER);
          clearErrors2(COST_CENTER_VALUE);
          clearErrors2(ACCOUNT);
          clearErrors2(DEBIT_AMOUNT);
          clearErrors2(CREDIT_AMOUNT);
          clearErrors2(ENTRY_DATE);

          setcostCenterOption(costCenterOptions);
          // setVoucherVal("");
        }
      });
    }
  };

  const onSubmitVoucher: SubmitHandler<IVoucherForm> = (data) => {
    const equalAmt = Number(CreditAmount) === Number(DebitAmount);
    if (voucherStatus === "POSTED") {
      if (equalAmt) {
        handleSubmitVoucherData(data);
      } else {
        dispatch(
          setMessage({
            message: "Credit and debit amount must be equal for posted voucher",
            type: failure,
          })
        );
      }
    } else {
      handleSubmitVoucherData(data);
    }
  };

  const onSubmitData: SubmitHandler<IAddVoucherEntries> = (data) => {
    let UID = Math.floor(Math.random() * Date.now());

    if (costCenterList && costCenterList?.length > 0) {
      if (isEditing) {
        const updatedDataIndex = mergeVouchersData?.findIndex(
          (s: any) => s?.entry_id === dataById?.entry_id
        );
        const init = {
          ...dataById,
          entry_id: dataById?.entry_id,
          entry_date: data?.entry_date,
          accounts_data: data?.accounts_data?.value,
          debit_amount: data?.debit_amount || "0",
          credit_amount: data?.credit_amount || "0",
          cost_center: costCenterList?.map((items: any) => ({
            label: items.costCenter?.value,
            values: items?.costCenterValue?.map((s: any) => ({
              label_values: s.label,
            })),
          })),
          entry_remarks: data?.entry_remarks,
        };
        let updateArray: any = [...mergeVouchersData];
        updateArray[updatedDataIndex] = init;
        setMergeVouchersData(updateArray);
        setcostCenterOption(costCenterOptions);
        handleResetEntry();
        setIsEditing(false);
      } else {
        setIsVoucher(true);
        const init = {
          entry_id: `${UID}`,
          entry_date: data?.entry_date,
          accounts_data: data?.accounts_data?.value,
          debit_amount: data?.debit_amount || "0",
          credit_amount: data?.credit_amount || "0",
          cost_center: costCenterList?.map((items: any) => ({
            label: items.costCenter?.value,
            values: items?.costCenterValue?.map((s: any) => ({
              label_values: s.label,
            })),
          })),
          entry_remarks: data?.entry_remarks,
        };
        dispatch(setAddVoucherData(requestGenerator(init)));
        setcostCenterOption(costCenterOptions);
        handleResetEntry();
      }
    } else {
      dispatch(
        setMessage({
          message: "Please add atlease one cost center",
          type: failure,
        })
      );
    }
  };

  const handleVoucherLog = () => {
    setShowVoucherLogModal(!showVoucherLogModal);
  };
  const handleAddVoucher = () => {
    console.log("call");
    setShowAddVoucherModal((prevState) => !prevState);
  };
  // Credit || Debit
  useEffect(() => {
    let selectedCreditAmountSum = 0;
    let selectedDebitAmountSum = 0;
    for (let i = 0; i < mergeVouchersData?.length; i += 1) {
      selectedCreditAmountSum += parseFloat(
        mergeVouchersData[i]?.credit_amount
      );
      selectedDebitAmountSum += parseFloat(mergeVouchersData[i]?.debit_amount);
    }
    setCreditAmount(selectedCreditAmountSum);
    setDebitAmount(selectedDebitAmountSum);
  }, [mergeVouchersData]);
  const handleCostView = () => {
    setShowCostViewPopup((prevState) => !prevState);
  };
  // delete modal close
  const handleDeleteModalOpen = (payload: any) => {
    setDeletePopData(payload);
    // setShowDelete((prevState) => !prevState)
    setShowDelete(true);
  };
  const handleDeleteModalClose = () => {
    // setShowDelete((prevState) => !prevState)
    setShowDelete(false);
    setDeletePopData("");
  };
  useEffect(() => {
    let tempArr: any = [];
    tempArr =
      voucherListData && voucherListData?.length > 0
        ? voucherListData?.[0].entries
        : [];
    // voucherListData?.map((items: any) =>
    //   items?.entries?.map((s: any) => {
    //     let { entries, ...remainng } = items
    //     // delete objectClone.entries
    //     tempArr.push({ ...s, ...remainng })
    //   })
    // )

    const mergeTwoArrays = [...(addVoucherData ?? []), ...(tempArr ?? [])];
    setMergeVouchersData(mergeTwoArrays);
  }, [addVoucherData, voucherListData]);

  // Account Number getVoucher
  useEffect(() => {
    dispatch(getVoucher(requestGenerator({})));
    dispatch(
      getAccountNumber(
        requestGenerator({
          isFinalLevel: true,
          search: "",
        })
      )
    );

    dispatch(getreferenceDoc(requestGenerator({})));
  }, [dispatch]);
  // voucher No
  // const handleVoucherCode = (e: any) => {
  //   const data = {
  //     name: e?.label,
  //     initials: e?.value,
  //   };
  //   dispatch(getvoucherNo(requestGenerator(data))).then((code: any) => {
  //     setValue1(VOUCHER_NO, code?.payload);
  //   });
  // };

  // get All Voucher No Search API calls
  const handleSearchPopup = (id: any) => {
    // if (voucherSearch?.length ) {
    const requestData = {
      // search: voucherSearch,
      search: id,
      is_active: true,
    };

    dispatch(getvoucherData(requestGenerator(requestData))).then((res: any) => {
      setShowVoucherLogModal(false);
      setPdfBtnDisabled(false);
      if (res?.payload?.data?.length > 0) {
        setValue1(VOUCHER_NO, res?.payload?.data?.[0]?.number);
        setValue1(
          DATE,
          moment(res?.payload?.data?.[0]?.date)?.format("YYYY-MM-DD")
        );
        setValue1(REFERENCE_NO, res?.payload?.data?.[0]?.reference_number);
        setValue1(VOUCHER_REMARKS, res?.payload?.data?.[0]?.remarks);
        setValue1(VOUCHERS_TYPE, {
          label: res?.payload?.data?.[0]?.type,
          value: res?.payload?.data?.[0]?.type,
        });
        setValue1(VOUCHER_STATUS, {
          label: res?.payload?.data?.[0]?.status,
          value: res?.payload?.data?.[0]?.status,
        });
        setVoucherStatus(res?.payload?.data?.[0]?.status);
        if (res?.payload?.data?.[0]?.status === "POSTED") {
          setButtonDisable(true);
        } else {
          setButtonDisable(false);
        }
        setValue1(REF_DOC_CATEGORY, {
          label: res?.payload?.data?.[0]?.reference_doc_category,
          value: res?.payload?.data?.[0]?.reference_doc_category,
        });
      }
    });
    dispatch(clearSubmitVoucherData());
    setValue1(VOUCHERS_TYPE, null);
    setValue1(VOUCHER_STATUS, null);
    setVoucherStatus("");
    setValue1(VOUCHER_REMARKS, "");
    handleReset();
    handleResetEntry();
    // }
  };

  //  API calls for call center
  useEffect(() => {
    let payloadData = {
      search: "",
      page: 1,
      pageSize: 1000,
    };
    if (costCenter?.label === "Branch") {
      dispatch(getAllBranch(requestGenerator(payloadData))).then((e) => {
        setCostCenterValue(e?.payload?.data);
        setValue2(COST_CENTER_VALUE, "");
      });
    } else if (costCenter?.label === "Doctor") {
      dispatch(getAllDoctorList(requestGenerator(payloadData))).then((e) => {
        setCostCenterValue(e?.payload?.data);
        setValue2(COST_CENTER_VALUE, "");
      });
    } else {
      dispatch(getAllDepartment(requestGenerator(payloadData))).then((e) => {
        setCostCenterValue(e?.payload?.data);
        setValue2(COST_CENTER_VALUE, "");
      });
    }
  }, [costCenter]);

  useEffect(() => {
    dispatch(clearVoucherListData());
  }, [dispatch]);

  const handleViewCostCenterData = (items: any) => {
    setShowCostViewPopup((prevState) => !prevState);
    setCostViewData(items);
  };
  // Set Data On Update
  const handleEditData = (item: any) => {
    setIsEditing(true);
    setShowSaveNext(true);
    const filterData = mergeVouchersData?.find(
      (s: any) => s.entry_id === item.entry_id
    );
    setDataById(filterData);
    // setValue1(VOUCHER_NO, filterData?.number)
    // setValue1(VOUCHER_REMARKS, filterData?.remarks)
    // setValue1(DATE, moment(filterData?.date)?.format('YYYY-MM-DD'))
    // setValue1(VOUCHER_STATUS, {
    //   label: filterData?.status,
    //   value: filterData?.status,
    // })
    // setVoucherStatus(filterData?.status)
    // setValue1(VOUCHERS_TYPE, {
    //   label: filterData?.type,
    //   value: filterData?.type,
    // })
    // setValue1(REFERENCE_NO, filterData?.reference_number)
    // setValue1(REF_DOC_CATEGORY, {
    //   label: filterData?.reference_doc_category,
    //   value: filterData?.reference_doc_category,
    // })
    setValue2(ENTRY_DATE, moment(filterData?.entry_date)?.format("YYYY-MM-DD"));

    setValue2(ENTRY_REMARKS, filterData?.entry_remarks);
    setValue2(
      CREDIT_AMOUNT,
      filterData?.credit_amount > 0 ? filterData?.credit_amount : ""
    );
    setValue2(
      DEBIT_AMOUNT,
      filterData?.debit_amount > 0 ? filterData?.debit_amount : ""
    );
    setValue2(ACCOUNT, {
      label: `${filterData?.accounts_data?.code_id} - ${filterData?.accounts_data?.name}`,
      value: filterData?.accounts_data,
    });
    // Set Cost center fields
    const newCostCenterArray = item?.cost_center?.map((item: any) => {
      return {
        costCenter: {
          label: item?.label,
          value: item?.label,
        },
        costCenterValue: item?.values?.map((_item: any) => {
          return {
            label: _item?.label_values,
            value: _item?.label_values,
            _id: _item?._id,
          };
        }),
      };
    });
    setcostCenterOption(costCenterOptions);
    dispatch(setEditCostCenterData(newCostCenterArray));
  };

  useEffect(() => {
    setMergeVouchersData([]);
    dispatch(emptyAddVoucherData());
  }, []);
  // Reset Entry Fields
  const handleResetEntry = () => {
    const removeDisabledCostCenter = costCenterOptions?.map((_item: any) => {
      return {
        ..._item,
        isdisabled: false,
      };
    });
    setcostCenterOption(removeDisabledCostCenter);
    reset2();
    setValue2(ACCOUNT, "");
    setValue2(COST_CENTER, "");
    setValue2(COST_CENTER_VALUE, "");
    setValue2(CREDIT_AMOUNT, "");
    setValue2(ENTRY_DATE, moment(new Date()).format("YYYY-MM-DD"));
    setValue2(DEBIT_AMOUNT, "");
    setValue2(ENTRY_REMARKS, "");
    dispatch(clearCostCenterData());
  };
  // Reset
  const handleReset = () => {
    if (isVoucher) {
      setValue1(VOUCHERS_TYPE, voucherVal);
    } else {
      setValue1(VOUCHERS_TYPE, "");
    }
    setValue1(VOUCHER_NO, "");
    setValue1(VOUCHER_REMARKS, "");
    setValue1(VOUCHER_STATUS, "");
    setVoucherStatus("");
    setValue1(DATE, "");
    setValue1(REFERENCE_NO, "");
    setValue1(REF_DOC_CATEGORY, "");
    setValue2(ACCOUNT, "");
    setValue2(COST_CENTER, "");
    setValue2(CREDIT_AMOUNT, "");
    setValue2(ENTRY_DATE, moment(new Date()).format("YYYY-MM-DD"));
    setValue2(DEBIT_AMOUNT, "");
    setValue2(ENTRY_REMARKS, "");
  };
  //Add Cost Center
  const handleCostCenterAdd = () => {
    setValue2(COST_CENTER, "");
    setValue2(COST_CENTER_VALUE, "");

    dispatch(
      setCostCenterData({
        costCenter: watchCostCenter,
        costCenterValue: watchCostCenterValue,
      })
    );
  };
  // Remove Cost Center
  const handleCostCenterRemove = (item: any) => {
    const removeFromList = costCenterList?.filter((test: any) => {
      return test?.costCenter?.value !== item?.costCenter?.value;
    });
    dispatch(removeCostCenterData(removeFromList));
    const removeDisabledCostCenter = costCenterOptions?.map((_item: any) => {
      if (_item?.value === item?.costCenter?.value) {
        return {
          ..._item,
          isdisabled: false,
        };
      } else {
        return {
          ..._item,
        };
      }
    });
    setcostCenterOption(removeDisabledCostCenter);
  };
  //  Cost Center List Fields add
  useEffect(() => {
    if (costCenterList?.length > 0) {
      const costCenterField = costCenterOption?.map((item: any) => {
        const costCenterObject = costCenterList?.find((_item: any) => {
          return _item?.costCenter?.value === item?.value;
        });

        if (typeof costCenterObject == "object") {
          return {
            ...item,
            isdisabled: true,
          };
        } else {
          return {
            ...item,
            isdisabled: false,
          };
        }
      });
      setcostCenterOption(costCenterField);
    }
  }, [costCenterList]);

  useEffect(() => {
    if (watchCreditAmount) {
      clearErrors2(DEBIT_AMOUNT);
    }
  }, [watchCreditAmount]);
  useEffect(() => {
    if (watchDebitAmount) {
      clearErrors2(CREDIT_AMOUNT);
    }
  }, [watchDebitAmount]);

  useEffect(() => {
    setcostCenterOption(costCenterOptions);
    setValue2(COST_CENTER, "");
    setValue2(DEBIT_AMOUNT, "");
    setValue2(CREDIT_AMOUNT, "");
  }, []);

  useEffect(() => {
    if (voucherListData && voucherListData?.length) {
      setShowSaveNext(true);
    }
  }, [voucherListData]);

  useEffect(() => {
    return () => {
      handleResetEntry();
      setShowSaveNext(false);
      setIsEditing(false);
      dispatch(clearVoucherListData());
    };
  }, []);

  const handleDownloadPdf = async () => {
    setPdfBtnDisabled(false);
    const doc: any = new jsPDF("p", "pt", "a4");
    const width = doc.internal.pageSize.getWidth();
    let tableData: any = [];
    let creditTotal = 0;
    let debitTotal = 0;

    getVoucherData[0]?.entries?.forEach((items: any, index: number) => {
      let costCenterValue = "";
      creditTotal = creditTotal + items?.credit_amount;
      debitTotal = debitTotal + items?.debit_amount;

      items.cost_center?.forEach((testItem: any, index: any) => {
        testItem.values?.forEach((testChildItem: any, index: any) => {
          console.log(testItem?.label_values, "testItem?.label_values");
          costCenterValue += testChildItem?.label_values + " ";
        });
      });

      tableData.push([
        items.accounts_data.code_id,
        items.accounts_data.name,
        costCenterValue,
        getVoucherData[0].remarks,
        items.debit_amount,
        items.credit_amount,
      ]);
    });
    tableData.push(["", "", "", "Total", debitTotal, creditTotal]);
    let offsetY = 4.797777777777778;
    let lineHeight = 6.49111111111111;
    doc
      .text(`${userData?.mc_name}`, width / 2, 80, {
        align: "center",
      })
      .setFontSize(10)

      .setFont(undefined, "bold");
    doc
      .text(`Voucher no:  ${getVoucherData[0]?.number}`, 60, 120)
      .setFontSize(8)
      .setFont(undefined, "bold");
    doc
      .text(
        `Date ${moment(getVoucherData[0]?.date)?.format("DD/MM/YY")}`,
        400,
        120
      )
      .setFontSize(8)
      .setFont(undefined, "bold");

    doc.autoTable({
      startY: 155,
      styles: {
        halign: "center",
        textColor: "#000",
        fillColor: "#fefefe",
      },
      tableLineColor: "#000",
      tableLineWidth: 0.1,

      headStyles: { lineWidth: 0.1, lineColor: "#000" },
      bodyStyles: {
        fontSize: "8",
        lineWidth: 0.1,
        lineColor: "#000",
      },

      head: [
        ["A/c Code.", "A/c. Head", "Cost Center", "Remarks", "Dr.", "Cr."],
      ],
      body: tableData,
    });
    const dynamicHeight = doc?.autoTable?.previous?.finalY;

    const getNumberIntoWords = await NumberToWord(debitTotal);
    doc
      .text(
        `Amount In Words : ${getNumberIntoWords}`,
        40,
        dynamicHeight + lineHeight * 5 + offsetY
      )
      .setFontSize(8)
      .setFont(undefined, "bold");

    doc.save("request.pdf");
    window.open(doc.output("bloburl"), "_blank");
  };

  return (
    <>
      {loading || doctorState?.loading ? <Loader /> : ""}
      {showAddVoucherModal && (
        <Popup Children={AddVoucherModal} handleClose={handleAddVoucher} />
      )}
      {showCostViewPopup && (
        <Popup
          Children={CostViewModal}
          handleClose={() => handleCostView()}
          popData={costViewData}
        />
      )}
      {showDelete && (
        <Popup
          Children={DeleteMedicationPopup}
          popData={deletePopData}
          handleClose={handleDeleteModalClose}
          handleYes={() => {
            let tempArr = mergeVouchersData.filter(
              (data: any) => data.entry_id !== deletePopData
            );
            setMergeVouchersData(tempArr);
            handleDeleteModalClose();
          }}
          handleNo={() => {
            setShowDelete(false);
            setDeletePopData("");
          }}
        />
      )}
      {showVoucherLogModal && (
        <Popup
          Children={VoucherLogModal}
          handleClose={() => handleVoucherLog()}
          handleChildClick={(id: void) => {
            setVoucherSearch(id), handleSearchPopup(id);
          }}
        />
      )}
      <div className={styles.pageWrapper}>
        <div className={styles.searchContainer}>
          <input
            className={styles.searchInput}
            type="text"
            placeholder="Search Voucher"
            onChange={(e) => {
              setVoucherSearch(e.target.value);
              trimValue(e);
            }}
            value={voucherSearch}
            onKeyDown={(e: any) => {
              if (e?.key === "Enter") {
                // handleSearchPopup();
              }
            }}
          />
          <div className={styles.searchButton} onClick={handleSearchPopup}>
            <SearchIcon fillColor={"#ffffff"} />
          </div>
        </div>
        {/* Form start here */}
        <form
          className={styles.vouchersForm}
          onSubmit={handleSubmit1(onSubmitVoucher)}
        >
          <div className={styles.voucherContainer}>
            <div className={styles.formFieldRow}>
              <div className={styles.formFieldContainer}>
                <p className={styles.vouchersTitle}>Voucher </p>
              </div>

              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <div className={styles.formBtnContainer}>
                    <Button
                      title="Dowload pdf"
                      type="button"
                      customClass={styles.addBtn}
                      handleClick={() => handleDownloadPdf()}
                      disable={
                        pdfBtnDisabled ||
                        getVoucherData[0]?.status == "NON-POSTED"
                          ? true
                          : false
                      }
                    />

                    <Button
                      title="Voucher Log"
                      type="button"
                      customClass={styles.addBtn}
                      handleClick={handleVoucherLog}
                    />
                  </div>
                </div>
              </div>
              {/* Voucher Status */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={VOUCHER_STATUS} className={styles.formLabel}>
                    Voucher Status
                  </label>
                  <Select
                    className={styles.selectInputField}
                    value={watch1(VOUCHER_STATUS)}
                    isSearchable={true}
                    isClearable={true}
                    options={voucherStatusOptions?.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    components={animatedComponent}
                    maxMenuHeight={200}
                    closeMenuOnSelect={true}
                    placeholder={
                      <span className="selectPlaceholder">Select Voucher</span>
                    }
                    styles={reactDropdownStyles}
                    onChange={(e: any) => {
                      setVoucherStatus(e?.value);
                      setValue1(VOUCHER_STATUS, {
                        label: e?.label,
                        value: e?.value,
                      });
                    }}
                    defaultValue={{ label: "NON-POSTED", value: "NON-POSTED" }}
                    // defaultValue={voucherStatusOptions?.find(option => option === 'NON-POSTED')}
                  />
                </div>
              </div>
              {/* Voucher Type */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={VOUCHERS_TYPE} className={styles.formLabel}>
                    Voucher<span className="asterick">*</span>
                  </label>
                  <Select
                    className={styles.selectInputField}
                    {...register1(
                      VOUCHERS_TYPE,
                      vouchersValidators[VOUCHERS_TYPE]
                    )}
                    isSearchable={true}
                    isClearable={true}
                    isDisabled={mergeVouchersData?.length > 0}
                    options={getVouchers?.map((item: any) => ({
                      label: item.name,
                      value: item.initials,
                    }))}
                    components={animatedComponent}
                    maxMenuHeight={200}
                    closeMenuOnSelect={true}
                    placeholder={
                      <span className="selectPlaceholder">Select Voucher</span>
                    }
                    styles={reactVoucherStyles}
                    value={watch1(VOUCHERS_TYPE)}
                    onChange={(e: any) => {
                      setVoucherVal(e);
                      setValue1(VOUCHERS_TYPE, e);
                      trigger1(VOUCHERS_TYPE);
                      // handleVoucherCode(e);
                    }}
                  />
                </div>
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors1[VOUCHERS_TYPE] && (
                    <p className="dashboardFormError">
                      {errors1[VOUCHERS_TYPE].message as any}
                    </p>
                  )}
                </div>
                <div className={styles.addVoucherModal}>
                  <PlusIcon
                    fillColor={colors?.green1}
                    customClass={styles.iconStyle}
                    handleClick={handleAddVoucher}
                  />
                </div>
              </div>
              {/* Voucher No. */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={VOUCHER_NO} className={styles.formLabel}>
                    Voucher No
                  </label>
                  <input
                    type="text"
                    placeholder="Voucher No"
                    className={styles.inputField}
                    {...register1(VOUCHER_NO, vouchersValidators[VOUCHER_NO])}
                    onChange={(e) => trimValue(e)}
                    disabled={true}
                  />
                </div>
                {errors1[VOUCHER_NO] && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    <p className="dashboardFormError">
                      {errors1[VOUCHER_NO].message}
                    </p>
                  </div>
                )}
              </div>
              {/* Voucher Remarks */}
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label htmlFor={VOUCHER_REMARKS} className={styles.formLabel}>
                    Voucher Remarks
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Voucher Remarks"
                    className={styles.inputField}
                    {...register1(VOUCHER_REMARKS)}
                    onChange={(e) => trimValue(e)}
                  />
                </div>
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.formFieldRow}>
            {/* Date */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={DATE} className={styles.formLabel}>
                  Date<span className="asterick">*</span>
                </label>
                <input
                  type="date"
                  max="9999-12-31"
                  className={styles.inputField}
                  {...register1(DATE, vouchersValidators[DATE])}
                  // {...register2(DATE, vouchersValidators[DATE])}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors1[DATE] && (
                  <p className="dashboardFormError">
                    {errors1[DATE].message as any}
                  </p>
                )}
              </div>
            </div>
            {/* Reference Doc Category */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={REF_DOC_CATEGORY} className={styles.formLabel}>
                  Reference Doc Category<span className="asterick">*</span>
                </label>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">
                      Select Doc Category
                    </span>
                  }
                  {...register1(
                    REF_DOC_CATEGORY,
                    vouchersValidators[REF_DOC_CATEGORY]
                  )}
                  isSearchable={true}
                  isClearable={true}
                  options={getRef?.map((item: any) => ({
                    label: item.name,
                    value: item.name,
                  }))}
                  closeMenuOnSelect={true}
                  components={animatedComponent}
                  styles={reactDropdownStyles}
                  value={watch1(REF_DOC_CATEGORY) || []}
                  onChange={(e: any) => {
                    setValue1(REF_DOC_CATEGORY, e);
                    trigger1(REF_DOC_CATEGORY);
                  }}
                  maxMenuHeight={200}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors1[REF_DOC_CATEGORY] && (
                  <p className="dashboardFormError">
                    {errors1[REF_DOC_CATEGORY].message as any}
                  </p>
                )}
              </div>
            </div>
            {/* Reference No. */}
            <div className={styles.formFieldContainer}>
              <div className={styles.inputFieldContainer}>
                <label htmlFor={REFERENCE_NO} className={styles.formLabel}>
                  Reference No<span className="asterick">*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Reference No"
                  className={styles.inputField}
                  {...register1(REFERENCE_NO, vouchersValidators[REFERENCE_NO])}
                  // disabled={mergeVouchersData?.length > 0}
                  // onChange={(e) => trimValue(e)}
                />
              </div>
              <div className={styles.errorContainer}>
                <span className={styles.extraSpan}></span>
                {errors1[REFERENCE_NO] && (
                  <p className="dashboardFormError">
                    {errors1[REFERENCE_NO].message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.formBtnContainer}>
            <Button
              title="Save & Next"
              type="button"
              customClass={styles.addBtn}
              handleClick={handleSubmit1(onSaveNextVoucher)}
              disable={voucherListData?.length}
            />
          </div>
          {showSaveNext && (
            <>
              <form
                className={styles.vouchersForm}
                onSubmit={handleSubmit2(onSubmitData)}
              >
                <p className={styles.vouchersEntriesTitle}>Voucher Entries</p>
                <div className={styles.formFieldRow}>
                  {/* Account */}
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label htmlFor={ACCOUNT} className={styles.formLabel}>
                        Account<span className="asterick">*</span>
                      </label>
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            Select Account
                          </span>
                        }
                        {...register2(ACCOUNT, vouchersValidators[ACCOUNT])}
                        isSearchable={true}
                        isClearable={true}
                        options={getAccNum?.map((item: any) => ({
                          label: `${item.code} - ${item.name}`,
                          value: {
                            code_id: `${item.code}`,
                            name: item.name,
                            level_one: item.level_one,
                            level_two: item.level_two,
                            creditOrDebit: item.creditOrDebit,
                          },
                        }))}
                        maxMenuHeight={200}
                        value={watch2(ACCOUNT) || []}
                        components={animatedComponent}
                        closeMenuOnSelect={true}
                        styles={reactDropdownStyles}
                        onChange={(e: any) => {
                          setValue2(ACCOUNT, e);
                          trigger2(ACCOUNT);
                        }}
                      />
                    </div>
                    {errors2[ACCOUNT] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[ACCOUNT].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Debit Amount */}

                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={DEBIT_AMOUNT}
                        className={styles.formLabel}
                      >
                        Debit Amount<span className="asterick">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Debit Amount"
                        className={styles.inputField}
                        {...register2(
                          DEBIT_AMOUNT,
                          vouchersValidators[DEBIT_AMOUNT]
                        )}
                        disabled={watchCreditAmount !== "" ? true : false}
                        onKeyDown={(e: any) => disableArrowKey(e)}
                        onWheel={(e: any) => {
                          e.target.blur();
                        }}
                      />
                    </div>
                    {errors2[DEBIT_AMOUNT] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[DEBIT_AMOUNT].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/* Credit Amount */}
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={CREDIT_AMOUNT}
                        className={styles.formLabel}
                      >
                        Credit Amount<span className="asterick">*</span>
                      </label>
                      <input
                        type="number"
                        placeholder="Enter Credit Amount"
                        className={styles.inputField}
                        {...register2(
                          CREDIT_AMOUNT,
                          vouchersValidators[CREDIT_AMOUNT]
                        )}
                        onKeyDown={(e: any) => disableArrowKey(e)}
                        onWheel={(e: any) => {
                          e.target.blur();
                        }}
                        disabled={watchDebitAmount !== "" ? true : false}
                      />
                    </div>
                    {errors2[CREDIT_AMOUNT] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors2[CREDIT_AMOUNT].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                  {/*Entry Date */}
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label htmlFor={ENTRY_DATE} className={styles.formLabel}>
                        Entry Date<span className="asterick">*</span>
                      </label>
                      <input
                        type="date"
                        max="9999-12-31"
                        className={styles.inputField}
                        {...register2(
                          ENTRY_DATE,
                          vouchersValidators[ENTRY_DATE]
                        )}
                        // defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                      />
                    </div>
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors2[ENTRY_DATE] && (
                        <p className="dashboardFormError">
                          {errors2[ENTRY_DATE].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                  {/*Entry Remarks */}
                  <div className={styles.formFieldContainer}>
                    <div className={styles.inputFieldContainer}>
                      <label
                        htmlFor={ENTRY_REMARKS}
                        className={styles.formLabel}
                      >
                        Entry Remarks
                      </label>
                      <textarea
                        placeholder="Enter Entry Remarks"
                        className={styles.textArea}
                        {...register2(ENTRY_REMARKS)}
                      />
                    </div>
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      {errors2[ENTRY_REMARKS] && (
                        <p className="dashboardFormError">
                          {errors2[ENTRY_REMARKS].message as any}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* empty fieds */}
                  <div className={styles.formFieldContainer}></div>

                  <div className={styles.formFieldCostContainer}>
                    {/* Cost Center */}
                    <div className={styles.formFieldContainer}>
                      <div className={styles.inputFieldContainer}>
                        <label
                          htmlFor={COST_CENTER}
                          className={styles.formLabel}
                        >
                          Cost Center
                          {costCenterList?.length === 0 && (
                            <span className="asterick">*</span>
                          )}
                        </label>
                        <Select
                          className={styles.selectInputField}
                          placeholder={
                            <span className="selectPlaceholder">
                              Select Cost Center
                            </span>
                          }
                          styles={reactDropdownStyles}
                          {...register2(
                            COST_CENTER,
                            costCenterList?.length
                              ? { required: false }
                              : vouchersValidators[COST_CENTER]
                          )}
                          value={watch2(COST_CENTER) || []}
                          isSearchable={true}
                          isClearable={true}
                          maxMenuHeight={200}
                          options={costCenterOption?.map((item: any) => ({
                            label: item?.label,
                            value: item?.value,
                            isDisabled: item?.isdisabled,
                          }))}
                          components={animatedComponent}
                          closeMenuOnSelect={true}
                          onChange={(e: any) => {
                            setValue2(COST_CENTER, {
                              label: e?.label,
                              value: e?.value,
                            });
                          }}
                        />
                      </div>
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        {errors2[COST_CENTER] && (
                          <p className="dashboardFormError">
                            {errors2[COST_CENTER].message as any}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* Cost Center Value */}
                    <div className={styles.formFieldContainer}>
                      <div className={styles.inputFieldContainer}>
                        <label
                          htmlFor={COST_CENTER_VALUE}
                          className={styles.formLabel}
                        >
                          Cost Center Value
                          {costCenterList?.length === 0 && (
                            <span className="asterick">*</span>
                          )}
                          {/* <span className="asterick">*</span> */}
                        </label>
                        <Select
                          className={styles.selectInputField}
                          placeholder={
                            <span className="selectPlaceholder">
                              Select Cost Center Value
                            </span>
                          }
                          {...register2(
                            COST_CENTER_VALUE,
                            costCenterList?.length
                              ? { required: false }
                              : vouchersValidators[COST_CENTER_VALUE]
                          )}
                          isSearchable={true}
                          isClearable={true}
                          options={costCenterValue?.map((item: any) => ({
                            label: item?.name
                              ? item?.name
                              : item?.doctor_name
                              ? item?.doctor_name
                              : "",
                            value: item?._id,
                          }))}
                          isMulti
                          value={watch2(COST_CENTER_VALUE) || []}
                          maxMenuHeight={200}
                          components={animatedComponent}
                          closeMenuOnSelect={false}
                          styles={reactDropdownStyles}
                          onChange={(e: any) => {
                            setValue2(COST_CENTER_VALUE, e);
                            trigger2(COST_CENTER_VALUE);
                          }}
                          isDisabled={watchCostCenter == "" ? true : false}
                        />
                      </div>
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        {errors2[COST_CENTER_VALUE] && (
                          <p className="dashboardFormError">
                            {errors2[COST_CENTER_VALUE].message as any}
                          </p>
                        )}
                      </div>

                      <div className={styles.addcCostIconStyle}>
                        <PlusIcon
                          fillColor={colors?.green1}
                          customClass={styles.costIconStyle}
                          handleClick={() => {
                            if (
                              watchCostCenter !== "" &&
                              watchCostCenterValue !== ""
                            ) {
                              handleCostCenterAdd();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Cost Center List Fields */}

                  {costCenterList?.map((item: any) => {
                    return (
                      <>
                        <div
                          className={styles.formFieldCostContainer}
                          key={item?.costCenter?.value}
                        >
                          {/* Cost Center */}
                          <div className={styles.formFieldContainer}>
                            <div className={styles.inputFieldContainer}>
                              <label
                                htmlFor={COST_CENTER}
                                className={styles.formLabel}
                              >
                                Cost Center
                              </label>
                              <Select
                                className={styles.selectInputField}
                                styles={reactDropdownStyles}
                                value={{
                                  label: item?.costCenter?.label,
                                  value: item?.costCenter?.value,
                                }}
                                isDisabled={true}
                              />
                            </div>
                          </div>
                          {/* Cost Center Value */}
                          <div className={styles.formFieldContainer}>
                            <div className={styles.inputFieldContainer}>
                              <label
                                htmlFor={COST_CENTER_VALUE}
                                className={styles.formLabel}
                              >
                                Cost Center Value
                              </label>
                              <Select
                                className={styles.selectInputField}
                                value={item?.costCenterValue}
                                isMulti
                                styles={reactDropdownStyles}
                                isDisabled={true}
                              />
                            </div>
                            <div className={styles.addcCostIconStyle}>
                              <CloseIcon
                                customClass={styles.costIconStyle}
                                fillColor={colors.red1}
                                handleClick={() => handleCostCenterRemove(item)}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  <div className={styles.formBtnContainer}>
                    {isEditing ? (
                      <Button
                        title={"Update"}
                        customClass={styles.addBtn}
                        handleClick={handleSubmit2(onSubmitData)}
                      />
                    ) : (
                      <Button
                        title={"Add"}
                        type="submit"
                        customClass={styles.addBtn}
                        handleClick={handleSubmit2(onSubmitData)}
                      />
                    )}

                    <Button
                      title="Reset"
                      type="reset"
                      customClass={styles.resetBtn}
                      handleClick={async () => {
                        // await setIsVoucher(false)
                        await handleResetEntry();
                      }}
                    />
                  </div>
                </div>
              </form>
            </>
          )}
          {/* Table */}
          <div className={styles.tableContainer}>
            {!!mergeVouchersData?.length && (
              <TableV2
                tableHeaderData={voucherTableHeaderData}
                tableRowData={mergeVouchersData ?? []}
                handleClick={handleViewCostCenterData}
                handleClicks={handleDeleteModalOpen}
                handleRowClick={handleEditData}
                active={false}
              />
            )}
            {!!mergeVouchersData?.length && (
              <>
                <div className={styles.tableLabelContainer}>
                  <span className={styles.debitText}>
                    Total Debit Amount: ${DebitAmount}
                  </span>
                  <span className={styles.creditText}>
                    Total Credit Amount: ${CreditAmount}
                  </span>
                </div>
                <div className={styles.tableButtonContainer}>
                  <Button
                    title={
                      voucherListData?.length
                        ? "Update Voucher"
                        : "Submit Voucher"
                    }
                    // type="submit"
                    customClass={styles.submitBtn}
                    disable={
                      !mergeVouchersData?.length
                        ? true
                        : voucherListData?.length &&
                          updateDisable &&
                          voucherStatus === "POSTED"
                        ? true
                        : false
                    }
                    handleClick={handleSubmit1(onSubmitVoucher)}
                  />
                  {/* <Button
                    title="Reset"
                    type="reset"
                    customClass={styles.resetBtn}
                    handleClick={async () => {
                      await setIsVoucher(false)
                      await handleReset()
                    }
                    }
                  /> */}
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Vouchers;
