export const ViewInventoryHeaderData = [
  {
    Header: 'ITEM NO',
    accessor: (row: any) => {
      return row.items.item_no
    },
  },
  {
    Header: 'ITEM NAME',
    accessor: (row: any) => {
      return row.items.name ?? '-'
    },
  },
  {
    Header: 'QTY',
    accessor: (row: any) => {
      return row.qty ?? '-'
    },
  },
]

export const InventoryRowData = [
  {
    code: '1',
    name: 'name',
    qty: '1',
  },
]
