import { FC, useEffect, useRef, useState } from 'react'
import { Scheduler } from 'smart-webcomponents-react/scheduler'
import 'smart-webcomponents-react/source/styles/smart.default.css'
import '../schedular.css'
import styles from '../bookingappointment.module.scss'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addBranchData,
  clearCalanderData,
} from '../../../redux/features/ipd-booking/ipdBookingSlice'

import { requestGenerator } from '../../../utils/payloadGenerator'
import { getAllDepartment } from '../../../redux/features/department/departmentAsyncActions'
import { clearData } from '../../../redux/features/appointments/bookingAppointmentsSlice'
import Loader from '../../../components/common/spinner/Loader'
import DropdownV2 from '../../../components/common/dropdown/dropdownv2/DropdownV2'
import ReloadButton from '../../../components/common/reload-button/ReloadButton'
import PaginationV2 from '../../../components/common/pagination/paginationv2/PaginationV2'
import noRecordImage from '../../../assets/images/noRecordsFound.png'
import {
  getAllBookedBeds,
  getIpdAllBedsListForSchedular,
} from '../../../redux/features/ipd-booking/ipdBookingAsyncActions'
import SearchDropDown from '../../../components/common/search-dropdown/SearchDropDown'

interface IBedSchedular {}

const BedSchedular: FC<IBedSchedular> = () => {
  const scheduler = useRef(null)
  const dispatch = useAppDispatch()

  const {
    resources,
    totalCount,
    dataSource,
    hasMorePage,
    ipdBedsDataForSchedular,
  } = useAppSelector((state) => state.ipdBooking)
  const { isLoading } = useAppSelector((state) => state.appointment)
  const [selectedItem, setSelectedItem] = useState({
    name: 'Select All',
    _id: '',
  })
  const [selectedBranchItem, setSelectedBranchItem] = useState({
    name: 'Select Branch',
    _id: '',
  })
  const [searchString, setSearchString] = useState('')

  const [bedResources, setBedResources] = useState(resources)
  const itemsPerPage = 5
  const [currentPage, setCurrentPage] = useState(1)
  const lastPage = Math.ceil(totalCount / itemsPerPage)
  const { branchData } = useAppSelector((state) => state.login)
  const views: any[] = ['month']
  const hideAllDay = true
  const nonworkingDays: any[] = []
  const hideNonworkingWeekdays = true
  const firstDayOfWeek = 1
  const viewSelectorType = 'auto'
  const groups = ['_id']
  const timelineDayScale = 'halfHour'
  const inputRef = useRef(null)
  // open custom popup
  const handleCutomPopup = (e: any) => {
    e.preventDefault()
  }
  // useeffect for set branch
  useEffect(() => {
    dispatch(addBranchData(selectedBranchItem))
  }, [dispatch, selectedBranchItem])

  useEffect(() => {
    let requestData = {
      page: currentPage,
      pageSize: itemsPerPage,
    }
    dispatch(getIpdAllBedsListForSchedular(requestGenerator(requestData)))
  }, [dispatch, currentPage, itemsPerPage])

  // get all doctor's appoinments
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        getAllBookedBeds(
          requestGenerator({ branch_id: selectedBranchItem?._id })
        )
      )
    }, 60000)
    return () => clearInterval(interval)
  }, [dispatch, selectedBranchItem?._id])

  useEffect(() => {
    selectedBranchItem?._id &&
      dispatch(
        getAllBookedBeds(
          requestGenerator({ branch_id: selectedBranchItem?._id })
        )
      )
  }, [dispatch, selectedBranchItem?._id])

  // go to previous page
  const handlePrevious = () => {
    setSelectedItem({
      name: 'Select All',
      _id: '',
    })
    if (searchString?.length > 0) {
      setSearchString('')
    }
    currentPage !== 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)
  }

  // go to next page
  const handleNext = () => {
    setSelectedItem({
      name: 'Select All',
      _id: '',
    })
    if (searchString?.length > 0) {
      setSearchString('')
    }
    currentPage < lastPage
      ? setCurrentPage(currentPage + 1)
      : setCurrentPage(currentPage)
  }

  // login for filter doctors
  useEffect(() => {
    if (selectedItem?._id === '') {
      setBedResources(resources)
    } else {
      let tempArr = []
      tempArr =
        resources?.length > 0 &&
        resources[0]?.dataSource?.filter(
          (item: any) => item?.id === selectedItem?._id
        )
      setBedResources([
        {
          label: 'Beds',
          value: '_id',
          dataSource: tempArr || [],
        },
      ])
    }
  }, [resources, selectedItem, searchString])

  useEffect(() => {
    dispatch(
      getAllDepartment(
        requestGenerator({
          search: '',
          page: 1,
          pageSize: 100,
        })
      )
    )
  }, [dispatch])

  // disable delete
  const handleItemChanging = (e: any) => {
    e.preventDefault()
  }
  // data cleanup
  useEffect(() => {
    return () => {
      dispatch(clearData())
      dispatch(clearCalanderData())
    }
  }, [dispatch])

  const [reoladDate, setReloadDate] = useState(new Date())

  // reolad
  const handleReloadButton = () => {
    dispatch(
      getAllBookedBeds(requestGenerator({ branch_id: selectedBranchItem?._id }))
    )
    setReloadDate(new Date())
  }

  // handle more appointment information

  const handleEventMenuOpening = (e: any) => {
    e.preventDefault()
  }
  useEffect(() => {
    branchData?.branches?.length > 0
      ? setSelectedBranchItem({
          name: branchData?.branches[0].name,
          _id: branchData?.branches[0]._id,
        })
      : setSelectedBranchItem({ name: 'Select Branch', _id: '' })
    setSelectedItem({ name: 'Select All', _id: '' })
  }, [branchData?.branches])

  useEffect(() => {
    let data = {
      search: searchString,
      page: 1,
      pageSize: 5,
    }
    dispatch(getIpdAllBedsListForSchedular(requestGenerator(data)))
  }, [dispatch, searchString])
  useEffect(() => {
    if (searchString === '') {
      setSelectedItem({ name: 'Select All', _id: '' })
    }
  }, [searchString])

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className={styles.dropdownPaginationContainer}>
        <div className={styles.dropdownMainContainer}>
          <div className={styles.searchDropDownContainer}>
            <span className={styles.text}>Bed :</span>
            <SearchDropDown
              searchString={searchString}
              setSearchString={setSearchString}
              dropdownDataToSee={ipdBedsDataForSchedular}
              dropDownKeyName="bed_name"
              placeholder="Search Bed"
              customClass={styles.search}
              customClassdata={styles.dropdownList}
              customClassItem={styles.dropdownItem}
              inputCustomClass={styles.dropdownItem}
              setState={() => setSelectedItem({ name: 'Select All', _id: '' })}
              handleClick={(item: any, setVal: any, setShowDropdown: any) => {
                setVal(item?.bed_name)
                setShowDropdown(false)
                setSelectedItem({ name: item?.bed_name, _id: item?._id })
              }}
            />
          </div>
          <DropdownV2
            data={branchData?.branches}
            type="Branch"
            keyName="name"
            customClass={styles.branchDropdown}
            selectedItem={selectedBranchItem}
            setSelectedItem={setSelectedBranchItem}
            notShowAllOption={true}
            handleClick={(item: any) => {
              setSelectedBranchItem({ name: item?.name, _id: item?._id })
              setSelectedItem({ name: 'Select All', _id: '' })
            }}
          />
        </div>
        <div className={styles.reloadPaginationComponent}>
          <ReloadButton
            customClass={styles.mobileAppointmentReloadButton}
            handleClick={handleReloadButton}
            reoladDate={reoladDate}
          />
          <PaginationV2
            handleNext={() => handleNext()}
            handlePrevious={() => handlePrevious()}
            handleReset={() => setCurrentPage(1)}
            nextPage={hasMorePage}
          />
        </div>
      </div>
      {resources?.length > 0 && resources[0]?.dataSource?.length > 0 ? (
        <Scheduler
          ref={scheduler}
          id="scheduler"
          dataSource={dataSource}
          views={views}
          view="month"
          hideTodayButton
          maxEventsPerCell={1}
          hideAllDay={hideAllDay}
          hourStart={0}
          hourEnd={23}
          nonworkingDays={nonworkingDays}
          hideNonworkingWeekdays={hideNonworkingWeekdays}
          firstDayOfWeek={firstDayOfWeek}
          viewSelectorType={viewSelectorType}
          groups={groups}
          timelineDayScale={timelineDayScale}
          resources={bedResources}
          onEditDialogOpening={(e) => handleCutomPopup(e)}
          disableDrag={true}
          disableDrop={true}
          disableResize={true}
          onItemChanging={(e) => handleItemChanging(e)}
          unfocusable={true}
          onContextMenuOpening={(ev: any) => {
            ev.preventDefault()
          }}
          onEventMenuOpening={(e) => handleEventMenuOpening(e)}
          onViewChanging={(e) => {
            console.log('event for view change>>', e)
          }}
        ></Scheduler>
      ) : (
        <div style={{ margin: '10px' }}>
          <img
            src={noRecordImage}
            alt="no records found"
            style={{ width: '100%' }}
          />
        </div>
      )}
    </>
  )
}

export default BedSchedular
