import { FC, useState, useEffect } from 'react'
import Divider from '../../../components/common/divider/Divider'
import Pagination from '../../../components/common/pagination/Pagination'
import {
  CloseIcon,
  SearchButton,
} from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import styles from './itemRequested.module.scss'
import { itemRequestedHeaderData } from '../../../constants/table-data/itemRequestedTableData'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getAllRequestedItem } from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Loader from '../../../components/common/spinner/Loader'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Select, { components } from 'react-select'
import { sourceDestinationdata } from '../../../constants/data'
import { trimValue } from '../../../utils/utils'

interface IItemRequested {
  handleClose?: any
}

const ItemRequested: FC<IItemRequested> = ({ handleClose }) => {
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [search, setSearch] = useState('')
  const [source, setSource] = useState('')
  const dispatch = useAppDispatch()
  const { isLoading, allRequestedItem } = useAppSelector(
    (state) => state.inventoryRequest
  )

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let payloadData = {
      page: pageIndex,
      pageSize: dataPerPage,
      search: search,
      source: source,
    }
    dispatch(getAllRequestedItem(requestGenerator(payloadData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [pageIndex, dataPerPage])

  // const data: Cols[] = allRequestedItem;
  // const columns: Column<Cols>[] = itemRequestedHeaderData;
  // const options: TableOptions<Cols> = {
  //   data,
  //   columns,
  // };

  // const {
  //   state,
  //   // @ts-ignore
  //   setGlobalFilter,
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  // } = useTable(options, useGlobalFilter, useSortBy);
  // // @ts-ignore
  // const { globalFilter } = state;

  const handleSearch = () => {
    setPageIndex(1)
    let payloadData = {
      page: 1,
      pageSize: dataPerPage,
      search: search,
      source: source,
    }
    dispatch(getAllRequestedItem(requestGenerator(payloadData))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.submitRequestPopupMainContaier}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.uploadContainer}>
          <p className={styles.title}>Request's Items</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.searchContainer}>
            <Select
              className={styles.select}
              placeholder="Select Source"
              closeMenuOnSelect={true}
              isSearchable={true}
              options={sourceDestinationdata?.map((item: any) => ({
                label: item?.lable,
                value: item?.value,
              }))}
              onChange={(e: any) => {
                setSource(e.value)
              }}
              maxMenuHeight={200}
            />
            <input
              type="text"
              className={styles.inputField}
              placeholder="Search by item name"
              value={search}
              onKeyDown={(e: any) => {
                if (e?.key === 'Enter') {
                  handleSearch()
                }
              }}
              onChange={(e) => {
                trimValue(e)
                setSearch(e.target.value)
                if (search !== '' && e.target.value === '') {
                  setPageIndex(1)
                  setDataPerPage(10)
                  let payloadData = {
                    page: 1,
                    pageSize: 10,
                    search: '',
                    source: source,
                  }
                  dispatch(
                    getAllRequestedItem(requestGenerator(payloadData))
                  ).then((result) => {
                    setTotalPage(result.payload.lastPage)
                    setTotalData(result.payload.total)
                  })
                }
              }}
            />
            <SearchButton
              handleClick={() => handleSearch()}
              width={38}
              height={38}
            />
          </div>
          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={itemRequestedHeaderData}
              tableRowData={allRequestedItem}
            />
          </div>
          {totalData > 10 && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
              pageSize={dataPerPage}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ItemRequested
