import {
    REPORT_NAME,
    REPORT_ROLE,
} from '../constants/reportBuilderConstant'

export const reportBuilderValidators = {
    [REPORT_NAME]: {
        required: "Please Enter Report Name"
    },
    [REPORT_ROLE]: {
        required: "Please Selecte Role"
    }
}