import { FC, useEffect, useState } from 'react'
import styles from './submitpobtnPopup.module.scss'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import Button from '../../../components/common/button/Button'
import { primaryInformationHeaderData } from './primaryInformationTableData'
import { poformHeaderData } from './poFormTableData'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  emptyPoFormData,
  getPoFormData,
  setFetchPoId,
  updateDirectPoStatus,
  updateInventoryPoData,
  updatePoCheckStatus,
} from '../../../redux/features/inventory-request/inventoryRequestSlice'
import { addInventoryAddPo } from '../../../redux/features/inventory-request/inventoryRequestAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import AddPoNotes from './addpo-notes/page'
import { useLocation } from 'react-router-dom'
import Loader from '../../../components/common/spinner/Loader'

interface ISubmitPobtnPopup {
  handleOpen?: any
}

const SubmittedPobtnPopup: FC<ISubmitPobtnPopup> = ({ handleOpen }) => {
  const [tabs, setTabs] = useState('Primary Information')
  const [value, setValue] = useState()
  const [active, setActive] = useState<boolean>(false)
  const [addNotes, setAAddNotes] = useState(false)
  const [idNotes, setIdNotes] = useState('')
  const [poFormAllData, setPoFormAllData] = useState<any>([])
  const location = useLocation()

  const {
    inventoryUniquePoData,
    poFormData,
    directPoStatus,
    poCheckStatus,
    isLoading,
  } = useAppSelector((state) => state.inventoryRequest)
  const { branchData } = useAppSelector((state) => state.login)
  useEffect(() => {
    let supplierList: any = []
    inventoryUniquePoData.forEach((element: any) => {
      let checkSupplier = supplierList.filter(
        (p: any) => p?._id?.toString() === element?.supplier_id?._id.toString()
      )
      if (checkSupplier.length === 0) {
        supplierList.push({
          _id: element?.supplier_id?._id,
          name: element?.supplier_id?.name,
          createdAt: element?.createdAt,
        })
      }
    })

    setPoFormAllData(supplierList)
  }, [inventoryUniquePoData])

  const dispatch = useAppDispatch()
  const submitPoData = () => {
    let data = {
      items: poFormData?.map((items: any) => {
        const isNanQuantity = Number(items.poQuantity)
        let discount = items.discount
          ? (items.sell_price * isNanQuantity * items.discount) / 100
          : 0
        const isAddFunc = () => {
          if (isNaN(isNanQuantity)) {
            return items.sell_price
          } else {
            return items.sell_price * isNanQuantity - discount
          }
        }
        let discountPrice = items.discount
          ? (items.sell_price * items.discount) / 100
          : 0
        return {
          item_id: items?.item_id,
          name: items?.item_name || items?.name,
          requested_price: items?.sell_price - discountPrice,
          requested_qty: items?.poQuantity,
          notes: items?.poNotes ?? '',
          expected_date: items?.expected_delievery_date,
          supplier_id: items?.supplier_id?._id,
          currency: 'KD',
          store_id:
            location?.pathname === '/mainstore'
              ? branchData?.main_store?.[0]?._id
              : branchData?.pharmacy_store?.[0]?._id,
          base_unit_type_id: items?.base_unit_type?._id,
          req_unit_type_id: items?.updatedMasterUnitType?.value,
          total_amount: isAddFunc(),
        }
      }),
    }
    dispatch(addInventoryAddPo(requestGenerator(data))).then((e: any) => {
      if (e.type === 'inventory/addInventoryAddPo/fulfilled') {
        dispatch(setFetchPoId(e.payload?.[0]?.doc_id))
        dispatch(emptyPoFormData())
        handleOpen()
        let data = poFormAllData?.filter(
          (s: any) => s?._id !== poFormData?.[0]?.supplier_id?._id
        )
        setPoFormAllData(data)
        let data2 = inventoryUniquePoData?.filter(
          (s: any) => s?.supplier_id?._id !== poFormData?.[0]?.supplier_id?._id
        )
        dispatch(updateInventoryPoData(data2))
        let poStatusData: any = []
        directPoStatus?.forEach((s: any) => {
          let filterData = poFormData.filter(
            (q: any) => s.item_id === q?.item_id
          )
          if (filterData.length === 0) {
            poStatusData.push(s)
          }
        })
        dispatch(updateDirectPoStatus(poStatusData))
        let requestPoData: any = []
        poCheckStatus?.forEach((s: any) => {
          let filterData = poFormData?.filter(
            (q: any) => s?.item_id === q?.item_id
          )
          if (filterData.length === 0) {
            requestPoData.push(s)
          }
        })
        dispatch(updatePoCheckStatus(requestPoData))
      }
    })
  }

  const handleClickPoData = (item: any, row: any) => {
    setValue(item)
    if (value === item) {
      setActive(true)
    } else {
      setActive(false)
    }
    let inventoryData = inventoryUniquePoData.filter(
      (p: any) => p?.supplier_id?._id?.toString() === row?._id?.toString()
    )
    dispatch(getPoFormData(inventoryData))
  }

  return (
    <>
      {isLoading && <Loader />}
      <CustomModal
        showModal={addNotes}
        closeModal={() => setAAddNotes(false)}
        title="Add Notes"
        width="35%"
        height="320px"
      >
        <AddPoNotes objId={idNotes} closeModal={() => setAAddNotes(false)} />
      </CustomModal>
      <div className={styles.mainContainer}>
        <p
          className={
            tabs === 'Primary Information'
              ? styles.tabContent
              : styles.disableTab
          }
          onClick={() => setTabs('Primary Information')}
        >
          Primary Information
        </p>
        <p
          className={tabs === 'PO Form' ? styles.tabContent : styles.disableTab}
          onClick={() => poFormData?.length && setTabs('PO Form')}
        >
          PO Form
        </p>
      </div>

      {tabs === 'Primary Information' && (
        <>
          <div className={styles.tablestyle}>
            <TableV2
              tableHeaderData={primaryInformationHeaderData}
              tableRowData={poFormAllData}
              setActive={setValue}
              active={value}
              handleRow={(item: any, row: any) => handleClickPoData(item, row)}
            />
          </div>

          <div className={styles.button}>
            <Button
              title="Next"
              handleClick={() => setTabs('PO Form')}
              disable={!poFormData?.length}
            />
          </div>
        </>
      )}

      {tabs === 'PO Form' && (
        <>
          <div className={styles.tablestyle}>
            <TableV2
              tableHeaderData={poformHeaderData}
              tableRowData={poFormData ?? []}
              active={false}
              handleClick={(item: any) => (
                setIdNotes(item?._id), setAAddNotes(true)
              )}
            />
          </div>
          <div className={styles.button}>
            <Button
              title="Submit"
              handleClick={() => submitPoData()}
              disable={!poFormData?.length}
            />
          </div>
        </>
      )}
    </>
  )
}

export default SubmittedPobtnPopup
