import { FC, useState, useEffect } from 'react'
import styles from './createCampaigns.module.scss'
import { Input } from '../../../../components/common/input/input'
import { disableArrowKey, trimValue } from '../../../../utils/utils'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import Pagination from '../../../../components/common/pagination/Pagination'
import { Label } from '../../../../components/common/label'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  failure,
  genderData,
  reactSelectStyle,
} from '../../../../constants/data'
import Button from '../../../../components/common/button/Button'
import { createCampaignsValidators } from './createCampaignsValidator'
import {
  Cols,
  ICreateCampaignForm,
  ICreateCampaignForm1,
} from './createCampaignsInterface'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  AGE_FROM,
  AGE_TO,
  CAMPAIGN_NAME,
  END_DATE,
  EXTERNAL_SOURCE,
  GENDER,
  NATIONALITY,
  NO_OF_TARGET_AUDIENCE,
  PIN_CODE,
  START_DATE,
  TAGS_SOURCE,
  TARGET_AUDIENCE,
} from './createCampaignsConstant'
import { useLocation, useNavigate } from 'react-router-dom'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  addCampaign,
  searchCriteria,
  updateCampaign,
} from '../../../../redux/features/call-center-campaign/campaignAsyncActions'
import { searchCriteriaHeaderData } from './createCampaignsTableData'
import TableV3 from '../../../../components/common/table/tableV3/TableV3'
import Loader from '../../../../components/common/spinner/Loader'
import moment from 'moment'
import { SearchIcon } from '../../../../components/common/svg-components'
import { colors } from '../../../../constants/color'
import { clearCampaignData } from '../../../../redux/features/call-center-campaign/campaignSlice'
import {
  ADD_CAMPAIGN_TYPE,
  UPDATE_CAMPAIGN_TYPE,
} from '../../../../constants/asyncActionsType'
import { customSelectStyles } from '../../../../styles/customStyles'
import { getPatientSelectionList } from '../../../../redux/features/patient-emr/patient/patientAsyncAction'
import { setMessage } from '../../../../redux/features/toast/toastSlice'

interface ICreateCampaigns {}

const CreateCampaigns: FC<ICreateCampaigns> = () => {
  const animatedComponent = makeAnimated()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isLoading, searchCriteriaData } = useAppSelector(
    (state) => state.campaign
  )
  const { masterValueData } = useAppSelector((state) => state.login)
  const { nationalityData } = useAppSelector((state) => state.patient)
  const [nationalityDropdownData, setNationalityData] = useState<any[]>([])
  const [tagsSourceData, setTagsSourceData] = useState<any[]>([])
  const [externalSourceData, setExternalSourceData] = useState<any[]>([])
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [total, setTotal] = useState(0)
  const [disable, setDisable] = useState<boolean>(false)
  const [nameDisable, setNameDisable] = useState<boolean>(false)
  // Table
  const data: Cols[] = searchCriteriaData
  const columns: Column<Cols>[] = searchCriteriaHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  // For Table
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state
  const location = useLocation()

  const updateCampaigById = location?.state?.user
  // React Hook form for the form handling
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<ICreateCampaignForm>({})

  const {
    handleSubmit: handleSubmitfirstCriteria,
    register: register2,
    watch: watch2,
    setValue: setValue2,
    trigger: trigger2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm<ICreateCampaignForm1>()
  const startDate = watch(START_DATE)
  const endDate = watch(END_DATE)
  const formData = watch2()
  console.log('formData', formData)
  const [maxEndDate, setMaxEndDate] = useState<any>('')
  useEffect(() => {
    if (startDate) {
      const date = new Date(startDate)
      date.setDate(date.getDate() + 1)
      setMaxEndDate(date)
    }
  }, [startDate])

  const onSubmit: SubmitHandler<ICreateCampaignForm> = (data: any) => {
    if (updateCampaigById?._id) {
      const editPayload = {
        id: updateCampaigById?._id,
        data: {
          [CAMPAIGN_NAME]: data[CAMPAIGN_NAME],
          [START_DATE]: data[START_DATE],
          [END_DATE]: data[END_DATE],
        },
      }
      dispatch(updateCampaign(requestGenerator(editPayload))).then((result) => {
        if (result.type === `${UPDATE_CAMPAIGN_TYPE}/fulfilled`) {
          navigate('/campaigns/onGoingCampaigns')
        }
      })
    } else {
      const createPayload = {
        [CAMPAIGN_NAME]: data[CAMPAIGN_NAME],
        [START_DATE]: data[START_DATE],
        [END_DATE]: data[END_DATE],
        [TARGET_AUDIENCE]: data[TARGET_AUDIENCE],
        [NO_OF_TARGET_AUDIENCE]: data[NO_OF_TARGET_AUDIENCE],
        criteria: {
          [GENDER]: formData[GENDER]?.value,
          [AGE_FROM]: formData[AGE_FROM],
          [AGE_TO]: formData[AGE_TO],
          [NATIONALITY]: formData[NATIONALITY]?.label,
          [PIN_CODE]: formData[PIN_CODE],
          [TAGS_SOURCE]:
            formData[TAGS_SOURCE] && formData[TAGS_SOURCE]?.length > 0
              ? formData[TAGS_SOURCE]?.map((item: any) => item?.label)
              : [],
          [EXTERNAL_SOURCE]:
            formData[EXTERNAL_SOURCE] && formData[EXTERNAL_SOURCE]?.length > 0
              ? formData[EXTERNAL_SOURCE]?.map((item: any) => item?.label)
              : [],
        },
      }
      dispatch(addCampaign(requestGenerator(createPayload))).then((result) => {
        if (result.type === `${ADD_CAMPAIGN_TYPE}/fulfilled`) {
          navigate('/campaigns/onGoingCampaigns')
        }
      })
    }
  }

  const getCriteriaData = (data: any, page?: any) => {
    if (page) {
      setPageIndex(1)
    }
    let requestData = {
      is_active: true,
      search: '',
      page: page ? page : pageIndex,
      pageSize: dataPerPage,
      criteria: {
        gender: data?.gender?.value ?? null,
        age_from: data?.age_from ? data?.age_from : null,
        age_to: data?.age_to ? data?.age_to : null,
        nationality: data?.nationality?.label ?? null,
        pincode: data?.pincode ? data?.pincode : null,
        tags_or_sources:
          data?.tags_or_sources && data?.tags_or_sources?.length > 0
            ? data?.tags_or_sources?.map((item: any) => item?.label)
            : [],
        external_sources:
          data?.external_sources && data?.external_sources?.length > 0
            ? data?.external_sources?.map((item: any) => item?.label)
            : [],
      },
    }
    dispatch(searchCriteria(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotal(result.payload.total)
    })
  }

  //  Criteria Form fields
  const onCriteriaSubmit: SubmitHandler<ICreateCampaignForm1> = (data: any) => {
    console.log('data', data)
    if (data[AGE_FROM] && data[AGE_TO]) {
      if (Number(data[AGE_TO]) <= Number(data[AGE_FROM])) {
        dispatch(
          setMessage({
            message: 'Please enter age to value greater than age from',
            type: failure,
          })
        )
      } else {
        getCriteriaData(data, 1)
      }
    } else {
      getCriteriaData(data, 1)
    }
  }

  const setDropdownValue = (valueArr: any, setter: any) => {
    if (valueArr?.values?.length > 0) {
      let tempArr: any = []
      tempArr = valueArr?.values?.map((item: any) => {
        return {
          label: item?.value,
          value: item?._id,
        }
      })
      setter(tempArr)
    } else {
      setter([])
    }
  }
  useEffect(() => {
    const nationality = masterValueData?.find(
      (item: any) => item?.category_name === 'NATIONALITY'
    )
    const tags = masterValueData?.find(
      (item: any) => item?.category_name === 'ASSIGN_TAG'
    )
    const source = masterValueData?.find(
      (item: any) => item?.category_name === 'SOURCE_AD_CAMPAIGN'
    )
    setDropdownValue(nationality, setNationalityData)
    setDropdownValue(tags, setTagsSourceData)
    setDropdownValue(source, setExternalSourceData)
  }, [masterValueData])
  useEffect(() => {
    const tags = nationalityData?.find(
      (item: any) => item?.category_name === 'ASSIGN_TAG'
    )
    setDropdownValue(tags, setTagsSourceData)
  }, [nationalityData])
  useEffect(() => {
    let dataPayload = {
      category_name: ['ASSIGN_TAG'],
    }
    dispatch(getPatientSelectionList(requestGenerator(dataPayload)))
  }, [dispatch])
  // set the data on form
  useEffect(() => {
    if (updateCampaigById?._id) {
      reset(updateCampaigById)
      let startDate = moment(updateCampaigById?.start_date).format('YYYY-MM-DD')
      let endDate = moment(updateCampaigById?.end_date).format('YYYY-MM-DD')
      setValue(CAMPAIGN_NAME, updateCampaigById?.camp_name)
      setValue(START_DATE, startDate)
      setValue(END_DATE, endDate)
      setValue(TARGET_AUDIENCE, updateCampaigById?.target_audience)
      setValue(NO_OF_TARGET_AUDIENCE, updateCampaigById?.target_audience_no)
      setValue2(AGE_FROM, updateCampaigById?.criteria?.age_from)
      setValue2(AGE_TO, updateCampaigById?.criteria?.age_to)
      setValue2(PIN_CODE, updateCampaigById?.criteria?.pincode)
      const date = new Date()
      const campaignStartDate = new Date(updateCampaigById?.start_date)
      setDisable(true)
      if (campaignStartDate <= date) {
        setNameDisable(true)
      } else {
        setNameDisable(false)
      }
    }
  }, [updateCampaigById])
  //  NATIONALITY  Set Data
  useEffect(() => {
    if (updateCampaigById?._id) {
      const nationalityValue = nationalityDropdownData?.find(
        (item: any) => item?.label === updateCampaigById?.criteria?.nationality
      )
      setValue2(NATIONALITY, nationalityValue)
    }
  }, [nationalityDropdownData])

  // GENDER Dropdown // Set Data
  useEffect(() => {
    if (updateCampaigById?._id) {
      const genderValue = genderData?.map((item: any) => {
        if (
          updateCampaigById?.criteria?.gender?.toString() ===
          item?.value?.toString()
        ) {
          return {
            label: item?.label,
            value: item?.value,
          }
        }
      })
      if (genderValue) {
        setValue2(GENDER, genderValue)
      } else {
        setValue2(GENDER, '')
      }
    }
  }, [genderData])

  // TAGS_SOURCE  Dropdown // Set Data
  useEffect(() => {
    if (updateCampaigById?._id) {
      const tags = tagsSourceData?.find(
        (item: any) =>
          item?.label === updateCampaigById?.criteria?.tags_or_sources
      )
      setValue2(TAGS_SOURCE, tags)
    }
  }, [tagsSourceData])

  // expernal source value
  useEffect(() => {
    if (updateCampaigById?._id) {
      const externalSource = externalSourceData?.find(
        (item: any) =>
          item?.label === updateCampaigById?.criteria?.external_sources
      )
      setValue2(EXTERNAL_SOURCE, externalSource)
    }
  }, [externalSourceData])

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    if (!updateCampaigById?._id) {
      getCriteriaData(formData)
    }
  }, [pageIndex, dataPerPage])

  useEffect(() => {
    return () => {
      dispatch(clearCampaignData())
      setTotal(0)
      setTotalPage(0)
    }
  }, [])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.createCampaignsDetailsContainer}>
        {/* Form*/}
        <section className={styles.createCampaignsFormContainer}>
          <form
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.formBox}>
              <div className={styles.formFields}>
                {/* Campaign Name */}
                <div className={styles.inputFields}>
                  <Input
                    type="text"
                    placeholder="Enter Campaign Name"
                    {...register(
                      CAMPAIGN_NAME,
                      createCampaignsValidators[CAMPAIGN_NAME]
                    )}
                    onChange={(e: any) => trimValue(e)}
                    htmlFor={CAMPAIGN_NAME}
                    labelText="Campaign Name"
                    requiredField={true}
                    labelClass={styles.customLable}
                    errorMessage={errors?.[CAMPAIGN_NAME]?.message}
                    showErrors={errors?.[CAMPAIGN_NAME]}
                    disabled={nameDisable}
                  />
                </div>
                {/* Start Date */}
                <div className={styles.inputFields}>
                  <Input
                    type="date"
                    {...register(
                      START_DATE,
                      createCampaignsValidators[START_DATE]
                    )}
                    // onChange={(e: any) => trimValue(e)}
                    htmlFor={START_DATE}
                    labelText="Start Date"
                    requiredField={true}
                    labelClass={styles.customLable}
                    value={watch(START_DATE) || []}
                    min={new Date().toISOString().split('T')[0]}
                    max={
                      endDate &&
                      (new Date(endDate)?.toISOString().split('T')[0] ?? '')
                    }
                    errorMessage={errors?.[START_DATE]?.message}
                    showErrors={errors?.[START_DATE]}
                    disabled={nameDisable}
                  />
                </div>
                {/* End Date */}
                <div className={styles.inputFields}>
                  <Input
                    type="date"
                    max="9999-12-31"
                    min={
                      maxEndDate &&
                      (new Date(maxEndDate)?.toISOString()?.split('T')[0] ?? '')
                    }
                    {...register(END_DATE, createCampaignsValidators[END_DATE])}
                    // onChange={(e: any) => trimValue(e)}
                    htmlFor={END_DATE}
                    labelText="End Date"
                    requiredField={true}
                    labelClass={styles.customLable}
                    value={watch(END_DATE) || []}
                    errorMessage={errors?.[END_DATE]?.message}
                    showErrors={errors?.[END_DATE]}
                  />
                </div>
              </div>
              <div className={styles.formFields}>
                {/* Target Audience */}
                <div className={styles.inputFields}>
                  <Input
                    type="text"
                    placeholder="Enter Target Audience"
                    {...register(
                      TARGET_AUDIENCE
                      // createCampaignsValidators[TARGET_AUDIENCE]
                    )}
                    onChange={(e: any) => trimValue(e)}
                    htmlFor={TARGET_AUDIENCE}
                    labelText="Target Audience"
                    // requiredField={true}
                    labelClass={styles.customLable}
                    // errorMessage={errors?.[TARGET_AUDIENCE]?.message}
                    // showErrors={errors?.[TARGET_AUDIENCE]}
                    disabled={disable}
                  />
                </div>
                {/* No. of Target Audience */}
                <div className={styles.inputFields}>
                  <Input
                    type="number"
                    placeholder="Enter No of Target Audience"
                    {...register(
                      NO_OF_TARGET_AUDIENCE
                      // createCampaignsValidators[NO_OF_TARGET_AUDIENCE]
                    )}
                    onChange={(e: any) => trimValue(e)}
                    onKeyDown={(e: any) => disableArrowKey(e)}
                    onWheel={(e: any) => {
                      e.target.blur()
                    }}
                    htmlFor={NO_OF_TARGET_AUDIENCE}
                    labelText="No. of Target Audience"
                    // requiredField={true}
                    labelClass={styles.customLable}
                    // errorMessage={errors?.[NO_OF_TARGET_AUDIENCE]?.message}
                    // showErrors={errors?.[NO_OF_TARGET_AUDIENCE]}
                    disabled={disable}
                  />
                </div>
                {/* Blank */}
                <div className={styles.inputFields}></div>
              </div>
              <hr className={styles.formDivider} />
              {/* Criteria Form */}
              <form onSubmit={handleSubmitfirstCriteria(onCriteriaSubmit)}>
                <div className={styles.criteriaTitle}>
                  <p className={styles.criteriaTitleText}> Criteria </p>
                </div>
                <div className={styles.formFields}>
                  {/* Gender */}
                  <div className={styles.selectFields}>
                    <div className={styles.selectInputFieldContainer}>
                      <Label
                        htmlFor={GENDER}
                        labelText="Gender"
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            Select Gender
                          </span>
                        }
                        {...register2(GENDER)}
                        isSearchable={true}
                        isClearable={true}
                        value={watch2(GENDER)}
                        options={genderData}
                        components={animatedComponent}
                        closeMenuOnSelect={true}
                        styles={customSelectStyles}
                        onChange={(e: any) => {
                          setValue2(GENDER, e)
                          trigger2(GENDER)
                        }}
                        isDisabled={disable}
                      />
                    </div>
                  </div>
                  {/* Age From */}
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      placeholder="Enter Age From"
                      {...register2(AGE_FROM)}
                      onChange={(e: any) => trimValue(e)}
                      onKeyDown={(e: any) => disableArrowKey(e)}
                      onWheel={(e: any) => {
                        e.target.blur()
                      }}
                      htmlFor={AGE_FROM}
                      labelText="Age From"
                      labelClass={styles.customLable}
                      disabled={disable}
                    />
                  </div>
                  {/* Age To */}
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      placeholder="Enter Age To"
                      {...register2(AGE_TO)}
                      onChange={(e: any) => trimValue(e)}
                      onKeyDown={(e: any) => disableArrowKey(e)}
                      onWheel={(e: any) => {
                        e.target.blur()
                      }}
                      htmlFor={AGE_TO}
                      labelText="Age To"
                      labelClass={styles.customLable}
                      disabled={disable}
                    />
                  </div>
                </div>
                <div className={styles.formFields}>
                  {/* Nationality */}
                  <div className={styles.selectFields}>
                    <div className={styles.selectInputFieldContainer}>
                      <Label
                        htmlFor={NATIONALITY}
                        labelText="Nationality"
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            Select Nationality
                          </span>
                        }
                        {...register2(NATIONALITY)}
                        isSearchable={true}
                        isClearable={true}
                        value={watch2(NATIONALITY)}
                        options={nationalityDropdownData}
                        components={animatedComponent}
                        closeMenuOnSelect={true}
                        maxMenuHeight={120}
                        styles={customSelectStyles}
                        onChange={(e: any) => {
                          setValue2(NATIONALITY, e)
                          trigger2(NATIONALITY)
                        }}
                        isDisabled={disable}
                      />
                    </div>
                  </div>

                  {/*Pin Code */}
                  <div className={styles.inputFields}>
                    <Input
                      type="number"
                      placeholder="Enter Pin Code"
                      {...register2(PIN_CODE)}
                      onChange={(e: any) => trimValue(e)}
                      htmlFor={PIN_CODE}
                      onKeyDown={(e: any) => disableArrowKey(e)}
                      onWheel={(e: any) => {
                        e.target.blur()
                      }}
                      labelText="Pin Code"
                      labelClass={styles.customLable}
                      disabled={disable}
                    />
                  </div>
                  {/* Tags/Source */}
                  <div className={styles.selectFields}>
                    <div className={styles.selectInputFieldContainer}>
                      <Label
                        htmlFor={TAGS_SOURCE}
                        labelText="Tags/Source"
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            Select Tags/Source
                          </span>
                        }
                        {...register2(TAGS_SOURCE)}
                        isSearchable={true}
                        isClearable={true}
                        isMulti
                        value={watch2(TAGS_SOURCE)}
                        options={tagsSourceData}
                        components={animatedComponent}
                        closeMenuOnSelect={false}
                        maxMenuHeight={120}
                        styles={customSelectStyles}
                        onChange={(e: any) => {
                          setValue2(TAGS_SOURCE, e)
                          trigger2(TAGS_SOURCE)
                        }}
                        isDisabled={disable}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.formFields}>
                  {/* External Source */}
                  <div className={styles.selectFields}>
                    <div className={styles.selectInputFieldContainer}>
                      <Label
                        htmlFor={EXTERNAL_SOURCE}
                        labelText="External Source"
                        customClass={styles.customLabel}
                      />
                      <Select
                        className={styles.selectInputField}
                        placeholder={
                          <span className="selectPlaceholder">
                            External Source
                          </span>
                        }
                        {...register2(EXTERNAL_SOURCE)}
                        isSearchable={true}
                        isClearable={true}
                        isMulti
                        value={watch2(EXTERNAL_SOURCE)}
                        options={externalSourceData}
                        components={animatedComponent}
                        closeMenuOnSelect={false}
                        maxMenuHeight={120}
                        styles={customSelectStyles}
                        onChange={(e: any) => {
                          setValue2(EXTERNAL_SOURCE, e)
                          trigger2(EXTERNAL_SOURCE)
                        }}
                        isDisabled={disable}
                      />
                    </div>
                  </div>

                  {/* Search Button */}
                  <div className={styles.searchIconField}>
                    <button
                      type="submit"
                      className={styles.searchContainer}
                      disabled={updateCampaigById?._id ? true : false}
                      onClick={handleSubmitfirstCriteria(onCriteriaSubmit)}
                    >
                      <SearchIcon fillColor={colors.white1} />
                    </button>{' '}
                    <Button
                      title="Reset"
                      type="button"
                      customClass={styles.resetButtonStyle}
                      disable={updateCampaigById?._id ? true : false}
                      handleClick={() => {
                        reset2()
                        setValue2(GENDER, '')
                        setValue2(NATIONALITY, '')
                        setValue2(TAGS_SOURCE, '')
                        setValue2(EXTERNAL_SOURCE, '')
                        getCriteriaData({}, 1)
                      }}
                    />
                  </div>
                  <div className={styles.inputFields}></div>
                </div>
              </form>
            </div>
            {/* Table */}
            <div className={styles.createCampaignsTableContainer}>
              <div className={styles.tableContainer}>
                <TableV3
                  getTableProps={getTableProps}
                  getTableBodyProps={getTableBodyProps}
                  headerGroups={headerGroups}
                  rows={rows}
                  prepareRow={prepareRow}
                />
              </div>
              {/* Pagination */}
              {!!(total > 10) && (
                <Pagination
                  setDataPerPage={setDataPerPage}
                  pageIndexOptions={pageIndexOptions}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                />
              )}
              {/* Button */}
              <div className={styles.buttonContainer}>
                <Button
                  title={
                    updateCampaigById?._id
                      ? 'Update Campaign'
                      : 'Create Campaign'
                  }
                  type="submit"
                  customClass={styles.createCampaignBtn}
                  disable={
                    updateCampaigById?._id
                      ? false
                      : searchCriteriaData && searchCriteriaData?.length > 0
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </form>
        </section>
      </div>
    </>
  )
}

export default CreateCampaigns
