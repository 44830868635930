import {
  ACCOUNT_HOLDER_NAME,
  ACCOUNT_NUMBER,
  ANNUAL_LEAVES,
  BANK_IDENTIFIER_CODE,
  BANK_NAME,
  BASIC_SALARY,
  BIRTH_DATE,
  BRANCH,
  BRANCH_LOCATION,
  CIVIL_ID,
  DATE_OF_JOINING,
  DEPARTMENT,
  DESIGNATION,
  EMPLOYEES_ID,
  GENDER,
  LOCAL_ADDRESS,
  NAME_AR,
  NAME_EN,
  NATIONALITY,
  OVERTIME_TYPE,
  PAYMENT_MEDIUM,
  PHONE,
  PROBATION_END_DATE,
  PROBATION_PERIOD,
  REPORTING_TO,
  SHIFT_TYPE,
  SUB_COMPANY,
  TAX_PAYER_ID,
  WEEKEND,
  EMPLOYEE_NATIONAL_ID,
  SALARY_TYPE,
  HOURLY_SALARY,
  EMPLOYEE_PROFILE_PIC,
  ALLOWANCE_NAME,
  ALLOWANCE_VALUE,
  DEDUCTION_NAME,
  DEDEUCTION_VALUE,
} from "../../employees//employeeConstants";
import { IAddEmployeePersonalDetailsForm } from "../employeeInterface";

export const addEmployeeDetailsValidators = {
  [BRANCH]: {
    required: "",
  },
  
  [EMPLOYEES_ID]: {
    required: "",
  },

  [NAME_EN]: {
    required: "",
  },
  [NAME_AR]: {
    required: "Please enter name in arabic",
  },
  [BIRTH_DATE]: {
    required: "Please select date of birth",
    validate: {
      futureDate: (value: any) => {
        const currentDate = new Date();
        const selectedDate = new Date(value);
        if (selectedDate > currentDate) {
          return "Please not select future date";
        }
        return true;
      },
    },
  },
  [GENDER]: {
    required: "Please select gender",
  },
  [PHONE]: {
    required: "Please enter phone number",
    minLength: {
      value: 7,
      message: "Phone number must be at least 7 characters"
    },
    maxLength: {
      value: 16,
      message: "Phone number must not exceed 15 characters"
    },
    // pattern: {
    //   value: /^\+?[1-9]\d{1,14}$/,
    //   message: "Invalid phone number"
    // }
  },
  [NATIONALITY]: {
    required: "Please select nationality",
  },
  [EMPLOYEE_NATIONAL_ID]: {
    required: "Please enter civil id no",
    minLength: {
      value: 9,
      message: "Civil ID must be at least 9 characters"
    },
    maxLength: {
      value: 18,
      message: "Civil ID must not exceed 18 characters"
    },
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return 'Please enter positive value';
        }
      },
    },
    // pattern: {
    //   value: /^[A-Za-z0-9]{10}$/,
    //   message: "Civil ID must contain only letters and digits"
    // }
  },
  [LOCAL_ADDRESS]: {
    required: "Please enter local address",
  },
  [EMPLOYEE_PROFILE_PIC]: {
    required: "Please select profile picture",
    validate: {
      fileSize: (file: FileList) => {
        return (
          file[0].size <= 2 * 1024 * 1024 || 'File size should be less than 2MB'
        )
      },
      fileType: (file: FileList) =>
        /jpeg|png/.test(file[0].type) || 'Only JPEG/PNG files are allowed',
    },
  },
  // Company Details
  [DEPARTMENT]: {
    required: "Please select department",
  },
  [DESIGNATION]: {
    required: "Please enter designation",
  },
  [REPORTING_TO]: {
    required: "Please select reporting to",
  },
  [OVERTIME_TYPE]: {
    required: "Please select overtime type",
  },
  [SHIFT_TYPE]: {
    required: "",
  },
  [SUB_COMPANY]: {
    required: "",
  },
  [ANNUAL_LEAVES]: {
    required: "Please enter annual leaves",
  },
  [DATE_OF_JOINING]: {
    required: "Please select date of joining",
  },
 
  [PROBATION_END_DATE]: {
    required: "",
  },
  [PROBATION_PERIOD]: {
    required: "",
  },
  [WEEKEND]: {
    required: "Please select weekends",
  },
  [SALARY_TYPE]: {
    required: "",
  },
  [BASIC_SALARY]: {
    required: "Please enter basic salary",
  },
  [HOURLY_SALARY]: {
    required: "Please enter hourly rate",
  },
  [PAYMENT_MEDIUM]: {
    required: "Please enter payment medium",
  },
  [ALLOWANCE_NAME]: {
    required: "",
  },
  [ALLOWANCE_VALUE]: {
    required: "",
  },
  [DEDUCTION_NAME]: {
    required: "",
  },
  [DEDEUCTION_VALUE]: {
    required: "",
  },
 
  

  // Bank Details
  [ACCOUNT_HOLDER_NAME]: {
    required: "",
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: "Only alphabets are allowed"
    }
  },
  [ACCOUNT_NUMBER]: {
    required: "",
    minLength: {
      value: 5,
      message: "Account number must be at least 5 characters"
    },
    maxLength: {
      value: 17,
      message: "Account number must not exceed 17 characters"
    },
    
  },
  [BANK_NAME]: {
    required: "",
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: "Only alphabets are allowed"
    }
  },
  [BANK_IDENTIFIER_CODE]: {
    required: "",
  },
  [BRANCH_LOCATION]: {
    required: "",
  },
  [TAX_PAYER_ID]: {
    required: "",
  },
};
