import { createSlice } from '@reduxjs/toolkit'
import { IRoleUser } from '../../../interfaces/apiInterface'
import {
  getUserRole,
  updateReportMapping,
  updateRoleStatus,
  reportPocAsync,
  addReportQuery,
  updateReportQuery,
  getReportBuilderConfigList,
  getReportBuilderList,
  getReportsList,
  getDynamicReportQueryData,
  updateReportStatusById
} from './roleAsynActions'

export const initialState: IRoleUser = {
  isLoading: false,
  userRole: [],
  error: null,
  isStatusUpdated: false,
  reportBuilderConfigData:[],
  reportBuilderData:[],
  reportTypeList:[],
  dynamicReportData:[],
  selctedBaseTableColumns:[],
  selctedChildTableColumns:[],
  selctedBaseTableOrders:[],
  reportBuilderFormValues:{}
}

export const roleSlice = createSlice({
  name: 'roleuser',
  initialState,
  reducers: {
    addBaseTableColumn: (state, action) => {
      const selectedBaseColumn = {[action.payload]:`$${action.payload}`}
      state.selctedBaseTableColumns = [
        ...state.selctedBaseTableColumns,
        selectedBaseColumn,
      ];
    },
    removeBaseTableColumn: (state, action) => {
      const removedBaseTableColumnData = state.selctedBaseTableColumns?.filter(
        (item: any) => Object.values(item)[0] !== `$${action.payload}`
      );
      state.selctedBaseTableColumns = removedBaseTableColumnData;
    },
    updateBaseTableColumn: (state, action) => {
      state.selctedBaseTableColumns = action.payload;
    },
    addChildTableColumn: (state, action) => {
      const selectedChildColumn = {[action.payload]:`$${action.payload}`}
      state.selctedChildTableColumns = [
        ...state.selctedChildTableColumns,
        selectedChildColumn,
      ];
    },
    removeChildTableColumn: (state, action) => {
      const removedChildTableColumnData = state.selctedChildTableColumns?.filter(
        (item: any) => Object.values(item)[0] !== `$${action.payload}`
      );
      state.selctedChildTableColumns = removedChildTableColumnData;
    },
    updateChildTableColumn: (state, action) => {
      state.selctedChildTableColumns = action.payload;
    },
    addBaseTableOrder: (state, action) => {
      const selectedBaseOrder = {[action.payload.original]:String(state.selctedBaseTableOrders.length + 1)}
      state.selctedBaseTableOrders = [
        ...state.selctedBaseTableOrders,
        selectedBaseOrder,
      ];
    },
    removeBaseTableOrder: (state, action) => {
      const removedBaseTableOrdersData = state.selctedBaseTableOrders?.filter(
        (item: any) => Object.keys(item)[0] !== action.payload.original
      );
      state.selctedBaseTableOrders = removedBaseTableOrdersData;
    },
    updateBaseTableOrder: (state, action) => {
      state.selctedBaseTableOrders = action.payload;
    },
    reportBuilderFormData: (state, action) => {
      state.reportBuilderFormValues = action.payload;
    },
  },
  extraReducers(builder) {
    // SHARE QUESTIONNAIRE LINK
    builder
      .addCase(getUserRole.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getUserRole.fulfilled, (state, action) => {
        state.isLoading = false
        state.userRole = action.payload || []
      })
      .addCase(getUserRole.rejected, (state, error) => {
        state.isLoading = false
        state.userRole = []
        state.error = error
      })
      .addCase(updateRoleStatus.pending, (state) => {
        state.isLoading = true
        state.isStatusUpdated = false
      })
      .addCase(updateRoleStatus.fulfilled, (state) => {
        state.isLoading = false
        state.isStatusUpdated = true
      })
      .addCase(updateRoleStatus.rejected, (state) => {
        state.isLoading = false
        state.isStatusUpdated = false
      })
      .addCase(updateReportMapping.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateReportMapping.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateReportMapping.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(reportPocAsync.pending, (state) => {
        state.isLoading = true
      })
      .addCase(reportPocAsync.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(reportPocAsync.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addReportQuery.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addReportQuery.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addReportQuery.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateReportQuery.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateReportQuery.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateReportQuery.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getReportBuilderConfigList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getReportBuilderConfigList.fulfilled, (state, action) => {
        state.isLoading = false
        state.reportBuilderConfigData = action.payload 
      })
      .addCase(getReportBuilderConfigList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getReportBuilderList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getReportBuilderList.fulfilled, (state, action) => {
        state.isLoading = false
        state.reportBuilderData = action.payload.data
      })
      .addCase(getReportBuilderList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getReportsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getReportsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.reportTypeList = action.payload
      })
      .addCase(getReportsList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getDynamicReportQueryData.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDynamicReportQueryData.fulfilled, (state, action) => {
        state.isLoading = false
        state.dynamicReportData = action.payload
      })
      .addCase(getDynamicReportQueryData.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateReportStatusById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateReportStatusById.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateReportStatusById.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  addBaseTableColumn,
  removeBaseTableColumn,
  updateBaseTableColumn,
  addChildTableColumn,
  removeChildTableColumn,
  updateChildTableColumn,
  addBaseTableOrder,
  removeBaseTableOrder,
  updateBaseTableOrder,
  reportBuilderFormData
} = roleSlice.actions

export default roleSlice.reducer
