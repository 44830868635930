import Tabs from '../../components/core/tab/Tabs'
import styles from './styles.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import FloatingBar from '../../components/common/floatingbar/FloatingBar'
import { AgentsFloatingBarData, crmAdminTabData } from '../../constants/data'
import { useEffect } from 'react'

const CrmAdminLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === '/crmadmin') {
      navigate('/crmadmin/new')
    }
  }, [location, navigate])
  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.tabContainer}>
          <Tabs tabData={crmAdminTabData} />
          <Outlet />
        </div>
        {/* <div className={styles.floatingBarContainer}>
          <FloatingBar floatingBarData={AgentsFloatingBarData} />
        </div> */}
      </div>
    </div>
  )
}

export default CrmAdminLayout
