import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import GlobalSearch from '../../../components/common/global-search-component/page'
import SmartSearch from '../../../components/common/smart-search/SmartSearch'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { SubmittedPiHeadersData } from './data'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useEffect, useState } from 'react'
import { getSubmittedPiAsync } from '../../../redux/features/purchase-invoice/purchaseAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { trimValue } from '../../../utils/utils'
import Pagination from '../../../components/common/pagination/Pagination'
import Loader from '../../../components/common/spinner/Loader'
import styles from './style.module.scss'

const SubmittedPruchaseInvoice = () => {
  const { submittedPiData, isLoading } = useAppSelector(
    (state) => state.purchaseInvoice
  )
  const dispatch = useAppDispatch()
  const data: any = submittedPiData ?? []
  const columns: Column<any>[] = SubmittedPiHeadersData
  const options: TableOptions<any> = {
    data,
    columns,
  }

  const [searchTest, setSearchTest] = useState('')
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<any>(0)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalData, setTotalData] = useState(0)

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= Math.ceil(totalPage); i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let requestData = {
      search: searchTest,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getSubmittedPiAsync(requestGenerator(requestData))).then(
      (result: any) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPerPage, dispatch, pageIndex])

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  const handleSearch = () => {
    setPageIndex(1)
    if (searchTest?.length) {
      let requestData = {
        search: searchTest,
        page: 1,
        pageSize: dataPerPage,
      }
      dispatch(getSubmittedPiAsync(requestGenerator(requestData))).then(
        (result: any) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <GlobalSearch
        placeholder="Search"
        value={searchTest}
        onKeyDown={(e: any) => {
          if (e?.key === 'Enter') {
            handleSearch()
          }
        }}
        onChange={(e: any) => {
          trimValue(e)
          setSearchTest(e.target.value)
          if (searchTest !== '' && e.target.value === '') {
            setPageIndex(1)
            setDataPerPage(10)
            let requestData = {
              page: 1,
              pageSize: 10,
              order_by: { name: 1 },
            }
            dispatch(getSubmittedPiAsync(requestGenerator(requestData))).then(
              (result: any) => {
                setTotalPage(result.payload.lastPage)
                setTotalData(result.payload.total)
              }
            )
          }
        }}
        handleSearch={() => handleSearch()}
      >
        <SmartSearch
          placeHolder="Smart Search"
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          isDisable={!searchTest?.length}
        />
      </GlobalSearch>
      <div className={styles.tableContainer}>
        <TableV3
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        />
      </div>
      {!!(totalData > 10) && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageSize={dataPerPage}
        />
      )}
    </>
  )
}

export default SubmittedPruchaseInvoice
