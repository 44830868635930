import axios from "axios";
import { IAPIPayload } from "../../../interfaces/apiInterface";
import {
  GET_USER_ROLE,
  REPORT_BUILDER_POC,
  UPDATE_REPORT_MAPPING_URL,
  CREATE_REPORT_QUERY_URL,
  GET_REPORT_BUILDER_CONFIG_URL,
  GET_REPORT_BUILDER_URL,
  GET_REPORTS_TYPE_URL,
  GET_DYNAMIC_REPORT_BY_QUERY_URL,
  UPDATE_REPORT_QUERY_URL,
  UPDATE_REPORT_STATUS_URL
} from "../../../config/config";

export const getRoleUser = (data: IAPIPayload) => {
  return axios.post(GET_USER_ROLE, data);
};

export const updaterolestatus = (data: IAPIPayload) => {
  return axios.post(GET_USER_ROLE, data);
};

export const reportMapping = (data: IAPIPayload) => {
  return axios.post(UPDATE_REPORT_MAPPING_URL, data);
};

export const reportPoc = (data: IAPIPayload) => {
  return axios.post(REPORT_BUILDER_POC, data);
};

export const createReportQuery = (data: IAPIPayload) => {
  return axios.post(CREATE_REPORT_QUERY_URL, data);
};

export const updateReportBuilderQuery = (data: IAPIPayload) => {
  return axios.post(UPDATE_REPORT_QUERY_URL, data);
};

export const getReportBuilderConfig = (data: IAPIPayload) => {
  return axios.post(GET_REPORT_BUILDER_CONFIG_URL, data);
};

export const getReportBuilder = (data: IAPIPayload) => {
  return axios.post(GET_REPORT_BUILDER_URL, data);
};

export const getReportsType = (data: IAPIPayload) => {
  return axios.post(GET_REPORTS_TYPE_URL, data);
};

export const getDynamicReportByQuery = (data: IAPIPayload) => {
  return axios.post(GET_DYNAMIC_REPORT_BY_QUERY_URL, data);
};

export const updateReportStatus = (data: IAPIPayload) => {
  return axios.post(UPDATE_REPORT_STATUS_URL, data);
};