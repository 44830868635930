import {
  ATTACHEMENTS,
  DEPARTMENT,
  EMPLOYEE,
  EMPLOYEE_PROFILE_PIC,
  END_DATE,
  FILE,
  LEAVE_CALCULATION,
  LEAVE_TYPE,
  NAME,
  PAID_LEAVES,
  REASON,
  SELECTED_DAYS,
  START_DATE,
  STATUS,
  UNPAID_LEAVES,
  _id,
  emp_id,
  name_en,
} from '../../../constants/applyLeaveConstants'

export const applyLeaveValidator = {
  [STATUS]: {
    required: 'Please select status',
  },
  [NAME]: {
    required: 'Please select status',
  },
  [DEPARTMENT]: {
    required: 'Please select status',
  },
  [_id]: {
    required: 'Please enter id',
  },
  [emp_id]: {
    required: 'Please enter id',
  },
  [name_en]: {
    required: 'Please enter id',
  },
  [EMPLOYEE]: {
    required: 'Please select employee',
  },
  [LEAVE_TYPE]: {
    required: 'Please select leave types',
  },
  [LEAVE_CALCULATION]: {
    required: 'Please select leave calculation',
  },
  [START_DATE]: {
    required: 'Please select start date',
  },
  [END_DATE]: {
    required: 'Please select end date',
  },
  [SELECTED_DAYS]: {
    required: 'Please enter days',
  },
  [PAID_LEAVES]: {
    required: 'Please enter paid leaves',
  },
  [UNPAID_LEAVES]: {
    required: 'Please enter unpaid leaves',
  },
  [REASON]: {
    required: 'Please enter reason',
  },
  [FILE]: {
    required: 'Please select user photo',
  },
  [EMPLOYEE_PROFILE_PIC]: {
    required: 'Please select user photo',
  },
  [ATTACHEMENTS]: {
    required: '',
  },
}
