/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { Cols } from '../../../interfaces/interfaces'
import Pagination from '../../../components/common/pagination/Pagination'
import TableV3 from '../../../components/common/table/tableV3/TableV3'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../hooks/index'
import SmartSearch from '../../../components/common/smart-search/SmartSearch'
import { trimValue } from '../../../utils/utils'
import Loader from '../../../components/common/spinner/Loader'
import GlobalSearch from '../../../components/common/global-search-component/page'
import { getAllPatientList } from '../../../redux/features/patient-emr/patient/patientAsyncAction'
import { SearchModalHeaderData } from './data'
import styles from './styles.module.scss'
interface ISearchModal {
  handleRowClick?: any
}

const SearchModal: FC<ISearchModal> = ({ handleRowClick }) => {
  const dispatch = useAppDispatch()

  const { isLoading, patientListData } = useAppSelector(
    (state) => state.patient
  )

  // Define State Variables
  const [patientSearch, setPatientSearch] = useState<string>('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(10)
  // React Table define
  const data: Cols[] = patientListData
  const columns: Column<Cols>[] = SearchModalHeaderData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // API call for patient history modal
  useEffect(() => {
    const requestData = {
      search: patientSearch,
      page: pageIndex,
      pageSize: dataPerPage,
      // is_active: isDefault,
    }
    dispatch(getAllPatientList(requestGenerator(requestData))).then(
      (result) => {
        setTotalData(result.payload.total)
        setTotalPage(result.payload.lastPage)
      }
    )
  }, [dispatch, dataPerPage, pageIndex])

  const handleSearch = () => {
    if (patientSearch?.length > 0) {
      let payloadData = {
        search: patientSearch,
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(getAllPatientList(requestGenerator(payloadData))).then(
        (result) => {
          setTotalData(result.payload.total)
          setTotalPage(result.payload.lastPage)
        }
      )
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.patientSearchContainer}>
        <GlobalSearch
          placeholder="Search"
          value={patientSearch}
          onChange={(e: any) => {
            trimValue(e)
            setPatientSearch(e.target.value)
            if (patientSearch !== '' && e.target.value.length === 0) {
              setGlobalFilter('')
              let payloadData = {
                search: e.target.value,
                page: pageIndex,
                pageSize: dataPerPage,
              }
              dispatch(getAllPatientList(requestGenerator(payloadData))).then(
                (result) => {
                  setTotalData(result.payload.total)
                  setTotalPage(result.payload.lastPage)
                }
              )
            }
          }}
          handleSearch={() => handleSearch()}
        >
          <SmartSearch
            placeHolder="Smart Search"
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            isDisable={!patientSearch?.length}
          />
        </GlobalSearch>
        <div className={styles.tableContainer}>
          <TableV3
            handleClick={handleRowClick}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          />
        </div>
        {totalData > 10 && (
          <Pagination
            pageSize={dataPerPage}
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )}
      </div>
    </>
  )
}

export default SearchModal
