import { FC, useEffect, useState } from 'react'
import styles from './docUpload.module.scss'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import { dataURI } from '../../../../utils/utils'
import { fileType } from '../../../../interfaces/interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import AttachFiles from '../../../../components/common/attach-files/single-file/AttachSingleFile'
import { UPLOAD_DOC } from '../../../../constants/constant'
import Button from '../../button/Button'
import TableV2 from '../../table/tableV2/TableV2'
import { uploadDocModalValidators } from '../../../../form-validators/uploadDocValidator'
import Loader from '../../spinner/Loader'

interface ISettledInvoiceModal {
  setModelOpenClose?: any
  handleClose?: any
  handleYes?: any
  popData?: any
  headerData?: any
  handleOpen?: any
  handleNo?: any
  heading?: boolean
}

const DocUplaod: FC<ISettledInvoiceModal> = ({
  handleClose,
  handleYes,
  popData,
  headerData,
  handleOpen,
  handleNo,
  heading,
}) => {
  const [doc, setDoc] = useState({ name: '', data_uri: '' })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<any>({})

  const onSubmit: SubmitHandler<any> = (data) => {
    if (handleYes) {
      handleYes(data, doc)
      setValue(UPLOAD_DOC, undefined)
      setDoc({ name: '', data_uri: '' })
    }
  }

  const docField = watch(UPLOAD_DOC)
  const fileName = docField?.[0]

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        setDoc(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  return (
    <>
      {heading && <Loader />}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.closeIconContainer}>
          <CloseIcon
            customClass={styles.closeIconStyle}
            fillColor={colors.green1}
            handleClick={() => handleClose()}
          />
        </div>
        <p className={styles.title}>File Upload</p>
        <Divider customClass={styles.dividerStyle} />
        <div className={styles.searchFieldContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputFieldsContainer}>
              <div className={styles.labelField}>
                <label htmlFor={UPLOAD_DOC} className={styles.labelText}>
                  Select file <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer1}>
                  <AttachFiles
                    attachmentContainerCustomClass={
                      styles.attachmentContainerCustomClass
                    }
                    register={register}
                    fileKey={UPLOAD_DOC}
                    id={UPLOAD_DOC}
                    fileList={doc}
                    validation={uploadDocModalValidators[UPLOAD_DOC]}
                    isName={true}
                    isDocument={true}
                    accept=".jpg, .jpeg, .png, .pdf, .txt, .pdf"
                  />
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[UPLOAD_DOC] && (
                      <p className="dashboardFormError">
                        {errors[UPLOAD_DOC].message as any}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonConatiner}>
              <Button title="Submit" type="submit" />
            </div>
            <TableV2
              tableHeaderData={headerData}
              tableRowData={popData}
              active={false}
              tableCustomClass={styles.tableContainer}
              handleClick={(doc: any) => {
                if (handleOpen) {
                  handleOpen(doc)
                }
              }}
              setModelOpenClose={(doc: any) => {
                if (handleNo) {
                  handleNo(doc)
                }
              }}
            />
          </form>
        </div>
      </div>
    </>
  )
}

export default DocUplaod
