import { Link } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loader from "../../../components/common/spinner/Loader";
import { LeftArrowIcon } from "../../../components/common/svg-components";
import styles from "./createIpdMainLayout.module.scss";
import { ErrorBoundary } from "../../../components/core/error-boundary/ErrorBoundary";

const LazyLoadIpdForm = lazy(() => import("./ipd-create-form/IpdCreateForm"));
const LazyLoadTimeLine = lazy(() => import("./ipd-timeline/IpdTimeline"));

const CreateIpdMainLayout = () => {
  return (
    <>
      <Link
        to="/ipd"
        style={{
          marginBottom: "20px",
          color: "#0E26A3",
          display: "block",
          fontSize: "18px",
        }}
      >
        <LeftArrowIcon
          fillColor="#0E26A3"
          height={14}
          customClass={styles.iconStyle}
        />
        Back
      </Link>
      <ErrorBoundary fallback={<>error occured...</>}>
        <Suspense fallback={<Loader />}>
          <LazyLoadIpdForm />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary fallback={<>error occured...</>}>
        <Suspense fallback={<Loader />}>
          <LazyLoadTimeLine />
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export default CreateIpdMainLayout;
