import { useState, useEffect } from 'react'
import Button from '../../../components/common/button/Button'
import Pagination from '../../../components/common/pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  addAttribute,
  getAllLevelData,
  getGenerateCode,
} from '../../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { level2TableHeader } from '../../../constants/data'
import { useNavigate } from 'react-router-dom'
import {
  setBackId,
  setDropdownAttempts,
  setGenerateCode,
} from '../../../redux/features/view-chat-of-accounts/accountsSlice'
import Level2Modal from '../../../components/common/modal/level2-modal/Level2Modal'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import styles from './Level-2.module.scss'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { GET_ALL_LEVEL_DATA_TYPE } from '../../../constants/asyncActionsType'

const Level2List = () => {
  const {
    getAllData,
    addAttributeData,
    dropdownAttempt,
    selectedOptionDropdown,
  } = useAppSelector((state) => state.accounts)
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [editAddData, setEditAddData] = useState<any>({})
  const [popupData, setPopupdata] = useState<any>({})
  const dispatch = useAppDispatch()

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  const attribute = addAttributeData.id

  useEffect(() => {
    const level2ID = {
      level_Id: 2,
      parent_Level_Type_Id: dropdownAttempt?.id,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllLevelData(requestGenerator(level2ID))).then((result) =>
      setTotalPage(result.payload.lastPage)
    )
  }, [dispatch, dataPerPage, pageIndex, dropdownAttempt])

  useEffect(() => {
    setPageIndex(1)
    dispatch(addAttribute(requestGenerator(selectedOptionDropdown))).then(
      (e) => {
        if (e.type === 'post-attribute/addAttribute/fulfilled') {
          const levelID = {
            level_Id: 2,
            parent_Level_Type_Id: selectedOptionDropdown?.id,
            page: 1,
            pageSize: dataPerPage
          }
          dispatch(
            setDropdownAttempts(requestGenerator(selectedOptionDropdown))
          )

          // dispatch(getAllLevelData(requestGenerator(levelID))).then(
          //   (res: any) => {
          //     if (res?.type === `${GET_ALL_LEVEL_DATA_TYPE}/fulfilled`) {
          //       setTotalPage(res?.payload?.lastPage)
          //     }
          //   }
          // )
        }
      }
    )
  }, [selectedOptionDropdown])

  const generateCode = () => {
    const level2ID = {
      levelId: 2,
      parentLevelTypeId: dropdownAttempt?.id,
    }
    dispatch(getGenerateCode(requestGenerator(level2ID)))
    setShowAddModal((prevState) => !prevState)
    setEditAddData({})
  }
  const navigate = useNavigate()
  const getLevel2RawData = (itemId: any, item: any) => {
    const levelID = {
      level_Id: 3,
      parent_Level_Type_Id: item.code,
    }

    dispatch(setBackId(item.code))
    navigate('/viewchartofaccounts/level3list', {
      state: {
        levelID,
        path: `${levelID?.parent_Level_Type_Id}`,
      },
    })
    dispatch(getAllLevelData(requestGenerator(levelID)))
  }
  return (
    <>
      <div className={styles.level2Container}>
        {attribute ? (
          <>
            {/*  Popup Component */}
            <CustomModal
              showModal={showAddModal}
              closeModal={() => {
                setPopupdata({})
                setShowAddModal(false)
              }}
            >
              <Level2Modal
                popData={editAddData}
                formData={popupData}
                handleSuccess={() => {
                  setPopupdata({})
                  const level2ID = {
                    level_Id: 2,
                    parent_Level_Type_Id: dropdownAttempt?.id,
                    page: pageIndex,
                    pageSize: dataPerPage,
                  }
                  dispatch(getAllLevelData(requestGenerator(level2ID))).then(
                    (result) => setTotalPage(result.payload.lastPage)
                  )
                }}
                handleClose={() => {
                  setPopupdata({})
                  setShowAddModal(false)
                }}
              />
            </CustomModal>
            {/* Title Section */}
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>{addAttributeData?.name}</h1>
              {/*  Add Button  */}
              <Button
                title={'Add'}
                customClass={styles.addBtn}
                type="button"
                handleClick={generateCode}
              />
            </div>
            {/* table */}
            {!!getAllData?.length && (
              <>
                <TableV2
                  tableHeaderData={level2TableHeader}
                  handleClick={(data: any) => {
                    dispatch(setGenerateCode({ code: data?.code }))
                    setShowAddModal((prevState) => !prevState)
                    setPopupdata(data)
                  }}
                  tableRowData={getAllData}
                  handleRow={getLevel2RawData}
                />
              </>
            )}
          </>
        ) : (
          // / Level 1 Not Selected Message /
          <div className={styles.tableMainMsg}>
            <span className={styles.tableMsg}>Please select level 1 </span>
          </div>
        )}
      </div>
      {attribute && getAllData?.length > 0 && (
        <Pagination
          setDataPerPage={setDataPerPage}
          pageIndexOptions={pageIndexOptions}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageSize={dataPerPage}
        />
      )}
    </>
  )
}
export default Level2List
