import { FC, useState } from "react";
import styles from "./assign.module.scss";
import {
  CheckIcon,
  UncheckIcon,
} from "../../../../../components/common/svg-components";
import { colors } from "../../../../../constants/color";
import TableV2 from "../../../../../components/common/table/tableV2/TableV2";
import ButtonGroups from "../../../../../components/common/button-group/ButtonGroups";
import { AssignHeader } from "./AssignTableData";
import Button from "../../../../../components/common/button/Button";

interface IAssignPopup {
  popData?: any;
  children?: any;
  handleSubmit?: any;
  handleSelectAll?: any;
  setvlidType?: any;
  internalType?: string;
  setinternalType?: any;
  handleReset?: any;
  assignLeadList?: any;
  setassignPageIndex?: any;
}
const AssignPopup: FC<IAssignPopup> = ({
  children,
  popData,
  handleSubmit,
  handleSelectAll,
  setvlidType,
  internalType,
  setinternalType,
  handleReset,
  assignLeadList,
  setassignPageIndex,
}) => {
  const [selectSystemUser, setSelectSystemUser] = useState<boolean>(false);
  const [external, setExternal] = useState<boolean>(false);

  const handleSelectSystemUser = () => {
    setSelectSystemUser(!selectSystemUser);
    setvlidType((pre: any) => !pre);
    setassignPageIndex(1);

    if (internalType === "") {
      setinternalType("INTERNAL");
    } else {
      setinternalType("");
    }
  };

  const handleExternal = () => {
    setExternal(!external);
    setvlidType((pre: any) => !pre);
    setassignPageIndex(1);
  };

  return (
    <>
      <div
        className={styles.actionSavePopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        {/* <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => { handleClose() }} /> */}
        <div className={styles.actionSaveContainer}>
          {/* <p className={styles.title}>Assign</p> */}
          {/* <Divider customClass={styles.dividerStyle} /> */}

          <div className={styles.check}>
            {selectSystemUser ? (
              <>
                <CheckIcon
                  fillColor={colors.green1}
                  handleClick={() => handleSelectSystemUser()}
                />
              </>
            ) : (
              <UncheckIcon
                fillColor={colors.grey2}
                handleClick={() => handleSelectSystemUser()}
              />
            )}
            <label>Internal</label>
            {external ? (
              <>
                <CheckIcon
                  fillColor={colors.green1}
                  handleClick={() => handleExternal()}
                />
              </>
            ) : (
              <UncheckIcon
                fillColor={colors.grey2}
                handleClick={() => handleExternal()}
              />
            )}
            <label>External</label>
          </div>

          {/* <div className={styles.text}>
            <p>TOTAL:150 | ASSIGNED:145</p>
          </div> */}

          <div className={styles.tableContainer}>
            <TableV2
              tableHeaderData={AssignHeader}
              tableRowData={popData?.data?.length ? popData?.data : []}
            />
          </div>

          {children}

          <div className={styles.button}>
            <ButtonGroups
              titleOne="Select All"
              handleClickOne={handleSelectAll}
              handleClickTwo={handleSubmit}
              titleTwo="Submit"
              dissableTwo={assignLeadList.length ? false : true}
            />

            <Button
              type="submit"
              title="Reset"
              customClass={styles.nextButton}
              handleClick={handleReset}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignPopup;
