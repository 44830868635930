import { FC } from 'react'
import styles from './paginationV2.module.scss'
import {
  CalanderIcon,
  NextIcon,
  PreviousIcon,
  ResetIcon,
} from '../../svg-components'
import { colors } from '../../../../constants/color'
interface IPaginationProps {
  handlePrevious: any
  handleReset: any
  handleNext: any
  nextPage?: boolean
}

const PaginationV2: FC<IPaginationProps> = ({
  handlePrevious,
  handleReset,
  handleNext,
  nextPage,
}) => {
  return (
    <div className={styles.paginationContainer}>
      <div className={styles.iconContainer} onClick={() => handlePrevious()}>
        <PreviousIcon fillColor={colors.grey2} />
      </div>
      <div className={styles.iconContainer} onClick={() => handleReset()}>
        <ResetIcon fillColor={colors.grey2} />
      </div>
      <div
        className={nextPage ? styles.iconContainerActive : styles.iconContainer}
        onClick={() => handleNext()}
      >
        <NextIcon fillColor={nextPage ? colors.green1 : colors.grey2} />
      </div>
    </div>
  )
}
export default PaginationV2
