import {
  ACCOUNT,
  ACCOUNT1,
  ACCOUNT_NAME,
  ADD_VOUCHER_CODE,
  ADD_VOUCHER_NAME,
  COST_CENTER,
  COST_VOUCHER_NO,
  CREDIT_AMOUNT,
  DATE,
  DEBIT_AMOUNT,
  ENTRY_DATE,
  ENTRY_REMARKS,
  REFERENCE_NO,
  REF_DOC_CATEGORY,
  VOUCHERS_TYPE,
  VOUCHER_NO,
  VOUCHER_REMARKS,
  COST_CENTER_VALUE,
  VOUCHER_STATUS,
} from './voucherConstant'
import { IAddVoucherEntries } from './voucherInterface'

export const vouchersValidators = {
  [VOUCHERS_TYPE]: {
    required: 'Please select voucher',
  },
  [VOUCHER_STATUS]: {
    required: 'Please select voucher',
  },
  [VOUCHER_NO]: {},
  [DATE]: {
    required: 'Please select date',
  },
  [VOUCHER_REMARKS]: {
    required: 'Please enter voucher remarks',
  },
  [REF_DOC_CATEGORY]: {
    required: 'Please select reference doc category',
  },
  [REFERENCE_NO]: {
    required: 'Please enter reference no',
    pattern: {
      value: /^\d+$/,
      message: 'Please enter only numbers',
    },
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return 'Please enter valid number'
        }
      },
    },
  },
  [ACCOUNT]: {
    required: 'Please search or select account',
  },
  [ACCOUNT1]: {
    required: 'Please search account',
  },
  [DEBIT_AMOUNT]: {
    validate: {
      handleAmount: (value: any, values: IAddVoucherEntries) => {
        const credit_amount = values[CREDIT_AMOUNT]
        if (!value && !credit_amount) {
          return 'You must fill out either debit amount or credit amount greater than 0'
        } else if (!value && credit_amount) {
          return undefined
        } else if (Number(value) < 0) {
          return 'Please enter positive numbers'
        }
      },
    },
  },
  [CREDIT_AMOUNT]: {
    validate: {
      handleAmount: (value: any, values: IAddVoucherEntries) => {
        const debit_amount = values[DEBIT_AMOUNT]
        if (!value && !debit_amount) {
          return 'You must fill out either debit amount or credit amount greater than 0'
        } else if (!value && debit_amount) {
          return undefined
        } else if (Number(value) < 0) {
          return 'Please enter positive numbers'
        }
      },
    },
  },

  [ENTRY_DATE]: {
    required: 'Please select entry date',
  },
  [COST_CENTER]: {
    required: 'Please select cost center',
  },
  [COST_CENTER_VALUE]: {
    required: 'Please select cost center value',
  },
  [ENTRY_REMARKS]: {
    required: 'Please add entry remarks',
  },
}
// add voucher no
export interface IAddVoucherModalValidators {
  [ADD_VOUCHER_NAME]: {
    required: string
  }
  [ADD_VOUCHER_CODE]: {
    required: string
    pattern: string
  }
}
export const addVoucherModalValidators = {
  [ADD_VOUCHER_NAME]: {
    required: 'Please enter name',
  },
  [ADD_VOUCHER_CODE]: {
    required: 'Please enter initials code',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: 'Only alphabets are allowed',
    },
  },
}

// cost view modal
export interface IcostViewModalValidators {
  [COST_VOUCHER_NO]: {
    required: string
  }
  [ACCOUNT_NAME]: {
    required: string
  }
}
export const costViewModalValidators: IcostViewModalValidators = {
  [COST_VOUCHER_NO]: {
    required: 'Please enter voucher no',
  },
  [ACCOUNT_NAME]: {
    required: 'Please enter account name',
  },
}
