import React, { FC, useEffect, useState } from 'react'
import styles from './formBuilderNameListModal.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { DropDownIcon, FormIcon } from '../../svg-components'
import { getAllCreateNewForms } from '../../../../redux/features/create-new-form/createNewFormAsynActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useNavigate } from 'react-router-dom'
import { saveDataForFormBuilderHeader } from '../../../../redux/features/create-new-form/createNewFormSlice'
import Loader from '../../spinner/Loader'

interface IFormBuilderNameListModal {
  handleOpen: any
  formBuilderRef: any
  handleClose: any
  selectField: any
  handleInsurancePlan?: any
}

const FormBuilderNameListModal: FC<IFormBuilderNameListModal> = ({
  formBuilderRef,
  handleOpen,
  handleClose,
  selectField,
  handleInsurancePlan,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { createNewFormData, isLoading } = useAppSelector(
    (state) => state.createNewForm
  )

  useEffect(() => {
    let requestData = {
      search: '',
      page: 1,
      pageSize: 1000,
      as_per_user_access: true,
    }
    dispatch(getAllCreateNewForms(requestGenerator(requestData)))
  }, [])

  const handlePreviewFormDialogOpen = (item: any) => {
    selectField(item)
    handleClose(false)
    handleOpen(true)
    dispatch(saveDataForFormBuilderHeader({ state: { id: item?._id } }))
  }
  return (
    <>
      <>
        <div className={styles.mainContainer} ref={formBuilderRef}>
          <div className={styles.container}>
            {createNewFormData?.length > 0 ? (
              createNewFormData?.map((item: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <div className={styles.formContainer}>
                      <FormIcon customClass={styles.iconStyle} />
                      <p
                        className={styles.formNameTextStyle}
                        onClick={() => handlePreviewFormDialogOpen(item)}
                      >
                        {item?.name}
                      </p>
                    </div>
                  </React.Fragment>
                )
              })
            ) : (
              <div className={styles.formContainer}>
                <p className={styles.formNameTextStyle}>
                  {isLoading ? 'Loading...' : 'No Forms Found'}
                </p>
              </div>
            )}
          </div>
        </div>
      </>
      {/* )} */}
    </>
  )
}

export default FormBuilderNameListModal
