import { useState } from 'react'
import PreBuildReportBuilder from './PreBuildReportBuilder'
import DynamicReportBuilder from './DynamicReportBuilder'
import { Label } from '../../components/common/label'
import Select from 'react-select'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { typeOfReport } from './data'
import styles from './ReportBuilder.module.scss'

const ReportBuilder = () => {
  const [selectedReportType, setSelectedReportType] = useState<any>(
    typeOfReport[1]
  )

  return (
    <>
      <div className={styles.reportBuilderContainer}>
        <div className={styles.reportTypeContainer}>
          <div className={styles.inputFieldContainer}>
            <Label labelText={`Type`} requiredField={true} flexBasis="30%" />
            <div className={styles.inlineItems}>
              <Select
                className={styles.selectInputField}
                placeholder={`Select Type`}
                closeMenuOnSelect={true}
                value={selectedReportType}
                onChange={(e: any) => {
                  setSelectedReportType(e)
                }}
                components={{ DropdownIndicator }}
                options={typeOfReport}
                isSearchable={false}
                maxMenuHeight={200}
              />
            </div>
          </div>
        </div>
        {selectedReportType?.value === 'PRE_BUILD_REPORT' && (
          <PreBuildReportBuilder />
        )}
        {selectedReportType?.value === 'DYNAMIC_REPORT' && (
          <DynamicReportBuilder />
        )}
      </div>
    </>
  )
}

export default ReportBuilder
