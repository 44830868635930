import { FC, useState } from "react";
import { colors } from "../../../../constants/color";
import Divider from "../../../../components/common/divider/Divider";
import { CloseIcon } from "../../../../components/common/svg-components";
import styles from "./deletePopup.module.scss";
import { useAppDispatch } from "../../../../hooks";
import Button from "../../../../components/common/button/Button";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import { deleteLeave, getAllLeave } from "../../../../redux/features/leave/leaveAsyncActions";
import { DELETE_DOCUMENT, DELETE_LEAVE } from "../../../../constants/asyncActionsType";

interface IDeletePopup {
  popData?: any;
  handleClose?: any;
}
const DeletePopup: FC<IDeletePopup> = ({ popData,  handleClose, }) => {
  const dispatch = useAppDispatch();
  const [type, setType] = useState<string>("employee");
  const handleYes = () => {
    handleClose()
    let deletePayload = {
      id: popData.id,
    };
  dispatch(deleteLeave(requestGenerator(deletePayload))).then((e) => {
    if (e.type === `${DELETE_LEAVE}/fulfilled`) {
      let payloadData = {
        is_active: true,
      page: 1,
      pageSize: 100,
      };
      dispatch(getAllLeave(requestGenerator(payloadData)));
    }
  });
  };
  return (
    <>
      <div className={styles.deletePopupContainer} onClick={(e) => {
        e.stopPropagation();
      }}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()} />
        <div className={styles.deleteContainer}>
          <p className={styles.title}>Delete</p>
          <Divider customClass={styles.dividerStyle} />

          <div className={styles.confirmationContainer}>
            <p className={styles.confirmationText}>
              Are you sure you want to delete ?
            </p>
            <div className={styles.buttonContainer}>
              <Button
                title="Yes"
                customClass={styles.yesBtn}
                handleClick={handleYes} />
              <Button
                title="No"
                customClass={styles.noBtn}
                handleClick={() => handleClose()} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletePopup;
