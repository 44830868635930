import { CheckIcon, UncheckIcon } from "../../components/common/svg-components";
import { colors } from "../../constants/color";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setSelectedSlotStatus } from "../../redux/features/physio/physioSlice";

export const viewSlotsHeaderData: any = [
  {
    Header: "TIME",
    Cell: (props: any) => {
      return <>{props.row.original.label}</>;
    },
  },
  {
    Header: "ACTION",
    Cell: ({ row }: any) => {
      const { selectedSlotsStatus } = useAppSelector((state) => state.physio);
      const dispatch = useAppDispatch();
      const handleSlotsSelection = (items: any) => {
        const updateArr = selectedSlotsStatus?.map((s: any) => {
          if (s.id === row.original.id) {
            return { ...s, selected: !s.selected };
          } else {
            return { ...s };
          }
        });
        dispatch(setSelectedSlotStatus(updateArr));
      };
      return row?.original?.selected ? (
        <CheckIcon
          fillColor={colors.green1}
          handleClick={handleSlotsSelection}
        />
      ) : (
        <UncheckIcon
          fillColor={colors.grey1}
          handleClick={handleSlotsSelection}
        />
      );
    },
  },
];
