import {
  EMPLOYEES_BENIFT,
  EMPLOYEES_NAME,
  EMP_LEAVE,
  EMP_SALARY,
  GRATUITY,
  NET_PAYABLE,
  PAYMENT_DETAILS,
  PAYMENT_MODE,
  PAYMENT_STATUS,
  TOTAL_DEDUCTION,
} from '../pages/payroll/add-new/addNewConstant'

export const addnewValidators = {
  [EMPLOYEES_NAME]: {
    required: 'Please enter name',
  },
  [EMP_SALARY]: {
    required: 'Please enter salary',
  },
  [GRATUITY]: {
    required: 'Please enter gratuity',
  },
  [EMP_LEAVE]: {
    required: 'Please enter accrued leaves',
  },
  [EMPLOYEES_BENIFT]: {
    required: 'Please Enter Total Benifts',
  },
  [TOTAL_DEDUCTION]: {
    required: 'Please enter total deduction',
  },
  [NET_PAYABLE]: {
    required: 'Please enter net payable',
  },
  [PAYMENT_MODE]: {
    required: 'Please enter payment mode',
  },
  [PAYMENT_DETAILS]: {
    required: 'Please enter payment details',
  },
  [PAYMENT_STATUS]: {
    required: 'Please enter payment details',
  },
}
