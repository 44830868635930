import { IPD_INVOICE_CASE_ID, IPD_INVOICE_FILE_NUMBER, IPD_INVOICE_PATIENT_NAME } from "../constants/constant"

export interface IIpdInvoiceValidators {
    [IPD_INVOICE_CASE_ID]: {
        required: string
    }
    [IPD_INVOICE_PATIENT_NAME]: {
        required: string
    }
    [IPD_INVOICE_FILE_NUMBER]: {
        required: string
    }
}


export const ipdInvoiceValidators: any = {
    [IPD_INVOICE_CASE_ID]: {
        required: 'Please select Case Id',
    },
    [IPD_INVOICE_PATIENT_NAME]: {
        required: 'Please select Patient name',
    },
    [IPD_INVOICE_FILE_NUMBER]: {
        required: 'Please select File number',
    }
}