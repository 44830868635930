import { FC, useEffect } from 'react'
import { colors } from '../../../../../constants/color'
import { statusSummaryData } from '../../../../../constants/table-data/statusSummaryPopupData'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import { getAppointmentSummary } from '../../../../../redux/features/appointment/appointmentAsyncActions'
import { requestGenerator } from '../../../../../utils/payloadGenerator'
import Divider from '../../../divider/Divider'
import { CloseIcon } from '../../../svg-components'
import TableV2 from '../../../table/tableV2/TableV2'
import styles from './statusSummaryPopup.module.scss'
import Loader from '../../../spinner/Loader'

interface IStatusSummaryPopup {
  popData?: any
}

const StatusSummaryPopup: FC<IStatusSummaryPopup> = ({ popData }) => {
  const dispatch = useAppDispatch()
  const { isLoading, appointmentSummary } = useAppSelector(
    (state) => state.appointment
  )

  useEffect(() => {
    dispatch(
      // getAppointmentSummary(requestGenerator({ date: popData ? popData : '' }))
      getAppointmentSummary(requestGenerator(popData ? popData : {}))
    )
  }, [dispatch])

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.popupContainer}>
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
        />
        <div
          className={styles.viewAppointment}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <p className={styles.title}>Status Summary</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tableBlock}>
            <TableV2
              tableHeaderData={statusSummaryData}
              tableRowData={appointmentSummary}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default StatusSummaryPopup
