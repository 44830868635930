import moment from 'moment'
import { handlePadStart } from '../../utils/utils'

export const itemRequestedHeaderData: any = [
  {
    Header: 'DATE',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.req_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: 'DOC ID',
    accessor: 'doc_id',
  },
  {
    Header: 'REQ ID',
    accessor: 'req_no',
    Cell: ({ row }: any) => {
      const originalId = row?.original?.req_no
      const reqId = handlePadStart(originalId)
      return <p>{reqId}</p>
    },
  },
  {
    Header: 'SOURCE',
    accessor: 'source',
  },
  {
    Header: 'SUB SOURCE',
    accessor: 'subsource',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.subsource ? (
            <span>{props?.row?.original?.subsource}</span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'ITEM NAME',
    accessor: 'item_name',
  },
  {
    Header: 'UNIT TYPE',
    accessor: 'unit_type',
  },
  {
    Header: 'REQ.QTY',
    accessor: 'requested_qty',
  },
  {
    Header: 'ISSUE QTY',
    accessor: 'fulfilled_qty',
  },
  {
    Header: 'REMAINING QTY',
    accessor: 'remaining_qty',
  },
]
