import styles from './newsCard.module.scss'
import NewsImage from '../../../assets/images/newsImage.png'
import ReporterImage from '../../../assets/images/reporterImage.png'

const NewsCard = () => {
  return (
    <>
      <div className={styles.newsMainContainer}>
        <img src={NewsImage} alt="news_image" className={styles.imgStyle} />
        <p className={styles.newsText}>
          “Russia should understand that it is not possible to speak with
          Armenia in that language.” Hovsep Khurshudyan
        </p>
        <div className={styles.newsInfo}>
          <img src={ReporterImage} alt="person" className={styles.personImg} />
          <div className={styles.reporterContent}>
            <p className={styles.nameText}>John Rastogi</p>
            <p className={styles.postText}>News Reporter</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewsCard
