import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import ButtonGroups from '../../../../components/common/button-group/ButtonGroups'
import NotesPopup from '../../../../components/common/modal/notes-popup/NotesPopup'
import StatusConfirmationPopup from '../../../../components/common/modal/status-confirmation-popup/StatusConfirmationPopup'
import ViewSpecialitiesPopup from '../../../../components/common/modal/view-specialities-popup/ViewSpecialitiesPopup'
import Pagination from '../../../../components/common/pagination/Pagination'
import Popup from '../../../../components/common/popup/Popup'
import Loader from '../../../../components/common/spinner/Loader'
import Table from '../../../../components/common/table/Table'
import { medicalCenterDepartmentTableHeader } from '../../../../constants/data'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getAllDepartment } from '../../../../redux/features/department/departmentAsyncActions'
import { clearDepartmentInfo } from '../../../../redux/features/department/departmentSlice'
import { clearSpecialityInfo } from '../../../../redux/features/specialities/specialitiesSlice'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { trimValue } from '../../../../utils/utils'
import { SearchButton } from '../../../../components/common/svg-components'
import styles from './departmentList.module.scss'
import StatusConfirmationPopupV4 from '../../../../components/common/modal/status-confirmation-popup/statusConfirmationPopupV4'
import { updateDepartmentStatus } from '../../../../redux/features/treatmentPlans/treatmentPlansAsyncActions'

interface IAppProps {}

const DepartmentList: FC<IAppProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  /* Pagination Dependency */
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  /* Pagination Dependency */

  const [toggle, setToggle] = useState<boolean>(false)
  const [toggleValue, setToggleValue] = useState()
  const [showNotes, setShowNotes] = useState<boolean>(false)
  const [notesData, setNotesData] = useState({})
  const [toggleData, setToggleData] = useState<any>({})
  const [confirm, setConfirm] = useState<boolean>(false)
  const [showSpecialities, setShowSpecialities] = useState<boolean>(false)
  const [specialityData, setSpecialityData] = useState({})
  const [searchDepartment, setSearchDepartment] = useState<string>('')
  const { isLoading, departmentData, departmentlistInfo } = useAppSelector(
    (state) => state.department
  )

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let data = {
      search: searchDepartment,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllDepartment(requestGenerator(data))).then((result) => {
      setTotalPage(result.payload.lastPage)
      setTotalData(result.payload.total)
    })
  }, [dispatch, pageIndex, dataPerPage])

  const handleSearch = () => {
    setPageIndex(1)
    if (searchDepartment?.length > 0) {
      let data = {
        search: searchDepartment,
        page: pageIndex,
        pageSize: dataPerPage,
      }
      dispatch(getAllDepartment(requestGenerator(data))).then((result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      })
    }
  }

  const handleNotes = (item: any) => {
    const notes = item.notes
    if (notes && notes !== '-') {
      setShowNotes(!showNotes)
      setNotesData(item)
    }
  }

  const handleEdit = (item: any) => {
    navigate('managedepartment', {
      state: { id: item?._id },
    })
  }

  const handleSpecialities = (item: any) => {
    setShowSpecialities(!showSpecialities)
    setSpecialityData(item)
  }

  const handleActiveDepartment = (item: any) => {
    setToggle(!toggle)
    setConfirm(!confirm)
    setToggleData(item)
    setToggleValue(item?._id)
  }

  const handleStatus = () => {
    const statusPayload = {
      id: toggleData?._id,
      data: {
        is_active: !toggleData?.is_active,
      },
    }
    let data = {
      search: searchDepartment,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(updateDepartmentStatus(requestGenerator(statusPayload))).then(
      (e) => {
        if (e.type === 'department/updateDepartmentStatus/fulfilled') {
          setConfirm(false)
          dispatch(getAllDepartment(requestGenerator(data))).then((result) => {
            setTotalPage(result.payload.lastPage)
            setTotalData(result.payload.total)
          })
        }
      }
    )
  }

  return (
    <>
      {isLoading && <Loader />}
      {showSpecialities && (
        <Popup
          Children={ViewSpecialitiesPopup}
          popData={specialityData}
          handleClose={() => setShowSpecialities(false)}
        />
      )}
      {showNotes && (
        <Popup
          Children={NotesPopup}
          popData={notesData}
          handleClose={() => setShowNotes(false)}
        />
      )}
      {confirm && (
        <Popup
          popData="Department"
          Children={StatusConfirmationPopupV4}
          handleClose={() => {
            setConfirm(false)
          }}
          handleYes={handleStatus}
        />
      )}
      <div className={styles.medicalCenterDepartmentContainer}>
        <div className={styles.searchFilter}>
          <div className={styles.searchContainer}>
            <div className={styles.inputFieldContainer}>
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder="Search by department name"
                value={searchDepartment}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
                onChange={(e) => {
                  trimValue(e)
                  setSearchDepartment(e.target.value)
                  if (searchDepartment !== '' && e.target.value.length === 0) {
                    setPageIndex(1)
                    setDataPerPage(10)
                    let requestData = {
                      search: '',
                      page: 1,
                      pageSize: 10,
                    }
                    dispatch(
                      getAllDepartment(requestGenerator(requestData))
                    ).then((result) => {
                      setTotalPage(result.payload.lastPage)
                      setTotalData(result.payload.total)
                    })
                  }
                }}
              />
              <SearchButton
                handleClick={() => handleSearch()}
                customClass={styles.inputSearchButton}
              />
            </div>
          </div>
          <ButtonGroups
            titleOne="Add Department"
            titleTwo="Manage Specialties"
            btnOneCustomClass={styles.btnOneCustomClass}
            btnTwoCustomClass={styles.btnTwoCustomClass}
            handleClickOne={() => {
              navigate('managedepartment')
              dispatch(clearDepartmentInfo())
            }}
            handleClickTwo={() => {
              navigate('managespecialities')
              dispatch(clearSpecialityInfo())
            }}
          />
        </div>
        <Table
          tableHeaderData={medicalCenterDepartmentTableHeader}
          tableRowData={departmentData}
          toogleValue={toggleValue}
          handleAction={handleEdit}
          handleNotes={handleNotes}
          handleSpecialities={handleSpecialities}
          handleActiveMC={handleActiveDepartment}
        />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}

export default DepartmentList
