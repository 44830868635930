import { FC, useEffect, useState } from "react";
import styles from "./callcenterdashboard.module.scss";
import TableV2 from "../../../../components/common/table/tableV2/TableV2";
import AssignPopup from "../popup/assign/AssignPopup";
import { AdminHeader } from "./CallCenterDashboardTableData";
import DetailsPopup from "../popup/detail/DetailPopup";
import { useNavigate } from "react-router-dom";
import assigned from "../../../../assets/images/assigned.png";
import totalleads from "../../../../assets/images/totalleads.png";
import campaignSvg from "../../../../assets/images/campaign.png";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  adminDashbordAgentDetails,
  getCallCenterDashbordAgent,
  getCallCenterDashbordAllLeads,
  getCallCenterDashbordAssignLeads,
  getCallCenterDashbordCount,
} from "../../../../redux/features/call-center-admin-dashbord/callCenterDashbordAsyncActions";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import {
  CALL_CENTER_ADMIN_AGENT_DETAILS_LIST,
  GET_CALL_CENTER_ADMIN_ALL_LEADS,
  GET_CALL_CENTER_ADMIN_ASSIGN_LEAD,
  GET_CALL_CENTER_ADMIN_DASHBORD_AGENT,
  GET_CALL_CENTER_ADMIN_DASHBORD_COUNT,
} from "../../../../constants/asyncActionsType";
import { CustomModal } from "../../../../components/common/custom-modal/modal";
import Pagination from "../../../../components/common/pagination/Pagination";
import { setMessage } from "../../../../redux/features/toast/toastSlice";
import { success } from "../../../../constants/data";
import {
  resetAssignList,
  setSelectAllAssignList,
} from "../../../../redux/features/call-center-admin-dashbord/callCenterDashbordSlice";
import Loader from "../../../../components/common/spinner/Loader";
interface ICallCenterDashboard {}

const CallCenterDashboard: FC<ICallCenterDashboard> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showFilePopup, setShowFilePopup] = useState<boolean>(false);
  const [showDetaiilPopup, setShowDetailPopup] = useState<boolean>(false);
  const [detailPopData, setDetailPopData] = useState<any>({});
  const [agenetDetails, setagenetDetails] = useState<any>({});
  const [vlidType, setvlidType] = useState(false);
  const [internalType, setinternalType] = useState("");

  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);

  const [assignDataPerPage, setassignDataPerPage] = useState(10);
  const [assignTotalPage, setassignTotalPage] = useState(0);
  const [assignPageIndex, setassignPageIndex] = useState(1);

  const [detailsDataPerPage, setdetailsDataPerPage] = useState(10);
  const [detailsTotalPage, setdetailsTotalPage] = useState(0);
  const [detailsPageIndex, setdetailsPageIndex] = useState(1);

  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  const {
    count,
    agentsList,
    allLeadsList,
    assignLeadList,
    isLoading,
    agentDetailsList,
  } = useAppSelector((state) => state.callCenterAdminDashbord);

  const handleassign = (notesObject: {} | any) => {
    setShowFilePopup((prevState) => !prevState);
    setagenetDetails(notesObject);
  };

  const handleCloseAssigns = () => {
    setShowFilePopup((prevState) => !prevState);
    setvlidType(false);
    setinternalType("");
    setassignPageIndex(1)
  };

  const handledetail = (notesObject: {} | any) => {
    setShowDetailPopup((prevState) => !prevState);
    setDetailPopData(notesObject);
    setdetailsPageIndex(1);
  };

  // Get Dashbord Count
  useEffect(() => {
    dispatch(getCallCenterDashbordCount(requestGenerator({}))).then(
      (e: any) => {
        if (e.type === `${GET_CALL_CENTER_ADMIN_DASHBORD_COUNT}/fulfilled`) {
        } else {
        }
      }
    );
  }, []);

  // Get All Dashbord Agent List
  useEffect(() => {
    const requestData = {
      pageSize: dataPerPage,
      page: pageIndex,
    };
    dispatch(getCallCenterDashbordAgent(requestGenerator(requestData))).then(
      (e: any) => {
        if (e.type === `${GET_CALL_CENTER_ADMIN_DASHBORD_AGENT}/fulfilled`) {
          setTotalPage(e?.payload.lastPage);
        } else {
        }
      }
    );
  }, [dataPerPage, pageIndex]);

  // Assign Popup Pagination
  const assignPageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= assignTotalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const assignPageIndexOptions = assignPageIndexArray();

  // Details Popup Pagination
  const detailsPageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= detailsTotalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const detailsPageIndexOptions = detailsPageIndexArray();

  // Assign Popup Get All Leads List
  useEffect(() => {
    const requestData = {
      pageSize: assignDataPerPage,
      page: assignPageIndex,
      is_assigned: false,
      type: vlidType
        ? internalType !== ""
          ? internalType
          : "EXTERNAL"
        : undefined,
    };

    if (showFilePopup) {
      dispatch(
        getCallCenterDashbordAllLeads(requestGenerator(requestData))
      ).then((e: any) => {
        if (e.type === `${GET_CALL_CENTER_ADMIN_ALL_LEADS}/fulfilled`) {
          setassignTotalPage(e?.payload.lastPage);
        } else {
        }
      });
    }
  }, [
    showFilePopup,
    assignDataPerPage,
    assignPageIndex,
    internalType,
    vlidType,
  ]);

  // Assign Popup Submit Leads
  const handleSubmit = () => {
    const requestData = {
      agent_id: agenetDetails?._id,
      leads: assignLeadList?.map((item: any) => {
        return item?._id;
      }),
    };

    const agentRequestData = {
      pageSize: 10,
      page: 1,
    };

    dispatch(
      getCallCenterDashbordAssignLeads(requestGenerator(requestData))
    ).then((e: any) => {
      if (e.type === `${GET_CALL_CENTER_ADMIN_ASSIGN_LEAD}/fulfilled`) {
        setShowFilePopup((prevState) => !prevState);
        dispatch(
          setMessage({
            message: "Leads Assign Successfully",
            type: success,
          })
        );

        dispatch(
          getCallCenterDashbordAgent(requestGenerator(agentRequestData))
        );
        dispatch(resetAssignList());
      } else {
      }
    });
  };

  // Get All Details for Details Pop
  useEffect(() => {
    const requestData = {
      pageSize: detailsDataPerPage,
      page: detailsPageIndex,
      agent_id: detailPopData?._id,
    };

    if (showDetaiilPopup) {
      dispatch(adminDashbordAgentDetails(requestGenerator(requestData))).then(
        (e: any) => {
          if (e.type === `${CALL_CENTER_ADMIN_AGENT_DETAILS_LIST}/fulfilled`) {
            setdetailsTotalPage(e?.payload.lastPage);
          } else {
          }
        }
      );
    }
  }, [showDetaiilPopup, detailsDataPerPage, detailsPageIndex]);

  // Assign Pop Set All Laeds For Submit
  const handleSelectAll = () => {
    dispatch(setSelectAllAssignList(allLeadsList?.data));
  };

  const handleReset = () => {
    dispatch(resetAssignList());
  };

  return (
    <>
      {isLoading && <Loader />}

      {
        <CustomModal
          title="Assign"
          showModal={showFilePopup}
          closeModal={handleCloseAssigns}
          width="750px"
          height="90dvh"
        >
          <AssignPopup
            popData={allLeadsList}
            handleSubmit={handleSubmit}
            handleSelectAll={handleSelectAll}
            setvlidType={setvlidType}
            internalType={internalType}
            setinternalType={setinternalType}
            handleReset={handleReset}
            assignLeadList={assignLeadList}
            setassignPageIndex={setassignPageIndex}
          >
            {allLeadsList?.total > 10 ? (
              <Pagination
                setDataPerPage={setassignDataPerPage}
                pageIndexOptions={assignPageIndexOptions}
                pageIndex={assignPageIndex}
                setPageIndex={setassignPageIndex}
              />
            ) : (
              ""
            )}
          </AssignPopup>
        </CustomModal>
      }

      {
        <CustomModal
          title="Details"
          showModal={showDetaiilPopup}
          closeModal={() => setShowDetailPopup(!showDetaiilPopup)}
          width="950px"
          height="90dvh"
        >
          <DetailsPopup popData={agentDetailsList} agentData={detailPopData}>
            {agentDetailsList?.total > 10 ? (
              <Pagination
                setDataPerPage={setdetailsDataPerPage}
                pageIndexOptions={detailsPageIndexOptions}
                pageIndex={detailsPageIndex}
                setPageIndex={setdetailsPageIndex}
              />
            ) : (
              ""
            )}
          </DetailsPopup>
        </CustomModal>
      }

      <div className={styles.top}>
        <div className={styles.tab}>
          <div className={styles.data} onClick={() => navigate("/lead/leads")}>
            <div className={styles.left}>
              <img src={totalleads} className={styles.imgStyle} alt="" />
            </div>
            <div className={styles.right}>
              <h4>{count[0]?.total_leads ? count[0]?.total_leads : 0}</h4>
              <p>Total Leads</p>
            </div>
          </div>
        </div>

        <div className={styles.tab}>
          <div className={styles.data}>
            <div className={styles.left}>
              <img src={assigned} className={styles.imgStyle} alt="" />
            </div>
            <div className={styles.right}>
              <h4>{count[0]?.assign_count ? count[0]?.assign_count : 0}</h4>
              <p>Assigned</p>
            </div>
          </div>
        </div>

        <div className={styles.tab}>
          <div
            className={styles.data}
            onClick={() => navigate("/campaigns/onGoingCampaigns")}
          >
            <div className={styles.left}>
              <img src={campaignSvg} className={styles.imgStyle} alt="" />
            </div>
            <div className={styles.right}>
              <h4>{count[0]?.ongoing_camp ? count[0]?.ongoing_camp : 0}</h4>
              <p>On-going Campaigns</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.admin}>
        <TableV2
          tableHeaderData={AdminHeader}
          tableRowData={agentsList?.data?.length ? agentsList?.data : []}
          handleRowClick={handleassign}
          handleClick={handledetail}
        />

        {agentsList?.total > 10 ? (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default CallCenterDashboard;
