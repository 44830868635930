import { requestGenerator } from '../../../utils/payloadGenerator'
import {
  CheckRoundIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from '../../../components/common/svg-components'
import ToggleSwitchV2 from '../../../components/common/toggle-switch/ToggleSwitchV2'
import { colors } from '../../../constants/color'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getAllLeave,
  updateLeaveStatus,
} from '../../../redux/features/leave/leaveAsyncActions'
import styles from './leaveManagement.module.scss'
import moment from 'moment'
import { UPDATE_LEAVE_STATUS } from '../../../constants/asyncActionsType'

export const leaveManagementTableHeaderData: any = [
  {
    Header: 'EMPLOYEE ID',
    accessor: (row: any) => {
      return `${row.emp_id}`
    },
  },
  {
    Header: 'NAME',
    accessor: (row: any) => {
      return `${row.name.toUpperCase()}`
    },
  },
  {
    Header: 'DATE FROM',
    accessor: (row: any) => {
      return moment(row?.start_date).format('DD MMM YYYY') ?? '-'
    },
  },
  {
    Header: 'DATE TO',
    accessor: (row: any) => {
      return moment(row?.end_date).format('DD MMM YYYY') ?? '-'
    },
  },
  {
    Header: 'DAYS',
    accessor: (row: any) => {
      return `${row?.leave_calculation}`
    },
  },
  {
    Header: 'LEAVE TYPE',
    accessor: (row: any) => {
      return `${row.leave_type === '' ? '-' : row.leave_type}`
    },
  },
  {
    Header: 'REASON',
    Cell: (props: any) => {
      const notesObject = {
        noteDetail: props?.row?.original?.reason,
      }
      return (
        <>
          {notesObject?.noteDetail ? (
            <span
              style={{
                color: 'var(--blue1)',
                cursor: 'pointer',
                fontSize: 'var(--font-12)',
                fontWeight: 'var(--font-normal)',
              }}
              onClick={() => props.onClick(notesObject)}
            >
              VIEW
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'FILE',
    Cell: (props: any) => {
      const noteDetail = props?.row?.original?.file
      return (
        <>
          {noteDetail ? (
            <span
              style={{
                color: 'var(--blue1)',
                cursor: 'pointer',
                fontSize: 'var(--font-12)',
                fontWeight: 'var(--font-normal)',
              }}
              onClick={() => props.onRowClick(noteDetail)}
            >
              VIEW
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'APPLIED ON',
    accessor: (row: any) => {
      return moment(row?.createdAt).format('DD-MMM-YYYY') ?? '-'
    },
  },
  {
    Header: 'STATUS',
    disableSortBy: true,
    accessor: 'status',
    Cell: ({ row }: any) => {
      const dispatch = useAppDispatch()
      const { allLeaveData } = useAppSelector((state) => state.leave)
      const handleToggle = (item: any) => {
        let itemActive = item.status === 'PAID' ? 'UNPAID' : 'PAID'
        const payload = {
          status: itemActive,
          id: item._id,
        }
        dispatch(updateLeaveStatus(requestGenerator(payload))).then((e) => {
          let payloadData = {
            is_active: true,
            page: 1,
            pageSize: 10,
          }
          dispatch(getAllLeave(requestGenerator(payloadData)))
        })
      }
      return (
        <>
          {/* <ToggleSwitchV2
            isToggled={row?.original?.status === 'PAID' ? true : false}
            handleToggle={() => handleToggle(row?.original)}
          /> */}
          <p>{row?.original?.status}</p>
        </>
      )
    },
  },
  {
    Header: 'ACTIONS',
    Cell: ({ row, onOpen, onAction }: any) => {
      const today = moment(new Date()).format('DD-MMM-YYYY')
      const date = moment(row?.original?.createdAt).format('DD-MMM-YYYY')
      const dispatch = useAppDispatch()
      const handleDelete = () => {
        onOpen({
          isDeleteDialogOpen: true,
          id: row?.original?._id,
        })
      }
      const navigate = useNavigate()
      const handleEdit = (item: any) => {
        if (item.status) {
          navigate('apply-leave', {
            state: { user: item },
          })
        }
      }
      const handleApprove = (item: any) => {
        if (row?.original?.is_approved === 'INITIATE') {
          const payload = {
            id: row?.original?._id,
            is_approved: 'APPROVED',
          }
          dispatch(updateLeaveStatus(requestGenerator(payload))).then((e) => {
            if (e.type === `${UPDATE_LEAVE_STATUS}/fulfilled`) {
              let payloadData = {
                is_active: true,
                page: 1,
                pageSize: 100,
              }
              dispatch(getAllLeave(requestGenerator(payloadData)))
            }
          })
        }
      }
      const handleReject = (item: any) => {
        if (row?.original?.is_approved === 'INITIATE') {
          const payload = {
            id: row?.original?._id,
            is_approved: 'REJECTED',
          }
          dispatch(updateLeaveStatus(requestGenerator(payload))).then((e) => {
            if (e.type === `${UPDATE_LEAVE_STATUS}/fulfilled`) {
              let payloadData = {
                is_active: true,
                page: 1,
                pageSize: 100,
              }
              dispatch(getAllLeave(requestGenerator(payloadData)))
            }
          })
        }
      }
      return (
        <>
          <div className={styles.leaveManagementTableActions}>
            {(row?.original?.is_approved === 'APPROVED' ||
              row?.original?.is_approved === 'INITIATE') && (
              <span className={styles.iconStyle}>
                <CheckRoundIcon
                  fillColor={
                    row?.original?.is_approved === 'APPROVED'
                      ? colors.green1
                      : colors.grey4
                  }
                  handleClick={() => {
                    handleApprove(row?.original)
                  }}
                />
              </span>
            )}

            {(row?.original?.is_approved === 'REJECTED' ||
              row?.original?.is_approved === 'INITIATE') && (
              <span className={styles.iconStyle}>
                <CloseIcon
                  fillColor={
                    row?.original?.is_approved === 'REJECTED'
                      ? colors.red1
                      : colors.grey4
                  }
                  handleClick={() => {
                    // if (today === date) {
                    handleReject(row?.original)
                    // }
                  }}
                />
              </span>
            )}
            <span className={styles.iconStyle}>
              <EditIcon
                fillColor={colors.grey4}
                handleClick={() => {
                  if (row?.original?.is_approved === 'INITIATE') {
                    handleEdit(row?.original)
                  }
                }}
              />
            </span>
            <span className={styles.iconStyle}>
              <DeleteIcon
                fillColor={colors.grey4}
                customClass={styles.deleteIcon}
                // handleClick={handleDelete}
                handleClick={() => {
                  if (row?.original?.is_approved === 'INITIATE') {
                    handleDelete()
                  }
                }}
              />
            </span>
          </div>
        </>
      )
    },
  },
]
