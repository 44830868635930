import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/button/Button";
import {
  addIpdTimelineNote,
  ipdHistory,
} from "../../redux/features/ipd/ipdAsyncActions";
import { requestGenerator } from "../../utils/payloadGenerator";
import moment from "moment";
import styles from "../../pages/ipd/ipd-dashboard/ipdDashboard.module.scss";

export const openCaseIpdDashboardTableHeaderData: any = [
  {
    Header: "FILE NO.",
    accessor: (row: any) => {
      return row?.emr_no ?? "-";
    },
  },
  {
    Header: "CASE NO.",
    accessor: (row: any) => {
      return row?.case_no ?? "-";
    },
  },
  {
    Header: "PATIENT NAME",
    accessor: (row: any) => {
      return row?.patient_name ?? "-";
    },
  },
  {
    Header: "CASE DESCRIPTION",
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.case_description ? (
            <span
              className={styles.viewLink}
              onClick={() => props.onClick(props?.row?.original)}
            >
              View
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "DEPARTMENT",
    accessor: (row: any) => {
      return row?.department_name ?? "-";
    },
  },
  {
    Header: "WARD",
    accessor: (row: any) => {
      return row?.ward_name ?? "-";
    },
  },
  {
    Header: "ROOM",
    accessor: (row: any) => {
      return row?.room_name ?? "-";
    },
  },
  {
    Header: "BED",
    accessor: (row: any) => {
      return row?.bed_name ?? "-";
    },
  },
  {
    Header: "ATTEND",
    Cell: (props: any) => {
      const dispatch = useAppDispatch();
      const handleNotesDispatch = () => {
        let payloadData = {
          ipd_id: props?.row?.original?._id,
          patient_id: props?.row?.original?.patient_id,
          date: moment().format("DD/MM/YYYY"),
          notes: [],
        };

        dispatch(addIpdTimelineNote(requestGenerator(payloadData))).then(
          (res) => {
            if (res.type === "ipd/addIpdTimelineNotes/fulfilled") {
              navigate("/ipd/createipdmainlayout/consultation", {
                state: {
                  patient_id: props?.row?.original?.patient_id,
                  ipd_id: props?.row?.original?._id,
                },
              });
            }
          }
        );
      };

      const navigate = useNavigate();
      return (
        <Button
          title="Attend"
          customClass={styles.buttonOutline}
          type="button"
          handleClick={() => handleNotesDispatch()}
        />
      );
    },
  },
  {
    Header: "DISCHARGE",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.discharged === true ? (
            <span>Yes</span>
          ) : props?.row?.original?.discharged === false ? (
            <span>No</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "IPD HISTORY",
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch();
      const handleIpdHistory = () => {
        let data = {
          ipd_case_id: props?.row?.original?._id,
        };
        dispatch(ipdHistory(requestGenerator(data)));
      };
      return (
        <span
          className={styles.viewLink}
          onClick={() => (handleIpdHistory(), props.onRowClick())}
        >
          View
        </span>
      );
    },
  },
];

export const closeCaseIpdDashboardTableHeaderData: any = [
  {
    Header: "FILE NO.",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.emr_no ? (
            <span>{props?.row?.original?.emr_no}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "CASE NO.",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.case_no ? (
            <span>{props?.row?.original?.case_no}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "PATIENT NAME",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.patient_name ? (
            <span>{props?.row?.original?.patient_name}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "CASE DESCRIPTION",
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.case_description ? (
            <span
              className={styles.viewLink}
              onClick={() => props.onClick(props?.row?.original)}
            >
              View
            </span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "DEPARTMENT",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.department_name ? (
            <span>{props?.row?.original?.department_name}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "WARD",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.ward_name ? (
            <span>{props?.row?.original?.ward_name}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "ROOM",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.room_name ? (
            <span>{props?.row?.original?.room_name}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "BED",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.bed_name ? (
            <span>{props?.row?.original?.bed_name}</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "DISCHARGE",
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.discharged === true ? (
            <span>Yes</span>
          ) : props?.row?.original?.discharged === false ? (
            <span>No</span>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    Header: "IPD HISTORY",
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch();

      const handleIpdHistory = () => {
        let data = {
          ipd_case_id: props?.row?.original?._id,
        };
        dispatch(ipdHistory(requestGenerator(data)));
      };
      return (
        <span
          className={styles.viewLink}
          onClick={() => (handleIpdHistory(), props.onRowClick())}
        >
          View
        </span>
      );
    },
  },
];
