import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Tabs from '../../components/core/tab/Tabs'
import { AgentsFloatingBarData, agentleadsTabData } from '../../constants/data'
import FloatingBar from '../../components/common/floatingbar/FloatingBar'
import { lazy, Suspense } from 'react'
import { ErrorBoundary } from '../../components/core/error-boundary/ErrorBoundary'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { callingApi } from '../../redux/features/agents/agentAsyncActions'
import { requestGenerator } from '../../utils/payloadGenerator'
import { Device } from 'twilio-client'
import {
  setAgentLeadType,
  setCallStatus,
  setDevice,
} from '../../redux/features/agents/agentSlice'
import Loader from '../../components/common/spinner/Loader'
import styles from './agentdashboardlayout.module.scss'

const LazyLoadFilterContainer = lazy(
  () => import('./assigned-campaigns/FilterContainer')
)

const AgentDashboardLayout = () => {
  const { generateToken } = useAppSelector((state) => state.agent)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (location.pathname === '/agent') {
      navigate('/agent/agentleads')
    }
  }, [location, navigate])

  useEffect(() => {
    dispatch(callingApi(requestGenerator({ identity: 'Twilio' })))
  }, [dispatch])

  useEffect(() => {
    if (generateToken?.length) {
      const device: any = new Device()
      device?.setup(generateToken, { debug: true })

      device.on('ready', () => {
        dispatch(setDevice(device))
        dispatch(setCallStatus('calling'))
      })
      device.on('connect', (connection: any) => {
        // setConn(connection);
        dispatch(setCallStatus('oncall'))
      })
      device.on('disconnect', () => {
        dispatch(setCallStatus('disconnected'))
        // setConn(null);
      })
      device.on('incoming', (connection: any) => {
        dispatch(setCallStatus('incoming'))
        // setConn(connection);
        connection.on('reject', () => {
          dispatch(setCallStatus('receivecall'))
          // setConn(null);
        })
      })
      device.on('cancel', () => {
        dispatch(setCallStatus('rejecting'))
        // setConn(null);
      })
      device.on('reject', () => {
        dispatch(setCallStatus('rejected'))
        // setConn(null);
      })

      return () => {
        device.destroy()
        dispatch(setDevice(null))
        dispatch(setCallStatus('Offline'))
      }
    }
  }, [dispatch, generateToken])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.tabContainer}>
        {location?.pathname === '/agent/agentleads' && (
          <ErrorBoundary fallback={<>error occured....</>}>
            <Suspense fallback={<Loader />}>
              <LazyLoadFilterContainer />
            </Suspense>
          </ErrorBoundary>
        )}
        <Tabs tabData={agentleadsTabData} />
        <Outlet />
      </div>
      {/* <div className={styles.floatingBarContainer}>
        <FloatingBar floatingBarData={AgentsFloatingBarData} />
      </div> */}
    </div>
  )
}
export default AgentDashboardLayout
