export const ViewServicesTableData: any = [
  {
    Header: "PROCEDURE",
      Cell: (props: any) => {
        return <>{props.row.original.procedure_id.name ?? "-"}</>;
      },
  },
  {
    Header: "PROCEDURE SUBTYPE",
      Cell: (props: any) => {
        return <>{props.row.original.procedure_id.procedure_subtype ?? "-"}</>;
      },
  },
  {
    Header: "SERVICE",
      Cell: (props: any) => {
        return <>{props.row.original?.service_id?.name ?? "-"}</>;
      },
  },
  {
    Header: "SELECTED TOOTHS",
    Cell: (props: any) => {
      let tooth_numbers = props.row.original.selected_tooths ? props.row.original.selected_tooths.map((item:any)=>item.display_tooth_number).join(','):' - ';
      return <>{tooth_numbers}</>;
    },
  },
  {
    Header: "SESSIONS",
    Cell: (props: any) => {
      return <>{props.row.original.sessions ?? ""}</>;
    },
  },
  {
    Header: "SESSION#",
    Cell: (props: any) => {
      return <>{props.row.original.sessionsIndex ?? ""}</>;
    },
  },
  {
    Header: "STATUS",
    Cell: (props: any) => {
      return <div style={{textTransform: 'capitalize'}}>{props.row.original.status ?? ""}</div>;
    },
  },
  {
    Header: "QUANTITY",
    Cell: (props: any) => {
      return <>{props.row.original.quantity ?? "- "}</>;
    },
  },
  {
    Header: "UNIT PRICE",
    Cell: (props: any) => {
      return <>{props.row.original.unit_price ?? " - "}</>;
    },
  },
  {
    Header: "PRICE",
    Cell: (props: any) => {
      return <>{props.row.original.price ?? " - "}</>;
    },
  },
  {
    Header: "DISCOUNT",
    Cell: (props: any) => {
      return <>{props.row.original.discount ?? " - "}</>;
    },
  },
  {
    Header: "ATTENDED BY",
    Cell: (props: any) => {
      return <>{props.row.original.attended_by_id.name ?? ""}</>;
    },
  },
];
