import { FC, useEffect, useState } from 'react';
import styles from './consultationNotesPopup.module.scss';
import TableV2 from '../../table/tableV2/TableV2';
import Button from '../../button/Button';
import Divider from '../../divider/Divider';
import { CloseIcon } from '../../svg-components';
import { colors } from '../../../../constants/color';
import { IConsultationNotesForm } from '../../../../interfaces/interfaces';
import {
  CONSULTATION_NOTES,
  CONSULTATION_NOTES_VIEW,
} from '../../../../constants/constant';
import { useForm } from 'react-hook-form';
import { trimValue } from '../../../../utils/utils';
import {
  consultationPopupNotesHeaderData,
  consultationTable,
} from '../../../../constants/table-data/consultationTableData';
import Popup from '../../popup/Popup';
import NotesPopup from '../notes-popup/NotesPopup';
import { requestGenerator } from '../../../../utils/payloadGenerator';
import {
  addIpdTimelineNote,
  deleteIpdTimelineNote,
  editIpdTimelineNote,
  getAllIpdMedication,
} from '../../../../redux/features/ipd/ipdAsyncActions';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import Loader from '../../spinner/Loader';
import DeleteMedicationPopup from '../delete-medication-popup/DeleteMedicationPopup';
import moment from 'moment';

interface IConsultationNotesPopup {
  handleClose?: any;
  handleOpen?: any;
  popData?: any;
}

const ConsultationNotesPopup: FC<IConsultationNotesPopup> = ({
  handleClose,
  handleOpen,
  popData,
}) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.ipd);
  const [notesData, setNotesData] = useState<any>(popData);
  const [noteInfo, setNoteInfo] = useState<any>({});
  const [showDelete, setShowDelete] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IConsultationNotesForm>({});

  const formData = watch();

  useEffect(() => {
    if (
      formData[CONSULTATION_NOTES_VIEW] === undefined ||
      formData[CONSULTATION_NOTES_VIEW] === null
    ) {
      setValue(CONSULTATION_NOTES_VIEW, 'NEW');
    }
  }, [formData[CONSULTATION_NOTES_VIEW]]);

  useEffect(() => {
    if (formData[CONSULTATION_NOTES_VIEW] === 'VIEW') {
      setValue(CONSULTATION_NOTES_VIEW, 'VIEW');
      setValue(CONSULTATION_NOTES, '');
    }
  }, [formData[CONSULTATION_NOTES_VIEW]]);

  useEffect(() => {
    if (noteInfo?._id !== undefined) {
      setValue(CONSULTATION_NOTES, noteInfo?.note);
    }
  }, [noteInfo]);

  const onSubmit = async (data: IConsultationNotesForm) => {
    let payloadData = {
      ipd_id: popData?.ipd_id,
      patient_id: popData?.patient_id,
      date: popData?.date,
      notes: [
        {
          note: data?.notes,
          time: moment(new Date()).format('hh:mm:ss'),
        },
      ],
    };

    if (noteInfo?._id !== undefined) {
      let reqData = {
        ipd_id: popData?.ipd_id,
        date: popData?.date,
        note_id: noteInfo?._id,
        note: data?.notes,
        time: moment(new Date()).format('hh:mm:ss'),
      };
      dispatch(editIpdTimelineNote(requestGenerator(reqData))).then((e) => {
        if (e.type === 'ipd/editIpdTimelineNotes/fulfilled') {
          reset();
          setNoteInfo({});
          dispatch(
            getAllIpdMedication(
              requestGenerator({
                ipd_id: popData?.ipd_id,
                patient_id: popData?.patient_id,
              })
            )
          ).then((e) => {
            setNotesData(
              e.payload?.filter((item: any) => item?._id === popData?._id)[0]
            );
          });
        }
      });
    } else {
      dispatch(addIpdTimelineNote(requestGenerator(payloadData))).then((e) => {
        if (e.type === 'ipd/addIpdTimelineNotes/fulfilled') {
          reset();
          dispatch(
            getAllIpdMedication(
              requestGenerator({
                ipd_id: popData?.ipd_id,
                patient_id: popData?.patient_id,
              })
            )
          ).then((e) => {
            setNotesData(
              e.payload?.filter((item: any) => item?._id === popData?._id)[0]
            );
          });
        }
      });
    }
  };

  const createTestValidators = {
    [CONSULTATION_NOTES]: {
      required: 'Please enter notes',
    },
  };

  const handleEdit = (item: any) => {
    setNoteInfo(item);
    setValue(CONSULTATION_NOTES_VIEW, 'NEW');
  };

  const handleDelete = () => {
    let payloadData = {
      ipd_id: popData?.ipd_id,
      date: popData?.date,
      note_id: deletedId,
    };
    dispatch(deleteIpdTimelineNote(requestGenerator(payloadData))).then((e) => {
      if (e.type === 'ipd/deleteIpdTimelineNotes/fulfilled') {
        setShowDelete(false);
        setDeletedId('');

        dispatch(
          getAllIpdMedication(
            requestGenerator({
              ipd_id: popData?.ipd_id,
              patient_id: popData?.patient_id,
            })
          )
        ).then((e) => {
          setNotesData(
            e.payload?.filter((item: any) => item?._id === popData?._id)[0]
          );
        });
      }
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      {showDelete && (
        <Popup
          Children={DeleteMedicationPopup}
          handleClose={() => setShowDelete(false)}
          handleNo={() => setShowDelete(false)}
          handleYes={() => handleDelete()}
        />
      )}
      <div
        className={styles.mainContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.uploadContainer}>
          <p className={styles.title}>Consultation Notes</p>
          <Divider customClass={styles.dividerStyle} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.radioBtnContainer}>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="new" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="new"
                    value="NEW"
                    {...register(CONSULTATION_NOTES_VIEW)}
                  />
                  <span className={styles.customRadio} />
                  <p className={styles.radioLabelTxt}>New</p>
                </label>
              </div>
              <div className={styles.singlRadioBtnConatainer}>
                <label htmlFor="view" className={styles.radioLabel}>
                  <input
                    className={styles.radioInput}
                    type="radio"
                    id="view"
                    value="VIEW"
                    {...register(CONSULTATION_NOTES_VIEW)}
                  />
                  <span className={styles.customRadio} />
                  <p className={styles.radioLabelTxt}>View</p>
                </label>
              </div>
            </div>
            {formData[CONSULTATION_NOTES_VIEW] === 'NEW' ? (
              <>
                <div className={styles.labelField}>
                  <label className={styles.labelText}>
                    Notes <span className="asterick">*</span>
                  </label>
                  <div className={styles.fieldErrorContainer}>
                    <textarea
                      className={styles.inputField}
                      placeholder="Enter notes"
                      {...register(
                        CONSULTATION_NOTES,
                        createTestValidators[CONSULTATION_NOTES]
                      )}
                      onChange={(e) => trimValue(e)}
                    />
                    {errors[CONSULTATION_NOTES] && (
                      <p className="errorText">
                        {errors[CONSULTATION_NOTES]?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.btnContainer}>
                  {noteInfo?._id !== undefined ? (
                    <Button title={'Update'} />
                  ) : (
                    <Button
                      title={'Add'}
                      disable={
                        moment(new Date()).format('DD/MM/YYYY') ===
                        popData?.date
                          ? false
                          : true
                      }
                    />
                  )}
                </div>
              </>
            ) : (
              <div className={styles.tableContainer}>
                <TableV2
                  tableHeaderData={consultationPopupNotesHeaderData}
                  tableRowData={
                    notesData?.notes?.length > 0 ? notesData?.notes : []
                  }
                  handleRowClick={handleOpen}
                  setModelOpenClose={(id: any) => {
                    setShowDelete(true);
                    setDeletedId(id);
                  }}
                  handleClick={handleEdit}
                  active={false}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ConsultationNotesPopup;
