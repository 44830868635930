import React, { FC } from 'react';
import styles from './ipdTestlistPopup.module.scss';
import { CloseIcon } from '../../svg-components';
import { colors } from '../../../../constants/color';
import Divider from '../../divider/Divider';
import { ipdTestlistPopupData } from '../../../../constants/table-data/ipdTestlistPopupData';
import TableV2 from '../../table/tableV2/TableV2';

interface IIpdTestlistPopup {
  handleClose?: any;
  popData?: any;
}

const IpdTestListPopup: FC<IIpdTestlistPopup> = ({ handleClose, popData }) => {
  return (
    <>
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>Test</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.tabelContainer}>
            <TableV2
              tableHeaderData={ipdTestlistPopupData}
              tableRowData={popData}
              active={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IpdTestListPopup;
