import { FC, useEffect } from 'react'
import { colors } from '../../../../constants/color'
import styles from './employeeleave.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { CloseIcon } from '../../../../components/common/svg-components'
import Divider from '../../../../components/common/divider/Divider'
import TableV3 from '../../../../components/common/table/tableV3/TableV3'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { Cols } from '../../../../interfaces/interfaces'
import { employeeleaveData } from '../../../../constants/data'
import { getMonthlyEmployeeAttendanceAction } from '../../../../redux/features/attendance/attendanceAsyncAction'
interface IEmployeeLeavePopup {
  popData?: any
  handleClose?: any
  heading?: any
}
const EmployeeLeavePopup: FC<IEmployeeLeavePopup> = ({
  popData,
  handleClose,
  heading,
}) => {
  // For Table
  const data: Cols[] =
    popData?.attendance?.length > 0 ? popData?.attendance : []
  const columns: Column<Cols>[] = employeeleaveData
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)

  const presentDays = popData?.attendance?.filter((item: any) => {
    return item?.attendance === 'PRESENT'
  })

  const absentDays = popData?.attendance?.filter((item: any) => {
    return item?.attendance === 'ABSENT' && item?.halfday === false
  })

  const absentHalfDays = popData?.attendance?.filter((item: any) => {
    return item?.attendance === 'ABSENT' && item?.halfday === true
  })
  const totalPresent =
    Number(presentDays?.length ?? 0) + Number(absentHalfDays?.length ?? 0) * 0.5
  const totalAbsent =
    Number(absentDays?.length ?? 0) + Number(absentHalfDays?.length ?? 0) * 0.5

  return (
    <>
      <div
        className={styles.deletePopupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <div className={styles.deleteContainer}>
          <p className={styles.title}>Employee Attendance</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.head}>
            <label>Month:</label>
            <span>{heading}</span>
          </div>
          <div className={styles.details}>
            <label>Employee Name</label>
            {/* <p>{name_en}</p> */}
            <input disabled value={popData?.name_en} />
            <label>Total Present</label>
            <input disabled value={totalPresent ?? 0} /> <br />
            <label>Total Absent</label>
            <input disabled value={totalAbsent ?? 0} />
          </div>
          <div className={styles.confirmationContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default EmployeeLeavePopup
