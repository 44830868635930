import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../button/Button'
import { AssetsArray } from '../../../../constants/data'
import { trimValue } from '../../../../utils/utils'
import {
  IaddLevel3Validators,
  addLevel3Validators,
} from '../../../../form-validators/addLevel3Validators'
import {
  Level3_CHECK,
  Level3_CODE,
  Level3_LIST,
  Level3_NAME,
} from '../../../../constants/constant'
import { useLocation } from 'react-router-dom'
import {
  addAsset,
  getAllLevelData,
  updateAsset,
} from '../../../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import styles from './Level3Modal.module.scss'
import Loader from '../../spinner/Loader'
import {
  ADD_ASSETDATA,
  UPDATE_ASSETDATA,
} from '../../../../constants/asyncActionsType'
import { setGenerateCode } from '../../../../redux/features/view-chat-of-accounts/accountsSlice'
interface ILevel3ModalProps {
  handleClose?: any
  popData?: string | any
  formData?: any
  handleSuccess?: any
}

const Level3Modal: FC<ILevel3ModalProps> = ({
  handleClose,
  popData,
  formData,
  handleSuccess,
}) => {
  const dispatch = useAppDispatch()
  const [data, setData] = useState(false)
  const { dropdownAttempt, getGenerateData, loading } = useAppSelector(
    (state) => state.accounts
  )
  const location = useLocation()
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IaddLevel3Validators>({})

  const onSubmit: SubmitHandler<IaddLevel3Validators> = (data) => {
    const init = {
      name: data?.name,
      code: getGenerateData?.code,
      parentLevelTypeId: location?.state?.levelID?.parent_Level_Type_Id,
      isFinalLevel: data?.isFinalLevel,
      creditOrDebit: data?.creditOrDebit,
      levelId: 3,
      level_one: getGenerateData?.code?.toString().substring(0, 1),
      level_two: getGenerateData?.code?.toString().substring(0, 3),
    }
    if (formData?._id) {
      const editPaylod = {
        id: formData?._id,
        data: init,
      }
      dispatch(updateAsset(requestGenerator(editPaylod))).then((e) => {
        if (e.type === `${UPDATE_ASSETDATA}/fulfilled`) {
          handleSuccess && handleSuccess()
        }
      })
    } else {
      dispatch(addAsset(requestGenerator(init))).then((e) => {
        if (e.type === `${ADD_ASSETDATA}/fulfilled`) {
          handleSuccess && handleSuccess()
        }
      })
    }
    handleClose()
  }

  const handleSetClick = () => {
    setData(!data)
  }
  const handleReset = () => {
    reset()
  }
  useEffect(() => {
    if (formData?.name) {
      setValue(Level3_NAME, formData?.name)
      setData(formData?.isFinalLevel)
      setValue(Level3_CHECK, formData?.isFinalLevel)
      setValue(Level3_LIST, formData?.creditOrDebit)
    }
  }, [formData])
  useEffect(() => {
    return () => {
      dispatch(setGenerateCode({}))
    }
  }, [])
  return (
    <>
      {loading && <Loader />}
      <div>
        <h1 className={styles.levelModalHeading}>
          {popData ? `Add ${dropdownAttempt?.name} at Level-3` : ''}
        </h1>
        <hr className={styles.levelDivider} />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.addLevelForm}>
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabel}> Code </label>
              <input
                type="text"
                placeholder="Code"
                value={getGenerateData?.code}
                disabled
                {...register(Level3_CODE, addLevel3Validators[Level3_CODE])}
                className={styles.inputField}
              />
              <div className={styles.checkboxContainerBlank}></div>
            </div>
          </div>

          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabels}>
                {' '}
                Name<span className="asterick">*</span>{' '}
              </label>
              <input
                type="text"
                placeholder="Enter Name"
                className={styles.inputFields}
                {...register(Level3_NAME, addLevel3Validators[Level3_NAME])}
                onChange={(e) => trimValue(e)}
              />
            </div>

            <div className={styles.errorContainer}>
              <span className={styles.extraSpan}></span>
              {errors[Level3_NAME] && (
                <p className="dashboardFormError">
                  {errors[Level3_NAME].message}
                </p>
              )}
            </div>
          </div>

          <div className={styles.formFieldContainer}>
            <div className={styles.checkContainer}>
              <label className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  {...register(Level3_CHECK, addLevel3Validators[Level3_CHECK])}
                  className={styles.checkboxField}
                />
                <span
                  className={styles.checkboxLabel}
                  onClick={handleSetClick}
                ></span>
              </label>
              <span className={styles.checkboxLabelText}>
                It is a final Level
              </span>
            </div>
          </div>
          {data === true ? (
            <>
              <div className={styles.formFieldContainer}>
                <div className={styles.inputFieldContainer}>
                  <label className={styles.formLabels}></label>
                  <select
                    className={styles.selectInputField}
                    {...register(Level3_LIST, addLevel3Validators[Level3_LIST])}
                  >
                    <option value="">Select Debit or Credit</option>
                    {AssetsArray?.map((AssetsArray, index) => (
                      <option value={AssetsArray} key={index}>
                        {AssetsArray}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpanSelect}></span>
                  {errors[Level3_LIST] && (
                    <p className="dashboardFormError">
                      {errors[Level3_LIST].message}
                    </p>
                  )}
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          <div className={styles.formButtonContainer}>
            <Button
              title="Submit"
              type="submit"
              customClass={styles.submitButton}
            />
            <Button
              title="Reset"
              type="reset"
              handleClick={() => handleReset()}
              customClass={styles.resetButton}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default Level3Modal
