import TableV2 from "../../../../components/common/table/tableV2/TableV2";
import { ipdViewAllData } from "./table-data";
import { useState, useEffect } from "react";
import { CustomModal } from "../../../../components/common/custom-modal/modal";
import ConsultationModal from "./consultation-modal/page";
import IpdTestsModal from "./ipd-tests-modal/page";
import MedicationModal from "./medication-modal/page";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { requestGenerator } from "../../../../utils/payloadGenerator";
import { ipdHistory } from "../../../../redux/features/ipd/ipdAsyncActions";
import { useLocation } from "react-router-dom";
import Loader from "../../../../components/common/spinner/Loader";

const IpdCaseViewAll = () => {
  const [consultationModal, setConsultationModal] = useState(false);
  const [consultationData, setConsultationData] = useState([]);
  const [testModal, setTestModal] = useState(false);
  const [testData, setTestData] = useState([]);
  const [medicationModal, SetMedicationModal] = useState(false);
  const [medicationData, setMedicationData] = useState([]);
  const handleConsultationClick = (items: any) => {
    setConsultationData(items);
    setConsultationModal(true);
  };

  const location = useLocation()?.state;
  const { isLoading, ipdViewHistory } = useAppSelector((state) => state.ipd);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (location?.ipd_id) {
      let data = {
        ipd_case_id: location?.ipd_id,
      };
      dispatch(ipdHistory(requestGenerator(data)));
    }
  }, [dispatch, location?.ipd_id]);

  const handleTest = (items: any) => {
    setTestModal(true);
    setTestData(items);
  };

  const handleMeication = (items: any) => {
    setMedicationData(items);
    SetMedicationModal(true);
  };
  return (
    <>
      {isLoading && <Loader />}
      <CustomModal
        showModal={consultationModal}
        closeModal={() => setConsultationModal(false)}
        title="Consultation Notes"
        width="60%"
        height="480px"
      >
        <ConsultationModal popData={consultationData} />
      </CustomModal>
      <CustomModal
        showModal={testModal}
        closeModal={() => setTestModal(false)}
        title="Tests Notes"
        width="60%"
        height="480px"
      >
        <IpdTestsModal popData={testData} />
      </CustomModal>
      <CustomModal
        showModal={medicationModal}
        closeModal={() => SetMedicationModal(false)}
        title="Medications Notes"
        width="60%"
        height="480px"
      >
        <MedicationModal popData={medicationData} />
      </CustomModal>
      <div style={{ marginTop: "40px" }}>
        <TableV2
          tableHeaderData={ipdViewAllData}
          tableRowData={ipdViewHistory ?? []}
          active={false}
          handleRowClick={(item: any) => handleConsultationClick(item)}
          handleClick={(item: any) => handleTest(item)}
          setModelOpenClose={(item: any) => handleMeication(item)}
        />
      </div>
    </>
  );
};

export default IpdCaseViewAll;
