import { useEffect, useState } from 'react'
import { Label } from '../../components/common/label'
import Select from 'react-select'
import Loader from '../../components/common/spinner/Loader'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../components/common/button/Button'
import { requestGenerator } from '../../utils/payloadGenerator'
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { IDynamicReportBuilderForm } from '../../interfaces/dynamicReportBuilderInterfaces'
import {
  DYNAMIC_REPORT_NAME,
  DYNAMIC_REPORT_START_DATE,
  DYNAMIC_REPORT_END_DATE,
  DYNAMIC_REPORT_BRANCH,
  DYNAMIC_REPORT_DOCTOR,
  DYNAMIC_REPORT_PATIENT,
} from '../../constants/dynamicReportBuilderConstant'
import { dynamicReportBuilderValidators } from '../../form-validators/dynamicReportBuilderValidators'
import {
  getReportsList,
  getDynamicReportQueryData,
} from '../../redux/features/role/roleAsynActions'
import { getAllDoctors } from '../../redux/features/appointments/bookingAppointmentAsyncActions'
import { getAllPatientList } from '../../redux/features/patient-emr/patient/patientAsyncAction'
import { Input } from '../../components/common/input/input'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import Popup from '../../components/common/popup/Popup'
import ReportsNotesModal from '../reports-poc/ReportsNotesModal'
import styles from './DynamicReportBuilder.module.scss'

const DynamicReportBuilder = () => {
  const dispatch = useAppDispatch()
  const { isLoading, reportTypeList } = useAppSelector(
    (state) => state.roleUser
  )
  const { branchData } = useAppSelector((state) => state.login)
  const { doctorData } = useAppSelector((state) => state.appointments)
  const { patientListData } = useAppSelector((state) => state.patient)
  const [teableHeaderData, setTableHeaderData] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [selectedRowData, setSelectedRowData] = useState<any>({})
  const [showReportsNotesModal, setShowReportsNotesModal] = useState<any>(false)

  // React Hook form for the form handling
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IDynamicReportBuilderForm>({})

  const { dynamic_report_name } = watch()
  const onSubmit: SubmitHandler<IDynamicReportBuilderForm> = (data) => {
    let selectedBranchIds: any = []
    if (data?.branch?.length > 0) {
      selectedBranchIds = data?.branch?.map(
        (selectedBranchObject: any) => selectedBranchObject?.value
      )
    } else {
      selectedBranchIds = null
    }
    let selectedDoctorIds: any = []
    if (data?.doctor?.length > 0) {
      selectedDoctorIds = data?.doctor?.map(
        (selectedDoctorObject: any) => selectedDoctorObject?.value
      )
    } else {
      selectedDoctorIds = null
    }
    let selectedPatientIds: any = []
    if (data?.patient?.length > 0) {
      selectedPatientIds = data?.patient?.map(
        (selectedPatientObject: any) => selectedPatientObject?.value
      )
    } else {
      selectedPatientIds = null
    }

    const genrateReportPayload = {
      id: data?.dynamic_report_name?._id,
      filter: {
        fromDate: data?.start_date,
        toDate: data?.end_date,
        branch_id: selectedBranchIds,
        doctor_id: selectedDoctorIds,
        patient_id: selectedPatientIds,
      },
    }
    dispatch(
      getDynamicReportQueryData(requestGenerator(genrateReportPayload))
    ).then((res) => {
      if (res.type === 'role/getDynamicReportByQuery/fulfilled') {
        setTableData(res.payload)
      }
    })
  }

  // useEffect for get report type list
  useEffect(() => {
    const requestDataForDoctorsList = {
      is_active: true,
      page: 1,
      pageSize: 1000,
    }
    const requestDataForPatientList = {
      search: '',
      page: 1,
      pageSize: 1000,
      is_active: true,
    }
    dispatch(getReportsList(requestGenerator({})))
    dispatch(getAllDoctors(requestGenerator(requestDataForDoctorsList)))
    dispatch(getAllPatientList(requestGenerator(requestDataForPatientList)))
  }, [dispatch])

  // useEffect for table
  useEffect(() => {
    if (dynamic_report_name?.table_headers?.length > 0) {
      const genrateHeader = dynamic_report_name?.table_headers?.map(
        (headerKey: any) => {
          return {
            Header: headerKey,
            Cell: ({ row }: any) => {
              let rowData = row?.original?.[headerKey]
              if (typeof rowData === 'string' || typeof rowData === 'number') {
                return <p>{rowData ? rowData : '-'}</p>
              } else if (typeof rowData === 'object') {
                return (
                  <p
                    style={{ color: '#0e26a3', textDecoration: 'underline' }}
                    onClick={() => {
                      setSelectedRowData(rowData)
                      setShowReportsNotesModal((prevState: any) => !prevState)
                    }}
                  >
                    View
                  </p>
                )
              } else {
                return <p>{'-'}</p>
              }
            },
          }
        }
      )
      setTableHeaderData(genrateHeader)
    }
  }, [dynamic_report_name])

  // function for notes modal close
  const reportsNotesModalClose = () => {
    setSelectedRowData({})
    setShowReportsNotesModal((prevState: any) => !prevState)
  }

  return (
    <>
      {isLoading && <Loader />}
      {showReportsNotesModal && (
        <Popup
          Children={ReportsNotesModal}
          handleClose={reportsNotesModalClose}
          popData={selectedRowData}
        />
      )}
      <form
        className={styles.dynamicReportBuilderFormContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.dynamicReportTypeContainer}>
          <div className={styles.inputFieldContainer}>
            <Label
              labelText={`Report Type`}
              requiredField={true}
              flexBasis="30%"
            />
            <div className={styles.inlineItems}>
              <Select
                className={styles.selectInputField}
                placeholder={`Select Report Type`}
                closeMenuOnSelect={true}
                components={{ DropdownIndicator }}
                {...register(
                  DYNAMIC_REPORT_NAME,
                  dynamicReportBuilderValidators[DYNAMIC_REPORT_NAME]
                )}
                value={watch(DYNAMIC_REPORT_NAME)}
                options={reportTypeList?.map((reportDetail: any) => {
                  return {
                    label: reportDetail?.name,
                    value: reportDetail?._id,
                    ...reportDetail,
                  }
                })}
                onChange={(e: any) => {
                  const resetObject = {
                    start_date: '',
                    end_date: '',
                    branch: null,
                    doctor: null,
                    patient: null,
                  }
                  reset(resetObject)
                  setValue(DYNAMIC_REPORT_NAME, e)
                  trigger(DYNAMIC_REPORT_NAME)
                  setTableData([])
                }}
                isSearchable={false}
                maxMenuHeight={200}
              />
              {errors[DYNAMIC_REPORT_NAME] && (
                <p className={styles.formError}>
                  {errors[DYNAMIC_REPORT_NAME].message as any}
                </p>
              )}
            </div>
          </div>
        </div>
        {dynamic_report_name?.filter?.length > 0 &&
          dynamic_report_name?.filter?.some(
            (item: any) => item?.name === 'DATE'
          ) && (
            <div className={styles.dateFieldsContainer}>
              <div style={{ flexBasis: '30%' }}>
                <Input
                  {...register(DYNAMIC_REPORT_START_DATE)}
                  type="date"
                  labelText="Start Date"
                  requiredField={false}
                />
              </div>
              <div style={{ flexBasis: '30%' }}>
                <Input
                  {...register(DYNAMIC_REPORT_END_DATE)}
                  type="date"
                  labelText="End Date"
                  requiredField={false}
                />
              </div>
            </div>
          )}
        <div className={styles.nameFilterContainer}>
          {dynamic_report_name?.filter?.length > 0 &&
            dynamic_report_name?.filter?.some(
              (item: any) => item?.name === 'Branch'
            ) && (
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={`Branch`}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={`Select Branch`}
                    closeMenuOnSelect={false}
                    components={{ DropdownIndicator }}
                    value={watch(DYNAMIC_REPORT_BRANCH)}
                    {...register(DYNAMIC_REPORT_BRANCH)}
                    options={branchData?.branches?.map((branchDetail: any) => {
                      return {
                        label: branchDetail?.name,
                        value: branchDetail?._id,
                      }
                    })}
                    onChange={(e: any) => {
                      setValue(DYNAMIC_REPORT_BRANCH, e)
                    }}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                  />
                </div>
              </div>
            )}
          {dynamic_report_name?.filter?.length > 0 &&
            dynamic_report_name?.filter?.some(
              (item: any) => item?.name === 'Doctor'
            ) && (
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={`Doctor`}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={`Select Doctor`}
                    closeMenuOnSelect={false}
                    components={{ DropdownIndicator }}
                    value={watch(DYNAMIC_REPORT_DOCTOR)}
                    {...register(DYNAMIC_REPORT_DOCTOR)}
                    options={doctorData?.map((item: any) => {
                      return {
                        label: item?.doctor_name,
                        value: item?._id,
                      }
                    })}
                    onChange={(e: any) => {
                      setValue(DYNAMIC_REPORT_DOCTOR, e)
                    }}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                  />
                </div>
              </div>
            )}
          {dynamic_report_name?.filter?.length > 0 &&
            dynamic_report_name?.filter?.some(
              (item: any) => item?.name === 'Patient'
            ) && (
              <div className={styles.inputFieldContainer}>
                <Label
                  labelText={`Patient`}
                  requiredField={false}
                  flexBasis="31%"
                />
                <div className={styles.inlineItems}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={`Select Patient`}
                    closeMenuOnSelect={false}
                    components={{ DropdownIndicator }}
                    value={watch(DYNAMIC_REPORT_PATIENT)}
                    {...register(DYNAMIC_REPORT_PATIENT)}
                    options={patientListData?.map((item: any) => {
                      return {
                        label: item?.patient_name,
                        value: item?._id,
                      }
                    })}
                    onChange={(e: any) => {
                      setValue(DYNAMIC_REPORT_PATIENT, e)
                    }}
                    isSearchable={false}
                    maxMenuHeight={200}
                    isMulti
                  />
                </div>
              </div>
            )}
        </div>
        <div className={styles.dynamicReportFormBtnContainer}>
          <Button
            title="Generate Report"
            type="submit"
            customClass={styles.dynamicReportSubmitBtn}
          />
        </div>
      </form>
      {tableData?.length > 0 && (
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={teableHeaderData}
            tableRowData={tableData?.length ? tableData : []}
          />
        </div>
      )}
    </>
  )
}

export default DynamicReportBuilder
