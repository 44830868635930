import { Level3_CHECK, Level3_CODE, Level3_LIST, Level3_NAME } from "../constants/constant";

export interface IaddLevel3Validators {
  [Level3_NAME]: {
    required: string;
  };
  [Level3_CODE]: {};
  [Level3_CHECK]: {};
  [Level3_LIST]:{
    required:string
  }
}
export const addLevel3Validators: IaddLevel3Validators = {
  [Level3_NAME]: {
    required: "Please enter name",
  },
  [Level3_CODE]: {},
  [Level3_CHECK]: {},
  [Level3_LIST]:{
    required:"Please select debit or credit"
  }
};
