import React, { useEffect, useState } from 'react';
import TableV2 from '../../../../../components/common/table/tableV2/TableV2';
import { ipdTestHeaderData } from '../../../../../constants/table-data/ipdTestTableData';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { requestGenerator } from '../../../../../utils/payloadGenerator';
import { getAllIpdTest } from '../../../../../redux/features/ipd/ipdAsyncActions';
import Popup from '../../../../../components/common/popup/Popup';
import IpdTestListPopup from '../../../../../components/common/modal/ipd-testlist-popup/IpdTestListPopup';
import IpdTestResultPopup from '../../../../../components/common/modal/ipd-test-result-popup/IpdTestResultPopup';
import NotesPopup from '../../../../../components/common/modal/notes-popup/NotesPopup';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../../components/common/spinner/Loader';

const IpdTest = () => {
  const dispatch = useAppDispatch();
  const { ipdTestData, ipdId, isLoading } = useAppSelector(
    (state) => state.ipd
  );
  const [showTest, setShowTest] = useState(false);
  const [testData, setTestData] = useState<any>();
  const [showResult, setShowResult] = useState(false);
  const [resultData, setResultData] = useState<any>();
  const [showNotes, setShowNotes] = useState(false);
  const [notesData, setNotesData] = useState();
  const location = useLocation()?.state;
  console.log(location, 'location');

  useEffect(() => {
    dispatch(getAllIpdTest(requestGenerator({ ipd_id: location?.ipd_id })));
  }, [dispatch, ipdId]);

  return (
    <>
      {isLoading && <Loader />}
      {showTest && (
        <Popup
          Children={IpdTestListPopup}
          handleClose={() => setShowTest(false)}
          popData={testData}
        />
      )}
      {showResult && (
        <Popup
          Children={IpdTestResultPopup}
          handleClose={() => setShowResult(false)}
          popData={resultData}
          handleOpen={(item: any) => {
            setShowNotes(true);
            setNotesData(item);
          }}
        />
      )}
      {showNotes && (
        <Popup
          Children={NotesPopup}
          handleClose={() => setShowNotes(false)}
          popData={{
            // notes: 'tcrtrgrxxgcrt',
            notes: notesData,
          }}
        />
      )}
      <TableV2
        tableHeaderData={ipdTestHeaderData}
        tableRowData={ipdTestData}
        active={false}
        handleClick={(profile: any, item: any) => {
          setShowTest(true);
          setTestData(
            item?.map((item: any) => {
              return { ...item, profile_name: profile };
            })
          );
        }}
        handleRowClick={(profile: any, item: any) => {
          setShowResult(true);
          // setResultData(item?.map((id: any) => id?.test_component_id));
          setResultData(
            item?.map((itemN: any) => {
              return { ...itemN, profile_name: profile };
            })
          );
        }}
      />
    </>
  );
};

export default IpdTest;
