import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/button/Button';
import Select from 'react-select';
import { DropdownIndicator } from '../../components/common/dropdown-indicator/DropdownIndicator';
import { setMessage } from '../../redux/features/toast/toastSlice';
import { failure, warning } from '../../constants/data';
import moment from 'moment';
import styles from '../../pages/ipd/ipd-booking/ipdBooking.module.scss';

export const ipdBookingTableHeaderData: any = [
  {
    Header: 'BED NO',
    accessor: 'bed_no',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.bed_no ? (
            <span>{props?.row?.original?.bed_no}</span>
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  // {
  //   Header: "BED TYPE",
  //   accessor: "bed_type",
  //   Cell: (props: any) => {
  //     return (
  //       <>
  //         {props?.row?.original?.bed_type ? (
  //           <span>{props?.row?.original?.bed_type}</span>
  //         ) : (
  //           "-"
  //         )}
  //       </>
  //     );
  //   },
  // },
  // {
  //   Header: "DESCRIPTION",
  //   accessor: "case_no",
  //   Cell: (props: any) => {
  //     return (
  //       <>
  //         <span
  //           className={styles.viewLink}
  //           onClick={() => props.onClick(props?.row?.original)}
  //         >
  //           View
  //         </span>
  //       </>
  //     );
  //   },
  // },
  {
    Header: 'STATUS',
    // accessor: "status",
    disableSortBy: true,
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.booked === false ? (
            <span className={styles.availableLabel}>Available</span>
          ) : props?.row?.original?.booked === true ? (
            <span className={styles.notAvailableLabel}>UnAvailable</span>
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  {
    Header: 'CHARGE/DAY',
    accessor: 'charges',
    Cell: (props: any) => {
      return (
        <>
          {props?.row?.original?.charges ? (
            <span>{props?.row?.original?.charges}</span>
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  {
    Header: 'BOOK',
    // accessor: "department",
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch();
      // console.log("props.getPropsData :>> ", props.getPropsData);

      const handleBook = () => {
        if (
          props.getPropsData?.patient_name === undefined ||
          props.getPropsData?.admissionDate?.length === 0
        ) {
          dispatch(
            setMessage({
              message: 'Please select patient and admission date',
              type: warning,
            })
          );
        } else {
          const bookModalData = {
            ...props.row.original,
            patient_id: props.getPropsData?.patient_id,
            patient_name: props.getPropsData?.patient_name,
            ipd_id: props.getPropsData?._id,
            admissionDate: props.getPropsData?.admissionDate,
          };
          props.onRowClick(bookModalData);
        }
      };

      return (
        <>
          {props?.row?.original?.booked === false ? (
            <Button
              title="Book"
              customClass={styles.buttonOutline}
              type="button"
              handleClick={handleBook}
            />
          ) : props?.row?.original?.booked === true ? (
            <Button
              title="View"
              customClass={styles.buttonOutline}
              type="button"
              handleClick={handleBook}
            />
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  {
    Header: 'ACTIVE DATE',
    accessor: (row: any) => {
      return moment(row?.admission_date).format('DD MMM YYYY');
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.admission_date).format(
        'DD MMM YYYY'
      );
      return (
        <>
          {props?.row?.original?.admission_date ? (
            <span>{convertDate}</span>
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  {
    Header: 'INACTIVE DATE',
    accessor: (row: any) => {
      return moment(row?.discharge_date).format('DD MMM YYYY');
    },
    Cell: (props: any) => {
      const convertDate = moment(props?.row?.original?.discharge_date).format(
        'DD MMM YYYY'
      );
      return (
        <>
          {props?.row?.original?.discharge_date ? (
            <span>{convertDate}</span>
          ) : (
            '-'
          )}
        </>
      );
    },
  },
  {
    Header: 'NOTE',
    // accessor: "sr_no",
    Cell: (props: any) => {
      return (
        <>
          <span
            className={styles.viewLink}
            onClick={() => props.onPopClose(props?.row?.original)}
          >
            View
          </span>
        </>
      );
    },
  },
];

export const ipdBookingData: any = [
  {
    bed_no: 1,
    bed_type: 'Single',
    status: 'Available',
    charge_day: 500,
    active_date: '25 Jan 2023',
    inactive_date: '02 Feb 2023',
    description_notes: 'lorem ipsum lorem ipsum',
    notes: 'lorem ipsum lorem ipsum',
    department: 'local',
    wards: 'general',
    room: '101',
    admitted_date: '2023-09-10',
    book_id: 213,
  },
  {
    bed_no: 2,
    bed_type: 'Double',
    status: 'Available',
    charge_day: 500,
    active_date: '25 Jan 2023',
    inactive_date: '02 Feb 2023',
    description_notes: 'lorem ipsum lorem ipsum',
    notes: 'lorem ipsum lorem ipsum',
    department: 'global',
    wards: 'general',
    room: '102',
    admitted_date: '2023-09-10',
    book_id: 212,
  },
];
