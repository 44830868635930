import { FC, useState, useEffect } from 'react';
import styles from './addInsuranceModal.module.scss';
import { SearchButton } from '../../svg-components';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { requestGenerator } from '../../../../utils/payloadGenerator';
import Loader from '../../spinner/Loader';
import TableV3 from '../../table/tableV3/TableV3';
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import { Cols } from '../../../../interfaces/interfaces';
import { addInsuranceHeaderData } from '../../../../constants/table-data/userTableData';
import { trimValue } from '../../../../utils/utils';
import { patientInsurancePlanList } from '../../../../redux/features/invoice-module/invoiceAsynActions';
import Button from '../../button/Button';
import { setMessage } from '../../../../redux/features/toast/toastSlice';
import { failure, warning } from '../../../../constants/data';
import { GET_PATIENT_INSURANCE_PLAN_LIST } from '../../../../constants/asyncActionsType';

interface IAddInsuranceModal {
  setModelOpenClose?: any;
  handleRowClick?: any;
  handleClose?: any;
  handleOpen?: any;
  handleNotesPreview?: any;
  handleInsuranceRowClick?: any;
  popData?: any;
  handleSaveInsurance?: any;
}
const AddInsuranceModalV2: FC<IAddInsuranceModal> = ({
  handleOpen,
  handleNotesPreview,
  popData,
  handleClose,
  handleSaveInsurance,
}) => {
  const dispatch = useAppDispatch();
  const { isLoading, addInsurancePlanList } = useAppSelector(
    (state) => state.invoice
  );
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [activateSmartSearch, setActivateSmartSearch] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  // React Table define
  const data: Cols[] = addInsurancePlanList;
  const columns: Column<Cols>[] = addInsuranceHeaderData;
  const options: TableOptions<Cols> = {
    data,
    columns,
  };

  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy);
  // @ts-ignore
  const { globalFilter } = state;

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();
  const setTotalPageData = (data: any) => {
    setTotalPage(data.lastPage);
  };
  useEffect(() => {
    const requestData = {
      patient_id: popData?.patient_id,
      search: searchValue,
      page: pageIndex,
      pageSize: dataPerPage,
    };
    dispatch(patientInsurancePlanList(requestGenerator(requestData))).then(
      (result) => {
        if (result.type === `${GET_PATIENT_INSURANCE_PLAN_LIST}/fulfilled`) {
          setTotalPageData(result.payload);
        }
      }
    );
  }, [dispatch]);

  const handleInputSearch = () => {
    setActivateSmartSearch(true);
    const requestData = {
      patient_id: popData?.patient_id,
      search: searchValue,
      page: pageIndex,
      pageSize: dataPerPage,
    };
    dispatch(patientInsurancePlanList(requestGenerator(requestData))).then(
      (result) => {
        if (result.type === `${GET_PATIENT_INSURANCE_PLAN_LIST}/fulfilled`) {
          setTotalPageData(result.payload);
        }
      }
    );
  };

  const handleRow = (item: any) => {
    setSelectedItem(item);
  };
  console.log('popData', popData);

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.searchFieldContainer}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '25px',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <input
              type="text"
              className={styles.inputSearchContainer}
              placeholder="Search"
              onChange={(e) => {
                trimValue(e);
                setSearchValue(e.target.value);
                setGlobalFilter('');
                if (e.target.value === '') {
                  setActivateSmartSearch(false);
                  setGlobalFilter('');
                  const requestData = {
                    patient_id: popData?.patient_id,
                    search: searchValue,
                    page: pageIndex,
                    pageSize: dataPerPage,
                  };
                  dispatch(
                    patientInsurancePlanList(requestGenerator(requestData))
                  ).then((result) => {
                    if (
                      result.type ===
                      `${GET_PATIENT_INSURANCE_PLAN_LIST}/fulfilled`
                    ) {
                      setTotalPageData(result.payload);
                    }
                  });
                }
              }}
            />

            <SearchButton
              handleClick={() => {
                if (!!searchValue) {
                  handleInputSearch();
                }
              }}
              customClass={styles.inputSearchButton}
            />
          </div>

          <div
            style={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '18px',
            }}
          >
            <input
              type="text"
              className={
                !activateSmartSearch
                  ? styles.inputSmartSearchContainer
                  : styles.inputSearchContainer
              }
              placeholder="Smart Search"
              disabled={!activateSmartSearch}
              onChange={(e) => {
                trimValue(e);
                setGlobalFilter(e.target.value);
              }}
              value={searchValue === '' ? searchValue : globalFilter}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <TableV3
            setActive={setSelectedItem}
            active={selectedItem?._id}
            handleRow={handleRow}
            handleOpen={handleOpen}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            handleRowClick={handleNotesPreview}
          />
        </div>

        {/* {addInsurancePlanList && addInsurancePlanList?.length > 0 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )} */}
      </div>

      <div className={styles.buttonContainer}>
        <Button
          title="Submit"
          type="button"
          customClass={styles.submitButtonStyle}
          handleClick={() => {
            if (selectedItem) {
              handleSaveInsurance(selectedItem);
              handleClose && handleClose();
            } else {
              dispatch(
                setMessage({
                  message: 'Please select one inusrance',
                  type: warning,
                })
              );
            }
          }}
        />
      </div>
    </>
  );
};

export default AddInsuranceModalV2;
