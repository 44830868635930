import styles from './tableData.module.scss'

export const CriteriaHeader: any[] = [
  // {
  //   Header: 'SR NO',
  //   accessor: (row: any) => {
  //     return <p>-</p>;
  //   },
  // },
  {
    Header: 'LEAD NAME',
    accessor: (row: any) => {
      return `${row.name}`
    },
  },
  {
    Header: 'GENDER',
    accessor: (row: any) => {
      return `${row.gender}`
    },
  },
  {
    Header: 'AGE',
    accessor: (row: any) => {
      return `${row.age}`
    },
  },
  {
    Header: 'NATIONALITY',
    accessor: (row: any) => {
      return <p>{row?.nationality?.length > 0 ? row?.nationality : '-'}</p>
    },
  },
  {
    Header: 'PIN CODE',
    accessor: (row: any) => {
      return <p>{row?.pincode?.length > 0 ? row?.pincode : '-'}</p>
    },
  },
  {
    Header: 'TAGS',
    Cell: (props: any) => {
      const tags = props?.row?.original?.assign_tag
      return (
        <>
          {/* {props?.row?.original?.assign_tag?.length > 0 ? (
            <p
              className={styles.blueLinkText}
              onClick={() => {
                if (props?.row?.original?.assign_tag?.length > 0) {
                  props?.onClick(props?.row?.original?.assign_tag)
                } else {
                  return
                }
              }}
            >
              View
            </p>
          ) : (
            <p>-</p>
          )} */}
          {tags && tags?.length > 0
            ? tags?.map((item: string, index: number) => {
                return item ? (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ) : (
                  ''
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: 'STATUS',
    Cell: ({ row }: any) => {
      return (
        <>
          <p
            className={
              row?.original?.is_active ? styles.completed : styles.cancelled
            }
          >
            {' '}
            {row?.original?.is_active ? 'ACTIVE' : 'INACTIVE'}
          </p>
        </>
      )
    },
  },
]
