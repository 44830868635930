import moment from "moment";
import styles from "./ongoingtreatmentplsnpopup.module.scss";

export const ongoingTreatmentPlanPopupHeaderData: any = [
  {
    Header: "Date",
    accessor: (row: any) => {
      return moment(row?.createdAt).format("DD-MMM-YYYY") ?? "-";
    },
  },
  {
    Header: "Diagnosis Number",
    accessor: (row: any) => {
      return row?.diagnosis_number ?? "-";
    },
  },
  {
    Header: "Status",
    accessor: (row: any) => {
      return row?.diagnosis_treatments_status ?? "-";
    },
  },
  {
    Header: "Services",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onRowClick(props.row.original)}
        >
          View
        </p>
      );
    },
  },
];
