import { FC, useState, useEffect } from 'react'
import Button from '../../../components/common/button/Button'
import styles from './adddocument.module.scss'
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated'
import {
  DOCUMENT_TYPE,
  REMARK,
  EMP_TYPE,
  EMP_NAME,
  EXPIRY_DATE,
  SUB_COMPANIES,
  _EMPID,
  ATTACHEMENTS,
} from '../../../constants/constant'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { trimValue } from '../../../utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { IAddDocuments } from '../../../interfaces/interfaces'
import { documentsValidators } from '../../../form-validators/addDoumentValidators'
import AttachFile from '../../../components/common/attach-files/multiple-file/Attachfiles'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Loader from '../../../components/common/spinner/Loader'
import {
  addDocument,
  editDocumentById,
  getAllAttachs,
  getAllDocument,
} from '../../../redux/features/document/documentAsyncActions'
import Popup from '../../../components/common/popup/Popup'
import SearchModal from '../document-search-modal/SearchModal'
import SearchDesign from '../search-design/SearchDesign'
import moment from 'moment'
import {
  ADD_DOCUMENT,
  EDIT_DOCUMENT_BY_ID,
} from '../../../constants/asyncActionsType'
import { Input } from '../../../components/common/input/input'
interface IAddDocument {
  popData?: string | any
}
export type fileType = {
  name: string
  data_uri: any
  attachements: any
}

const AddDocument: FC<IAddDocument> = ({ popData }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)
  const [searchModalData, setSearchModalData] = useState({})
  const [documentData, setdocumentData] = useState<any>({})
  const [file, setFile] = useState<any>()
  const [attachments, setAttachments] = useState<any[]>([])
  const [type, setType] = useState<string>(
    location?.state?.hasOwnProperty('user')
      ? location.state.user.type
      : 'EMPLOYEE'
  )
  const animatedComponent = makeAnimated()
  const { isLoading, allAttchsData } = useAppSelector((state) => state.document)
  const { masterValueData } = useAppSelector((state) => state.login)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    trigger,
    reset,
    formState: { errors },
  } = useForm<IAddDocuments>({})

  const onSubmit: SubmitHandler<IAddDocuments> = (data: any) => {
    const dataPayload = {
      emp_id: documentData._id,
      name: data.name,
      document_type: data.document_type?.value,
      type: data.type,
      remark: data.remark,
      expiry_date: data.expiry_date,
      sub_company: data?.sub_companies?.value,
      attachements: data.attachements,
      has_document: true,
    }
    if (data && data._id) {
      let id = data._id
      const dataPayload = {
        name: data.name,
        document_type: data.document_type?.value,
        type: data.type,
        remark: data.remark,
        expiry_date: data.expiry_date,
        sub_company: data?.sub_companies?.value,
        attachements: data.attachements,
        has_document: true,
      }
      dispatch(
        editDocumentById(requestGenerator({ id, data: dataPayload, type }))
      ).then((e) => {
        if (e.type === `${EDIT_DOCUMENT_BY_ID}/fulfilled`) {
          navigate('/documents')
          let payloadData = {
            page: 1,
            pageSize: 100,
            order_by: { is_default: -1 },
            type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
          }
          dispatch(getAllDocument(requestGenerator(payloadData)))
        }
      })
    } else {
      dispatch(addDocument(requestGenerator(dataPayload))).then((e) => {
        if (e.type === `${ADD_DOCUMENT}/fulfilled`) {
          navigate('/documents')
          let payloadData = {
            page: 1,
            pageSize: 100,
            order_by: { is_default: -1 },
            type: type === 'company' ? 'COMPANY' : 'EMPLOYEE',
          }
          dispatch(getAllDocument(requestGenerator(payloadData)))
        }
      })
    }
  }
  //edit Api
  let data = location?.state
  useEffect(() => {
    if (data && masterValueData?.length) {
      setValue(_EMPID, data?.user?.emp_id)
      setValue(_EMPID, data?.user?.sub_company)
      reset(data.user)
      let expirydate =
        moment(data?.user?.expiry_date)?.format('YYYY-MM-DD') ?? ''
      setValue(EXPIRY_DATE, expirydate)

      const subType = masterValueData
        ?.find((item: any) => item?.category_name === 'SUB-COMPANY')
        ?.values?.find((item: any) => {
          return item?._id == data?.user?.sub_company
        })
      setValue(SUB_COMPANIES, {
        label: subType?.value,
        value: subType?._id,
      })

      const documentType = masterValueData
        ?.find((item: any) => item?.category_name === 'DOCUMENT-TYPE')
        ?.values?.find((item: any) => {
          return item?._id == data?.user?.document_type
        })

      setValue(DOCUMENT_TYPE, {
        label: documentType?.value,
        value: documentType?._id,
      })
      let attachements = data?.user?.attachements
      let attachementsData: any = []
      attachements.forEach((element: any) => {
        attachementsData.push({ name: element, data_uri: element })
      })
      const requestData = {
        attachements: attachementsData,
      }
      dispatch(getAllAttachs(requestGenerator(requestData))).then((e) => {
        data.user.attachements = e?.payload
      })
    }
  }, [dispatch, masterValueData?.length])

  // Set Attachements on Edit
  useEffect(() => {
    if (allAttchsData && allAttchsData.length > 0) {
      const attachementsData = allAttchsData?.map(
        (item: fileType, index: number) => {
          let nameWithId =
            (item?.name || '')?.split('/')?.[
              item.name?.split('/')?.length - 1
            ] || ''
          let nameWithoutID = nameWithId
            ? nameWithId?.split('/')[nameWithId?.split('/').length - 1]
            : ''
          const finalItem = {
            name: nameWithoutID ?? 'File.png',
            data_uri: item?.data_uri,
          }
          return finalItem
        }
      )
      setValue(ATTACHEMENTS, attachementsData)
      setAttachments(attachementsData)
    } else {
      setValue(ATTACHEMENTS, [])
      setAttachments([])
    }
  }, [allAttchsData])

  const handleBack = () => {
    reset()
    navigate('/documents')
  }
  const handleReset = () => {
    reset()
    setAttachments([])
    setValue(_EMPID, '')
    setValue(EMP_NAME, '')
    setValue(DOCUMENT_TYPE, '')
    setValue(REMARK, '')
  }
  // Search Employee Popup
  const handleSearchPopup = () => {
    handleReset()
    setAttachments([])
    setSearchModalData({})
    setShowSearchModal((prevState) => !prevState)
  }
  // Search Employee modal close
  const handleSearchModalClose = () => {
    setShowSearchModal((prevState) => !prevState)
    setSearchModalData({})
  }
  // Search Employee Get Popup Data By Id
  const handleSearchGetPopupData = (item: any) => {
    setdocumentData(item)
    setShowSearchModal((prevState) => !prevState)
  }

  useEffect(() => {
    if (documentData?._id) {
      setValue(_EMPID, documentData?.emp_id)
    }
  }, [documentData])
  const multiSelectStyles = {
    indicatorSeparator: () => ({
      display: 'none',
      color: '#797979',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      color: '#797979',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      color: '#797979',
      display: 'none',
    }),
  }
  useEffect(() => {
    if (documentData) {
      setAttachments([])
    }
  }, [documentData])
  return (
    <>
      {isLoading && <Loader />}
      {showSearchModal && (
        <Popup
          Children={SearchModal}
          popData={searchModalData}
          handleClose={() => handleSearchModalClose()}
          setModelOpenClose={setShowSearchModal}
          handleSubmit={handleSearchGetPopupData}
        />
      )}
      <div className={styles.addContainer}>
        <div className={styles.searchMainContainer}>
          <div className={styles.searchContainer}>
            <SearchDesign
              handleSearchClick={() =>
                type === 'COMPANY' ? undefined : handleSearchPopup()
              }
              customClass={
                data?.user?._id || type === 'COMPANY'
                  ? styles.disableInputSearchContainer
                  : styles.inputSearchContainer
              }
            />
          </div>
        </div>
        <h1>Manage Document Details</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.radioFieldContainer}>
            <label className={styles.radioFieldName}>Type :</label>
            <div className={styles.radiogroup}>
              <input
                className={styles.radioInput}
                type="radio"
                id="Radio"
                value="EMPLOYEE"
                checked={type === 'EMPLOYEE'}
                {...register(EMP_TYPE, documentsValidators[EMP_TYPE])}
                onClick={() => {
                  if (!location?.state?.hasOwnProperty('user')) {
                    setType('EMPLOYEE')
                  }
                  let data = location?.state
                  if (!data) {
                    handleReset()
                  }
                }}
              />
              <label
                className={
                  location?.state?.employee
                    ? styles.selectText
                    : styles.radioLabel
                }
              >
                Employee
              </label>
              <input
                className={styles.radioInput}
                type="radio"
                value="COMPANY"
                checked={type === 'COMPANY'}
                onClick={() => {
                  if (!location?.state?.hasOwnProperty('user')) {
                    setType('COMPANY')
                  }
                  if (!data) {
                    handleReset()
                  }
                }}
                {...register(EMP_TYPE, documentsValidators[EMP_TYPE])}
              />
              <label
                className={
                  location?.state?.company
                    ? styles.selectText
                    : styles.radioLabel
                }
              >
                Company
              </label>
              {errors[EMP_TYPE] && (
                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  <p className="dashboardFormError">
                    {errors[EMP_TYPE].message}
                  </p>
                </div>
              )}
            </div>
          </div>
          {type === 'EMPLOYEE' ? (
            <>
              <fieldset
                disabled={documentData?._id || location?.state ? false : true}
                className={styles.formDisable}
              >
                <div className={styles.formFieldContainer}>
                  <label>
                    Employee <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Input
                      placeholder="Employee"
                      className={styles.input}
                      disabled={true}
                      {...register(_EMPID, documentsValidators[_EMPID])}
                    />
                    {errors[_EMPID] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[_EMPID].message}
                        </p>
                      </div>
                    )}
                  </div>
                  <label>
                    Document Type <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Select
                      className={styles.selectInputField}
                      placeholder={
                        <div className="selectPlaceholder">
                          Select Document Type
                        </div>
                      }
                      {...register(
                        DOCUMENT_TYPE,
                        documentsValidators[DOCUMENT_TYPE]
                      )}
                      isSearchable={true}
                      isClearable={true}
                      value={watch(DOCUMENT_TYPE || [])}
                      options={masterValueData
                        ?.find(
                          (item: any) => item?.category_name === 'DOCUMENT-TYPE'
                        )
                        ?.values?.map((item: any) => ({
                          label: item?.value,
                          value: item?._id,
                        }))}
                      components={{
                        DropdownIndicator: (props: any) => {
                          if (documentData?._id || location?.state) {
                            return <components.DropdownIndicator {...props} />
                          } else {
                            return null
                          }
                        },
                      }}
                      closeMenuOnSelect={true}
                      maxMenuHeight={120}
                      styles={multiSelectStyles}
                      onChange={(e: any) => {
                        setValue(DOCUMENT_TYPE, {
                          label: e.label,
                          value: e.value,
                        })
                        trigger(DOCUMENT_TYPE)
                      }}
                    />
                    {errors[DOCUMENT_TYPE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[DOCUMENT_TYPE].message as any}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.formFieldContainer}>
                  <label>
                    Document Name
                    <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Input
                      className={styles.input}
                      type="text"
                      placeholder="Document Name"
                      {...register(EMP_NAME, documentsValidators[EMP_NAME])}
                      onChange={(e: any) => trimValue(e)}
                    />
                    {errors[EMP_NAME] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[EMP_NAME].message}
                        </p>
                      </div>
                    )}
                  </div>
                  <label>
                    Expiry Date <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    {documentData._id ? (
                      <Input
                        className={styles.input}
                        type="date"
                        id="birthDate"
                        min={new Date()?.toISOString()?.split('T')?.[0]}
                        {...register(
                          EXPIRY_DATE,
                          documentsValidators[EXPIRY_DATE]
                        )}
                      />
                    ) : (
                      <Input
                        className={styles.input}
                        type="date"
                        id="birthDate"
                        {...register(
                          EXPIRY_DATE,
                          documentsValidators[EXPIRY_DATE]
                        )}
                      />
                    )}
                    {errors[EXPIRY_DATE] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[EXPIRY_DATE].message}
                        </p>
                      </div>
                    )}
                  </div>
                  <label>
                    Attachments <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <AttachFile
                      fileKey={ATTACHEMENTS}
                      defaultAttachments={attachments ?? []}
                      setValue={setValue}
                      clearErrors={clearErrors}
                      isMultiSelect={true}
                      {...register(
                        ATTACHEMENTS,
                        documentsValidators[ATTACHEMENTS]
                      )}
                      file={file}
                      customClass={styles.attacheFileName}
                      setFile={setFile}
                      attachments={attachments}
                      setAttachments={setAttachments}
                    />
                    {errors[ATTACHEMENTS] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[ATTACHEMENTS].message}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.formFieldContainer}>
                  <label>
                    Remark <span className="asterick">*</span>
                  </label>
                  <div className={styles.inputFieldContainer}>
                    <Input
                      type="text"
                      className={styles.remark}
                      placeholder="Remark"
                      {...register(REMARK, documentsValidators[REMARK])}
                      onChange={(e: any) => trimValue(e)}
                    />
                    {errors[REMARK] && (
                      <div className={styles.errorContainer}>
                        <span className={styles.extraSpan}></span>
                        <p className="dashboardFormError">
                          {errors[REMARK].message}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </fieldset>
            </>
          ) : (
            <>
              <div className={styles.formFieldContainer}>
                <label>
                  Sub Company <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={
                      <div className="selectPlaceholder">
                        Select Sub Company
                      </div>
                    }
                    {...register(
                      SUB_COMPANIES,
                      documentsValidators[SUB_COMPANIES]
                    )}
                    isSearchable={true}
                    isClearable={true}
                    value={watch(SUB_COMPANIES || [])}
                    options={
                      Array.isArray(masterValueData) &&
                      masterValueData.length > 0
                        ? masterValueData
                            .find(
                              (item: any) =>
                                item.category_name === 'SUB-COMPANY'
                            )
                            ?.values?.map((item: any) => ({
                              label: item?.value,
                              value: item?._id,
                            }))
                        : []
                    }
                    components={animatedComponent}
                    closeMenuOnSelect={true}
                    maxMenuHeight={120}
                    styles={multiSelectStyles}
                    onChange={(e: any) => {
                      setValue(SUB_COMPANIES, {
                        label: e.label,
                        value: e.value,
                      })
                      trigger(SUB_COMPANIES)
                    }}
                  />
                  {errors[SUB_COMPANIES] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[SUB_COMPANIES].message as any}
                      </p>
                    </div>
                  )}
                </div>
                <label htmlFor={DOCUMENT_TYPE}>
                  Document Type <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  <Select
                    className={styles.selectInputField}
                    placeholder={
                      <div className="selectPlaceholder">
                        Select Document Type
                      </div>
                    }
                    {...register(
                      DOCUMENT_TYPE,
                      documentsValidators[DOCUMENT_TYPE]
                    )}
                    isSearchable={true}
                    isClearable={true}
                    value={watch(DOCUMENT_TYPE || [])}
                    options={masterValueData
                      ?.find(
                        (item: any) => item?.category_name === 'DOCUMENT-TYPE'
                      )
                      ?.values?.map((item: any) => ({
                        label: item?.value,
                        value: item?._id,
                      }))}
                    components={{
                      DropdownIndicator: (props: any) => {
                        if (documentData?._id || location?.state) {
                          return <components.DropdownIndicator {...props} />
                        } else {
                          return null
                        }
                      },
                    }}
                    closeMenuOnSelect={true}
                    maxMenuHeight={120}
                    styles={multiSelectStyles}
                    onChange={(e: any) => {
                      setValue(DOCUMENT_TYPE, {
                        label: e.label,
                        value: e.value,
                      })
                      trigger(DOCUMENT_TYPE)
                    }}
                  />
                  {errors[DOCUMENT_TYPE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[DOCUMENT_TYPE].message as any}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.formFieldContainer}>
                <label>
                  Document Name
                  <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  <Input
                    className={styles.input}
                    type="text"
                    placeholder="Document Name"
                    {...register(EMP_NAME, documentsValidators[EMP_NAME])}
                    onChange={(e: any) => trimValue(e)}
                  />
                  {errors[EMP_NAME] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[EMP_NAME].message}
                      </p>
                    </div>
                  )}
                </div>
                <label>
                  Expiry Date <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  {documentData._id ? (
                    <Input
                      className={styles.input}
                      type="date"
                      id="birthDate"
                      min={new Date()?.toISOString()?.split('T')?.[0]}
                      {...register(
                        EXPIRY_DATE,
                        documentsValidators[EXPIRY_DATE]
                      )}
                    />
                  ) : (
                    <Input
                      className={styles.input}
                      type="date"
                      id="birthDate"
                      {...register(
                        EXPIRY_DATE,
                        documentsValidators[EXPIRY_DATE]
                      )}
                    />
                  )}
                  {errors[EXPIRY_DATE] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[EXPIRY_DATE].message}
                      </p>
                    </div>
                  )}
                </div>
                <label>
                  Attachments <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  <AttachFile
                    fileKey={ATTACHEMENTS}
                    defaultAttachments={attachments ?? []}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    isMultiSelect={true}
                    {...register(
                      ATTACHEMENTS,
                      documentsValidators[ATTACHEMENTS]
                    )}
                    file={file}
                    customClass={styles.attacheFileName}
                    setFile={setFile}
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                  {errors[ATTACHEMENTS] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[ATTACHEMENTS].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.formFieldContainer}>
                <label htmlFor={REMARK}>
                  Remark <span className="asterick">*</span>
                </label>
                <div className={styles.inputFieldContainer}>
                  <Input
                    type="text"
                    className={styles.remark}
                    placeholder="Remark"
                    {...register(REMARK, documentsValidators[REMARK])}
                    onChange={(e: any) => trimValue(e)}
                  />
                  {errors[REMARK] && (
                    <div className={styles.errorContainer}>
                      <span className={styles.extraSpan}></span>
                      <p className="dashboardFormError">
                        {errors[REMARK].message}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className={styles.buttonConatiner}>
            <Button title="Submit" type="submit" customClass={styles.buttons} />
            <Button
              title="Reset"
              type="reset"
              customClass={styles.resetButtonStyle}
              handleClick={handleReset}
              disable={data?.user?.expiry_date ? true : false}
            />
            <Button
              title="Back"
              customClass={styles.resetButtonStyle}
              handleClick={handleBack}
            />
          </div>
        </form>
      </div>
    </>
  )
}
export default AddDocument
