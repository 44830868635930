import { createSlice } from '@reduxjs/toolkit'
import { IPhysio } from '../../../interfaces/apiInterface'
import {
  appointmentDataByIdAsync,
  getAllPhysioSlots,
} from './physioAsyncActions'

export const initialState: IPhysio = {
  isLoading: false,
  error: null,
  physioSlots: [],
  selectedSlotsStatus: [],
  roomBookingDetailById: {},
  appointmentDataById: {},
}

export const physioSlice = createSlice({
  name: 'physio',
  initialState,
  reducers: {
    setSelectedSlotStatus: (state, action) => {
      state.selectedSlotsStatus = action.payload
    },
    setRoomBookingDetailById: (state, action) => {
      state.roomBookingDetailById = action.payload
    },
    clearRoomBookingDetailById: (state) => {
      state.roomBookingDetailById = {}
    },
    clearAppointmentDataById: (state) => {
      state.appointmentDataById = {}
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllPhysioSlots.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllPhysioSlots.fulfilled, (state, action) => {
      state.isLoading = false
      state.physioSlots = action.payload.slots
    })
    builder.addCase(getAllPhysioSlots.rejected, (state, error) => {
      state.isLoading = false
      state.physioSlots = []
      state.error = error
    })

    builder.addCase(appointmentDataByIdAsync.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(appointmentDataByIdAsync.fulfilled, (state, action) => {
      state.isLoading = false
      state.appointmentDataById = action.payload
    })
    builder.addCase(appointmentDataByIdAsync.rejected, (state, error) => {
      state.isLoading = false
      state.error = error
    })
  },
})

export const {
  setSelectedSlotStatus,
  setRoomBookingDetailById,
  clearRoomBookingDetailById,
  clearAppointmentDataById,
} = physioSlice.actions
export default physioSlice.reducer
