import TableV2 from "../../../../../components/common/table/tableV2/TableV2";
import { ipdConsultationData, notesData } from "./consultation-table";
import { CustomModal } from "../../../../../components/common/custom-modal/modal";
import { useState } from "react";

const ConsultationModal = ({ popData }: any) => {
  const [notesModal, setNotesModal] = useState(false);
  const [notesRowData, setNotesRowData] = useState([]);
  const handleNotesClick = (items: any) => {
    setNotesModal(true);
    setNotesRowData(items);
  };
  return (
    <>
      <CustomModal
        showModal={notesModal}
        closeModal={() => setNotesModal(false)}
        width="50%"
        height="400px"
        title="Notes"
      >
        <div style={{ marginTop: "40px" }}>
          <TableV2
            tableHeaderData={notesData}
            tableRowData={notesRowData}
            active={false}
          />
        </div>
      </CustomModal>
      <div style={{ marginTop: "40px" }}>
        <TableV2
          tableHeaderData={ipdConsultationData}
          tableRowData={popData}
          active={false}
          handleClick={(item: any) => handleNotesClick(item)}
        />
      </div>
    </>
  );
};

export default ConsultationModal;
