import { FC, useEffect, useState } from 'react'
import styles from './uploadModal.module.scss'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import { dataURI, getDataUriFileSize } from '../../../../utils/utils'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  ICreatePrimaryFormInputs,
  fileType,
} from '../../../../interfaces/interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import AttachFiles from '../../../../components/common/attach-files/single-file/AttachSingleFile'
import { UPLOAD_JSON_FILE } from '../../../../constants/constant'
import Button from '../../button/Button'
import { uploadFile } from '../../../../redux/features/doctor-dashboard/doctorDashboardAsyncActions'
import { uploadModalValidators } from '../../../../form-validators/uploadModalValidators'
import Loader from '../../spinner/Loader'

interface ISettledInvoiceModal {
  setModelOpenClose?: any
  handleRowClick?: any
  handleClose?: any
  // uploaddata?: any;
}

const UploadModal: FC<ISettledInvoiceModal> = ({ handleClose }) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector((state) => state.doctor)

  const [userPhoto, setuserPhoto] = useState({ name: '', data_uri: '' })

  const {
    register,
    // reset,
    setError,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    trigger,
    formState: { errors },
  } = useForm<ICreatePrimaryFormInputs>({})
  const formData = watch()

  const onSubmit: SubmitHandler<ICreatePrimaryFormInputs> = (data) => {
    if (userPhoto) {
      if (getDataUriFileSize(userPhoto?.data_uri) >= 2 * 1024 * 1024) {
        setError(UPLOAD_JSON_FILE, {
          type: 'custom',
          message: 'Maximum attachments size is 2MB',
        })
      } else {
        clearErrors(UPLOAD_JSON_FILE)

        let data = {
          emr_file: {
            name: userPhoto.name,
            data_uri: userPhoto.data_uri,
          },
        }
        dispatch(uploadFile(requestGenerator(data))).then((e) => {
          if (e?.type === 'doctorDashboard/uploadFileEmr/fulfilled') {
            handleClose()
          }
        })
        // uploaddata(userPhoto);
      }
    } else {
    }
  }

  const userPhotoField = watch(UPLOAD_JSON_FILE)
  const fileName = userPhotoField?.[0]

  useEffect(() => {
    const fileList: fileType = { name: '', data_uri: '' }
    const getDataURI = async (fileName: File) => {
      try {
        const result = await dataURI(fileName)
        fileList.data_uri = result
        fileList.name = fileName.name

        console.log(fileList.data_uri, 'fileuri')
        setuserPhoto(fileList)
      } catch (error) {
        console.log({ error })
      }
    }
    if (fileName) {
      getDataURI(fileName)
    }
  }, [fileName])

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.closeIconContainer}>
          <CloseIcon
            customClass={styles.closeIconStyle}
            fillColor={colors.green1}
            handleClick={() => handleClose()}
          />
        </div>
        <p className={styles.title}>File Upload</p>
        <Divider customClass={styles.dividerStyle} />
        <div className={styles.searchFieldContainer}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.inputFieldsContainer}>
              <div className={styles.labelField}>
                <label htmlFor={UPLOAD_JSON_FILE} className={styles.labelText}>
                  Select file
                  <span className="asterick">*</span>
                </label>
                <div className={styles.fieldErrorContainer1}>
                  <AttachFiles
                    attachmentContainerCustomClass={
                      styles.attachmentContainerCustomClass
                    }
                    register={register}
                    fileKey={UPLOAD_JSON_FILE}
                    id={UPLOAD_JSON_FILE}
                    fileList={userPhoto}
                    validation={uploadModalValidators[UPLOAD_JSON_FILE]}
                    isName={true}
                    isDocument={true}
                    accept=".json"
                  />
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan}></span>
                    {errors[UPLOAD_JSON_FILE] && (
                      <p className="dashboardFormError">
                        {errors[UPLOAD_JSON_FILE].message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttonConatiner}>
              <Button title="Submit" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default UploadModal
