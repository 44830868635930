import { useEffect, useState } from 'react'
import styles from './PatientPayment.module.scss'
import Button from '../../../components/common/button/Button'
import {
  allowedNumberOfDigitsAfterDecimal,
  handlePadStart,
  requestToDownloadPdf,
} from '../../../utils/utils'
import {
  ExportIcon,
  PrintIcon,
} from '../../../components/common/svg-components'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useNavigate } from 'react-router-dom'
import Popup from '../../../components/common/popup/Popup'
import AddPaymentModeModal from '../../../components/common/modal/receipt-add-payment-mode-modal/AddPaymentModeModal'
import { paymentModeModalData } from '../../../constants/data'
import {
  clearIpdInvoiceData,
  handlePaymentModeList,
  setIPDInvoiceLoading,
  setUpayAmountData,
  updatedNewPaymentAmountArray,
} from '../../../redux/features/ipd-invoice/ipdInvoiceSlice'
import { ipdInvoicePaymentHeaderData } from '../../../constants/table-data/patientIpdInvoiceTableData'
import PaymentLinkModal from '../../../components/common/modal/payment-link-modal/payment-link/PaymentLinkModal'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { generateUpayLink } from '../../../redux/features/invoice-module/invoiceAsynActions'
import OnlinePaymentModal from '../../../components/common/modal/online-payment-modal/OnlinePaymentModal'
import { clearIpdStoreData } from '../../../redux/features/ipd/ipdSlice'
import InvoiceConformationModal from '../../../components/common/modal/invoice-conformation-modal/InvoiceConformationModal'
import moment from 'moment'
import { SETTLED_INVOICE_VIEW } from '../../../config/config'

const PatientPayment = () => {
  const [showPaymentModeModal, setshowPaymentModeModal] = useState<any>(false)
  const [showUpayAmountModal, setshowUpayAmountModal] = useState(false)
  const [upayAmountData, setupayAmountData] = useState<any>('')

  const [onlinePaymentLinkData, setOnlinePaymentLinkData] = useState<any>({})
  const [showOnlinePaymentModeModal, setShowOnlinePaymentModeModal] =
    useState<boolean>(false)
  const [invoiceConformationModal, setinvoiceConformationModal] =
    useState<boolean>(false)
  const { invoicePaymemntDetails, patientPaymentModeList, patientIpdInfo } =
    useAppSelector((state) => state.ipdInvoice)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handlePaymentMode = () => {
    setshowPaymentModeModal(!showPaymentModeModal)
  }

  const addPaymentMode = (item: any) => {
    setshowPaymentModeModal(!showPaymentModeModal)
    let requestPayload: any = {
      payment_mode: item?.payment_mode_name,
      payment_label: item?.payment_mode_label,
      amount: item?.amount ?? 0,
      _id: item?.payment_mode_id,
    }
    dispatch(handlePaymentModeList(requestPayload))
  }

  const handleUpayAmountModalClose = () => {
    setshowUpayAmountModal(!showUpayAmountModal)
  }

  // Set Upay Amount Data in patientPaymentModeList Array
  const addUpayData = (data: any) => {
    setshowUpayAmountModal(!showUpayAmountModal)
    setupayAmountData(data)
    dispatch(setUpayAmountData(data))
  }

  const handleExit = () => {
    dispatch(clearIpdInvoiceData())
    dispatch(updatedNewPaymentAmountArray([]))
    navigate('/ipd-invoice/patientinfo')
  }
  const setPdfLoader = (loader: boolean) => {
    dispatch(setIPDInvoiceLoading(loader))
  }

  const handleGenerateLink = () => {
    const modifyPaymentModeData = patientPaymentModeList?.map((item: any) => {
      const { _id, ...rest } = item
      return rest
    })

    const requestPayload = {
      invoice_no: invoicePaymemntDetails?.invoice_no,
      partial_payment_array: modifyPaymentModeData,
    }

    dispatch(generateUpayLink(requestGenerator(requestPayload))).then(
      (e: any) => {
        if (e.type === 'invoice/getGenerateUpayLink/fulfilled') {
          if (e?.payload?.link) {
            const onlinePaymentLinkData = {
              patient_default_branch_id:
                patientIpdInfo?.patient_default_branch_id,
              name: patientIpdInfo?.patient_name,
              phone: patientIpdInfo?.phone,
              email: patientIpdInfo?.email,
              invoice_no: invoicePaymemntDetails?.invoice_no,
              transaction_id: e?.payload?.transaction_id,
              amount: upayAmountData?.amount || 0,
              link: e?.payload?.link,
            }
            // dispatch(clearLabInvoicePatientData())
            setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal)
            setOnlinePaymentLinkData(onlinePaymentLinkData)
          } else {
            setinvoiceConformationModal(true)
          }
        }
      }
    )
  }

  const onlinePaymentLinkModal = () => {
    setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal)
    setOnlinePaymentLinkData({})
  }

  useEffect(() => {
    return () => {
      dispatch(clearIpdInvoiceData())
      dispatch(clearIpdStoreData())
    }
  }, [])

  return (
    <>
      {showPaymentModeModal && (
        <Popup
          Children={AddPaymentModeModal}
          popData={paymentModeModalData}
          handleClose={handlePaymentMode}
          handleYes={addPaymentMode}
        />
      )}
      {invoiceConformationModal && (
        <Popup
          Children={InvoiceConformationModal}
          // popData={searchModalData}
          handleClose={() =>
            setinvoiceConformationModal(!invoiceConformationModal)
          }
          handleNo={handleExit}
          setModelOpenClose={() => {
            const requestedData = {
              _id: invoicePaymemntDetails?._id,
              email: patientIpdInfo?.email,
              is_email: false,
              offset: moment()?.utcOffset(),
            }
            requestToDownloadPdf(
              SETTLED_INVOICE_VIEW,
              requestGenerator(requestedData),
              's',
              setPdfLoader,
              'print'
            )
            handleExit()
          }}
          heading="Print Invoice"
          message="Do you want to print invoice?"
        />
      )}
      {showUpayAmountModal && (
        <Popup
          Children={PaymentLinkModal}
          popData={upayAmountData}
          handleClose={handleUpayAmountModalClose}
          setModelOpenClose={addUpayData}
        />
      )}

      {showOnlinePaymentModeModal && (
        <Popup
          Children={OnlinePaymentModal}
          popData={onlinePaymentLinkData}
          handleClose={onlinePaymentLinkModal}
          setModelOpenClose={setShowOnlinePaymentModeModal}
          message="/ReceptionistIPD/PatientIPDInfo"
        />
      )}

      <form className={styles.mainContainer}>
        <div className={styles.mainServiceContainer}>
          {/*invoiceInfoContainer*/}
          <div className={styles.invoiceInfoContainer}>
            <div className={styles.invoiceNoContainer}>
              <p className={styles.invoiceTextStyle}>Invoice no :</p>
              <p className={styles.invoiceStyle}>
                {invoicePaymemntDetails?.invoice_no
                  ? handlePadStart(invoicePaymemntDetails?.invoice_no)
                  : ''}
              </p>
            </div>
          </div>

          {/* payment container */}
          <div className={styles.paymentMainContainer}>
            <div className={styles.paymentContainer}>
              {!!(
                invoicePaymemntDetails?.invoice_no &&
                invoicePaymemntDetails?.insurance_claim_amount
              ) && (
                <div className={styles.amountStyle}>
                  Amount to be Claimed:
                  {allowedNumberOfDigitsAfterDecimal(
                    invoicePaymemntDetails?.insurance_claim_amount,
                    3
                  )}
                </div>
              )}

              {!!invoicePaymemntDetails?.invoice_no && (
                <div className={styles.outstandingStyle}>
                  Outstanding Amount:{' '}
                  {allowedNumberOfDigitsAfterDecimal(
                    invoicePaymemntDetails?.outstanding_amount,
                    3
                  )}
                </div>
              )}

              {!!invoicePaymemntDetails?.invoice_no && (
                <div className={styles.advanceDueStyle}>
                  Advance Due:{' '}
                  {allowedNumberOfDigitsAfterDecimal(
                    patientIpdInfo?.advance_amount,
                    3
                  )}
                </div>
              )}

              <div className={styles.buttonContainer}>
                <Button
                  type="button"
                  title="Add Payment Mode"
                  customClass={styles.addPaymentStyle}
                  handleClick={handlePaymentMode}
                />
                <div>
                  <Button
                    type="button"
                    title="Customer's E-Signature"
                    customClass={styles.eSignButtonStyle}
                    disable={true}
                  />
                </div>

                <PrintIcon customClass={styles.printIcon} />
                <ExportIcon customClass={styles.exportIcon} />
              </div>
            </div>

            <div className={styles.paymentDetailContainer}>
              <p
                className={styles.linkGenreateContainer}
                onClick={handleUpayAmountModalClose}
              >
                click here to share Upay link
              </p>
              <div className={styles.tableContainer}>
                <TableV2
                  tableHeaderData={ipdInvoicePaymentHeaderData}
                  tableRowData={patientPaymentModeList}
                  // active={false}
                />
              </div>

              <div className={styles.submitPaymentContainer}>
                <Button
                  type="button"
                  title="Submit"
                  customClass={styles.settle8Button}
                  handleClick={handleGenerateLink}
                />
                <Button
                  title="Exit"
                  type="button"
                  customClass={styles.backButton}
                  handleClick={() => handleExit()}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default PatientPayment
