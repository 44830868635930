import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getReportBuilderList } from '../../redux/features/role/roleAsynActions'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { requestGenerator } from '../../utils/payloadGenerator'
import Button from '../../components/common/button/Button'
import Loader from '../../components/common/spinner/Loader'
import TableV2 from '../../components/common/table/tableV2/TableV2'
import Pagination from '../../components/common/pagination/Pagination'
import { reportBuilderHeaderData } from '../../constants/table-data/reportBuilderListHeaderData'
import styles from './reportBuilderList.module.scss'

const ReportBuilderList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isLoading, reportBuilderData } = useAppSelector(
    (state) => state.roleUser
  )

  // Define state variables
  const [patientHistoryDate, setPatientHistoryDate] = useState<string>('')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()

  // useEffect for get reportbuilder list
  useEffect(() => {
    dispatch(getReportBuilderList(requestGenerator({})))
  }, [dispatch, dataPerPage, pageIndex])

  return (
    <>
      {isLoading && <Loader/>}
      <div className={styles.reportPocListContainer}>
        <div className={styles.reportsBtnContainer}>
          <Button
            title="Create Report"
            handleClick={() => {
              navigate('/reportbuilder/createreport')
            }}
          />
        </div>
        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={reportBuilderHeaderData}
            tableRowData={reportBuilderData}
            active={false}
          />
        </div>
        {/* {reportBuilderData?.length > 0 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        )} */}
      </div>
    </>
  )
}

export default ReportBuilderList
