import moment from 'moment';
import styles from './tableData.module.scss';

export const ipdTestHeaderData: any = [
  {
    Header: 'DATE',
    accessor: 'date',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.updatedAt;
      const formattedDate = moment(originalDate).format('DD MMM YYYY');
      return <>{formattedDate}</>;
    },
  },
  {
    Header: 'TEST TYPE',
    accessor: 'TYPE',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.is_internal ? 'Internal' : 'External'}</p>;
    },
  },
  {
    Header: 'TEST DEPT',
    accessor: 'job_type',
  },
  {
    Header: 'TEST NAME',
    accessor: 'nameww',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onClick(
              props?.row?.original?.profile[0]?.profile_name,
              props?.row?.original?.profile[0]?.tests
            );
          }}
        >
          View
        </p>
      );
    },
  },
  {
    Header: 'DOCTOR',
    accessor: 'doctor_name',
  },
  {
    Header: 'RESULTS',
    accessor: 'result',
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => {
            props?.onRowClick(
              props?.row?.original?.profile[0]?.profile_name,
              props?.row?.original?.profile[0]?.tests
            );
          }}
        >
          View
        </p>
      );
    },
  },
  // {
  //   Header: 'NOTES',
  //   accessor: 'notes',
  //   Cell: (props: any) => {
  //     return (
  //       <p
  //         className={styles.blueLinkText}
  //         // onClick={() => {
  //         //   props?.onRowClick(props?.row?.original);
  //         // }}
  //       >
  //         View
  //       </p>
  //     );
  //   },
  // },
  {
    Header: 'STATUS',
    accessor: 'result_status',
  },
];
