import { useEffect, useState } from 'react'
import { IItemTableInventory } from '../../../interfaces/interfaces'
import { SubmitHandler, useForm } from 'react-hook-form'
import Select from 'react-select'
import styles from './addNewItem.module.scss'
import Button from '../../../components/common/button/Button'
import {
  INVENTORY_ITEM_NAME,
  INVENTORY_ITEM_CODE,
  INVENTORY_BASE_UNIT_TYPE,
  INVENTORY_GROUP,
  INVENTORY_QUANTITY,
  INVENTORY_CHARGABLE,
  INVENTORY_COST_PRICE,
  INVENTORY_SELL_PRICE,
  INVENTORY_EXPIRY_DAYS,
  INVENTORY_NOTES,
  INVENTORY_DRUG_CLASS,
  INVENTORY_BRAND,
  INVENTORY_CATEGORY,
} from '../../../constants/constant'
import { inventoryItemTableValidators } from '../../../form-validators/inventoryItemTableValidators'
import { useLocation, useNavigate } from 'react-router'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { disableArrowKey, trimValue } from '../../../utils/utils'
import {
  createInventoryItem,
  updateInventoryItem,
} from '../../../redux/features/inventory-item/inventoryItemAsyncActions'
import Loader from '../../../components/common/spinner/Loader'
import { reactSelectStyle } from '../../../constants/data'

const AddNewItem = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { isLoading } = useAppSelector((state) => state.inventoryMaster)
  const { masterValueData } = useAppSelector((state) => state.login)
  const itemDetailsObjectById = location?.state?.itemDetailData
  let inventoryUnitTypeData = [] as any

  const [costPrice, setCostPrice] = useState('')
  const [sellPrice, setSellPrice] = useState('')

  const handleDecimalsOnValue = (value: any) => {
    const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s
    return value.match(regex)[0]
  }

  useEffect(() => {
    if (itemDetailsObjectById !== undefined) {
      setCostPrice(itemDetailsObjectById?.cost_price)
      setSellPrice(itemDetailsObjectById?.sell_price)
    }
  }, [itemDetailsObjectById])

  masterValueData?.map((item: any) => {
    if (item?.category_name === 'INVENTORY_UNIT_TYPE') {
      inventoryUnitTypeData = inventoryUnitTypeData.concat(item?.values)
    } else {
      return
    }
  })
  const groupOptions: any[] = [
    { label: 'Group', value: 'group' },
    { label: 'Individual', value: 'individual' },
  ]

  const chargableOptions: any[] = [
    { label: 'None', value: 'NONE', disabled: true },
    { label: 'Inventory', value: 'INVENTORY', disabled: true },
    { label: 'Invoice', value: 'INVOICE', disabled: true },
    { label: 'Both', value: 'BOTH' },
  ]

  const inventoryTypeOptions: any[] = [
    { label: 'GENERAL', value: 'GENERAL' },
    { label: 'MEDICINES', value: 'MEDICINES' },
  ]

  const handleKeyDown = (e: any, length: number) => {
    if (e.target.value.length >= length && e.key !== 'Backspace') {
      e.preventDefault()
    }
  }

  // use form
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IItemTableInventory>({})

  const watchType = watch('type')
  const watchGeneralTypeValue =
    watchType?.value === 'GENERAL' || watchType === undefined

  const onSubmit: SubmitHandler<IItemTableInventory> = (data: any) => {
    let dataPayload: any = {}
    dataPayload = {
      id: itemDetailsObjectById?._id,
      ...data,
    }
    dataPayload.quantity = data?.quantity?.length ? data?.quantity : undefined
    dataPayload.expiry_days = data?.expiry_days?.length
      ? data?.expiry_days
      : undefined
    dataPayload.chargable = data.chargable.value
    dataPayload.unit_type = data.base_unit.value
    dataPayload.group = data.group.value
    dataPayload.type = data?.type?.hasOwnProperty('value')
      ? data?.type?.value
      : 'GENERAL'
    dataPayload.medicines_compositions = data?.compositions?.length
      ? data?.compositions
      : undefined
    delete dataPayload.base_unit
    delete dataPayload.code

    console.log('dataPayload', dataPayload)

    if (itemDetailsObjectById?._id) {
      dispatch(updateInventoryItem(requestGenerator(dataPayload))).then((e) => {
        if (e.type === 'inventoryItem/updateInventoryItem/fulfilled') {
          navigate('/inventoryitemtable')
        }
      })
    } else {
      dispatch(createInventoryItem(requestGenerator(dataPayload))).then((e) => {
        if (e.type === 'inventoryItem/createInventoryItem/fulfilled') {
          navigate('/inventoryitemtable')
        }
      })
    }
  }

  // set the data on form
  useEffect(() => {
    if (itemDetailsObjectById) {
      reset(itemDetailsObjectById)
      const groupFind = groupOptions.find(
        (itm: any) => itm.value === itemDetailsObjectById.group
      )
      const chargableFind = chargableOptions.find(
        (itm: any) => itm.value === itemDetailsObjectById.chargable
      )

      setValue(INVENTORY_GROUP, groupFind)
      setValue(INVENTORY_CHARGABLE, chargableFind)
      setValue(INVENTORY_BASE_UNIT_TYPE, {
        label: itemDetailsObjectById?.base_unit_type?.value,
        value: itemDetailsObjectById?.base_unit_type?._id,
      })
      setValue('type', {
        label: itemDetailsObjectById?.type,
        value: itemDetailsObjectById?.type,
      })
      setValue('compositions', itemDetailsObjectById?.medicines_compositions)
      setValue('drug_class', itemDetailsObjectById?.drug_class)
      setValue('brand', itemDetailsObjectById?.brand)
      setValue('notes', itemDetailsObjectById?.notes)
      setValue('category', itemDetailsObjectById?.category)
    }
  }, [reset, itemDetailsObjectById])

  console.log('itemDetailsObjectById', itemDetailsObjectById)

  const handleReset = () => {
    reset()
    setValue(INVENTORY_ITEM_NAME, '')
    setValue(INVENTORY_ITEM_CODE, '')
    setValue(INVENTORY_BASE_UNIT_TYPE, '')
    setValue(INVENTORY_GROUP, '')
    setValue(INVENTORY_QUANTITY, '')
    setValue(INVENTORY_CHARGABLE, '')
    setValue(INVENTORY_COST_PRICE, '')
    setValue(INVENTORY_SELL_PRICE, '')
    setValue(INVENTORY_EXPIRY_DAYS, '')
    setValue(INVENTORY_BRAND, '')
    setValue(INVENTORY_DRUG_CLASS, '')
    setValue(INVENTORY_NOTES, '')
    setValue(INVENTORY_CATEGORY, '')
    setCostPrice('')
    setSellPrice('')
  }

  const typeInventory: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Type<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <Select
            className={styles.select}
            closeMenuOnSelect={true}
            isSearchable={true}
            defaultValue={{
              label: inventoryTypeOptions?.[0]?.label,
              value: inventoryTypeOptions?.[0]?.value,
            }}
            value={watch('type')}
            {...register('type')}
            options={inventoryTypeOptions}
            onChange={(e: any) => {
              setValue('type', e)
            }}
            maxMenuHeight={200}
          />
        </div>
      </div>
    )
  }

  const ItemNameInput = () => (
    <div className={styles.labelField}>
      <label htmlFor={INVENTORY_ITEM_NAME} className={styles.labelText}>
        Item Name <span className="asterick">*</span>
      </label>
      <div className={styles.fieldErrorContainer}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Enter Item Name"
          {...register(
            INVENTORY_ITEM_NAME,
            inventoryItemTableValidators[INVENTORY_ITEM_NAME]
          )}
          onChange={(e) => trimValue(e)}
        />
        {errors[INVENTORY_ITEM_NAME] && (
          <p className="dashboardFormError">
            {errors[INVENTORY_ITEM_NAME].message as any}
          </p>
        )}
      </div>
    </div>
  )

  const ItemCodeInput = () => (
    <div className={styles.labelField}>
      <label htmlFor={INVENTORY_ITEM_CODE} className={styles.labelText}>
        Item Code <span className="asterick">*</span>
      </label>
      <div className={styles.fieldErrorContainer}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Enter Item Code"
          {...register(
            INVENTORY_ITEM_CODE,
            inventoryItemTableValidators[INVENTORY_ITEM_CODE]
          )}
          onChange={(e) => trimValue(e)}
        />
        {errors[INVENTORY_ITEM_CODE] && (
          <p className="dashboardFormError">
            {errors[INVENTORY_ITEM_CODE].message as any}
          </p>
        )}
      </div>
    </div>
  )

  const Input_SellPrice: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Sell Price<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <input
            type="text"
            min="1"
            step={1}
            className={styles.inputField}
            placeholder="Enter Sell Price"
            {...register(
              INVENTORY_SELL_PRICE,
              inventoryItemTableValidators[INVENTORY_SELL_PRICE]
            )}
            onChange={(e: any) => {
              trimValue(e)
              setSellPrice(handleDecimalsOnValue(e.target.value))
            }}
            value={sellPrice}
          />
          {errors[INVENTORY_SELL_PRICE] && (
            <p className="errorText">
              {errors[INVENTORY_SELL_PRICE].message as any}
            </p>
          )}
        </div>
      </div>
    )
  }

  const Input_CostPrice: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Cost Price<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <input
            type="text"
            min="1"
            step={1}
            className={styles.inputField}
            placeholder="Enter Cost Price"
            {...register(
              INVENTORY_COST_PRICE,
              inventoryItemTableValidators[INVENTORY_COST_PRICE]
            )}
            onChange={(e: any) => {
              trimValue(e)
              setCostPrice(handleDecimalsOnValue(e.target.value))
            }}
            value={costPrice}
            id="cost_price"
          />
          {errors[INVENTORY_COST_PRICE] && (
            <p className="errorText">
              {errors[INVENTORY_COST_PRICE].message as any}
            </p>
          )}
        </div>
      </div>
    )
  }

  const Input_Quantity: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Quantity<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <input
            type="number"
            min="1"
            step={1}
            className={styles.inputField}
            placeholder="Enter Quantity"
            {...register('quantity', {
              required: watchGeneralTypeValue,
              pattern: {
                value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
                message: 'Please enter number upto 6 digit',
              },
            })}
            onChange={(e: any) => {
              trimValue(e)
            }}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
          />
          {errors[INVENTORY_QUANTITY] && (
            <p className="errorText">Please enter quantity upto 6 digits</p>
          )}
        </div>
      </div>
    )
  }

  const Input_ExpiryDays: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Expiry Days<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <input
            type="number"
            min="1"
            step={1}
            className={styles.inputField}
            placeholder="Enter Expiry Days"
            {...register('expiry_days', {
              required: watchGeneralTypeValue,
              pattern: {
                value: /^[0-9]{0,6}(?:\.[0-9]{1,3})?$/,
                message: 'Please enter expiry days upto 6 digit',
              },
            })}
            onChange={(e: any) => {
              trimValue(e)
            }}
            onKeyDown={(e: any) => disableArrowKey(e)}
            onWheel={(e: any) => {
              e.target.blur()
            }}
          />
          {errors[INVENTORY_EXPIRY_DAYS] && (
            <p className="errorText">Please enter expiry days upto 6 digits</p>
          )}
        </div>
      </div>
    )
  }

  const Select_Group: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Group<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <Select
            className={styles.select}
            placeholder="Group"
            closeMenuOnSelect={true}
            isSearchable={true}
            value={watch(INVENTORY_GROUP)}
            {...register(
              INVENTORY_GROUP,
              inventoryItemTableValidators[INVENTORY_GROUP]
            )}
            options={groupOptions}
            onChange={(e: any) => {
              setValue(INVENTORY_GROUP, e)
              trigger(INVENTORY_GROUP)
            }}
            maxMenuHeight={200}
          />
          {errors[INVENTORY_GROUP] && (
            <p className="errorText">
              {errors[INVENTORY_GROUP].message as any}
            </p>
          )}
        </div>
      </div>
    )
  }

  const Select_Chargable: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Chargeable<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <Select
            className={styles.select}
            placeholder="Chargeable"
            closeMenuOnSelect={true}
            isSearchable={true}
            value={watch(INVENTORY_CHARGABLE)}
            {...register(
              INVENTORY_CHARGABLE,
              inventoryItemTableValidators[INVENTORY_CHARGABLE]
            )}
            options={chargableOptions}
            onChange={(e: any) => {
              setValue(INVENTORY_CHARGABLE, e)
              trigger(INVENTORY_CHARGABLE)
            }}
            maxMenuHeight={200}
            isOptionDisabled={(option) => option.disabled}
          />
          {errors[INVENTORY_CHARGABLE] && (
            <p className="errorText">
              {errors[INVENTORY_CHARGABLE].message as any}
            </p>
          )}
        </div>
      </div>
    )
  }

  const Select_BaseUnit: any = () => {
    return (
      <div className={styles.labelField}>
        <label className={styles.labelText}>
          Base Unit Type<span className="asterick">*</span>
        </label>
        <div className={styles.fieldErrorContainer}>
          <Select
            className={styles.select}
            placeholder="Base Unit Type"
            closeMenuOnSelect={true}
            isSearchable={true}
            value={watch(INVENTORY_BASE_UNIT_TYPE)}
            {...register(
              INVENTORY_BASE_UNIT_TYPE,
              inventoryItemTableValidators[INVENTORY_BASE_UNIT_TYPE]
            )}
            options={inventoryUnitTypeData?.map((item: any) => ({
              label: item?.value,
              value: item?._id,
            }))}
            onChange={(e: any) => {
              setValue(INVENTORY_BASE_UNIT_TYPE, e)
              trigger(INVENTORY_BASE_UNIT_TYPE)
            }}
            styles={reactSelectStyle}
            maxMenuHeight={200}
          />
          {errors[INVENTORY_BASE_UNIT_TYPE] && (
            <p className="errorText">
              {errors[INVENTORY_BASE_UNIT_TYPE].message as any}
            </p>
          )}
        </div>
      </div>
    )
  }

  const submitButton = () => (
    <div className={styles.btnContainer}>
      <Button title="Submit" type="submit" />
      <Button
        title="Reset"
        type="button"
        customClass={styles.backBtn}
        handleClick={handleReset}
      />
      <Button
        title="Back"
        customClass={styles.backBtn}
        type="button"
        handleClick={() => {
          navigate('/inventoryitemtable')
        }}
      />
    </div>
  )

  const medicalComposition = () => (
    <div className={styles.labelField}>
      <label className={styles.labelText}>
        Composition<span className="asterick">*</span>
      </label>
      <div className={styles.fieldErrorContainer}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Enter Composition"
          {...register('compositions', { required: !watchGeneralTypeValue })}
          onChange={(e) => trimValue(e)}
        />
        {errors?.compositions?.type === 'required' && (
          <p className="dashboardFormError">Please enter composition</p>
        )}
      </div>
    </div>
  )

  const drugClass = () => (
    <div className={styles.labelField}>
      <label className={styles.labelText}>Drug Class</label>
      <div className={styles.fieldErrorContainer}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Enter Drug class"
          {...register('drug_class')}
          onChange={(e) => trimValue(e)}
        />
      </div>
    </div>
  )

  const brand = () => (
    <div className={styles.labelField}>
      <label className={styles.labelText}>Brand</label>
      <div className={styles.fieldErrorContainer}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Enter Brand"
          {...register('brand')}
          onChange={(e) => trimValue(e)}
        />
      </div>
    </div>
  )

  const notes = () => (
    <div className={styles.labelField}>
      <label className={styles.labelText}>Notes</label>
      <div className={styles.fieldErrorContainer}>
        <textarea
          className={styles.inputField}
          placeholder="Enter Notes"
          {...register('notes')}
          onChange={(e) => trimValue(e)}
        />
      </div>
    </div>
  )

  const category = () => (
    <div className={styles.labelField}>
      <label className={styles.labelText}>
        Category <span className="asterick">*</span>
      </label>
      <div className={styles.fieldErrorContainer}>
        <input
          type="text"
          className={styles.inputField}
          placeholder="Enter Category"
          {...register('category', { required: !watchGeneralTypeValue })}
          onChange={(e) => trimValue(e)}
        />
        {errors?.compositions?.type === 'required' && (
          <p className="dashboardFormError">Please enter category</p>
        )}
      </div>
    </div>
  )

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.manageBranchContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputFieldsContainer}>
            <div>
              {typeInventory()}
              {ItemNameInput()}
              {ItemCodeInput()}
              {watchGeneralTypeValue && Input_Quantity()}
              {watchType?.value === 'MEDICINES' && brand()}
              {watchType?.value === 'MEDICINES' && notes()}
            </div>
            <div>
              {Select_BaseUnit()}
              {Select_Group()}
              {Select_Chargable()}
              {watchType?.value === 'MEDICINES' && drugClass()}
            </div>
            <div>
              {Input_CostPrice()}
              {Input_SellPrice()}
              {watchGeneralTypeValue && Input_ExpiryDays()}
              {!watchGeneralTypeValue && medicalComposition()}
              {watchType?.value === 'MEDICINES' && category()}
            </div>

            {submitButton()}
          </div>
        </form>
      </div>
    </>
  )
}

export default AddNewItem
