import ToggleSwitchV2 from "../../../components/common/toggle-switch/ToggleSwitchV2";
import {
  DeleteIcon,
  EditIcon,
} from "../../../components/common/svg-components";
import { useAppDispatch } from "../../../hooks";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { getAllEmployee, updateEmployeeStatus } from "../../../redux/features/employee/employeeAsyncActions";
import { useNavigate } from "react-router-dom";
import { calculateDaysBetweenTwoDates, utcToDate } from "../../../utils/utils";
import styles from "./employees.module.scss";
import { UPDATE_EMPLOYEE_STATUS } from "../../../constants/asyncActionsType";

// Employee Table Data
export const employeeTableHeaderData: any = [
  {
    Header: "EMP ID",
    accessor: (row: any) => {
      const employeeId = row?.emp_id;
      return employeeId ? employeeId : "-";
    },
  },
  {
    Header: "IMAGE",
    accessor: "icon",
    Cell: (props: any) => {
      return (
        <>
          <div className={styles.employeeTableImg}>
            <img
              className={styles.imgStyle}
              src={props?.row?.original?.profile_pic}
              alt="employee-image"
            />
          </div>
        </>
      );
    },
  },
  {
    Header: "NAME",
    accessor: (row: any) => {
      const employeeName = row?.name_en;
      return employeeName ? employeeName : "-";
    },
  },
  {
    Header: "DEPARTMENT",
    Cell: ({ row }: any) => {
      const departments: any = row?.original?.department;
      return departments && departments.length > 0
        ? departments.map((item: any, index: number) => {
          return (
            <div key={`dept-${index}`} className={styles.columnFlex}>
              <span>
                {index + 1}. {item?.name}
              </span>
            </div>
          );
        })
        : "-";
    },
  },
  {
    Header: "SPECIALITY",
    accessor: (row: any) => {
      let specialitiesValue = row?.specialities[0]?.name;
      if (!specialitiesValue) {
        specialitiesValue = row?.specialities?.name;
      }
      return specialitiesValue ? specialitiesValue : "-";
    },
  },
  {
    Header: "DESIGNATION",
    accessor: (row: any) => {
      const designationValue = row?.company_details?.designation;
      return designationValue ? designationValue : "-";
    },
  },
  {
    Header: "REPORTING TO",
    Cell: (props: any) => {
      let reportName = props?.row?.original?.usrReporting?.length > 0 ? props?.row?.original?.usrReporting[0]?.name : '-'
      return reportName;
    },
  },
  {
    Header: "AT WORK",
    Cell: (props: any) => {
      let employeeAtWork = props?.row?.original?.company_details?.joining_date;
      const formatData: any = employeeAtWork
        ? utcToDate(employeeAtWork, true)
        : "";
      let getEmployeeAtWorkDuration = calculateDaysBetweenTwoDates(formatData);
      var currentDate = new Date();
      employeeAtWork = new Date(employeeAtWork);

      const compareDate: any = employeeAtWork > currentDate;
      return <>
        {compareDate ? "-" : getEmployeeAtWorkDuration}
      </>;
    },
  },
  {
    Header: "STATUS",
    Cell: ({ row }: any) => {
      const is_active = row?.original?.status;
      const dispatch = useAppDispatch();
      const handleToggle = (item: any) => {
        console.log(item, 'items')
        const payload = {
          emp_id: item._id,
          user_id: item.user_id,
          status: !item.status,
        };
        dispatch(updateEmployeeStatus(requestGenerator(payload))).then((e) => {
          if (e.type === `${UPDATE_EMPLOYEE_STATUS}/fulfilled`) {
            dispatch(getAllEmployee(requestGenerator({})))
          }
        })
      };
      return (
        <ToggleSwitchV2
          isToggled={is_active}
          handleToggle={() => handleToggle(row?.original)}
        />
      );
    },
  },
  {
    Header: "ACTIONS",
    Cell: ({ row, onRowClick }: any) => {
      const navigate = useNavigate();
      const handleDelete = () => {
        onRowClick({
          isDeleteDialogOpen: true,
          emp_id: row?.original?._id,
        });
      };
      const handleEdit = (item: any) => {
      console.log(item,'item');
        navigate("/employees/addEmployee", {
          state: { user: item },
        });
      };
      return (
        <>
          <div className={styles.employeeTableActions}>
            <EditIcon
              fillColor="#CDD4D8"
              customClass={styles.iconStyle}
              handleClick={() =>
                row?.original?.status && handleEdit(row?.original)
              }
            />
            {/* <DeleteIcon
              fillColor={colors.grey4}
              customClass={styles.deleteIcon}
              handleClick={() => handleDelete()}
            /> */}
          </div>
        </>
      );
    },
  },
];
