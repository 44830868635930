import { FC, useEffect, useState } from 'react';
import styles from './criteria.module.scss';
import {
  CloseIcon,
  SearchButton,
} from '../../../../../components/common/svg-components';
import { colors } from '../../../../../constants/color';
import { genderData, reactSelectStyle } from '../../../../../constants/data';
import Button from '../../../../../components/common/button/Button';
import {
  Cols,
  IInternalCriteriaForm,
} from '../../../../../interfaces/interfaces';
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import Loader from '../../../../../components/common/spinner/Loader';
import TableV3 from '../../../../../components/common/table/tableV3/TableV3';
import { getAllCriteria } from '../../../../../redux/features/internal/internalAsyncActions';
import { requestGenerator } from '../../../../../utils/payloadGenerator';
import Select from 'react-select';
import { CriteriaHeader } from '../../../../../constants/table-data/criteriaExternalData';
import { useForm } from 'react-hook-form';
import {
  INTERNAL_AGE_FROM,
  INTERNAL_AGE_TO,
  INTERNAL_EXTERNAL_SOURCE,
  INTERNAL_GENDER,
  INTERNAL_NATIONALITY,
  INTERNAL_PINCODE,
  INTERNAL_TAG,
} from '../../../../../constants/constant';
import {
  blockInvalidCharacter,
  disableArrowKey,
} from '../../../../../utils/utils';
import {
  createInternalLead,
  getAllLeadCountInfo,
  getInternalLead,
} from '../../../../../redux/features/lead-pool/callCenterAsyncActions';
import Pagination from '../../../../../components/common/pagination/Pagination';
import { getPatientSelectionList } from '../../../../../redux/features/patient-emr/patient/patientAsyncAction';

interface ICriteria {
  handleClose: any;
  handleSubmitData: any;
}
const Criteria: FC<ICriteria> = ({ handleClose, handleSubmitData }) => {
  const dispatch = useAppDispatch();
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const { masterValueData } = useAppSelector((state) => state.login);
  const { isLoading, internalLeadData, totalLeadCountInfo } = useAppSelector(
    (state) => state.callCenter
  );
  const { nationalityData } = useAppSelector((state) => state.patient);

  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };

  const pageIndexOptions = pageIndexArray();

  // For Table
  const data: Cols[] = internalLeadData;
  const columns: Column<Cols>[] = CriteriaHeader;
  const options: TableOptions<Cols> = {
    data,
    columns,
  };
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<IInternalCriteriaForm>({});

  const formData = watch();

  useEffect(() => {
    let dataPayload = {
      category_name: ['ASSIGN_TAG'],
      search: '',
    };
    dispatch(getPatientSelectionList(requestGenerator(dataPayload)));
  }, [dispatch]);

  const onSubmit = async (data: any) => {
    data = {
      ...data,
      [INTERNAL_GENDER]: data[INTERNAL_GENDER]?.value,
      [INTERNAL_NATIONALITY]: data[INTERNAL_NATIONALITY]?.label,
      age_to: Number(formData[INTERNAL_AGE_TO]),
      age_from: Number(formData[INTERNAL_AGE_FROM]),
      tags:
        formData[INTERNAL_TAG]?.length > 0
          ? formData[INTERNAL_TAG]?.map((item: any) => item?.label)
          : [],
      external_source:
        formData[INTERNAL_EXTERNAL_SOURCE]?.length > 0
          ? formData[INTERNAL_EXTERNAL_SOURCE]?.map((item: any) => item?.label)
          : [],
      isAddingData: true,
    };
    dispatch(createInternalLead(requestGenerator(data))).then((e) => {
      if (e?.type === 'callCenter/createInternalLead/fulfilled') {
        dispatch(getAllLeadCountInfo(requestGenerator({})));

        handleClose();
      }
    });
  };

  const handleSearch = () => {
    let requestData = {
      page: 1,
      pageSize: dataPerPage,
      is_active: true,
      gender: formData[INTERNAL_GENDER]?.value,
      age_to: Number(formData[INTERNAL_AGE_TO]),
      age_from: Number(formData[INTERNAL_AGE_FROM]),
      nationality: formData[INTERNAL_NATIONALITY]?.label,
      pincode: formData[INTERNAL_PINCODE],
      tags:
        formData[INTERNAL_TAG]?.length > 0
          ? formData[INTERNAL_TAG]?.map((item: any) => item?.label)
          : [],
      external_source:
        formData[INTERNAL_EXTERNAL_SOURCE]?.length > 0
          ? formData[INTERNAL_EXTERNAL_SOURCE]?.map((item: any) => item?.label)
          : [],
      isAddingData: false,
    };

    dispatch(getInternalLead(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage);
      setPageIndex(1);
    });
  };

  useEffect(() => {
    let requestData = {
      page: pageIndex,
      pageSize: dataPerPage,
      is_active: true,
      gender: formData[INTERNAL_GENDER]?.value,
      age_to: Number(formData[INTERNAL_AGE_TO]),
      age_from: Number(formData[INTERNAL_AGE_FROM]),
      nationality: formData[INTERNAL_NATIONALITY]?.label,
      pincode: formData[INTERNAL_PINCODE],
      tags:
        formData[INTERNAL_TAG]?.length > 0
          ? formData[INTERNAL_TAG]?.map((item: any) => item?.label)
          : [],
      external_source:
        formData[INTERNAL_EXTERNAL_SOURCE]?.length > 0
          ? formData[INTERNAL_EXTERNAL_SOURCE]?.map((item: any) => item?.label)
          : [],
      isAddingData: false,
    };

    dispatch(getInternalLead(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage);
    });
  }, [pageIndex, dataPerPage]);

  const handleReset = () => {
    setValue(INTERNAL_AGE_TO, '');
    setValue(INTERNAL_AGE_FROM, '');
    setValue(INTERNAL_PINCODE, '');
    setValue(INTERNAL_GENDER, '');
    setValue(INTERNAL_NATIONALITY, '');
    setValue(INTERNAL_TAG, []);
    setValue(INTERNAL_EXTERNAL_SOURCE, []);
    let requestData = {
      page: pageIndex,
      pageSize: dataPerPage,
      is_active: true,
      gender: '',
      age_to: '',
      age_from: '',
      nationality: '',
      pincode: '',
      tags: [],
      external_source: [],
      isAddingData: false,
    };

    dispatch(getInternalLead(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage);
    });
  };

  return (
    <>
      {isLoading && <Loader />}

      <div
        className={styles.actionSavePopupContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => handleClose()}
        />
        <form
          className={styles.actionSaveContainer}
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className={styles.title}>Criteria</p>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <div className={styles.labelField}>
                <label className={styles.labelText}>Gender</label>
                <div className={styles.fieldErrorContainer}>
                  <Select
                    className={styles.select}
                    placeholder="Select Gender"
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    value={watch(INTERNAL_GENDER)}
                    {...register(INTERNAL_GENDER)}
                    options={genderData}
                    onChange={(e: any) => {
                      setValue(INTERNAL_GENDER, e);
                      trigger(INTERNAL_GENDER);
                    }}
                    maxMenuHeight={200}
                  />
                </div>
              </div>
              <div className={styles.labelField}>
                <label className={styles.labelText}>Age From</label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="number"
                    className={styles.inputField}
                    placeholder="Enter Age From"
                    {...register(INTERNAL_AGE_FROM)}
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                      blockInvalidCharacter(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  {formData[INTERNAL_AGE_FROM] < 0 && (
                    <p className="errorText">Enter positive value</p>
                  )}
                </div>
              </div>
              <div className={styles.labelField}>
                <label className={styles.labelText}>Age To</label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="number"
                    className={styles.inputField}
                    placeholder="Enter Age To"
                    {...register(INTERNAL_AGE_TO)}
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                      blockInvalidCharacter(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  {formData[INTERNAL_AGE_TO] < 0 && (
                    <p className="errorText">Enter positive value</p>
                  )}
                </div>
              </div>

              <div className={styles.labelField}>
                <label className={styles.labelText}>Nationality</label>
                <div className={styles.fieldErrorContainer}>
                  <Select
                    className={styles.select}
                    placeholder="Select Nationality"
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    value={watch(INTERNAL_NATIONALITY)}
                    {...register(INTERNAL_NATIONALITY)}
                    options={masterValueData
                      ?.filter(
                        (item: any) => item?.category_name === 'NATIONALITY'
                      )[0]
                      ?.values?.map((item: any) => ({
                        label: item?.value,
                        value: item?._id,
                      }))}
                    onChange={(e: any) => {
                      console.log('ee', e);
                      setValue(INTERNAL_NATIONALITY, e);
                      trigger(INTERNAL_NATIONALITY);
                    }}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
              <div className={styles.labelField}>
                <label className={styles.labelText}>Pin Code</label>
                <div className={styles.fieldErrorContainer}>
                  <input
                    type="number"
                    className={styles.inputField}
                    placeholder="Enter Pin Code"
                    {...register(INTERNAL_PINCODE)}
                    onKeyDown={(e: any) => {
                      disableArrowKey(e);
                      blockInvalidCharacter(e);
                    }}
                    onWheel={(e: any) => {
                      e.target.blur();
                    }}
                  />
                  {formData[INTERNAL_PINCODE] < 0 && (
                    <p className="errorText">Enter positive value</p>
                  )}
                </div>
              </div>
              <div className={styles.labelField}>
                <label className={styles.labelText}>Tags</label>
                <div className={styles.fieldErrorContainer}>
                  <Select
                    className={styles.select}
                    placeholder="Select Tags"
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    isMulti
                    value={watch(INTERNAL_TAG) || []}
                    {...register(INTERNAL_TAG)}
                    options={nationalityData[0]?.values?.map((item: any) => ({
                      label: item?.value,
                      value: item?._id,
                    }))}
                    onChange={(e: any) => {
                      setValue(
                        INTERNAL_TAG,
                        e.map((item: any) => {
                          return item;
                        })
                      );
                      trigger(INTERNAL_TAG);
                    }}
                    maxMenuHeight={200}
                    styles={reactSelectStyle}
                  />
                </div>
              </div>
              <div className={styles.labelField}>
                <label className={styles.labelText}>External Source</label>
                <div className={styles.fieldErrorContainer}>
                  <Select
                    className={styles.select}
                    placeholder="Select Source"
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    isMulti
                    value={watch(INTERNAL_EXTERNAL_SOURCE) || []}
                    {...register(INTERNAL_EXTERNAL_SOURCE)}
                    options={masterValueData
                      ?.filter(
                        (item: any) =>
                          item?.category_name === 'SOURCE_AD_CAMPAIGN'
                      )[0]
                      ?.values?.map((item: any) => ({
                        label: item?.value,
                        value: item?._id,
                      }))}
                    onChange={(e: any) => {
                      setValue(
                        INTERNAL_EXTERNAL_SOURCE,
                        e.map((item: any) => {
                          return item;
                        })
                      );
                      trigger(INTERNAL_EXTERNAL_SOURCE);
                    }}
                    maxMenuHeight={200}
                  />
                </div>
              </div>
              <SearchButton
                customClass={styles.searchStyle}
                handleClick={handleSearch}
                height={38}
              />
              <Button
                title="Reset"
                customClass={styles.resetStyle}
                handleClick={handleReset}
                type="button"
              />
              <p className={styles.totalCount}>
                Total:{' '}
                <span className={styles.spanText}>
                  {totalLeadCountInfo?.total} Leads
                </span>
              </p>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              active={false}
              handleClick={(item: any) => handleSubmitData(item)}
            />
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
            <div className={styles.button}>
              <Button
                title="Import All"
                type="submit"
                disable={
                  formData[INTERNAL_PINCODE] < 0 ||
                  formData[INTERNAL_AGE_FROM] < 0 ||
                  formData[INTERNAL_AGE_TO] < 0 ||
                  internalLeadData?.length === 0
                    ? true
                    : false
                }
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default Criteria;
