import { FC, useEffect, useState } from 'react'
import styles from './assignAgent.module.scss'
import ReadOnlyLabelField from '../../readonly-labelfield/ReadOnlyLabelField'
import { useForm } from 'react-hook-form'
import { IAssignAgentForm } from '../../../../pages/call-center-admin/campaign/create-campaigns/createCampaignsInterface'
import { Input } from '../../input/input'
import {
  AGENT_NAME,
  NO_OF_LEADS,
} from '../../../../pages/call-center-admin/campaign/create-campaigns/createCampaignsConstant'
import { assignAgentValidators } from '../../../../pages/call-center-admin/campaign/create-campaigns/createCampaignsValidator'
import { disableArrowKey, trimValue } from '../../../../utils/utils'
import { Label } from '../../label'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { customSelectStyles } from '../../../../styles/customStyles'
import Button from '../../button/Button'
import { CustomRadio } from '../../custom-radio'
import TableV3 from '../../table/tableV3/TableV3'
import Pagination from '../../pagination/Pagination'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { getAllAssignee } from '../../../../redux/features/lead-pool/callCenterAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../spinner/Loader'
import {
  ASSIGN_AGENT_TO_LEADS_TYPE,
  GET_ALL_ASSIGNEE,
  GET_CAMPAIGNS_LEADS_TYPE,
} from '../../../../constants/asyncActionsType'
import {
  assignAgentToLeads,
  getLeadsForCampaign,
} from '../../../../redux/features/call-center-campaign/campaignAsyncActions'
import { assignAgentTableHeader } from '../../../../constants/table-data/assignAgentTableHeader'

interface IAssignAgentPopup {
  popupData: any
  handleClose: any
  getData: any
}

const AssignAgentPopup: FC<IAssignAgentPopup> = ({
  popupData,
  handleClose,
  getData,
}) => {
  const animatedComponent = makeAnimated()
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState('ASSIGNED')
  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [agentData, setAgentData] = useState<any[]>([])
  const { isLoading, leadsData } = useAppSelector((state) => state.campaign)

  // React table define
  const data: any = leadsData
  const columns: Column<any>[] = assignAgentTableHeader
  const options: TableOptions<any> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state
  const handleFilter = (e: any) => {
    setFilter(e.target.value)
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<IAssignAgentForm>({})
  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const onSubmit = (data: any) => {
    console.log('data', data)
    const payload = {
      ...data,
      [AGENT_NAME]: data[AGENT_NAME]?.value,
      campaign_id: popupData?._id,
    }
    if (Number(data[NO_OF_LEADS]) > Number(popupData?.unassigned_leads)) {
      setError(NO_OF_LEADS, {
        type: 'custom',
        message: `Leads should be less then ${popupData?.unassigned_leads} `,
      })
    } else {
      clearErrors(NO_OF_LEADS)
      dispatch(assignAgentToLeads(requestGenerator(payload))).then((e) => {
        if (e.type === `${ASSIGN_AGENT_TO_LEADS_TYPE}/fulfilled`) {
          getData()
          handleClose()
        }
      })
    }
  }
  useEffect(() => {
    dispatch(
      getAllAssignee(requestGenerator({ search: 'CALL_CENTER_AGENT' }))
    ).then((e) => {
      if (e.type === `${GET_ALL_ASSIGNEE}/fulfilled`) {
        if (e.payload && e.payload?.length > 0) {
          let tempArr: any = []
          tempArr = e.payload?.map((item: any) => {
            return {
              label: item?.name,
              value: item?._id,
            }
          })
          setAgentData(tempArr)
        } else {
          setAgentData([])
        }
      }
    })
  }, [])
  console.log('popupData', popupData)
  useEffect(() => {
    const payload = {
      is_assigned: filter === 'ASSIGNED' ? true : false,
      campaign_id: popupData?._id,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getLeadsForCampaign(requestGenerator(payload))).then((e) => {
      if (e.type === `${GET_CAMPAIGNS_LEADS_TYPE}/fulfilled`) {
        console.log('totalPage', e.payload)
        setTotalPage(e.payload?.lastPage)
      }
    })
  }, [filter, pageIndex, dataPerPage])

  return (
    <>
      {/* {isLoading && <Loader />} */}
      <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.mainContainer}>
          <div className={styles.campaignDetailContainer}>
            <div className={styles.campaignFieldFlex}>
              <ReadOnlyLabelField
                label="Campaign Name"
                field={popupData?.camp_name}
              />
            </div>
            <div>
              <ReadOnlyLabelField
                label="Total"
                field={popupData?.total_leads ?? '-'}
              />
              <ReadOnlyLabelField
                label="Assigned"
                field={popupData?.assigned_leads ?? '-'}
              />
              <ReadOnlyLabelField
                label="Unassigned"
                field={popupData?.unassigned_leads ?? '-'}
              />
            </div>
            {/* <h4 className={styles.criteriaTitle}>Criteria</h4>
            <div className={styles.campaignFieldFlex}>
              <ReadOnlyLabelField
                label="Gender"
                field={popupData?.criteria?.gender ?? 'Male, Female'}
              />
              <ReadOnlyLabelField
                label="Nationality"
                field={popupData?.criteria?.nationality ?? '-'}
              />
            </div> */}
            {/* <div className={styles.campaignFieldFlex}>
              <ReadOnlyLabelField
                label="Age from"
                field={popupData?.criteria?.age_from ?? '-'}
              />
              <ReadOnlyLabelField
                label="Age to"
                field={popupData?.criteria?.age_to ?? '-'}
              />
            </div>
            <div className={styles.campaignFieldFlex}>
              <ReadOnlyLabelField
                label="PIN CODE"
                field={popupData?.criteria?.pin_code ?? '-'}
              />
              <ReadOnlyLabelField
                label="External Source"
                field={popupData?.criteria?.external_source ?? '-'}
              />
            </div> */}
            {/* <div className={styles.campaignFieldFlex}>
              <ReadOnlyLabelField
                label="Tags"
                field={
                  popupData?.criteria?.tag_source &&
                  popupData?.criteria?.tag_source?.length > 0
                    ? popupData?.criteria?.tag_source?.join(' ')
                    : '-'
                }
              />
            </div> */}
          </div>
          <div className={styles.campaignAssignFormContainer}>
            {/* <ReadOnlyLabelField
              label="Total"
              field={popupData?.total_leads ?? '-'}
            />
            <ReadOnlyLabelField
              label="Assigned"
              field={popupData?.assigned_leads ?? '-'}
            />
            <ReadOnlyLabelField
              label="Unassigned"
              field={popupData?.unassigned_leads ?? '-'}
            /> */}
            <div className={styles.assignAgentForm}>
              <Input
                type="number"
                placeholder="Enter Number of leads"
                {...register(NO_OF_LEADS, assignAgentValidators[NO_OF_LEADS])}
                onChange={(e: any) => trimValue(e)}
                onKeyDown={(e: any) => disableArrowKey(e)}
                onWheel={(e: any) => {
                  e.target.blur()
                }}
                htmlFor={NO_OF_LEADS}
                labelText="No. of leads"
                requiredField={true}
                labelClass={styles.customLable}
                errorSpan={styles.errorSpan}
                errorMessage={errors?.[NO_OF_LEADS]?.message}
                showErrors={errors?.[NO_OF_LEADS]}
              />
              <div className={styles.selectFields}>
                <div className={styles.selectInputFieldContainer}>
                  <Label
                    htmlFor={AGENT_NAME}
                    labelText="Agent"
                    requiredField
                    customClass={styles.customLable}
                  />
                  <Select
                    className={styles.selectInputField}
                    placeholder={
                      <span className="selectPlaceholder">Select Agent</span>
                    }
                    {...register(AGENT_NAME, assignAgentValidators[AGENT_NAME])}
                    isSearchable={true}
                    isClearable={true}
                    value={watch(AGENT_NAME || {})}
                    options={agentData}
                    components={animatedComponent}
                    closeMenuOnSelect={true}
                    styles={customSelectStyles}
                    onChange={(e: any) => {
                      setValue(AGENT_NAME, e)
                      trigger(AGENT_NAME)
                    }}
                  />
                </div>
                {errors?.[AGENT_NAME]?.message && (
                  <div className={styles.errorContainer}>
                    <span className={styles.extraSpan} />
                    <p className="dashboardFormError">
                      {errors?.[AGENT_NAME]?.message}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <Button
            title="Assign Agents"
            type="submit"
            disable={
              !popupData?.unassigned_leads || popupData?.unassigned_leads === 0
                ? true
                : false
            }
          />
        </div>
        <div className={styles.radioFieldMainContainer}>
          <CustomRadio
            id="assigned"
            label="Assigned"
            value="ASSIGNED"
            name="type"
            checked={filter === 'ASSIGNED'}
            onChange={handleFilter}
          />
          <CustomRadio
            id="unassignd"
            label="Unassigned"
            value="UNASSIGNED"
            name="type"
            checked={filter === 'UNASSIGNED'}
            onChange={handleFilter}
          />
        </div>
        <div className={styles.leadsTableContainer}>
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>
          {/* Pagination */}
          {leadsData && leadsData.length !== 0 ? (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          ) : (
            ''
          )}
        </div>
      </form>
    </>
  )
}

export default AssignAgentPopup
