import { useEffect, FC, useState } from 'react'
import { pharmacyMedicationHeaderData } from '../../../../constants/table-data/ipdMedicationTableData'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getDiagnosisPatientMedications } from '../../../../redux/features/doctor-diagnosis/doctorDiagnosisAsyncActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import TableV2 from '../../table/tableV2/TableV2'
import styles from './pharmacyMedication.module.scss'
import Loader from '../../spinner/Loader'
import Pagination from '../../pagination/Pagination'
import { SearchButton } from '../../svg-components'

interface IPharmacyMedication {
  handleView?: any
  handleRow?: any
}

const PharmacyMedication: FC<IPharmacyMedication> = ({
  handleView,
  handleRow,
}) => {
  const { isLoading, allDiagnosisMedication } = useAppSelector(
    (state) => state.doctorDiagnosis
  )
  const { patientDataObjectById } = useAppSelector((state) => state.patient)
  const dispatch = useAppDispatch()

  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [totalData, setTotalData] = useState(0)
  const [searchDate, setSearchDate] = useState('')

  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }

  const pageIndexOptions = pageIndexArray()

  useEffect(() => {
    let data = {
      patient_id: patientDataObjectById?._id,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getDiagnosisPatientMedications(requestGenerator(data))).then(
      (result) => {
        setTotalPage(result.payload.lastPage)
        setTotalData(result.payload.total)
      }
    )
  }, [pageIndex, dataPerPage])

  const handleSearch = () => {
    let dateString = new Date(searchDate)
    let data = {
      patient_id: patientDataObjectById?._id,
      page: 1,
      pageSize: dataPerPage,
      date: dateString.toISOString(),
    }
    if (searchDate?.length > 0) {
      dispatch(getDiagnosisPatientMedications(requestGenerator(data))).then(
        (result) => {
          setTotalPage(result.payload.lastPage)
          setTotalData(result.payload.total)
        }
      )
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.searchContainer}>
        <input
          type="date"
          className={styles.inputDate}
          onChange={(e) => {
            setSearchDate(e.target.value)
            if (e.target.value === '') {
              let data = {
                patient_id: patientDataObjectById?._id,
                page: pageIndex,
                pageSize: dataPerPage,
              }
              dispatch(
                getDiagnosisPatientMedications(requestGenerator(data))
              ).then((result) => {
                setTotalPage(result.payload.lastPage)
                setTotalData(result.payload.total)
              })
            }
          }}
        />
        <SearchButton
          handleClick={() => {
            handleSearch()
          }}
          customClass={styles.inputSearchButton}
        />
      </div>

      <div style={{ minHeight: '70px' }} className={styles.tableContainer}>
        <TableV2
          tableHeaderData={pharmacyMedicationHeaderData}
          tableRowData={allDiagnosisMedication}
          active={false}
          handleRowClick={handleView}
          handleRow={(id: any, item: any, row: any) => {
            if (handleRow) {
              handleRow(id, item, row)
            }
          }}
        />
        {totalData > 10 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
      </div>
    </>
  )
}

export default PharmacyMedication
