import moment from 'moment'

export const CrmSentHeaderData: any = [
  {
    Header: 'DATE',
    accessor: 'date',
    Cell: ({ row }: any) => {
      return moment(row?.appointment_date).format('DD/MM/YYYY') ?? '-'
    },
  },
  {
    Header: 'FILE ID',
    accessor: 'emr_no',
  },
  {
    Header: 'PATIENT NAME',
    accessor: 'patient_name',
  },
]
