import { useEffect, useState } from "react";
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Pagination from "../../../components/common/pagination/Pagination";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { ledgerTableHeaderData } from "./ledgerTableData";
import Loader from "../../../components/common/spinner/Loader";
import { requestGenerator } from "../../../utils/payloadGenerator";
import TableV3 from "../../../components/common/table/tableV3/TableV3";
import { Label } from "../../../components/common/label";
import { costCenterOptions, monthOptions } from "../../../constants/data";
import styles from "./ledger.module.scss";
import LedgerViewModal from "./ledger- view-modal/LedgerViewModal";
import Popup from "../../../components/common/popup/Popup";
import { getAllLedgerBalance } from "../../../redux/features/ledger/ledgerAsyncActions";
import { Input } from "../../../components/common/input/input";
import { SearchIcon } from "../../../components/common/svg-components";
import moment from "moment";
import {
  getAccountNumber,
  getAllBranch,
  getAllDepartment,
} from "../../../redux/features/vouchers/vouchersAsynActions";
import { getAllDoctorList } from "../../../redux/features/receptionist/receptionistAsyncActions";

export interface Cols {}
const Ledger = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const firstDay = new Date(currentYear, 0, 1); // January 1st

  const [yearsFilter, setYearsFilter] = useState<any>({
    value: currentYear,
    label: currentYear,
  });
  const [monthsFilter, setMonthsFilter] = useState<any>(
    monthOptions.find((option) => option.value === currentMonth.toString())
  );
  const [accountsFilter, setAccountsFilter] = useState<any>();
  const [showViewBalancePopup, setShowViewBalance] = useState<boolean>(false);
  const [viewBalancePopData, setViewBalancePopData] = useState({});
  const [totalCreditAmount, setTotalCreditAmount] = useState(0);
  const [totalDebitAmount, setTotalDebitAmount] = useState(0);
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalData, setTotalData] = useState(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [startDate, setStartdate] = useState<any>(
    moment(firstDay).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment(currentDate).format("YYYY-MM-DD")
  );
  const [costCenter, setCostCenter] = useState<any>("");
  const [costCenterValueList, setCostCenterValueList] = useState<any>([]);
  const [costCenterValue, setCostCenterValue] = useState<any>("");

  const animatedComponent = makeAnimated();
  const dispatch = useAppDispatch();

  const { isLoading, allLedgerBalanceData, allLedgerListDataObject } =
    useAppSelector((state) => state.ledger);
  const { getAccountNum } = useAppSelector((state) => state.vouchers);
  const getAccNum = getAccountNum?.data;
  const getResptionist = useAppSelector((state) => state.receptionist);

  // For Table
  const data: Cols[] = allLedgerBalanceData ?? [];
  const columns: Column<Cols>[] = ledgerTableHeaderData ?? [];
  const options: TableOptions<Cols> = {
    data,
    columns,
  };
  // For Table
  const {
    // @ts-ignore
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy);
  // @ts-ignore

  // Credit Amount
  useEffect(() => {
    const calculatedTotalCreditAmount = allLedgerBalanceData?.reduce(
      (sum: any, row: any) => {
        const creditAmount = parseFloat(row?.credit_amount);
        return sum + creditAmount;
      },
      0
    );

    setTotalCreditAmount(calculatedTotalCreditAmount);
  }, [allLedgerBalanceData]);

  // Debit Amount
  useEffect(() => {
    const calculatedTotaldebitAmount = allLedgerBalanceData?.reduce(
      (sum: any, row: any) => {
        const debitAmount = parseFloat(row?.debit_amount);
        return sum + debitAmount;
      },
      0
    );
    setTotalDebitAmount(calculatedTotaldebitAmount);
  }, [allLedgerBalanceData]);

  /* Initial API call for ledger Balance Sheet */

  const getData = () => {
    let requestData = {
      // month: monthsFilter?.hasOwnProperty('value')
      //   ? Number(monthsFilter?.value)
      //   : undefined,
      // year: yearsFilter?.hasOwnProperty('value')
      //   ? yearsFilter?.value
      //   : undefined,
      range: {
        fromDate: new Date(startDate),
        // toDate: endDateMoment,
        toDate: new Date(endDate),
      },
      cost_center: {
        label: costCenter?.label,
        value: costCenterValue?.label,
      },
      account_id: accountsFilter?.value ? accountsFilter?.value : undefined,
      page: pageIndex,
      pageSize: dataPerPage,
    };
    if (startDate && endDate && accountsFilter) {
      dispatch(getAllLedgerBalance(requestGenerator(requestData))).then(
        (result) => {
          setTotalPage(result.payload.lastPage);
          setTotalData(result.payload.totalData);
        }
      );
    }
    // else {
    //   dispatch(
    //     setMessage({
    //       type: failure,
    //       message: 'Please select both From and End Date to Filter',
    //     })
    //   )
    // }
  };

  useEffect(() => {
    getData();
  }, [dataPerPage, dispatch, pageIndex, accountsFilter]);

  useEffect(() => {
    dispatch(
      getAccountNumber(
        requestGenerator({
          isFinalLevel: true,
          search: "",
        })
      )
    );
  }, []);

  // Dynamic year
  const startYear = currentYear - 5;
  let yearOptions = [];

  for (let i = startYear; i <= currentYear; i++) {
    yearOptions?.push({ value: i, label: i });
  }
  const handleViewBalance = () => {
    setShowViewBalance((prevState) => !prevState);
  };
  const handleViewBalancePopup = (viewObject: {}) => {
    setShowViewBalance((prevState) => !prevState);
    setViewBalancePopData(viewObject);
  };

  // Pagination
  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  const handleSearchData = () => {
    getData();
  };
  useEffect(() => {
    if (getAccNum && getAccNum?.length > 0) {
      setAccountsFilter({
        label: getAccNum[0]?.name,
        value: getAccNum[0]?.code,
      });
    } else {
      setAccountsFilter(undefined);
    }
  }, [getAccNum]);

  //  API calls for call center
  useEffect(() => {
    let payloadData = {
      search: "",
      page: 1,
      pageSize: 1000,
    };
    if (costCenter?.label === "Branch") {
      dispatch(getAllBranch(requestGenerator(payloadData))).then((e) => {
        setCostCenterValueList(e?.payload?.data);
        setCostCenterValue("");
      });
    } else if (costCenter?.label === "Doctor") {
      dispatch(getAllDoctorList(requestGenerator(payloadData))).then((e) => {
        setCostCenterValueList(e?.payload?.data);
        setCostCenterValue("");
      });
    } else {
      dispatch(getAllDepartment(requestGenerator(payloadData))).then((e) => {
        setCostCenterValueList(e?.payload?.data);
        setCostCenterValue("");
      });
    }
  }, [costCenter]);

  return (
    <>
      {isLoading || getResptionist?.isLoading && <Loader />}
      {showViewBalancePopup && (
        <Popup
          Children={LedgerViewModal}
          popData={viewBalancePopData}
          handleClose={() => handleViewBalance()}
        />
      )}
      <div className={styles.ledgerBalanceListContainer}>
        {/* Filters */}
        <form className={styles.ledgerBalanceFilter}>
          <div className={styles.formFields}>
            <div className={styles.formFieldsFirstContainer}>
              {/* Years */}
              <div className={styles.inputFieldContainer}>
                <Label
                  htmlFor={"From"}
                  labelText="From"
                  customClass={styles.customLabel}
                />
                {/* <Select
                className={styles.selectInputField}
                placeholder={
                  <span className="selectPlaceholder">Select year</span>
                }
                isClearable={true}
                isSearchable={true}
                options={yearOptions}
                value={yearsFilter || ''}
                components={animatedComponent}
                closeMenuOnSelect={true}
                maxMenuHeight={120}
                styles={customDropdownStyles}
                onChange={(e: any) => {
                  setYearsFilter(e)
                }}
              /> */}
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e: any) => setStartdate(e.target.value)}
                />
              </div>
              {/* Months */}
              <div className={styles.inputFieldContainer}>
                <Label
                  htmlFor={"TO"}
                  labelText="To"
                  customClass={styles.customLabel}
                />

                <Input
                  type="date"
                  value={endDate}
                  onChange={(e: any) => setEndDate(e.target.value)}
                />
              </div>
              {/* account */}
              <div className={styles.inputFieldContainer}>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">Select Account</span>
                  }
                  // isClearable={true}
                  isSearchable={true}
                  options={
                    getAccNum && getAccNum?.length > 0
                      ? getAccNum?.map((item: any) => {
                          return {
                            label: item?.name,
                            value: item?.code,
                          };
                        })
                      : []
                  }
                  value={accountsFilter}
                  components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={190}
                  onChange={(e: any) => {
                    setAccountsFilter(e);
                  }}
                />
              </div>
            </div>

            <div className={styles.formFieldsSecondContainer}>
              {/* Cost Center */}
              <div className={styles.inputFieldContainer}>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">Cost Center</span>
                  }
                  // isClearable={true}
                  isSearchable={true}
                  options={costCenterOptions?.map((item: any) => ({
                    label: item?.label,
                    value: item?.value,
                    isDisabled: item?.isdisabled,
                  }))}
                  value={costCenter}
                  onChange={(e) => setCostCenter(e)}
                  // components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={190}
                />
              </div>
              {/* Cost Center Value*/}
              <div className={styles.inputFieldContainer}>
                <Select
                  className={styles.selectInputField}
                  placeholder={
                    <span className="selectPlaceholder">Cost Center Value</span>
                  }
                  // isClearable={true}
                  isSearchable={true}
                  options={costCenterValueList?.map((item: any) => ({
                    label: item?.name
                      ? item?.name
                      : item?.doctor_name
                      ? item?.doctor_name
                      : "",
                    value: item?._id,
                  }))}
                  value={costCenterValue}
                  // components={animatedComponent}
                  closeMenuOnSelect={true}
                  maxMenuHeight={190}
                  onChange={(e: any) => {
                    setCostCenterValue(e);
                  }}
                />
              </div>
              <div
                className={styles.searchButton}
                onClick={() => handleSearchData()}
              >
                <SearchIcon fillColor={"#ffffff"} />
              </div>{" "}
            </div>
          </div>
        </form>
        {/* Table */}
        <div className={styles.openingBalanceContainer}>
          Opening Balance : $ {"  "}
          {allLedgerListDataObject?.openingClosingBalance?.creditOrDebit ===
          "Credit"
            ? allLedgerListDataObject?.openingClosingBalance?.opening_credit ||
              0
            : allLedgerListDataObject?.openingClosingBalance?.opening_debit ||
              0}{" "}
          {allLedgerListDataObject?.openingClosingBalance?.creditOrDebit ==
          "Credit"
            ? "Cr"
            : "Dr"}
        </div>
        <div className={styles.tableMainContainer}>
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              handleClick={handleViewBalancePopup}
              prepareRow={prepareRow}
              active={false}
            />
          </div>

          {/* Pagination */}
          {!!(totalData > 10) && (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
        </div>
        <div className={styles.openingBalanceContainer}>
          Closing Balance :$ {"  "}
          {/* {allLedgerListDataObject?.openingClosingBalance?.creditOrDebit ===
          "Credit"
            ? allLedgerListDataObject?.openingClosingBalance?.closing_credit < 0
              ? allLedgerListDataObject?.openingClosingBalance?.closing_credit *
                -1
              : allLedgerListDataObject?.openingClosingBalance
                  ?.closing_credit || 0
            : allLedgerListDataObject?.openingClosingBalance?.closing_credit < 0
            ? allLedgerListDataObject?.openingClosingBalance?.closing_credit *
              -1
            : allLedgerListDataObject?.openingClosingBalance?.closing_credit ||
              0}{" "}
          {allLedgerListDataObject?.openingClosingBalance?.creditOrDebit ==
          "Credit"
            ? allLedgerListDataObject?.openingClosingBalance?.closing_credit < 0 ?  "Dr" : "Cr"
            : "Dr"} */}
          {allLedgerListDataObject?.openingClosingBalance?.creditOrDebit ===
          "Credit"
            ? allLedgerListDataObject?.openingClosingBalance?.closing_credit < 0
              ? allLedgerListDataObject?.openingClosingBalance?.closing_credit *
                -1
              : allLedgerListDataObject?.openingClosingBalance
                  ?.closing_credit || 0
            : allLedgerListDataObject?.openingClosingBalance?.closing_debit < 0
            ? allLedgerListDataObject?.openingClosingBalance?.closing_debit * -1
            : allLedgerListDataObject?.openingClosingBalance?.closing_debit ||
              0}{" "}
          {allLedgerListDataObject?.openingClosingBalance?.creditOrDebit ==
          "Credit"
            ? allLedgerListDataObject?.openingClosingBalance?.closing_credit < 0
              ? "Dr"
              : "Cr"
            : allLedgerListDataObject?.openingClosingBalance?.closing_debit < 0
            ? "Cr"
            : "Dr"}
        </div>
        <div className={styles.tableLabelContainer}>
          <span className={styles.debitText}>
            Total Debit Amount: $ {totalDebitAmount}
          </span>
          <span className={styles.creditText}>
            Total Credit Amount: $ {totalCreditAmount}
          </span>
        </div>
      </div>
    </>
  );
};
export default Ledger;
