import { FC, useEffect, useState } from "react";
import styles from "./searchModal.module.scss";
import { colors } from "../../../constants/color";
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from "react-table";
import { Cols } from "../../../interfaces/interfaces";
import Divider from "../../../components/common/divider/Divider";
import { CloseIcon, SearchIcon } from "../../../components/common/svg-components/index";
import { trimValue } from "../../../utils/utils";
import TableV3 from "../../../components/common/table/tableV3/TableV3";
import { searchModalHeaderData } from "./SearchModalTableData"
import Pagination from "../../../components/common/pagination/Pagination";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import Loader from "../../../components/common/spinner/Loader";
import { requestGenerator } from "../../../utils/payloadGenerator";
import { getAllSearchUserList } from "../../../redux/features/document/documentAsyncActions";


interface ISearchModal {
  handleClose: (e?: React.MouseEvent<HTMLElement>) => void;
  isEditing?: boolean;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
  setModelOpenClose?: any;
  handleRowClick?: any;
  handleSubmit: (e?: React.MouseEvent<HTMLElement>) => void;
}
const SearchModal: FC<ISearchModal> = ({ handleClose,
  setIsEditing, setModelOpenClose,
  handleRowClick, handleSubmit }) => {
  const dispatch = useAppDispatch();
  const { isLoading, userListData, userListDataObject ,selectedObj} = useAppSelector(
    (state) => state.employee
  )

  // React Table define
  const data: Cols[] = userListData;
  const columns: Column<Cols>[] = searchModalHeaderData;
  const options: TableOptions<Cols> = {
    data,
    columns,
  };
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy);
  // @ts-ignore
  const { globalFilter } = state;
  const [dataPerPage, setDataPerPage] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalData, setTotalData] = useState(0);

  // function for creating Page Index Array
  const pageIndexArray = () => {
    let pageIndexOptions = [];
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i);
    }
    return pageIndexOptions;
  };
  const pageIndexOptions = pageIndexArray();

  useEffect(() => {
    const requestData = {
      search: "",
      page: pageIndex,
      pageSize: dataPerPage,
    };
    dispatch(getAllSearchUserList(requestGenerator(requestData))).then((result) =>{
      setTotalPage(result.payload.lastPage);
    setTotalData(result.payload.total);
  });
  }, [dispatch, dataPerPage, pageIndex]);
  const [searchValue, setSearchValue] = useState<string>("");

  const handleInputSearch = () => {
    setGlobalFilter("");
    const requestData = {
      name: searchValue,
      page: pageIndex,
      pageSize: dataPerPage,
    };
    dispatch(getAllSearchUserList(requestGenerator(requestData))).then((result) => {
      setTotalPage(result.payload.lastPage);
      
    });
    
  };
  useEffect(() => {
    if (searchValue === "") {
      setGlobalFilter("");
      const requestData = {
        name: searchValue,
        page: pageIndex,
        pageSize: dataPerPage,
      };
      
    }
  }, [dispatch, searchValue, dataPerPage, pageIndex]);
  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation();
        }} >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose();
            setIsEditing && setIsEditing(false);
          }} />

        <div className={styles.employeeListContainer}>
          <p className={styles.title}>Employee Search</p>
          <Divider customClass={styles.dividerStyle} />
          <div className={styles.searchFieldContainer}>
            <div className={styles.searchContainer}>
              <SearchIcon fillColor={"#797979"}
                customClass={styles.searchIconStyle} />
              <input
                className={styles.searchInput}
                type="text"
                placeholder="Search By Name"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  setGlobalFilter("");
                  trimValue(e);
                }} />

          <div className={styles.searchButton}
             onClick={() => handleInputSearch()} 
             >
              <SearchIcon fillColor={"#ffffff"} />
            </div>
            </div>   
          </div>

          <div className={styles.tableContainer}>
            <TableV3
              handleRowClick={handleSubmit}
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
              setModelOpenClose={setModelOpenClose}  />
          </div>

          {/* Pagination */}
          {/* {userListData?.length < 9 &&
            userListDataObject?.lastPage === 1 &&
            userListDataObject?.nextPage === 0 &&
            userListDataObject?.previousPage === 0 ? (
            " "
          ) : (
            <Pagination
              setDataPerPage={setDataPerPage}
              pageIndexOptions={pageIndexOptions}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex} />
          )} */}
          
        {totalData > 5 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex} />
        )}
        </div>
      </div>
    </>
  );
};
export default SearchModal;