import React, { FC, useState } from 'react'
import styles from '../notificationModal.module.scss'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import moment from 'moment'
import {
  getAllNotificationList,
  markReadNotification,
} from '../../../../../redux/features/app-notifications/appNotificationAsyncActions'
import { requestGenerator } from '../../../../../utils/payloadGenerator'
import Divider from '../../../divider/Divider'
import {
  PharmacyNotificationIcon,
  RectangleIcon,
} from '../../../svg-components'
import { setMessage } from '../../../../../redux/features/toast/toastSlice'
import { warning } from '../../../../../constants/data'
import { useLocation } from 'react-router-dom'

interface IPharmacyNotificationModal {
  setNotificationModalOpen?: any
  notiRef?: any
  customClassIcon?: any
  customClass?: any
  setData?: any
  patientType?: any
}

const PharmacyNotificationModal: FC<IPharmacyNotificationModal> = ({
  setNotificationModalOpen,
  notiRef,
  customClassIcon = '',
  customClass = '',
  setData,
  patientType,
}) => {
  const dispatch = useAppDispatch()
  const state = useLocation().state
  // const [notificationArrayList, setNotificationArrayList] =
  //   useState<boolean>(false);
  const { notificationListData } = useAppSelector(
    (state) => state.notifications
  )
  const [expandedItems, setExpandedItems] = useState<string[]>([])

  const formatDate = (notificationDate: any) => {
    const currentDate = moment().startOf('day')
    const formattedDate = moment(notificationDate)
    if (formattedDate.isSame(currentDate, 'day')) {
      return 'Today'
    }
    const yesterday = moment().subtract(1, 'day').startOf('day')
    if (formattedDate.isSame(yesterday, 'day')) {
      return 'Yesterday'
    }
    return formattedDate.format('DD MMMM YYYY')
  }

  const handleNotificationTime = (timestamp: any) => {
    const time = moment(timestamp).utcOffset(0, true).format('h:mm A')
    return time
  }

  const handleReadNotification = (item: any) => {
    if (state) {
      dispatch(
        setMessage({ message: 'On-Hold Order already selected', type: warning })
      )
    } else {
      if (patientType === 'regular') {
        setData(item)
        setNotificationModalOpen(false)
        let reqPayload = {
          notification_id: item?._id,
        }
        if (item?.is_read === false) {
          dispatch(markReadNotification(requestGenerator(reqPayload))).then(
            (e) => {
              if ((e.type = 'notification/markReadAppNotification/fulfilled')) {
                dispatch(getAllNotificationList(requestGenerator({})))
              }
            }
          )
        }
      } else {
        dispatch(
          setMessage({
            message: 'Please select regular patient type',
            type: warning,
          })
        )
      }
    }
  }
  const handleReadMore = (itemId: string) => {
    setExpandedItems((prevItems) => [...prevItems, itemId])
  }
  const handleReadLess = (itemId: string) => {
    setExpandedItems((prevItems) => prevItems.filter((id) => id !== itemId))
  }

  const isItemExpanded = (itemId: string) => {
    return expandedItems.includes(itemId)
  }

  return (
    <>
      <div className={styles.NotificationMainContainer} ref={notiRef}>
        <div className={[customClass, styles.mainContainer].join(' ')}>
          <RectangleIcon
            customClass={[customClassIcon, styles.rectangleIconStyle].join(' ')}
          />
          <p className={styles.notificationTitle}>Notifications</p>
          <Divider customClass={styles.headerDividerStyle} />
          <div className={styles.container}>
            {notificationListData?.notifications?.length > 0 ? (
              notificationListData?.notifications?.map(
                (item: any, index: number) => {
                  const showReadMore = item?.body.length > 100
                  const notificationBody = showReadMore
                    ? item?.body.slice(0, 100) + '...'
                    : item?.body
                  const isExpanded = isItemExpanded(item?._id)
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={
                          item?.is_read === true
                            ? styles.readNotificationContainer
                            : styles.unReadNotificationContainer
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          handleReadNotification(item)
                        }}
                      >
                        <div className={styles.notificationBox}>
                          <div className={styles.iconContainer}>
                            {
                              <PharmacyNotificationIcon
                                fillColor={
                                  item?.is_read === true ? '#797979' : '#02BF90'
                                }
                              />
                            }
                          </div>
                          <div className={styles.notificationContentContainer}>
                            <p
                              className={
                                item?.is_read === true
                                  ? styles.readtitleStyle
                                  : styles.unReadtitleStyle
                              }
                            >
                              {item?.title}
                            </p>
                            <p
                              className={
                                item?.is_read === true
                                  ? styles.readDescriptionStyle
                                  : styles.unReadDescriptionStyle
                              }
                            >
                              <p>
                                {isExpanded ? (
                                  <>
                                    {item?.body}
                                    <span
                                      className={styles.readMoreLink}
                                      onClick={() => handleReadLess(item?._id)}
                                    >
                                      Read Less
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {notificationBody}
                                    {showReadMore && (
                                      <span
                                        className={styles.readMoreLink}
                                        onClick={() =>
                                          handleReadMore(item?._id)
                                        }
                                      >
                                        Read More
                                      </span>
                                    )}
                                  </>
                                )}
                              </p>
                            </p>
                            <div className={styles.dateTimeContainer}>
                              <p
                                className={
                                  item?.is_read === true
                                    ? styles.readNotification_dayStyle
                                    : styles.unReadNotification_dayStyle
                                }
                              >
                                {formatDate(item?.notification_date)} -
                              </p>
                              <p
                                className={
                                  item?.is_read === true
                                    ? styles.readNotification_timeStyle
                                    : styles.unReadNotification_timeStyle
                                }
                              >
                                {handleNotificationTime(
                                  item?.notification_date
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {index !==
                        notificationListData?.notifications?.length - 1 && (
                        <Divider customClass={styles.dividerStyle} />
                      )}
                    </React.Fragment>
                  )
                }
              )
            ) : (
              <p className={styles.noRecordTextStyle}>No new notifications</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PharmacyNotificationModal
