import React, { FC, useEffect } from 'react';
import styles from './SettledEmailPopup.module.scss';
import Button from '../../button/Button';
import { CloseIcon } from '../../svg-components';
import { colors } from '../../../../constants/color';
import Divider from '../../divider/Divider';
import { SETTLED_INVOICE_PATIENT_EMAIL } from '../../../../constants/constant';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ISettledInvoicePatientEmail } from '../../../../interfaces/interfaces';
import { settledInvoiceEmailValidator } from '../../../../form-validators/settledInvoiceEmailValidators';
import { trimValue } from '../../../../utils/utils';
import Loader from '../../spinner/Loader';
import { useAppSelector } from '../../../../hooks';

interface ISettledEmailModal {
  handleClose?: any;
  popData?: string | any;
  setModelOpenClose?: any;
  handleYes: any;
}

const SettledEmailPopup: FC<ISettledEmailModal> = ({
  handleClose,
  popData,
  setModelOpenClose,
  handleYes,
}) => {
  // FORM
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ISettledInvoicePatientEmail>({});

  const onSubmit: SubmitHandler<ISettledInvoicePatientEmail> = (data: any) => {
    handleYes({ _id: popData?._id, ...data });
  };

  const { isLoading } = useAppSelector((state) => state.labInvoice);

  useEffect(() => {
    reset(popData);
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={() => {
            handleClose && handleClose();
          }}
        />
        <h1 className={styles.heading}>Email</h1>
        <Divider customClass={styles.dividerStyle} />

        <div className={styles.container}>
          <form
            noValidate
            className={styles.formContainer}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className={styles.labelFieldContainer}>
              <label
                htmlFor={SETTLED_INVOICE_PATIENT_EMAIL}
                className={styles.labelText}
              >
                Email
                <span className="asterick">*</span>
              </label>
              <div className={styles.fieldErrorContainer}>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className={styles.inputField}
                  {...register(
                    SETTLED_INVOICE_PATIENT_EMAIL,
                    settledInvoiceEmailValidator[SETTLED_INVOICE_PATIENT_EMAIL]
                  )}
                  onChange={(e) => trimValue(e)}
                />

                <div className={styles.errorContainer}>
                  <span className={styles.extraSpan}></span>
                  {errors[SETTLED_INVOICE_PATIENT_EMAIL] && (
                    <p className="dashboardFormError">
                      {errors[SETTLED_INVOICE_PATIENT_EMAIL].message as any}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <Button title="Submit" customClass={styles.buttonStyle} />
          </form>
        </div>
      </div>
    </>
  );
};

export default SettledEmailPopup;
