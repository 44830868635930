
import styles from "./employeeSearchModal.module.scss";
export const employeeSearchModalHeaderData: any = [
  {
    Header: 'NAME',
    Cell: (props: any) => {
      return (
        <span
          onClick={() => {
            props.onRowClick(props?.row?.original)
          }}
          style={{ cursor: 'pointer', color: '#0e26a3' }}
        >
          {props?.row?.original?.name}
        </span>
      )
    }
  },
  {
    Header: "SPECIALITIES",
    accessor: (row: any) => {
      const specialitiesValue = row?.specialities[0]?.name
      return specialitiesValue ? specialitiesValue : '-'
    }
  },
  {
    Header: 'DEPARTMENT',
    Cell: ({ row }: any) => {
      const departments: any = row?.original?.departments
      return departments && departments.length > 0
        ? departments.map((item: any, index: number) => {

          return (
            <div key={`dept-${index}`} className={styles.columnFlex}>
              <span>
                {index + 1}. {item?.name}
              </span>
            </div>
          )
        })
        : '-'
    },
  },
  {
    Header: 'MOBILE',
    accessor: (row: any) => {
      const phoneValue = row?.phone
      return phoneValue ? phoneValue : '-'
    }
  },
]

