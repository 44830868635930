import styles from './leaveFormData.module.scss'
import {
  DeleteIcon,
  EditIcon,
} from '../../../../components/common/svg-components'
import { colors } from '../../../../constants/color'
import moment from 'moment'

// Leave Form Table Data
export const leaveFormDataTableHeaderData: any = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'EMPLOYEE',
    accessor: 'employee',
  },
  {
    Header: 'PERIOD',
    accessor: 'period',
  },
  {
    Header: 'DAYS',
    accessor: 'days',
  },
  {
    Header: 'PAID DATES',
    accessor: 'paid_date',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.paid_date
      const formattedDate = moment(originalDate).format('	DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: 'AMOUNT',
    accessor: 'amount',
  },
  {
    Header: 'STATUS',
    accessor: 'status',
    Cell: ({ row }: any) => {
      return (
        <>
          {row?.original?.status ? (
            <span
              className={
                row?.original?.status === 'Paid'
                  ? styles.statusPaid
                  : row?.original?.status === 'Unpaid'
                  ? styles.statusUnpaid
                  : ''
              }
            >
              {row?.original?.status}
            </span>
          ) : (
            '-'
          )}
        </>
      )
    },
  },
  {
    Header: 'ACTIONS',
    Cell: (props: any) => {
      return (
        <>
          <div className={styles.leaveFormDataTableActions}>
            <span className={styles.iconStyle}>
              <EditIcon fillColor={colors.grey4} />
            </span>
            {/* <span className={styles.iconStyle}><DeleteIcon
              fillColor={colors.grey4} handleClick={() => props.onClick()}
            /></span> */}
          </div>
        </>
      )
    },
  },
]
export const leaveFormDataTableDummyData: any[] = [
  {
    id: '1',
    employee: 'Linda Metzmaier',
    period: '2019-22',
    days: '2',
    paid_date: '23 Mar 2022',
    amount: '125',
    status: 'Paid',
  },
  {
    id: '2',
    employee: 'Bradford Hills',
    period: '2019-23',
    days: '2',
    paid_date: '15 Jan 2023',
    amount: '250',
    status: 'Unpaid',
  },
  {
    id: '3',
    employee: 'Priya Rajdive',
    period: '2019-22',
    days: '2',
    paid_date: '15 Jan 2021',
    amount: '125',
    status: 'Paid',
  },
  {
    id: '4',
    employee: 'Linda Metzmaier',
    period: '2019-22',
    days: '2',
    paid_date: '15 Jan 2021',
    amount: '125',
    status: 'Paid',
  },
  {
    id: '5',
    employee: 'Bradford Hills',
    period: '2019-22',
    days: '2',
    paid_date: '15 Jan 2021',
    amount: '125',
    status: 'Paid',
  },
  {
    id: '6',
    employee: 'Priya Rajdive',
    period: '2019-22',
    days: '2',
    paid_date: '15 Jan 2021',
    amount: '125',
    status: 'Paid',
  },
]
