import React, { useState } from 'react'
import styles from './PharmacyPaymentNew.module.scss'
import {
  invoiceFormActionData,
  paymentModeModalData,
} from '../../../constants/data'
import Button from '../../../components/common/button/Button'
import {
  ExportIcon,
  PrintIcon,
} from '../../../components/common/svg-components'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { invoicePaymentHeaderData } from '../../../constants/table-data/labInvoiceTabledata'
import { useLocation, useNavigate } from 'react-router'
import {
  allowedNumberOfDigitsAfterDecimal,
  handleCalculateTotalAmountForPaymentMode,
  handlePadStart,
} from '../../../utils/utils'
import Popup from '../../../components/common/popup/Popup'
import AddPaymentModeModal from '../../../components/common/modal/receipt-add-payment-mode-modal/AddPaymentModeModal'
import {
  savePaymentMode,
  setAmountData,
  updatedNewPaymentAmountArray,
} from '../../../redux/features/invoice-module/invoiceSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import PaymentLinkModal from '../../../components/common/modal/payment-link-modal/payment-link/PaymentLinkModal'
import { generateUpayLink } from '../../../redux/features/invoice-module/invoiceAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import OnlinePaymentModal from '../../../components/common/modal/online-payment-modal/OnlinePaymentModal'
import { clearAllStates } from '../../../redux/features/pharmacy/pharmacySlice'

const PharmacyPaymentNew = () => {
  const [formActionValue, setFormActionValue] = useState(-1)
  const [showPaymentMode, setshowPaymentMode] = useState(false)

  const [amountModal, setamountModal] = useState<boolean>(false)
  const [paymentLinkData, setpaymentLinkData] = useState<any>({})
  const [onlinePaymentLinkData, setOnlinePaymentLinkData] = useState<any>({})
  const [showOnlinePaymentModeModal, setShowOnlinePaymentModeModal] =
    useState<boolean>(false)

  const { paymentModeData } = useAppSelector((state) => state.invoice)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  console.log(location, 'location')

  const handlePaymentMode = () => {
    setshowPaymentMode(!showPaymentMode)
  }

  const addPaymentMode = (item: any) => {
    setshowPaymentMode((prevState) => !prevState)
    let requestPayload: any = {
      payment_mode: item?.payment_mode_name,
      payment_label: item?.payment_mode_label,
      amount: item?.amount ?? 0,
      _id: item?.payment_mode_id,
    }
    dispatch(savePaymentMode(requestPayload))
  }

  const handlePaymentProcess = () => {
    setamountModal(!amountModal)
  }

  const handlePaymentLinkModalClose = () => {
    setamountModal(!amountModal)
    setpaymentLinkData({})
  }

  const addUpayData = (item: any) => {
    setpaymentLinkData(item)
    dispatch(setAmountData(item))
    setamountModal(!amountModal)
  }

  const handleGenerateLink = () => {
    const modifyPaymentModeData = paymentModeData?.map((item: any) => {
      const { _id, ...rest } = item
      return rest
    })
    const requestPayload = {
      invoice_no: location?.state?.order?.invoiceData?.invoice_no,
      partial_payment_array: modifyPaymentModeData,
    }

    dispatch(generateUpayLink(requestGenerator(requestPayload))).then(
      (e: any) => {
        if (e.type === 'invoice/getGenerateUpayLink/fulfilled') {
          if (e?.payload?.link) {
            const onlinePaymentLinkData = {
              name: location?.state?.order?.invoiceData?.pharmacyOrder[0]?.name,
              phone:
                location?.state?.order?.invoiceData?.pharmacyOrder[0]?.phone,
              // email: labInformationData?.email,
              invoice_no: location?.state?.order?.invoiceData?.invoice_no,
              // transaction_id: e?.payload?.transaction_id,
              amount: paymentLinkData?.amount || 0,
              link: e?.payload?.link,
            }
            setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal)
            setOnlinePaymentLinkData(onlinePaymentLinkData)
          } else {
            dispatch(clearAllStates())
            dispatch(updatedNewPaymentAmountArray([]))
            navigate('/pharmacy/pharmacy-info')
          }
        }
      }
    )
  }

  const invoiceOnlinePaymentModalClose = () => {
    setShowOnlinePaymentModeModal(!showOnlinePaymentModeModal)
    setOnlinePaymentLinkData({})
  }

  const handleExit = () => {
    dispatch(clearAllStates())
    // dispatch(clearInvoicePatientData());
    navigate('/pharmacy/pharmacy-info')
  }

  return (
    <>
      {showPaymentMode && (
        <Popup
          Children={AddPaymentModeModal}
          popData={paymentModeModalData}
          handleClose={handlePaymentMode}
          handleYes={addPaymentMode}
        />
      )}

      {amountModal && (
        <Popup
          Children={PaymentLinkModal}
          popData={paymentLinkData}
          handleClose={handlePaymentLinkModalClose}
          setModelOpenClose={addUpayData}
        />
      )}

      {showOnlinePaymentModeModal && (
        <Popup
          Children={OnlinePaymentModal}
          popData={onlinePaymentLinkData}
          handleClose={() => invoiceOnlinePaymentModalClose()}
          setModelOpenClose={setShowOnlinePaymentModeModal}
          message="/pharmacy/pharmacy-info"
        />
      )}

      <form className={styles.mainContainer}>
        <div className={styles.mainServiceContainer}>
          {/*invoiceInfoContainer*/}
          <div className={styles.invoiceInfoContainer}>
            <div className={styles.invoiceNoContainer}>
              <p className={styles.invoiceTextStyle}>Invoice no :</p>
              <p className={styles.invoiceStyle}>
                {location?.state?.order?.invoiceData?.invoice_no
                  ? handlePadStart(
                      location?.state?.order?.invoiceData?.invoice_no
                    )
                  : ''}
              </p>
            </div>

            {/* {patientPaymentInfo?.fixed_file_number ? (
              <div className={styles.fileNoContainer}>
                <p className={styles.invoiceFileTextStyle}>Fixed File No. :</p>
                <p className={styles.invoiceFileStyle}>
                  {handlePadStart(patientPaymentInfo?.fixed_file_number)}
                </p>
              </div>
            ) : (
              ''
            )} */}
          </div>

          {/* payment container */}
          <div className={styles.paymentMainContainer}>
            <div className={styles.paymentContainer}>
              <>
                <div className={styles.amountStyle}>
                  Amount to be Claimed:{' '}
                  {location?.state?.order?.invoiceData?.insurance_claim_amount
                    ? location?.state?.order?.invoiceData
                        ?.insurance_claim_amount
                    : 0}
                </div>
              </>
              {/* )} */}

              <div className={styles.outstandingStyle}>
                Outstanding Amount:{' '}
                {location?.state?.order?.invoiceData?.outstanding_amount &&
                location?.state?.order?.invoiceData?.outstanding_amount
                  ? allowedNumberOfDigitsAfterDecimal(
                      location?.state?.order?.invoiceData?.outstanding_amount,
                      3
                    )
                  : 0}
              </div>
              {/* <div className={styles.advanceDueStyle}>
                Advance Due:{' '}
                {patientPaymentInfo?.paid_amount
                  ? patientPaymentInfo?.paid_amount
                  : 0}
              </div> */}

              {/* {location?.state?.order?.invoiceData?.advanced_amount && ( */}
              <div className={styles.advanceDueStyle}>
                Advance Due:{' '}
                {location?.state?.order?.invoiceData?.advanced_amount
                  ? allowedNumberOfDigitsAfterDecimal(
                      location?.state?.order?.invoiceData?.advanced_amount,
                      3
                    )
                  : 0}
              </div>
              {/* )} */}

              {/* <div className={styles.copayDetailContainer}>
                <div className={styles.copayPercentageContainer}>
                  <label className={styles.labelText}>Co-pay %</label>
                  <input
                    type="text"
                    className={styles.inputFieldDisable}
                    value={location?.state?.order?.invoiceData?.co_pay ?? ""}
                    disabled={true}
                  />
                </div>
                <div className={styles.copayAmountContainer}>
                  <label className={styles.labelText}>Co-pay Amount</label>
                  <input
                    type="text"
                    className={styles.inputFieldDisable}
                    value={
                      location?.state?.order?.invoiceData?.co_pay_amount ?? ""
                    }
                    disabled={true}
                  />
                </div>
              </div> */}

              <div className={styles.buttonContainer}>
                <Button
                  type="button"
                  title="Add Payment Mode"
                  customClass={styles.addPaymentStyle}
                  handleClick={handlePaymentMode}
                  //   disable={
                  //     Number(
                  //       handleCalculateTotalAmountForPaymentMode(paymentModeData)
                  //         .amount
                  //     ) >= Number(generatedInvoiceObject?.outstanding_amount)
                  //       ? true
                  //       : false
                  //   }
                />
                <div>
                  <Button
                    type="button"
                    title="Customer's E-Signature"
                    customClass={styles.eSignButtonStyle}
                    // handleClick={() => setShowScribeDialog(true)}
                    disable={true}
                  />
                  {/* <ShareIcon
                    fillColor="#0E26A3"
                    customClass={styles.shareIcon}
                  /> */}
                </div>
                <PrintIcon customClass={styles.printIcon} />
                <ExportIcon customClass={styles.exportIcon} />
              </div>

              {/* <div className={styles.scribeContainer}>
                <p className={styles.eSignText}>Customer's E-Signature</p>
                <div className={styles.scribeAttachments}>
                  {!scribeNotesArr ||
                  scribeNotesArr === undefined ||
                  scribeNotesArr === "" ||
                  scribeNotesArr === null ||
                  scribeNotesArr?.length === 0
                    ? defaultNoAttachmentArr.map((_element: any) => {
                        return (
                          <>
                            <div
                              className={styles.scribeSingleAttachmentWrapper}
                            >
                              <div
                                className={styles.scribeSingleAttachment}
                                key={_element.id}
                              >
                                <img src={_element.imageUrl} />
                              </div>
                            </div>
                          </>
                        );
                      })
                    : scribeNotesArr.map((_element: any) => {
                        return (
                          <>
                            <div
                              className={styles.scribeSingleAttachmentWrapper}
                              key={_element.id}
                            >
                              <div
                                className={styles.scribeSingleAttachment}
                                onClick={() =>
                                  handleScribeImagesAndNotesPreviewDialogOpen(
                                    _element
                                  )
                                }
                              >
                                <img src={_element.imageUrl} />
                              </div>
                              <div
                                className={styles.closeIconContainer}
                                onClick={() =>
                                  dispatch(removeScribeNotesData(_element))
                                }
                              >
                                <RemoveTagCloseIcon fillColor="#02BF90" />
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
              </div> */}
            </div>

            <div className={styles.paymentDetailContainer}>
              <p
                className={styles.linkGenreateContainer}
                onClick={handlePaymentProcess}
              >
                click here to share Upay link
              </p>
              <div className={styles.tableContainer}>
                <TableV2
                  tableHeaderData={invoicePaymentHeaderData}
                  tableRowData={paymentModeData}
                  active={false}
                />
              </div>

              <div className={styles.submitPaymentContainer}>
                <Button
                  type="button"
                  title="Submit"
                  customClass={styles.settle8Button}
                  handleClick={handleGenerateLink}
                  disable={
                    Number(
                      handleCalculateTotalAmountForPaymentMode(paymentModeData)
                        .amount
                    ) >
                    Number(
                      location?.state?.order?.invoiceData?.outstanding_amount
                    )
                      ? true
                      : paymentModeData?.length === 0
                      ? true
                      : paymentModeData?.some((item: any) => !item?.amount)
                      ? true
                      : false
                  }
                />

                <Button
                  title="Exit"
                  type="button"
                  customClass={styles.backButton}
                  // handleClick={() => navigate("/invoice/labservices")}
                  handleClick={handleExit}
                />
              </div>
            </div>
          </div>
        </div>

        {/* action bar */}
        {/* <div className={styles.mainContainerFormActionSidebar}>
          <div className={styles.sidebarData}>
            {invoiceFormActionData?.map((item: any, index: any) => {
              return (
                <React.Fragment key={index}>
                  <div className={styles.iconStyleContainer} key={index}>
                    <item.icon
                      customClass={styles.iconStyle}
                      fillColor="#CDD4D8"
                      mouseEnter={() => setFormActionValue(index)}
                      mouseLeave={() => setFormActionValue(-1)}
                    />
                    <p className={styles.tooltiptext}>{item.name}</p>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div> */}
      </form>
    </>
  )
}

export default PharmacyPaymentNew
