import { FC } from 'react';
import styles from './InvoiceConformationModal.module.scss';
import { CloseIcon } from '../../svg-components';
import { colors } from '../../../../constants/color';
import Divider from '../../divider/Divider';
import Button from '../../button/Button';
import { useAppSelector } from '../../../../hooks';
import Loader from '../../spinner/Loader';

interface IPaymentConfirmationModal {
  handleClose?: any;
  setModelOpenClose?: any;
  handleYes: any;
  handleNo: any;
  heading: string;
  message: string;
}

const InvoiceConformationModal: FC<IPaymentConfirmationModal> = ({
  handleClose,
  setModelOpenClose,
  handleYes,
  handleNo,
  heading,
  message,
}) => {
  const { emrLoader } = useAppSelector((state) => state.patient);

  return (
    <>
      {emrLoader && <Loader />}

      <div
        className={styles.notesPopupContainer}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>{heading}</p>
          <Divider customClass={styles.dividerStyle} />
          <p className={styles.deleteText}>{message}</p>
          <div className={styles.btnContainer}>
            <Button
              title="Yes"
              customClass={styles.yesButtonStyle}
              handleClick={() => setModelOpenClose()}
            />
            <Button
              title="No"
              customClass={styles.noButtonStyle}
              handleClick={handleNo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceConformationModal;
