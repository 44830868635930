import { EyeIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { isBoldRow, isBoldRowiWithTotal } from '../../../constants/data'
import styles from './Balancesheet.module.scss'

// Balance Sheet Table Data
const currentDate = new Date()
const formattedDate = `AS AT ${currentDate.getDate()} ${currentDate.toLocaleString(
  'default',
  { month: 'short' }
)} ${currentDate.getFullYear()}`

// Liability
export const balanceSheetLiabilityTableHeaderData: any = [
  {
    Header: 'A/C NO.',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: 'DESCRIPTION',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: 'AMOUNT',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.close?.credit
            ? row?.original?.close?.credit
            : isBoldRow.includes(row?.original?.level)
            ? ''
            : 0}{' '}
        </p>
      )
    },
  },
  // {
  //   Header: 'VIEW',
  //   Cell: (props: any) => {
  //     return (
  //       <>
  //         <EyeIcon
  //           fillColor={colors.grey1}
  //           customClass={styles.openEyeIcon}
  //           handleClick={() => props.onClick(props?.row?.original)}
  //         />
  //       </>
  //     )
  //   },
  // },
]

// Assets
export const balanceSheetAssetsTableHeaderData: any = [
  {
    Header: 'A/C NO.',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_no ? row?.original?.account_no : '-'}
        </p>
      )
    },
  },
  {
    Header: 'DESCRIPTION',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.account_name ? row?.original?.account_name : '-'}
        </p>
      )
    },
  },
  {
    Header: 'AMOUNT',
    Cell: ({ row }: any) => {
      return (
        <p
          className={
            isBoldRowiWithTotal.includes(row?.original?.level)
            ? styles.boldTd
            : styles.normalStyleTd
          }
        >
          {row?.original?.close?.debit
            ? row?.original?.close?.debit
            : isBoldRow.includes(row?.original?.level)
            ? ''
            : 0}{' '}
        </p>
      )
    },
  },
  // {
  //   Header: 'VIEW',
  //   Cell: (props: any) => {
  //     return (
  //       <>
  //         <EyeIcon
  //           fillColor={colors.grey1}
  //           customClass={styles.openEyeIcon}
  //           handleClick={() => props.onClick(props?.row?.original?.type)}
  //         />
  //       </>
  //     )
  //   },
  // },
]
//View POPUP Liability TABLE
export const liabilityViewBalanceTableHeaderData: any = [
  {
    Header: 'VOUCHER NO.',
    accessor: (row: any) => {
      return row?.number ?? '-'
    },
  },
  {
    Header: 'DEBIT BALANCE',
    accessor: (row: any) => {
      return row?.debit_amount > 0 ? row?.debit_amount : '-'
    },
  },
  {
    Header: 'CREDIT BALANCE',
    accessor: (row: any) => {
      return row?.credit_amount > 0 ? row?.credit_amount : '-'
    },
  },
]
// View POPUP Assets TABLE
export const assetsViewBalanceTableHeaderData: any = [
  {
    Header: 'VOUCHER NO.',
    accessor: (row: any) => {
      return row?.number ?? '-'
    },
  },
  {
    Header: 'DEBIT BALANCE',
    accessor: (row: any) => {
      return row?.debit_amount ?? '-'
    },
  },
  {
    Header: 'CREDIT BALANCE',
    accessor: (row: any) => {
      return row?.credit_amount ?? '-'
    },
  },
]
