import styles from './patientHistoryTable.module.scss'

export const patientHistoryFormsHeader: any = [
  {
    Header: 'FILE NO',
    accessor: 'emr_no',
  },
  {
    Header: 'PATIENT NAME',
    accessor: 'patient_name',
  },
  {
    Header: 'ROLE',
    accessor: 'submitted_by_role',
    Cell: ({ row }: any) => {
      const form = row?.original?.diag_forms_submitted
      return (
        <>
          {form && form?.length > 0
            ? form?.map((item: any, index: number) => {
                return (
                  <p key={`form-name${index}`}>
                    {item?.submitted_by_role ?? '-'}
                  </p>
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: 'USER NAME',
    accessor: 'submitted_by_name',
    Cell: ({ row }: any) => {
      const form = row?.original?.diag_forms_submitted
      return (
        <>
          {form && form?.length > 0
            ? form?.map((item: any, index: number) => {
                return (
                  <p key={`form-name${index}`}>
                    {item?.submitted_by_name ?? '-'}
                  </p>
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: 'FORM',
    Cell: ({ row }: any) => {
      const form = row?.original?.diag_forms_submitted
      return (
        <>
          {form && form?.length > 0
            ? form?.map((item: any, index: number) => {
                return (
                  <p key={`form-name${index}`}>
                    {item?.form_builder_id?.name ?? '-'}
                  </p>
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: 'PREVIEW',
    Cell: ({ row, onClick }: any) => {
      const form = row?.original?.diag_forms_submitted
      return (
        <>
          {form && form?.length > 0
            ? form?.map((item: any, index: number) => {
                return (
                  <p
                    className={styles.viewPopupLink}
                    key={`form-view${index}`}
                    onClick={() => onClick(row?.original, index)}
                  >
                    {'View' ?? '-'}
                  </p>
                )
              })
            : '-'}
        </>
      )
    },
  },
]
