import React, { FC, Fragment, useEffect, useState } from 'react'
import styles from './createNewFormBuilder.module.scss'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { requestGenerator } from '../../../utils/payloadGenerator'
import Button from '../../../components/common/button/Button'
import { PolygonIcon } from '../../../components/common/svg-components'
import { FormBuilder, Form } from 'react-formio/lib/components'
import {
  createNewForm,
  updateCreateNewFormById,
} from '../../../redux/features/create-new-form/createNewFormAsynActions'
import { ErrorBoundary } from '../../../components/core/error-boundary/ErrorBoundary'

interface ICreateNewFormBuilder {}

const CreateNewFormBuilder: FC<ICreateNewFormBuilder> = () => {
  /* Dependency to navigate between pages */
  const navigate = useNavigate()
  /* Dependency to navigate between pages */

  /* Dependency to dispatch an action */
  const dispatch = useAppDispatch()
  /* Dependency to dispatch an action */

  /* Dependency for form builder */
  var selFormBuilderObj = {} // using a state variable and setting in handleFormBuilderChange was causing infinite loop issues so i have used var
  const [formBuilderJson, setFormBuilderJson] = useState({
    display: 'form',
    components: [],
  })
  /* Dependency for form builder */

  /* Selector to get the initial data of module from redux store*/
  const {
    isLoading,
    createNewFormData,
    createFormBuilderHeaderData,
    isStatusUpdated,
  } = useAppSelector((state) => state.createNewForm)
  /* Selector to get the initial data of module from redux store*/

  useEffect(() => {
    /* Navigate back to list component if the following dependencies are not present */
    if (
      !createFormBuilderHeaderData.name ||
      !createFormBuilderHeaderData.role_id
    ) {
      navigate('/formBuilder')
    }
    /* Navigate back to list component if the following dependencies are not present */

    if (
      createFormBuilderHeaderData.form &&
      createFormBuilderHeaderData.form !== '' &&
      createFormBuilderHeaderData.form !== null &&
      createFormBuilderHeaderData.form !== undefined
    ) {
      setFormBuilderJson(JSON.parse(createFormBuilderHeaderData.form))
    }
  }, [])

  /* Function definition for form submission */
  const onSubmit = () => {
    let rolesArray = createFormBuilderHeaderData?.role_id?.map(
      (item: any) => item?.value
    )
    if (
      createFormBuilderHeaderData._id &&
      createFormBuilderHeaderData._id !== '' &&
      createFormBuilderHeaderData._id !== undefined &&
      createFormBuilderHeaderData._id !== null
    ) {
      const dataToBeSent = {
        id: createFormBuilderHeaderData?._id,
        data: {
          name: createFormBuilderHeaderData.name,
          role_id: rolesArray,
          note: createFormBuilderHeaderData.note,
          form: JSON.stringify({ ...selFormBuilderObj }),
          is_questionnaire_form:
            createFormBuilderHeaderData?.is_questionnaire_form,
        },
      }

      dispatch(updateCreateNewFormById(requestGenerator(dataToBeSent))).then(
        (result) => {
          navigate('/formBuilder')
        }
      )
    } else {
      const dataToBeSent = {
        name: createFormBuilderHeaderData?.name,
        role_id: rolesArray,
        note: createFormBuilderHeaderData?.note,
        form: JSON.stringify({ ...selFormBuilderObj }),
        is_questionnaire_form:
          createFormBuilderHeaderData?.is_questionnaire_form,
      }
      dispatch(createNewForm(requestGenerator(dataToBeSent))).then((result) => {
        navigate('/formBuilder')
      })
    }
  }
  /* Function definition for form submission */

  /* Function definition for form builder - OnChange */
  const handleFormBuilderChange = (schema: any) => {
    try {
      selFormBuilderObj = schema
    } catch (e) {
      console.log('e', e)
    }
  }
  /* Function definition for form builder - OnChange */

  return (
    <>
      <div className={styles.mainFormBuilderContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.headerTitle}>
            <div className={styles.label_data}>
              <div>Form</div>
              <PolygonIcon />
              <div>{createFormBuilderHeaderData?.name}</div>
            </div>
            <div className={styles.label_data}>
              <div>Role</div>
              <PolygonIcon />
              <div>
                {createFormBuilderHeaderData?.role_id?.map(
                  (item: any, index: number) => {
                    return (
                      <Fragment key={`form-${index}`}>
                        {item?.label}
                        {index !==
                          createFormBuilderHeaderData?.role_id?.length - 1 &&
                          ' , '}
                      </Fragment>
                    )
                  }
                )}
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <Button title="Submit Form" handleClick={onSubmit} />
          </div>
        </div>
        <ErrorBoundary fallback={<p>Error in form builder</p>}>
          <FormBuilder
            form={formBuilderJson}
            onChange={handleFormBuilderChange}
          />
        </ErrorBoundary>
      </div>
    </>
  )
}

export default CreateNewFormBuilder
