import React from 'react'
import { Link } from 'react-router-dom'
import Fragment from 'react'

/* tableHeaderColumns definition for create New Forms module */
export const createNewFormsTableHeaderData: any = [
  {
    Header: 'ROLE',
    accessor: 'role_id',
    Cell: ({ row }: any) => {
      console.log('row', row?.original)
      const roles = row?.original?.role_detail
      return roles && roles?.length > 0
        ? roles?.map((item: any, index: number) => {
            return (
              <div key={`row=${index}`}>
                {index + 1}. {item?.name}
              </div>
            )
          })
        : '-'
    },
  },

  {
    Header: 'CURRENT FORMS',
    accessor: 'name',
  },
  {
    Header: 'NOTES',
    accessor: 'notes',
    Cell: () => {
      return React.createElement(
        Link,
        { to: '/', className: 'viewLinkStyle' },
        'View'
      )
    },
  },
  {
    Header: 'STATUS',
  },
  {
    Header: 'ACTIONS',
  },
  {
    Header: 'PREVIEW',
  },
]
/* tableHeaderColumns definition for create New Forms module */
