import moment from 'moment'
import { EditIcon } from '../../components/common/svg-components'
import { colors } from '../color'
import { useNavigate } from 'react-router-dom'

export const assignAgentTableHeader: any = [
  {
    Header: 'LEAD NAME',
    accessor: (row: any) => {
      return <>{row?.name ? row?.name : '-'}</>
    },
  },
  {
    Header: 'SOURCE',
    Cell: ({ row }: any) => {
      const source = row?.original?.external_source
      return <>{source ? source : '-'}</>
    },
  },
  {
    Header: 'TAG',
    Cell: ({ row }: any) => {
      const tags = row?.original?.tags
      return (
        <>
          {tags && tags?.length > 0
            ? tags?.map((item: string, index: number) => {
                return item ? (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ) : (
                  ''
                )
              })
            : '-'}
        </>
      )
    },
  },
  {
    Header: 'START DATE',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.start_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: 'END DATE',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.end_date
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: 'CONTACT NO',
    accessor: 'phone',
    Cell: ({ row }: any) => {
      console.log('total_leads', row)
      const phone = row?.original?.phone
      return phone ? phone : '-'
    },
  },
]
