import styles from "../style.module.scss";

export const ipdConsultationData: any = [
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "DOCTOR NAME",
    accessor: "doctor_name",
  },
  {
    Header: "NOTES",
    Cell: (props: any) => {
      return (
        <p
          className={styles.blueLinkText}
          onClick={() => props.onClick(props.row.original.notes)}
        >
          View
        </p>
      );
    },
  },
];

export const notesData: any = [
  {
    Header: "NOTES TIME",
    accessor: "time",
  },
  {
    Header: "NOTES",
    accessor: "note",
  },
];
