export const searchCriteriaHeaderData: any = [
  // {
  //   Header: "SR. NO.",
  //   accessor: (row: any, index: number) => {
  //     return `${row?.index + 1}`
  //   }
  // },
  {
    Header: 'LEAD NAME',
    accessor: (row: any) => {
      return `${row?.name}`
    },
  },

  {
    Header: ' CONTACT NO.',
    accessor: (row: any) => {
      const phone = row?.phone
      return phone ? phone : '-'
    },
  },
  {
    Header: 'AGE',
    accessor: (row: any) => {
      const age = row?.age
      return age ? age : '-'
    },
  },
  {
    Header: 'GENDER',
    accessor: (row: any) => {
      return `${row?.gender}`
    },
  },
  {
    Header: 'NATIONALITY',
    accessor: (row: any) => {
      const nationality = row?.nationality
      return nationality ? nationality : '-'
    },
  },
  {
    Header: 'PINCODE',
    accessor: (row: any) => {
      const pinCode = row?.pincode
      return pinCode ? pinCode : '-'
    },
  },
  {
    Header: 'TAGS',
    accessor: (row: any) => {
      // const tagsOrSources = row?.tags_or_sources
      console.log('row', row)
      const tags = row?.tags
      return (
        <>
          {tags && tags?.length > 0
            ? tags?.map((item: string, index: number) => {
                return item ? (
                  <div key={item}>
                    {index + 1}. {item}
                  </div>
                ) : (
                  ''
                )
              })
            : '-'}
        </>
      )
    },
  },
  // {
  //   Header: 'NOTES',
  //   accessor: (row: any) => {
  //     const notes = row?.notes
  //     return notes ? notes : '-'
  //   },
  // },
]
