import {
  GET__ROLE__USER,
  REPORT_POC_TYPE,
  UPDATE_REPORT_MAPPING_TYPE,
  UPDATE_ROLE_STATUS_TYPE,
  CREATE_REPORT_QUERY_TYPE,
  UPDATE_REPORT_QUERY_TYPE,
  GET_REPORT_BUILDER_CONFIG_TYPE,
  GET_REPORT_BUILDER_TYPE,
  GET_REPORTS_TYPE,
  GET_DYNAMIC_REPORT_BY_QUERY_TYPE,
  UPDATE_REPORT_STATUS_BY_ID_TYPE
} from "../../../constants/asyncActionsType";
import createAsyncThunkForSlice from "../../../utils/utils";
import {
  getRoleUser,
  reportMapping,
  reportPoc,
  updaterolestatus,
  createReportQuery,
  updateReportBuilderQuery,
  getReportBuilderConfig,
  getReportBuilder,
  getReportsType,
  getDynamicReportByQuery,
  updateReportStatus
} from "./roleCrud";

export const getUserRole = createAsyncThunkForSlice(
  GET__ROLE__USER,
  getRoleUser
);

export const updateRoleStatus = createAsyncThunkForSlice(
  UPDATE_ROLE_STATUS_TYPE,
  updaterolestatus
);

export const updateReportMapping = createAsyncThunkForSlice(
  UPDATE_REPORT_MAPPING_TYPE,
  reportMapping,
  { isToast: true }
);

export const reportPocAsync = createAsyncThunkForSlice(
  REPORT_POC_TYPE,
  reportPoc,
  { isToast: true }
);

export const addReportQuery = createAsyncThunkForSlice(
  CREATE_REPORT_QUERY_TYPE,
  createReportQuery,
  { isToast: true }
);

export const updateReportQuery = createAsyncThunkForSlice(
  UPDATE_REPORT_QUERY_TYPE,
  updateReportBuilderQuery,
  { isToast: true }
);

export const getReportBuilderConfigList = createAsyncThunkForSlice(
  GET_REPORT_BUILDER_CONFIG_TYPE,
  getReportBuilderConfig
);

export const getReportBuilderList = createAsyncThunkForSlice(
  GET_REPORT_BUILDER_TYPE,
  getReportBuilder
);

export const getReportsList = createAsyncThunkForSlice(
  GET_REPORTS_TYPE,
  getReportsType
);

export const getDynamicReportQueryData = createAsyncThunkForSlice(
  GET_DYNAMIC_REPORT_BY_QUERY_TYPE,
  getDynamicReportByQuery,
  { isToast: true }
);

export const updateReportStatusById = createAsyncThunkForSlice(
  UPDATE_REPORT_STATUS_BY_ID_TYPE,
  updateReportStatus,
  { isToast: true }
);