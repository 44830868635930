import React, { FC, useState } from 'react'
import styles from './ViewReceipt.module.scss'
import TableV3 from '../../table/tableV3/TableV3'
import Divider from '../../divider/Divider'
import { SearchButton } from '../../svg-components'
// import { colors } from "../../../../constants/color";
import { trimValue } from '../../../../utils/utils'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import { Cols } from '../../../../interfaces/interfaces'
import SmartSearch from '../../smart-search/SmartSearch'
import Loader from '../../spinner/Loader'
// import { useState } from "react";
// import Popup from "../../popup/Popup";
// import SettledEmailPopup from "../settled-email-popup/SettledEmailPopup";
// import {
//   getSettledInvoiceViewDetails,
//   getSettledReceiptViewDetails,
// } from "../../../../redux/features/lab-invoice/labInvoiceAsyncActions";
// import { requestGenerator } from "../../../../utils/payloadGenerator";
// import { useAppDispatch } from "../../../../hooks";

interface IViewReceiptModal {
  rowData?: any
  datas?: any
  searchValue?: any
  setSearchValue?: any
  handleSearch?: any
  children?: any
  activateSmartSearch?: any
  setshowSettleEmailPopup?: any
  setpatientReceiptData?: any
}

const ViewReceipt: FC<IViewReceiptModal> = ({
  rowData,
  datas,
  searchValue,
  setSearchValue,
  handleSearch,
  children,
  activateSmartSearch,
  setshowSettleEmailPopup,
  setpatientReceiptData,
}) => {
  const [pdfLoader, setPdfLoader] = useState(false)
  const data: Cols[] = datas ?? []
  const columns: Column<Cols>[] = rowData ?? []
  const options: TableOptions<Cols> = {
    data,
    columns,
  }
  const {
    state,
    // @ts-ignore
    setGlobalFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(options, useGlobalFilter, useSortBy)
  // @ts-ignore
  const { globalFilter } = state

  // const [patientReceiptData, setpatientReceiptData] = useState<any>({});
  // const [showSettleEmailPopup, setshowSettleEmailPopup] = useState(false);

  // const sendEmail = (data: any) => {
  //   const requestedData = {
  //     _id: data?._id,
  //     email: data?.patient_email,
  //     is_email: true,
  //   };
  //   dispatch(
  //     getSettledReceiptViewDetails(requestGenerator(requestedData))
  //   ).then((result) => {
  //     setshowSettleEmailPopup(false);
  //   });
  // };

  return (
    <>
      {/* {showSettleEmailPopup && (
        <Popup
          Children={SettledEmailPopup}
          handleClose={() => setshowSettleEmailPopup(!showSettleEmailPopup)}
          setModelOpenClose={() =>
            setshowSettleEmailPopup(!showSettleEmailPopup)
          }
          popData={patientReceiptData}
          handleYes={(data: any) => sendEmail(data)}
        />
      )} */}
      {pdfLoader ? <Loader /> : ''}
      <div
        className={styles.mainContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.searchFieldContainer}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '25px',
              width: '620px',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <input
                type="text"
                className={styles.inputSearchContainer}
                placeholder="Search Patient"
                onChange={(e) => {
                  trimValue(e)
                  setSearchValue(e.target.value)
                  setGlobalFilter('')
                }}
                onKeyDown={(e: any) => {
                  if (e?.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />

              <SearchButton
                handleClick={() => {
                  if (!!searchValue) {
                    handleSearch()
                  }
                }}
                customClass={styles.inputSearchButton}
              />
            </div>

            <SmartSearch
              placeHolder="Smart Search"
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              isDisable={!activateSmartSearch}
            />
          </div>

          <Divider customClass={styles.dividerStyling} />
          <div className={styles.tableContainer}>
            <TableV3
              getTableProps={getTableProps}
              handleRowClick={(item: any) => {
                setshowSettleEmailPopup(true)
                setpatientReceiptData({
                  _id: item?._id,
                  patient_email: item?.patient_detail[0]?.user?.email || '',
                })
              }}
              handleClick={setPdfLoader}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              rows={rows}
              prepareRow={prepareRow}
            />
          </div>

          {children}
        </div>
      </div>
    </>
  )
}

export default ViewReceipt
