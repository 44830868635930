import React, { useEffect, useState } from 'react'
import styles from './PatientIPDServices.module.scss'
import {
  CheckIcon,
  ExportIcon,
  PrintIcon,
  SearchIcon,
  UncheckIcon,
} from '../../../components/common/svg-components'
import Button from '../../../components/common/button/Button'
import TableV2 from '../../../components/common/table/tableV2/TableV2'
import { patientServicesHeaderData } from '../../../constants/table-data/patientIpdInvoiceTableData'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Popup from '../../../components/common/popup/Popup'
import AddServiceModal from '../../../components/common/modal/invoice-add-service-modal/AddServiceModal'
import PaymentConfirmationModal from '../../../components/common/modal/payment-confirmation-modal/PaymentConfirmationModal'
import { useNavigate } from 'react-router-dom'
import { createIpdNewInvoice } from '../../../redux/features/ipd-invoice/ipdAsyncActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { CREATE_IPD_NEW_INVOICE } from '../../../constants/asyncActionsType'
import { colors } from '../../../constants/color'
import { trimValue } from '../../../utils/utils'
import { setAddTestData } from '../../../redux/features/invoice-module/invoiceSlice'
import { setAddedNewServiceList } from '../../../redux/features/ipd-invoice/ipdInvoiceSlice'
import { setMessage } from '../../../redux/features/toast/toastSlice'
import { failure } from '../../../constants/data'

const PatientIPDServices = () => {
  const [addNewServicesPopup, setaddNewServicesPopup] = useState(false)
  const [conformationPopup, setconformationPopup] = useState(false)
  const [checkboxChange, setcheckboxChange] = useState(true)

  const [patientServicesData, setpatientServicesData] = useState<any>([])
  const [searchValue, setSearchValue] = useState<string>('')

  const {
    addedNewServicesList,
    patientIpdInfo,
    patientCaseDetails,
    consumableFilterList,
    patientServicesList,
  } = useAppSelector((state) => state.ipdInvoice)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleNewServices = () => {
    setaddNewServicesPopup(!addNewServicesPopup)
  }

  const handlePaymentConformationPopup = () => {
    setconformationPopup(!conformationPopup)
  }

  const handleProceedToPayment = () => {
    // const updateConsumableServiceList = consumableFilterList.map(
    //   (object: any) => {
    //     const { _id, ...remailning } = object;
    //     return remailning
    //   }
    // );

    const ipdServices = [...consumableFilterList, ...addedNewServicesList]

    const requestedPayload = {
      type: 'IPD',
      patient_id: patientIpdInfo?._id,
      ipd_services: ipdServices,
      ipd_case_id: patientIpdInfo?.ipdData?._id,
      is_discharge: checkboxChange ? false : true,
      insurance_plan_id: patientIpdInfo?.isIns
        ? patientIpdInfo?.ipdData?.insurance_plan_id
        : null,
    }

    dispatch(createIpdNewInvoice(requestGenerator(requestedPayload))).then(
      (e: any) => {
        if (e.type === `${CREATE_IPD_NEW_INVOICE}/fulfilled`) {
          navigate('/ipd-invoice/payment')
        } else {
        }
      }
    )
  }

  const handleDischarge = () => {
    const dischargeService = patientCaseDetails?.data.find((item: any) => {
      return item?._id === patientIpdInfo?.ipdData?._id
    })

    if (dischargeService?.doctor_discharge === true) {
      setcheckboxChange(!checkboxChange)
    }
  }

  // Set BE Services data in State
  useEffect(() => {
    setpatientServicesData(patientServicesList)
  }, [patientServicesList])

  // handle Search
  const handleInputChange = (e: any) => {
    trimValue(e)
    setSearchValue(e.target.value)
    const filterData = patientServicesList?.filter((item: any) => {
      // const itemName = item?.test_name?.toLowerCase();
      const itemName = item?.name?.toLowerCase()
      return itemName?.includes(e.target.value?.toLowerCase())
    })
    setpatientServicesData(filterData)
  }

  useEffect(() => {
    return () => {
      dispatch(setAddTestData([]))
      dispatch(setAddedNewServiceList([]))
    }
  }, [])

  return (
    <>
      {addNewServicesPopup && (
        <Popup
          Children={AddServiceModal}
          // popData={newServiceModalData}
          handleClose={handleNewServices}
          setModelOpenClose={setaddNewServicesPopup}
          // handleOpen={handleDescriptionModalOpen}
        />
      )}

      {conformationPopup && (
        <Popup
          Children={PaymentConfirmationModal}
          handleClose={handlePaymentConformationPopup}
          setModelOpenClose={setconformationPopup}
          handleYes={handleProceedToPayment}
        />
      )}

      <div className={styles.mainContainer}>
        <div className={styles.lableContainer}>
          <div className={styles.invoiceInfoContainer}>
            <p className={styles.invoiceTextStyle}>User Name :</p>
            {/* <p className={styles.invoiceStyle}>{userData?.name}</p> */}
            <p className={styles.invoiceStyle}>Andrew Davidson</p>
          </div>

          <div className={styles.invoiceInfoContainer}>
            <p className={styles.invoiceTextStyle}>Invoice Date:</p>
            {/* <p className={styles.invoiceStyle}>{formattedDate}</p> */}
            <p className={styles.invoiceStyle}>23 Jan 2023</p>
          </div>
        </div>

        <div className={styles.searchContainer}>
          <div className={styles.smartInputContainer}>
            <input
              type="text"
              className={styles.inputSearchContainer}
              placeholder="Search"
              value={searchValue}
              onChange={handleInputChange}
            />
            <SearchIcon
              fillColor="#797979"
              customClass={styles.searchIconStyle}
            />
          </div>

          <PrintIcon customClass={styles.iconContainer} />
          <ExportIcon customClass={styles.iconContainer} />

          <span className={styles.addServices}>
            <Button
              type="button"
              title="Add New Service"
              customClass={styles.newServiceButton}
              handleClick={handleNewServices}
              // disable={
              //     handlePaymentCondtions(labInformationData) ? false : true
              // }
              disable={patientIpdInfo?.isIns}
            />
          </span>

          <div className={styles.checkboxContainer}>
            {checkboxChange ? (
              <UncheckIcon
                fillColor={colors.grey1}
                handleClick={handleDischarge}
              />
            ) : (
              <CheckIcon
                fillColor={colors.green1}
                handleClick={handleDischarge}
              />
            )}

            <label className={styles.labelText}>Discharge</label>
          </div>
        </div>

        <div className={styles.tableContainer}>
          <TableV2
            tableHeaderData={patientServicesHeaderData}
            tableRowData={patientServicesData}
          />
        </div>

        <div className={styles.buttonContainer}>
          <Button
            type="submit"
            title="Proceed to Payment"
            customClass={styles.proceedToPaymentButton}
            // handleClick={() => {
            //   if (patientServicesList && patientServicesList?.length > 0) {
            //     handlePaymentConformationPopup();
            //   } else {
            //     dispatch(
            //       setMessage({
            //         message: patientIpdInfo?.isIns
            //           ? "Services no present in insurance "
            //           : "Add at least one service",
            //         type: failure,
            //       })
            //     );
            //   }
            // }}

            handleClick={() => {
              handlePaymentConformationPopup()
            }}
          />

          <Button
            title="Back"
            type="button"
            customClass={styles.backButton}
            handleClick={() => navigate(`/ipd-invoice/charges`)}
          />
        </div>
      </div>
    </>
  )
}

export default PatientIPDServices
