import React, { FC } from 'react'
import styles from './medicationViewPopup.module.scss'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Divider from '../../divider/Divider'
import {
  ipdMedicationViewHeaderData,
  pharmacyMedicationViewHeaderData,
} from '../../../../constants/table-data/ipdMedicationTableData'
import TableV2 from '../../table/tableV2/TableV2'
import { useLocation } from 'react-router-dom'

interface IMedicationView {
  popData?: any
  handleClose?: any
}

const MedicationViewPopup: FC<IMedicationView> = ({ popData, handleClose }) => {
  const location = useLocation()?.pathname

  return (
    <div
      className={styles.notesPopupContainer}
      onClick={(e) => e.stopPropagation()}
    >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={handleClose}
      />
      <div className={styles.notesContainer}>
        <p className={styles.title}>Medication View</p>
        <Divider customClass={styles.dividerStyle} />
        <div className={styles.tabelContainer}>
          <TableV2
            tableHeaderData={
              location === '/pharmacy/pharmacy-info'
                ? pharmacyMedicationViewHeaderData
                : ipdMedicationViewHeaderData
            }
            tableRowData={popData}
            active={false}
          />
        </div>
      </div>
    </div>
  )
}

export default MedicationViewPopup
