import { CheckIcon, UncheckIcon } from '../../components/common/svg-components'
import { colors } from '../../constants/color'
import { failure } from '../../constants/data'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  addBaseTableColumn,
  removeBaseTableColumn,
  updateBaseTableColumn,
  addBaseTableOrder,
  removeBaseTableOrder,
  updateBaseTableOrder,
} from '../../redux/features/role/roleSlice'
import { setMessage } from '../../redux/features/toast/toastSlice'
import { disableArrowKey, blockInvalidCharacter } from '../../utils/utils'
import styles from '../../pages/reports-poc/baseTableSelectColumnsModal.module.scss'

export const baseTableSelectColumnsPopupHeaderData: any = [
  {
    Header: 'COLUMN NAME',
    accessor: (row: any) => {
      return row ?? '-'
    },
  },
  {
    Header: 'SELECT COLUMN',
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const { selctedBaseTableColumns, selctedBaseTableOrders } =
        useAppSelector((state) => state.roleUser)
      const handleMainStoreCheck = (checkType: string) => {
        if (checkType === 'REMOVE') {
          dispatch(removeBaseTableColumn(props?.row?.original))
          dispatch(removeBaseTableOrder(props?.row))
        } else if (checkType === 'ADD') {
          dispatch(addBaseTableColumn(props?.row?.original))
          dispatch(addBaseTableOrder(props?.row))
        }
      }
      return (
        <>
          {selctedBaseTableColumns?.some(
            (item: any) => Object.values(item)[0] === `$${props.row.original}`
          ) ? (
            <CheckIcon
              fillColor={colors.green1}
              handleClick={() => handleMainStoreCheck('REMOVE')}
            />
          ) : (
            <UncheckIcon
              fillColor={colors.grey1}
              handleClick={() => handleMainStoreCheck('ADD')}
            />
          )}
        </>
      )
    },
  },
  {
    Header: 'ALIAS',
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()

      const { selctedBaseTableColumns } = useAppSelector(
        (state) => state.roleUser
      )
      const findSelectedTableColumn = selctedBaseTableColumns?.find(
        (selectedColumn: any) =>
          Object.values(selectedColumn)[0] === `$${props.row.original}`
      )
      const aliasText =
        findSelectedTableColumn === undefined
          ? ''
          : Object?.keys(findSelectedTableColumn)?.[0]

      const validateAliasText = (columnAliasText: string, item: any) => {
        if (columnAliasText?.length === 0) {
          dispatch(
            setMessage({
              message: 'Please enter valid text',
              type: failure,
            })
          )
          return item
        } else {
          return {
            [columnAliasText]: `${Object.values(item)[0]}`,
          }
        }
      }

      const handleChange = (e: any) => {
        const columnAliasText = e.target.value
        let tempArr: any = []
        tempArr = selctedBaseTableColumns?.map((item: any, index: number) => {
          if (Object.values(item)[0] === `$${props.row.original}`) {
            return validateAliasText(columnAliasText, item)
          } else {
            return item
          }
        })
        dispatch(updateBaseTableColumn(tempArr))
      }

      return (
        <input
          type="text"
          value={aliasText}
          placeholder="Text"
          className={styles.aliasTextField}
          onChange={handleChange}
        />
      )
    },
  },
  {
    Header: 'ORDER',
    disableSortBy: true,
    Cell: (props: any) => {
      const dispatch = useAppDispatch()

      const { selctedBaseTableOrders } = useAppSelector(
        (state) => state.roleUser
      )
      const findSelectedTableOrder = selctedBaseTableOrders?.find(
        (selectedOrder: any) =>
          Object.keys(selectedOrder)[0] === props.row.original
      )
      const orederText: any =
        findSelectedTableOrder === undefined
          ? ''
          : Object?.values(findSelectedTableOrder)?.[0]

      const validateOrderQty = (columnOrderText: string, item: any) => {
        if (columnOrderText?.length === 0) {
          dispatch(
            setMessage({
              message: 'Please enter valid order',
              type: failure,
            })
          )
          return item
        } else if (Number(columnOrderText) < 0) {
          dispatch(
            setMessage({
              message: 'Please enter valid order',
              type: failure,
            })
          )
          return item
        } else {
          return {
            [Object.keys(item)[0]]: columnOrderText,
          }
        }
      }

      const handleChange = (e: any) => {
        const columnOrderText = e.target.value
        let tempArr: any = []
        tempArr = selctedBaseTableOrders?.map((item: any, index: number) => {
          if (Object.keys(item)[0] === props.row.original) {
            return validateOrderQty(columnOrderText, item)
          } else {
            return item
          }
        })
        dispatch(updateBaseTableOrder(tempArr))
      }

      return (
        <input
          type="number"
          value={orederText}
          placeholder="Text"
          className={styles.aliasTextField}
          onChange={handleChange}
          onKeyDown={(e: any) => {
            disableArrowKey(e)
            blockInvalidCharacter(e)
          }}
          onWheel={(e: any) => {
            e.target.blur()
          }}
        />
      )
    },
  },
]
