import {
  AMOUNT,
  DAYS,
  LEAVES_AVAILABLE,
  PERIOD,
  SALARY,
  SELECT_EMPLOYEE,
  SELECT_PAID_DATE,
  STATUS,
} from "../leave-encashment/leaveEncashmentConstant";

export const leaveEncashmentValidators = {
  [SELECT_EMPLOYEE]: {
    required: "Please select employee",
  },
  [SALARY]: {
    required: "Please enter salary",
  },
  [PERIOD]: {
    required: "Please enter period",
  },
  [LEAVES_AVAILABLE]: {
    required: "Please enter leaves available",
  },
  [DAYS]: {
    required: "Please enter days",
  },
  [AMOUNT]: {
    required: "Please enter amount",
  },
  [SELECT_PAID_DATE]: {
    required: "Please select paid date",
  },
  [STATUS]: {
    required: "Please select status",
  },
};
