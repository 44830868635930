import { FC } from 'react'
import styles from './deleteMedicationPopup.module.scss'
import Divider from '../../divider/Divider'
import { CloseIcon } from '../../svg-components'
import { colors } from '../../../../constants/color'
import Button from '../../button/Button'
import Loader from '../../spinner/Loader'
import { useAppSelector } from '../../../../hooks'

interface IDeleteMedication {
  handleClose?: any
  handleYes?: any
  handleNo?: any
  heading?: string
  message?: string
}

const DeleteMedicationPopup: FC<IDeleteMedication> = ({
  handleClose,
  handleYes,
  handleNo,
  heading,
  message,
}) => {
  const { isLoading } = useAppSelector((state) => state.ipd)
  const { emrLoader } = useAppSelector((state) => state.patient)

  return (
    <>
      {isLoading || emrLoader ? <Loader /> : ''}
      <div
        className={styles.notesPopupContainer}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <CloseIcon
          customClass={styles.closeIconStyle}
          fillColor={colors.green1}
          handleClick={handleClose}
        />
        <div className={styles.notesContainer}>
          <p className={styles.title}>{heading ? heading : 'Delete'}</p>
          <Divider customClass={styles.dividerStyle} />
          <p className={styles.deleteText}>
            {message ? message : 'Are you sure you want to Delete ?'}
          </p>
          <div className={styles.btnContainer}>
            <Button
              title="Yes"
              customClass={styles.yesButtonStyle}
              handleClick={handleYes}
            />
            <Button
              title="No"
              customClass={styles.noButtonStyle}
              handleClick={handleNo}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteMedicationPopup
