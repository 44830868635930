import { Level4_CHECK, Level4_CODE, Level4_LIST, Level4_NAME} from "../constants/constant";

export interface IaddLevel4Validators {
  [Level4_NAME]: {
    required: string;
  };
  [Level4_CODE]: {};
  [Level4_CHECK]: {};
  [Level4_LIST]:{
    required:string
  }
}
export const addLevl4Validators = {
  [Level4_NAME]: {
    required: "Please enter name",
  },
  [Level4_CODE]: {},
  [Level4_CHECK]: {},
  [Level4_LIST]:{
    required:"Please select debit or credit"
  }
};