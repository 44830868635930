import { FC, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '../../button/Button'
import { trimValue } from '../../../../utils/utils'
import { Level2_CODE, Level2_NAME } from '../../../../constants/constant'
import { addLevl2Validators } from '../../../../form-validators/addLevel2Validators'
import { IaddLevel2Validators } from '../../../../interfaces/interfaces'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  addAsset,
  getAllLevelData,
  updateAsset,
} from '../../../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../../../utils/payloadGenerator'
import Loader from '../../spinner/Loader'
import styles from './Level2Modal.module.scss'
import { setGenerateCode } from '../../../../redux/features/view-chat-of-accounts/accountsSlice'
import {
  ADD_ASSETDATA,
  UPDATE_ASSETDATA,
} from '../../../../constants/asyncActionsType'
interface ILevel2ModalProps {
  handleClose?: any
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  popData?: string | any
  formData?: any
  handleSuccess?: any
}

const Level2Modal: FC<ILevel2ModalProps> = ({
  handleClose,
  popData,
  formData,
  handleSuccess,
}) => {
  const dispatch = useAppDispatch()
  const { dropdownAttempt, getGenerateData, loading } = useAppSelector(
    (state) => state.accounts
  )
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IaddLevel2Validators>({})

  const onSubmit: SubmitHandler<IaddLevel2Validators> = (data) => {
    const init = {
      name: data?.name,
      code: getGenerateData?.code,
      parentLevelTypeId: dropdownAttempt?.id || '1',
      isFinalLevel: false,
      creditOrDebit: '',
      levelId: 2,
      level_one: getGenerateData?.code?.toString().substring(0, 1),
      level_two: getGenerateData?.code?.toString().substring(0, 3),
    }
    if (formData?._id) {
      const editPaylod = {
        id: formData?._id,
        data: init,
      }
      dispatch(updateAsset(requestGenerator(editPaylod))).then((e) => {
        if (e.type === `${UPDATE_ASSETDATA}/fulfilled`) {
          handleSuccess && handleSuccess()
        }
      })
    } else {
      dispatch(addAsset(requestGenerator(init))).then((e) => {
        if (e.type === `${ADD_ASSETDATA}/fulfilled`) {
          handleSuccess && handleSuccess()
        }
      })
    }
    handleClose()
  }
  const handleReset = () => {
    reset()
  }
  useEffect(() => {
    return () => {
      dispatch(setGenerateCode({}))
    }
  }, [])

  useEffect(() => {
    if (formData?.name) {
      setValue(Level2_NAME, formData?.name)
    }
  }, [formData])
  return (
    <>
      {loading && <Loader />}
      <div>
        <h1 className={styles.levelModalHeading}>
          {popData ? `Add ${dropdownAttempt?.name} at Level-2` : ''}
        </h1>
        <hr className={styles.levelDivider} />
        <form className={styles.addLevelForm} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabel}>Code</label>
              <input
                type="text"
                value={getGenerateData?.code}
                disabled
                {...register(Level2_CODE, addLevl2Validators[Level2_CODE])}
                className={styles.inputField}
              />
            </div>
          </div>

          <div className={styles.formFieldContainer}>
            <div className={styles.inputFieldContainer}>
              <label className={styles.formLabels}>
                Name<span className="asterick">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Name"
                className={styles.inputFields}
                {...register(Level2_NAME, addLevl2Validators[Level2_NAME])}
                onChange={(e) => trimValue(e)}
              />
            </div>
            <div className={styles.errorContainer}>
              <span className={styles.extraSpan}></span>
              {errors[Level2_NAME] && (
                <p className="dashboardFormError">
                  {errors[Level2_NAME].message}
                </p>
              )}
            </div>
          </div>
          <div className={styles.formButtonContainer}>
            <Button
              title="Submit"
              type="submit"
              customClass={styles.submitButton}
            />
            <Button
              title="Reset"
              type="reset"
              handleClick={() => handleReset()}
              customClass={styles.resetButton}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default Level2Modal
