import { FC, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import FloatingBar from '../../../components/common/floatingbar/FloatingBar'
import styles from './campaignsLayout.module.scss'
import {
  CampaignsTabData,
  campaignsFloatingBarData,
} from '../../../constants/data'
import Tabs from '../../../components/core/tab/Tabs'

interface ICampaignsLayout {}

const CampaignsLayout: FC<ICampaignsLayout> = () => {
  const navigate = useNavigate()
  const location = useLocation().pathname
  useEffect(() => {
    if (location === '/campaigns') {
      navigate('/campaigns/onGoingCampaigns')
    }
  }, [location])
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.tabContainer}>
          <Tabs tabData={CampaignsTabData} />
          <Outlet />
        </div>
        {/* <div className={styles.floatingBarContainer}>
                    <FloatingBar floatingBarData={campaignsFloatingBarData} />
                </div> */}
      </div>
    </>
  )
}

export default CampaignsLayout
