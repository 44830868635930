import {
  APPOINTMENT_KEY,
  PHYSIO_SLOTS_KEY,
} from '../../../constants/asyncActionsType'
import createAsyncThunkForSlice from '../../../utils/utils'
import { appointmentDataById, physioViewSlotsApi } from './physioCrud'

export const getAllPhysioSlots = createAsyncThunkForSlice(
  PHYSIO_SLOTS_KEY,
  physioViewSlotsApi,
  { isToast: true }
)

export const appointmentDataByIdAsync = createAsyncThunkForSlice(
  APPOINTMENT_KEY,
  appointmentDataById
)
