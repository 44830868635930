import {
  AGENT_NAME,
  AGE_FROM,
  AGE_TO,
  CAMPAIGN_NAME,
  END_DATE,
  EXTERNAL_SOURCE,
  GENDER,
  NATIONALITY,
  NO_OF_LEADS,
  NO_OF_TARGET_AUDIENCE,
  PIN_CODE,
  START_DATE,
  TAGS_SOURCE,
  TARGET_AUDIENCE,
} from './createCampaignsConstant'
import { ICreateCampaignForm } from './createCampaignsInterface'

export const createCampaignsValidators = {
  [CAMPAIGN_NAME]: {
    required: 'Please enter campaign name',
  },

  [START_DATE]: {
    required: 'Please select start date',
  },
  [END_DATE]: {
    required: 'Please select end date',
    validate: {
      handleTime: (value: string, values: ICreateCampaignForm) => {
        const startDate = values[START_DATE]
        // console.log("startDate", startDate);
        if (value <= startDate) {
          return 'End date must be greater than start date'
        }
      },
    },
  },
  // [TARGET_AUDIENCE]: {
  //   required: 'Please enter target audience',
  // },
  [NO_OF_TARGET_AUDIENCE]: {
    // required: 'Please enter no of target audience',
    validate: {
      handleZero: (value: any) => {
        if (value <= 0) {
          return 'Please enter valid no of target audience'
        }
      },
    },
  },
  [GENDER]: {
    required: 'Please select gender',
  },
  [AGE_FROM]: {
    required: 'Please enter age from',
    validate: {
      handleValue: (value: any) => {
        if (Number(value) < 0) {
          return 'Please enter positive value'
        }
      },
    },
  },
  [AGE_TO]: {
    required: 'Please enter age to',
    validate: {
      handleValue: (value: any, data: any) => {
        if (Number(value) < 0) {
          return 'Please enter positive value'
        } else if (Number(value) <= Number(data.age_from)) {
          return 'Age to should greater than Age from'
        }
      },
    },
  },
  [NATIONALITY]: {
    required: 'Please select nationality',
  },
  [PIN_CODE]: {
    required: 'Please enter pincode',
    pattern: {
      value: /^\d{4,10}$/,
      message: 'Please enter valid 4 to 10 digit pin code',
    },
  },
  [TAGS_SOURCE]: {
    required: 'Please enter tags source',
  },
  [EXTERNAL_SOURCE]: {
    required: 'Please enter external source',
  },
}

export const assignAgentValidators = {
  [AGENT_NAME]: {
    required: 'Please select agent',
  },
  [NO_OF_LEADS]: {
    required: 'Please enter no of leads',
    validate: {
      handleValue: (value: any) => {
        if (value <= 0) {
          return 'Please enter positive values'
        }
      },
    },
  },
}
