import {
  INVENTORY_MASTER_NAME,
  INVENTORY_MASTER_CURRENCY,
  INVENTORY_MASTER_CONTACT_NO,
  INVENTORY_MASTER_CONTACT_PERSON,
} from '../constants/constant';

export interface IinventoryMasterTableValidators {
  [INVENTORY_MASTER_NAME]: {
    required: any;
    pattern: {
      value: any;
      message: any;
    };
  };
  [INVENTORY_MASTER_CURRENCY]: {
    required: any;
  };
  [INVENTORY_MASTER_CONTACT_NO]: {
    required: any;
  };
  [INVENTORY_MASTER_CONTACT_PERSON]: {
    required: any;
  };
}

export const inventoryMasterTableValidators: IinventoryMasterTableValidators = {
  [INVENTORY_MASTER_NAME]: {
    required: 'Please enter name',
    pattern: {
      value: /^[a-zA-Z\s]*$/,
      message: 'Please Enter valid name',
    },
  },
  [INVENTORY_MASTER_CURRENCY]: {
    required: 'Please select currency',
  },
  [INVENTORY_MASTER_CONTACT_NO]: {
    required: 'Please enter contact no',
  },
  [INVENTORY_MASTER_CONTACT_PERSON]: {
    required: 'Please enter contact person name',
  },
};
