import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LeftArrowIcon } from "../../components/common/svg-components";

interface IManageMasterTableNewtLayout {}

const ManageMasterTableNewtLayout: FC<IManageMasterTableNewtLayout> = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  useEffect(() => {
    if (location === "/mastertablemanagenew") {
      navigate("/mastertablemanagenew/managemasternew");
    }
  }, [location]);

  const istrue =
    location === "/mastertablemanagenew/managecategoryvalues" ||
    location === "/mastertablemanagenew/manageappoinmentstatus";

  return (
    <>
      {istrue && (
        <Link
          to={"/mastertablemanagenew/managemasternew"}
          style={{
            marginBottom: "20px",
            color: "#0E26A3",
            display: "block",
            fontSize: "18px",
          }}
        >
          <LeftArrowIcon
            fillColor="#0E26A3"
            height={14}
            // customClass={styles.iconStyle}
          />
          Back
        </Link>
      )}
      <Outlet />
    </>
  );
};

export default ManageMasterTableNewtLayout;
