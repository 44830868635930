import moment from 'moment'
import styles from '../table-data/pharmacycustomerinformation.module.scss'
import {
  CalculatorIcon,
  CrossIcon2,
} from '../../components/common/svg-components'
import { colors } from '../color'
import { useAppDispatch } from '../../hooks/index'
import { removeItemOrderData } from '../../redux/features/pharmacy/pharmacySlice'

export const medicinesTableHeaderData: any = [
  {
    Header: 'Product',
    accessor: 'name',
  },
  {
    Header: 'Qty',
    accessor: 'qty',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'DISCOUNT(%)',
    accessor: 'discount',
  },
  {
    Header: 'Expiry Date',
    accessor: 'expiry_date',
    Cell: (props: any) => {
      const expiryDate = props?.row?.expiry_date
      return <>{expiryDate ? expiryDate : '-'}</>
    },
  },
  {
    Header: 'Amount',
    accessor: 'total_amount',
  },
  {
    Header: 'Actions',
    accessor: 'action',
    Cell: (props: any) => {
      const dispatch = useAppDispatch()
      const handleRemoveAddedItem = (item: any) => {
        dispatch(removeItemOrderData(item))
      }
      return (
        <>
          <CrossIcon2
            fillColor1={colors.red1}
            handleClick={() => handleRemoveAddedItem(props?.row?.original)}
          />
        </>
      )
    },
  },
]

export const MedicinesTableDummyData: any = [
  {
    name: 'Aspirin',
    qty: '1',
    sell_price: '$10',
    discount: '0.00',
    expiry_date: '23 jan 2024',
    total_amount: '$10',
  },

  {
    name: 'Aspirin',
    qty: '1',
    sell_price: '$10',
    discount: '0.00',
    expiry_date: '23 jan 2024',
    total_amount: '$10',
  },
]
