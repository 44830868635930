import { DeleteIcon, EditIcon } from '../../../components/common/svg-components'
import { colors } from '../../../constants/color'
import { useAppSelector } from '../../../hooks'
import styles from './vouchers.module.scss'

export const voucherTableHeaderData: any = [
  {
    Header: 'ACCOUNT CODE',
    Cell: (props: any) => {
      return <span>{props?.row?.original?.accounts_data?.code_id ?? '-'}</span>
    },
  },

  {
    Header: 'ACCOUNT NAME',
    Cell: (props: any) => {
      return <span>{props?.row?.original?.accounts_data?.name ?? '-'}</span>
    },
  },
  {
    Header: 'DEBIT',
    accessor: 'debit_amount',
  },
  {
    Header: 'CREDIT',
    accessor: 'credit_amount',
  },
  {
    Header: 'COST CENTER',
    Cell: (props: any) => {
      return (
        <span
          className={styles.voucherCostCenter}
          onClick={() => props.onClick(props?.row?.original)}
        >
          View
        </span>
      )
    },
  },
  {
    Header: 'ENTRY REMARKS',
    Cell: (props: any) => {
      return (
        <span>
          {props?.row?.original?.entry_remarks
            ? props?.row?.original?.entry_remarks
            : '-'}
        </span>
      )
    },
  },
  {
    Header: 'ACTION',
    Cell: (props: any) => {
      const { voucherListData, addVoucherData } = useAppSelector(
        (state) => state.vouchers
      )
      return (
        <div className={styles.voucherTableActions}>
          <DeleteIcon
            fillColor={colors.grey4}
            customClass={styles.deleteIcon}
            handleClick={() => props.onClicks(props?.row?.original?.entry_id)}
          />
          <EditIcon
            fillColor={colors.grey4}
            customClass={styles.deleteIcon}
            handleClick={() => props?.onRowClick(props?.row?.original)}
          />
        </div>
      )
    },
  },
]
export const costCenterTableHeader: any = [
  {
    Header: 'COST CENTER',
    accessor: 'label',
  },
  {
    Header: 'COST CENTER VALUE',
    Cell: (props: any) => {
      const values = props?.row?.original?.values
      return (
        <>
          {values && values?.length > 0
            ? values?.map((item: any, index: number) => {
                return (
                  <p key={`cc-key${index}`}>
                    {index + 1}. {item?.label_values}
                  </p>
                )
              })
            : '-'}
        </>
      )
    },
  },
]
