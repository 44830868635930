// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newsCard_newsMainContainer__BO80Z {
  margin: 10px;
  width: 320px;
}
.newsCard_newsMainContainer__BO80Z .newsCard_imgStyle__AZ0VB {
  height: 200px;
  max-height: 240px;
}
.newsCard_newsMainContainer__BO80Z .newsCard_newsText__zlGDr {
  font-size: var(--font-14);
  font-weight: var(--font-semibold);
  color: var(--black5);
  margin: 10px 0;
}
.newsCard_newsMainContainer__BO80Z .newsCard_newsInfo__qcqfJ {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.newsCard_newsMainContainer__BO80Z .newsCard_newsInfo__qcqfJ .newsCard_personImg__VkQwD {
  height: 45px;
}
.newsCard_newsMainContainer__BO80Z .newsCard_newsInfo__qcqfJ .newsCard_reporterContent__ooPHs {
  margin-left: 10px;
}
.newsCard_newsMainContainer__BO80Z .newsCard_newsInfo__qcqfJ .newsCard_reporterContent__ooPHs .newsCard_nameText__h2lXD {
  font-size: var(--font-12);
  font-weight: var(--font-semibold);
  color: var(--black5);
}
.newsCard_newsMainContainer__BO80Z .newsCard_newsInfo__qcqfJ .newsCard_reporterContent__ooPHs .newsCard_postText__5hyRo {
  font-size: 10px;
  font-weight: var(--font-medium);
  color: var(--grey16);
}`, "",{"version":3,"sources":["webpack://./src/components/common/news-card/newsCard.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;AAAE;EACE,aAAA;EACA,iBAAA;AAEJ;AAAE;EACE,yBAAA;EACA,iCAAA;EACA,oBAAA;EACA,cAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AAEJ;AADI;EACE,YAAA;AAGN;AADI;EACE,iBAAA;AAGN;AAFM;EACE,yBAAA;EACA,iCAAA;EACA,oBAAA;AAIR;AAFM;EACE,eAAA;EACA,+BAAA;EACA,oBAAA;AAIR","sourcesContent":[".newsMainContainer {\n  margin: 10px;\n  width: 320px;\n  .imgStyle {\n    height: 200px;\n    max-height: 240px;\n  }\n  .newsText {\n    font-size: var(--font-14);\n    font-weight: var(--font-semibold);\n    color: var(--black5);\n    margin: 10px 0;\n  }\n  .newsInfo {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    .personImg {\n      height: 45px;\n    }\n    .reporterContent {\n      margin-left: 10px;\n      .nameText {\n        font-size: var(--font-12);\n        font-weight: var(--font-semibold);\n        color: var(--black5);\n      }\n      .postText {\n        font-size: 10px;\n        font-weight: var(--font-medium);\n        color: var(--grey16);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsMainContainer": `newsCard_newsMainContainer__BO80Z`,
	"imgStyle": `newsCard_imgStyle__AZ0VB`,
	"newsText": `newsCard_newsText__zlGDr`,
	"newsInfo": `newsCard_newsInfo__qcqfJ`,
	"personImg": `newsCard_personImg__VkQwD`,
	"reporterContent": `newsCard_reporterContent__ooPHs`,
	"nameText": `newsCard_nameText__h2lXD`,
	"postText": `newsCard_postText__5hyRo`
};
export default ___CSS_LOADER_EXPORT___;
