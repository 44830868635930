import { FC } from "react";
import { CloseIcon, Dashy2Icon } from "../../../../components/common/svg-components";
import { colors } from "../../../../constants/color";
import styles from "./header.module.scss";
import ToggleSwitch from "../../../../components/common/toggle-switch/ToggleSwtich";
interface IHeaderPopup {
  heading?: string;
  message?: string;
  handleClose?: (e?: React.MouseEvent<HTMLElement>) => void;
  popData?: string | any;
}
const HeaderPopup: FC<IHeaderPopup> = ({
  heading,
  message,
  handleClose,
  popData,
}) => {
  return (
    <div className={styles.qrcodeModalContainer}
      onClick={(e) => {
        e.stopPropagation();
      }}  >
      <CloseIcon
        customClass={styles.closeIconStyle}
        fillColor={colors.green1}
        handleClick={() => {
          handleClose && handleClose();
        }} />
      <h1 className={styles.qrcodeModalHeading}>Dashboard Customization</h1>
      <hr className={styles.descriptionDivider} />
      <div className={styles.qrcodeDescriptionMain}>
        <Dashy2Icon />
        <p>DashBoard</p>
      </div>
      <div className={styles.details}>
        <div className="row">
          <div className="col">
            <p>Total Leads</p>
          </div>
          <div className="col">
            <span>On</span>
            <ToggleSwitch />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Assigned</p>
          </div>
          <div className="col">
            <span>On</span>
            <ToggleSwitch />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>On-going  Champaings</p>
          </div>
          <div className="col">
            <span>On</span>
            <ToggleSwitch />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Table</p>
          </div>
          <div className="col">
            <span>On</span>
            <ToggleSwitch />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HeaderPopup;