import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from '../../../components/common/button/Button'
import Level3Modal from '../../../components/common/modal/level3-modal/Level3Modal'
import Pagination from '../../../components/common/pagination/Pagination'
import { useLocation } from 'react-router-dom'
import { level3TableHeader } from '../../../constants/data'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import {
  getAllLevelData,
  getGenerateCode,
} from '../../../redux/features/view-chat-of-accounts/accountsAsynActions'
import { requestGenerator } from '../../../utils/payloadGenerator'
import { removeLastTwoCharacters } from '../../../utils/utils'
import {
  setBackId,
  setGenerateCode,
} from '../../../redux/features/view-chat-of-accounts/accountsSlice'
import { CustomModal } from '../../../components/common/custom-modal/modal'
import styles from './Level-3.module.scss'
import TableV2 from '../../../components/common/table/tableV2/TableV2'

const Level3List = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()

  const [dataPerPage, setDataPerPage] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageIndex, setPageIndex] = useState<number>(1)
  const [showAddModal, setShowAddModal] = useState<boolean>(false)
  const [editAddData, setEditAddData] = useState<any>({})
  const [popupData, setPopupdata] = useState<any>({})

  const { getAllData, addAttributeData, backLevelId } = useAppSelector(
    (state) => state.accounts
  )
  const pageIndexArray = () => {
    let pageIndexOptions = []
    for (let i = 1; i <= totalPage; i++) {
      pageIndexOptions.push(i)
    }
    return pageIndexOptions
  }
  const pageIndexOptions = pageIndexArray()
  const generateCode = () => {
    const level3ID = {
      levelId: location?.state?.levelID?.level_Id,
      parentLevelTypeId: location?.state?.levelID?.parent_Level_Type_Id,
    }
    dispatch(getGenerateCode(requestGenerator(level3ID)))
    setShowAddModal((prevState) => !prevState)
    setEditAddData({})
  }
  useEffect(() => {
    const level3ID = {
      level_Id: location?.state?.levelID?.level_Id,
      parent_Level_Type_Id: location?.state?.levelID?.parent_Level_Type_Id,
      page: pageIndex,
      pageSize: dataPerPage,
    }
    dispatch(getAllLevelData(requestGenerator(level3ID))).then((result) =>
      setTotalPage(result.payload.lastPage)
    )
  }, [dispatch, dataPerPage, pageIndex])

  const getLevel3RawData = (itemId: any, item: any) => {
    const levelID = {
      level_Id: 4,
      parent_Level_Type_Id: item.code,
    }

    dispatch(setBackId(item.code))
    if (item.isFinalLevel === false) {
      navigate('/viewchartofaccounts/level4list', {
        state: {
          levelID,
          path: `${location?.state?.path} > ${levelID?.parent_Level_Type_Id}`,
          backPath: `${location?.state?.path}`,
        },
      })
    }
  }
  return (
    <>
      {/*  Popup Component */}
      <CustomModal
        showModal={showAddModal}
        closeModal={() => {
          setPopupdata({})
          setShowAddModal(false)
        }}
      >
        <Level3Modal
          popData={editAddData}
          formData={popupData}
          handleClose={() => {
            setPopupdata({})
            setShowAddModal(false)
          }}
          handleSuccess={() => {
            setPopupdata({})
            const level3ID = {
              level_Id: location?.state?.levelID?.level_Id,
              parent_Level_Type_Id:
                location?.state?.levelID?.parent_Level_Type_Id,
              page: pageIndex,
              pageSize: dataPerPage,
            }
            dispatch(getAllLevelData(requestGenerator(level3ID))).then(
              (result) => setTotalPage(result.payload.lastPage)
            )
          }}
        />
      </CustomModal>
      <div className={styles.level3Container}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            {addAttributeData?.name}
            {' > '}
            <span className={styles.path}>{location?.state?.path}</span>
          </h1>

          <Button
            title={'Add'}
            customClass={styles.addBtn}
            type="button"
            handleClick={generateCode}
          />
        </div>
        <>
          <TableV2
            tableHeaderData={level3TableHeader}
            tableRowData={getAllData?.length > 0 ? getAllData : []}
            handleRow={getLevel3RawData}
            handleClick={(data: any) => {
              dispatch(setGenerateCode({ code: data?.code }))
              setShowAddModal((prevState) => !prevState)
              setPopupdata(data)
            }}
          />
        </>
        {getAllData?.length > 0 && (
          <Pagination
            setDataPerPage={setDataPerPage}
            pageIndexOptions={pageIndexOptions}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            pageSize={dataPerPage}
          />
        )}
        <div className={styles.btnContainer}>
          <Button
            title="Back"
            type="button"
            handleClick={() => {
              const levelID = {
                level_Id: 2,
                parent_Level_Type_Id: removeLastTwoCharacters(backLevelId),
              }
              navigate('/viewchartofaccounts/level2list', {
                state: {
                  levelID,
                  path: location?.state?.backPath,
                },
              })
            }}
            customClass={styles.backBtn}
          />
        </div>
      </div>
    </>
  )
}
export default Level3List
