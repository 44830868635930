import styles from './lead.module.scss'
import moment from 'moment'

export const LeadHeader: any[] = [
  {
    Header: 'SR NO',
    accessor: 'sr_no',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.sr_no ? row?.original?.sr_no : '-'}</p>
    },
  },
  {
    Header: 'ADDED ON',
    accessor: 'createdAt',
    Cell: ({ row }: any) => {
      const originalDate = row?.original?.createdAt
      const formattedDate = moment(originalDate).format('DD MMM YYYY')
      return <>{formattedDate}</>
    },
  },
  {
    Header: 'LEAD NAME',
    accessor: 'name',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.name?.length > 0 ? row?.original?.name : '-'}</p>
      )
    },
  },
  {
    Header: 'CONTACT NO',
    accessor: 'phone',
  },
  {
    Header: 'GENDER',
    accessor: 'gender',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.gender?.length > 0 ? row?.original?.gender : '-'}</p>
      )
    },
  },
  {
    Header: 'NATIONALITY',
    accessor: 'nationality',
    Cell: ({ row }: any) => {
      return (
        <p>
          {row?.original?.nationality?.length > 0
            ? row?.original?.nationality
            : '-'}
        </p>
      )
    },
  },
  {
    Header: 'AGE',
    accessor: 'age',
    Cell: ({ row }: any) => {
      return <p>{row?.original?.age ? row?.original?.age : '-'}</p>
    },
  },
  {
    Header: 'SOURCE',
    accessor: 'type',
    Cell: ({ row }: any) => {
      return (
        <p>{row?.original?.type?.length > 0 ? row?.original?.type : '-'}</p>
      )
    },
  },
  {
    Header: 'STATUS',
    Cell: ({ row }: any) => {
      return (
        <>
          <p
            className={
              row?.original?.is_assigned ? styles.greenStyle : styles.redStyle
            }
          >
            {' '}
            {row?.original?.is_assigned ? 'Assigned' : 'Unassigned'}
          </p>
        </>
      )
    },
  },
]
export const LeadRow: any[] = [
  {
    srno: '#5689',
    addedon: '23 Nov 2023',
    leadname: 'John Smith',
    contactno: '98795135',
    gender: 'Male',
    nationality: 'Kuwait',
    age: '40',
    source: 'External',
  },
  {
    srno: '#5689',
    addedon: '23 Nov 2023',
    leadname: 'John Smith',
    contactno: '98795135',
    gender: 'Male',
    nationality: 'Kuwait',
    age: '40',
    source: 'External',
  },
  {
    srno: '#5689',
    addedon: '23 Nov 2023',
    leadname: 'John Smith',
    contactno: '98795135',
    gender: 'Male',
    nationality: 'Kuwait',
    age: '40',
    source: 'External',
  },
  {
    srno: '#5689',
    addedon: '23 Nov 2023',
    leadname: 'John Smith',
    contactno: '98795135',
    gender: 'Male',
    nationality: 'Kuwait',
    age: '40',
    source: 'External',
  },
]
