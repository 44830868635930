import { createSlice } from '@reduxjs/toolkit'
import { IIpdInvoice } from '../../../interfaces/apiInterface'
import {
  createIpdNewInvoice,
  getAllInventoryItems,
  getAllIpdPatientsList,
  getIpdInvoiceAllJobs,
  getIpdInvoiceCaseDetail,
  getIpdInvoiceDetails,
  getIpdInvoiceTreatment,
  getPatientProfilePic,
  updatePatientIpdTreatment,
} from './ipdAsyncActions'

export const initialState: IIpdInvoice = {
  patientList: [],
  invoiceAmountDetails: {},
  patientCaseDetails: [],
  isLoading: false,
  patientTreatmentList: [],
  patientIpdInfo: [],
  patientAllJobList: [],
  patientChargesList: [],
  primaryAllJobList: [],
  primaryTreatmentList: [],
  primaryChargesList: [],
  chargesFilterList: [],
  allInventoryItems: [],
  consumableServiceList: [],
  consumableFilterList: [],
  addTestText: [],
  patientServicesList: [],
  addedNewServicesList: [],
  invoicePaymemntDetails: [],
  patientPaymentModeList: [],
  patientProfileImg: [],
  treatmentBillStatusList: [],
  selectedPatientRowData: {},
  ipdLoading: false,
}

export const ipdInvoiceSlice = createSlice({
  name: 'ipdInvoice',
  initialState,
  reducers: {
    patientIpdInfoData: (state, action) => {
      state.patientIpdInfo = action.payload
      console.log(state.patientIpdInfo, 'state.patientIpdInfo')
    },

    // setAllJobsListData: (state, action) => {
    //     state.primaryAllJobList = action?.payload?.flatMap(({ profile, createdAt }: any) => profile?.map((x: any) => ({ ...x, createdAt })))?.flatMap(({ tests, createdAt }: any) => tests?.map((y: any) =>
    //     ({
    //         ...y, createdAt,
    //         date: createdAt,
    //         id: y?.test_no,
    //         name: y?.test_name,
    //         isBillable: y?.is_billable,
    //         price: y?.price,
    //         notes: y?.test_notes,
    //         type: "job_item"
    //     }
    //     )));

    //     // const removeDuplicates = [...new Set(state.primaryAllJobList)];

    //     // console.log(state.primaryAllJobList, "state.primaryAllJobList")
    //     state.primaryChargesList = [...state.primaryChargesList, ...state.primaryAllJobList];
    //     state.chargesFilterList = [...state.primaryChargesList, ...state.primaryAllJobList];
    // },

    // setTreatmentServiceData: (state, action) => {
    //     state.primaryTreatmentList = action.payload?.map((row: any) => {
    //         console.log()
    //         return {
    //             ...row,
    //             date: row?.createdAt,
    //             id: row?.sessionId,
    //             name: row?.service_id?.name,
    //             isBillable: row?.billable,
    //             price: row?.price,
    //             type: "treatment_item"
    //         }
    //     });
    //     state.primaryChargesList = [...state.primaryChargesList, ...state.primaryTreatmentList]
    //     state.chargesFilterList = [...state.primaryChargesList, ...state.primaryTreatmentList]

    // },

    setTestAddText: (state, action) => {
      const index = state.addTestText.indexOf(action.payload)
      if (index > -1) {
        state.addTestText.splice(index, 1)
      } else {
        state.addTestText.push(action.payload)
      }
    },

    // Add Item functions
    handleAddedItems: (state: any, action: any) => {
      state.consumableServiceList = [
        ...state?.consumableServiceList,
        action.payload,
      ]
      // console.log(state.consumableServiceList, ' Add.consumableServiceList');

      state.consumableFilterList = [
        ...state?.consumableFilterList,
        action.payload,
      ]
    },

    // Remove Added Item functions
    handleRemoveAddedItem: (state: any, action: any) => {
      // console.log(action.payload, "consumableServiceList payload")
      state.consumableServiceList = state.consumableServiceList.filter(
        (item: any) => {
          return item._id !== action.payload
        }
      )

      state.consumableFilterList = state.consumableFilterList.filter(
        (item: any) => {
          return item._id !== action.payload
        }
      )

      // console.log(state.consumableServiceList, ' Remove.consumableServiceList')
    },

    setServicesData: (state: any, action) => {
      state.patientServicesList = [
        ...state?.consumableServiceList,
        ...action.payload,
      ]
    },

    // Add New Services

    handleAddedNewServices: (state: any, action: any) => {
      state.patientServicesList = [
        ...state?.patientServicesList,
        action.payload,
      ]

      state.addedNewServicesList = [
        ...state?.addedNewServicesList,
        action.payload,
      ]
    },

    // Remove Added Service functions
    handleRemoveAddedNewService: (state: any, action: any) => {
      state.patientServicesList = state.patientServicesList.filter(
        (item: any) => {
          return item._id !== action.payload
        }
      )

      state.addedNewServicesList = state.addedNewServicesList.filter(
        (item: any) => {
          return item._id !== action.payload
        }
      )
    },

    // Save Paymemnt Mode
    handlePaymentModeList: (state: any, action: any) => {
      if (state.patientPaymentModeList?.length > 0) {
        if (
          !state.patientPaymentModeList?.some(
            (item: any) => item?._id === action.payload._id
          )
        ) {
          state.patientPaymentModeList = [
            ...state.patientPaymentModeList,
            action.payload,
          ]
        }
      } else {
        state.patientPaymentModeList = [
          ...state.patientPaymentModeList,
          action.payload,
        ]
      }
    },

    updatedNewPaymentAmountArray: (state, action) => {
      state.patientPaymentModeList = action.payload
    },

    setUpayAmountData: (state: any, action: any) => {
      if (state.patientPaymentModeList?.length > 0) {
        const findUpayIndex = state.patientPaymentModeList?.findIndex(
          (item: any) => item?._id === action.payload._id
        )
        if (findUpayIndex >= 0) {
          state.patientPaymentModeList[findUpayIndex] = action.payload
        } else {
          state.patientPaymentModeList = [
            ...state.patientPaymentModeList,
            action.payload,
          ]
        }
      } else {
        state.patientPaymentModeList = [
          ...state.patientPaymentModeList,
          action.payload,
        ]
      }
    },

    clearIpdInvoiceData: (state: any) => {
      state.patientList = []
      state.invoiceAmountDetails = {}
      state.patientCaseDetails = []
      // state.isLoading = false
      state.patientTreatmentList = []
      state.patientIpdInfo = []
      state.patientAllJobList = []
      state.primaryAllJobList = []
      state.primaryTreatmentList = []
      state.primaryChargesList = []
      state.allInventoryItems = []
      state.consumableServiceList = []
      state.addTestText = []
      state.patientServicesList = []
      state.addedNewServicesList = []
      state.invoicePaymemntDetails = []
      state.patientPaymentModeList = []
      state.patientProfileImg = []
      state.treatmentBillStatusList = []
      state.chargesFilterList = []
      state.consumableFilterList = []
      state.selectedPatientRowData = {}
    },

    // Filter Consumable List
    setFilteredConsumableList: (state, action) => {
      if (action.payload === 'Billable') {
        state.consumableFilterList = state.consumableServiceList.filter(
          (item: any) => {
            return item?.isBillable === 'Yes'
          }
        )
      } else if (action.payload === 'Non-billable') {
        state.consumableFilterList = state.consumableServiceList.filter(
          (item: any) => {
            return item?.isBillable === 'No'
          }
        )
      } else {
        state.consumableFilterList = state.consumableServiceList
      }
    },

    //Filter Charges List
    setFilteredChargesList: (state, action) => {
      if (action.payload === 'Billable') {
        state.chargesFilterList = state.primaryChargesList.filter(
          (item: any) => {
            return item?.isBillable == true
          }
        )
      } else if (action.payload === 'Non-billable') {
        state.chargesFilterList = state.primaryChargesList.filter(
          (item: any) => {
            return item?.isBillable == false
          }
        )
      } else {
        state.chargesFilterList = state.primaryChargesList.filter(
          (item: any) => {
            return item?.isBillable == false || item?.isBillable === true
          }
        )
      }
    },

    setPatientProfileImg: (state, action) => {
      state.patientProfileImg = action.payload
    },

    setAddedNewServiceList: (state, action) => {
      state.addedNewServicesList = action.payload
    },

    // Update Billable Status
    updatedTreatmentBillStatus: (state, action) => {
      state.treatmentBillStatusList = [
        ...state.treatmentBillStatusList,
        action.payload,
      ]
    },

    // Set Selected Patient Data in Create Case

    setSelectedPatientData: (state, action) => {
      state.selectedPatientRowData = action.payload
    },

    setChargesList: (state, action) => {
      state.primaryChargesList = action.payload
      state.chargesFilterList = action.payload
      console.log(state.primaryChargesList, 'state.primaryChargesList')
    },
    setIPDInvoiceLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },

  extraReducers: (builder) => {
    // GET All Patient List

    builder.addCase(getAllIpdPatientsList.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getAllIpdPatientsList.fulfilled, (state, action) => {
      state.isLoading = false
      console.log('action.payload', action.payload)
      state.patientList =
        action.payload && action.payload?.data?.length > 0 ? action.payload : []
    })
    builder.addCase(getAllIpdPatientsList.rejected, (state, error) => {
      state.isLoading = false
      // state.isLoading = false
    })

    // GET PATIENT INVOICE DETAILS

    builder.addCase(getIpdInvoiceDetails.pending, (state, action) => {})

    builder.addCase(getIpdInvoiceDetails.fulfilled, (state, action) => {
      console.log(action.payload, 'invoiceAmountDetails payload')
      state.invoiceAmountDetails = action.payload
    })
    builder.addCase(getIpdInvoiceDetails.rejected, (state, error) => {
      // state.isLoading = false
    })

    // GET PATIENT INVOICE CASE DETAILS

    builder.addCase(getIpdInvoiceCaseDetail.pending, (state, action) => {})

    builder.addCase(getIpdInvoiceCaseDetail.fulfilled, (state, action) => {
      console.log(action.payload, 'patientCaseDetails payload')
      state.patientCaseDetails = action.payload
    })
    builder.addCase(getIpdInvoiceCaseDetail.rejected, (state, error) => {
      // state.isLoading = false
    })

    // GET PATIENT INVOICE TREATMENT DETAILS

    builder.addCase(getIpdInvoiceTreatment.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getIpdInvoiceTreatment.fulfilled, (state, action) => {
      state.isLoading = false
      state.patientTreatmentList = action.payload
    })
    builder.addCase(getIpdInvoiceTreatment.rejected, (state, error) => {
      state.isLoading = false
    })

    // GET PATIENT INVOICE ALL JOBS

    builder.addCase(getIpdInvoiceAllJobs.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getIpdInvoiceAllJobs.fulfilled, (state, action) => {
      state.isLoading = false
      state.patientAllJobList = action.payload.data ?? []
    })
    builder.addCase(getIpdInvoiceAllJobs.rejected, (state, error) => {
      state.isLoading = false
    })

    // GET ALL INVENTORY ITEMS

    builder.addCase(getAllInventoryItems.pending, (state, action) => {
      state.isLoading = true
    })

    builder.addCase(getAllInventoryItems.fulfilled, (state, action) => {
      state.allInventoryItems = action.payload.data ?? []
      state.isLoading = false
    })
    builder.addCase(getAllInventoryItems.rejected, (state, error) => {
      state.isLoading = false
    })

    // CREATE INVOICE

    builder.addCase(createIpdNewInvoice.pending, (state, action) => {
      state.ipdLoading = true
    })

    builder.addCase(createIpdNewInvoice.fulfilled, (state, action) => {
      state.ipdLoading = false
      state.invoicePaymemntDetails = action.payload
    })
    builder.addCase(createIpdNewInvoice.rejected, (state, error) => {
      // state.isLoading = false
      state.ipdLoading = false
    })

    // GET PATIENT PROFILE PIC

    builder.addCase(getPatientProfilePic.pending, (state, action) => {})

    builder.addCase(getPatientProfilePic.fulfilled, (state, action) => {
      state.patientProfileImg = action.payload
    })
    builder.addCase(getPatientProfilePic.rejected, (state, error) => {
      // state.isLoading = false
    })

    // UPDATE TREATMENT BILL STATUS

    builder.addCase(updatePatientIpdTreatment.pending, (state, action) => {})

    builder.addCase(updatePatientIpdTreatment.fulfilled, (state, action) => {})
    builder.addCase(updatePatientIpdTreatment.rejected, (state, error) => {
      // state.isLoading = false
    })
  },
})

export const {
  patientIpdInfoData,
  // setAllJobsListData,
  // setTreatmentServiceData,
  setTestAddText,
  handleAddedItems,
  handleRemoveAddedItem,
  setServicesData,
  handleAddedNewServices,
  handleRemoveAddedNewService,
  handlePaymentModeList,
  updatedNewPaymentAmountArray,
  setUpayAmountData,
  clearIpdInvoiceData,
  setFilteredConsumableList,
  setFilteredChargesList,
  updatedTreatmentBillStatus,
  setSelectedPatientData,
  setPatientProfileImg,
  setChargesList,
  setAddedNewServiceList,
  setIPDInvoiceLoading,
} = ipdInvoiceSlice.actions
export default ipdInvoiceSlice.reducer
